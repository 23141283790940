import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { TaxRateData } from '../../types';

interface GetTaxRatesParams {
  params?: GetListingParams;
}
export const getTaxRates = async ({ params }: GetTaxRatesParams) => {
  const { data } = await Api.get<ListingResponse<TaxRateData>>(
    '/api/taxRates',
    {
      params,
    }
  );

  return data;
};
