import { useCallback, useMemo, useReducer } from 'react';

type Base<T> = {
  [key: string]: (...args: never[]) => T;
};

export type IUseMethodsCreate<T, M extends Base<T>> = (state: T) => {
  [P in keyof M]: (...args: Parameters<M[P]>) => T;
};

export type IUseMethodsWrapped<
  T,
  M extends Base<T>,
  C extends IUseMethodsCreate<T, M> = IUseMethodsCreate<T, M>,
> = {
  [P in keyof ReturnType<C>]: (...args: Parameters<ReturnType<C>[P]>) => void;
};

export type IUseMethodsAction<T, M extends Base<T>, P extends keyof M> = {
  type: P;
  payload: Parameters<M[P]>;
};

export type IUseMethodsReturn<
  T,
  M extends Base<T>,
  C extends IUseMethodsCreate<T, M> = IUseMethodsCreate<T, M>,
  W extends IUseMethodsWrapped<T, M, C> = IUseMethodsWrapped<T, M, C>,
> = [state: T, methods: W];

/**
 * A neater `useReducer` alternative that uses seperate methods instead of
 * a single dispatch function with actions.
 *
 * @param createMethods A function that creates the methods reducer object.
 *  The methods returned should take the previous state supplied to
 * createMethods and their own arguments to return the new state
 * @param initialState The initial state.
 * @returns A tupple in the form of `[state, methods]`.
 * `methods` will have the same structure and arguments as the ones returned
 * from `createMethods`, but won't return anything, only modify the state.
 */
export const useMethods = <
  T,
  M extends Base<T>,
  C extends IUseMethodsCreate<T, M> = IUseMethodsCreate<T, M>,
  W extends IUseMethodsWrapped<T, M, C> = IUseMethodsWrapped<T, M, C>,
>(
  createMethods: C,
  initialState: T
): IUseMethodsReturn<T, M, C, W> => {
  const reducer = useCallback(
    (reducerState: T, action: IUseMethodsAction<T, M, keyof M>) => {
      const methods = createMethods(reducerState);
      const method = methods[action.type];

      return method(...action.payload);
    },
    [createMethods]
  );

  const [state, dispatch] = useReducer(reducer, initialState);

  const wrappedMethods = useMemo(() => {
    const actionTypes = Object.keys(createMethods(initialState)) as (keyof M)[];

    return actionTypes.reduce(
      <P extends keyof M>(acc: W, type: P): W => ({
        ...acc,
        [type]: (...payload: Parameters<M[P]>) => dispatch({ type, payload }),
      }),
      {} as W
    );
  }, [createMethods, initialState]);

  return [state, wrappedMethods];
};
