type AsyncFunc<ARGT extends Array<unknown>, RETT> = (
  ...args: ARGT
) => Promise<RETT>;

/**
 * Takes an async function `fn` and wraps it so that executing it
 * again while it's waiting to resolve returns the same Promise
 *
 * @param fn The async function to execute
 * @returns An async function with the same signature as `fn`
 */
export default function reusePromise<ARGT extends Array<unknown>, RETT>(
  fn: AsyncFunc<ARGT, RETT>
): AsyncFunc<ARGT, RETT> {
  let currentlyExecutingPromise: Promise<RETT> | null = null;

  return async (...args: ARGT): Promise<RETT> => {
    if (currentlyExecutingPromise) {
      return currentlyExecutingPromise;
    }

    const promise = fn(...args);
    currentlyExecutingPromise = promise;
    const retValue = await promise;
    currentlyExecutingPromise = null;

    return retValue;
  };
}
