import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { Select } from 'chakra-react-select';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { WithChange } from '@bq/components/SettingsCRUD';

import { TicketStateSelect } from '../../TicketingForm/State/TicketStateSelect';
import { MailSelect } from './MailSelect/MailSelect';
import { MailTypeSelect } from './MailTypeSelect/MailTypeSelect';
import { TicketGroupSettings } from './types';
import { TypeSelect } from './TypeSelect/TypeSelect';

export const TicketingGroupFormFields = () => {
  const { t } = useTranslation('');
  const { register, control } =
    useFormContext<WithChange<TicketGroupSettings>>();
  const {
    field: defaultTicketType,
    fieldState: { error },
  } = useController<WithChange<TicketGroupSettings>, 'defaultTicketType'>({
    control,
    name: 'defaultTicketType',
    rules: {
      required: {
        value: true,
        message: t('Ticketing:error.default_ticket_type_required'),
      },
    },
  });
  const { field: defaultEmail } = useController<
    WithChange<TicketGroupSettings>,
    'defaultEmailAccountID'
  >({
    control,
    name: 'defaultEmailAccountID',
  });
  const { field: mailSubjectFormat } = useController<
    WithChange<TicketGroupSettings>,
    'mailSubjectFormat'
  >({
    control,
    name: 'mailSubjectFormat',
  });
  const { field: assignMode } = useController<
    WithChange<TicketGroupSettings>,
    'autoAssignMode'
  >({
    control,
    name: 'autoAssignMode',
  });
  const { field: stateAfterAgentReply } = useController<
    WithChange<TicketGroupSettings>,
    'stateAfterAgentReply'
  >({ control, name: 'stateAfterAgentReply' });

  const { field: stateAfterCustomerReply } = useController<
    WithChange<TicketGroupSettings>,
    'stateAfterCustomerReply'
  >({ control, name: 'stateAfterCustomerReply' });

  return (
    <VStack>
      <FormControl>
        <FormLabel>{t('global:name')}</FormLabel>
        <Input {...register('name')} size="sm"></Input>
      </FormControl>
      <FormControl>
        <FormLabel>{t('Ticketing:groupsForm.default_mail')}</FormLabel>
        <MailSelect
          onChange={(newValue) => {
            defaultEmail.onChange(newValue?.value ?? null);
          }}
          value={defaultEmail.value}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Ticketing:groupsForm.mail_subject_format')}</FormLabel>
        <MailTypeSelect
          onChange={(newValue) => {
            mailSubjectFormat.onChange(newValue);
          }}
          value={mailSubjectFormat.value}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Ticketing:groupsForm.auto_mail')}</FormLabel>
        <Checkbox {...register('autoMailToTicket')} />
      </FormControl>
      <FormControl isInvalid={!!error}>
        <FormLabel>{t('Ticketing:groupsForm.default_ticket_type')}</FormLabel>
        <TypeSelect
          onChange={(newValue) => {
            defaultTicketType.onChange(
              newValue
                ? {
                    name: newValue.label,
                    ID: newValue.value,
                  }
                : null
            );
          }}
          value={
            defaultTicketType.value
              ? {
                  label: defaultTicketType.value.name,
                  value: defaultTicketType.value.ID,
                }
              : null
          }
        />
        {error && <FormErrorMessage>{error?.message}</FormErrorMessage>}
      </FormControl>
      <FormControl>
        <FormLabel>{t('Ticketing:groupsForm.assign_mode')}</FormLabel>
        <Select
          selectedOptionColorScheme="brand"
          useBasicStyles
          size="sm"
          isClearable
          options={[
            {
              value: 'roundRobin',
              label: 'Round Robin',
            },
          ]}
          value={
            assignMode.value
              ? { value: assignMode.value, label: assignMode.value }
              : null
          }
          onChange={(newVal) => {
            assignMode.onChange(newVal?.value ?? null);
          }}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Ticketing:groupsForm.assign_on_update')}</FormLabel>
        <Checkbox {...register('assignOnUpdate')} />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Ticketing:form.state_after_agent_reply')}</FormLabel>
        <TicketStateSelect
          isClearable
          onChange={(newValue) => {
            stateAfterAgentReply.onChange(newValue?.value ?? null);
          }}
          value={stateAfterAgentReply.value}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Ticketing:form.state_after_customer_reply')}</FormLabel>
        <TicketStateSelect
          isClearable
          onChange={(newValue) => {
            stateAfterCustomerReply.onChange(newValue?.value ?? null);
          }}
          value={stateAfterCustomerReply.value}
        />
      </FormControl>
    </VStack>
  );
};
