import { useMemo } from 'react';

import { Column, ColumnMap } from './types';

export function findVisibleColumnsArr<COLT>(
  columns: Column<COLT>[],
  visibleColumns: string[]
): Column<COLT>[] {
  return columns.filter(
    (col) => col.title === '' || visibleColumns.includes(col.key)
  );
}

export function findVisibleColumns<COLT>(
  columns: Column<COLT>[],
  visibleColumns: ColumnMap
): Column<COLT>[] {
  return columns.filter((col) => {
    if (!col.title || col.alwaysVisible) {
      return true;
    }

    return visibleColumns[col.key] ?? col.defaultVisible ?? true;
  });
}

export function findDefaultVisibleColumns<COLT>(
  columns: Column<COLT>[]
): string[] {
  return columns
    .filter((col) => col.defaultVisible !== false)
    .map(({ key }) => key);
}

type ColumnList<COLT> = Column<COLT>[] | (() => Column<COLT>[]);
export function useVisibleColumns<COLT>(
  columns: ColumnList<COLT>,
  visible: ColumnMap
): Column<COLT>[] {
  return useMemo(() => {
    const colList = typeof columns === 'function' ? columns() : columns;

    return findVisibleColumns<COLT>(colList, visible);
  }, [visible, columns]);
}
