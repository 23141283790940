import { createContext, useContext } from 'react';

import { IDependenciesContext } from './types';

export const DependenciesContext = createContext<IDependenciesContext | null>(
  null
);

export function useDependencies(): IDependenciesContext {
  const ctx = useContext(DependenciesContext);
  if (!ctx) {
    throw new Error('Missing DependenciesContext');
  }

  return ctx;
}
