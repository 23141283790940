import { ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { PopoverLink } from 'BootQuery/Assets/components/PopoverTrigger';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

import { Address as IAddress } from '../../js/types';

interface Props {
  label: string;
  addresses: IAddress[];
}

export const Addresses = ({ label, addresses }: Props): ReactElement => (
  <Flex flexWrap="nowrap">
    <Text as="span">{label}: </Text>&nbsp;
    <Flex flexWrap="wrap">
      <WithSeparator separator=",&nbsp;">
        {addresses.map(({ email, contact }) => (
          <PopoverLink
            key={email}
            as="a"
            href={`mailto:${email}`}
            title={email}
          >
            {contact?.name ?? email}
          </PopoverLink>
        ))}
      </WithSeparator>
    </Flex>
  </Flex>
);
