import { UniqueIdentifier } from '@dnd-kit/core';

import { uniqid } from 'app/assets/js/tsutil';

import { PartialOnly } from '../type-util';
import {
  DndItem,
  FindItemResult,
  ModifyFunc,
  MoveTarget,
  ReplaceItem,
} from './types';

export function findPath<C, I extends DndItem<C> = DndItem<C>>(
  items: I[],
  itemId: UniqueIdentifier
): UniqueIdentifier[] | null {
  return findItem(items, itemId)?.path ?? null;
}

export function findItem<C, I extends DndItem<C> = DndItem<C>>(
  items: I[],
  itemId: UniqueIdentifier,
  parentPath: UniqueIdentifier[] = []
): FindItemResult<I> | null {
  for (let i = 0; i < items.length; ++i) {
    const item = items[i];
    if (itemId === item.id) {
      return { path: parentPath, item, position: i };
    }
    if (item.hasChildren) {
      const fromChildren = findItem<C, I>(item.children as I[], itemId);
      if (fromChildren) {
        return {
          ...fromChildren,
          path: [...parentPath, item.id, ...fromChildren.path],
        };
      }
    }
  }

  return null;
}

export function modifyChildrenAt<C, I extends DndItem<C> = DndItem<C>>(
  items: I[],
  path: UniqueIdentifier[],
  modify: ModifyFunc<C, I>
): I[] {
  const [currentPath, ...restOfPath] = path;

  if (!currentPath) {
    // Got to part of path we're searching for
    return modify(items);
  }

  return items.map((item) => {
    if (item.id === currentPath) {
      if (!item.hasChildren) {
        throw new Error(
          `Tried to modify children of childless item "${item.id}"`
        );
      }

      return {
        ...item,
        children: modifyChildrenAt<C, I>(
          item.children as I[],
          restOfPath,
          modify
        ),
      };
    }

    return item;
  });
}

export function addItem<C, I extends DndItem<C> = DndItem<C>>(
  items: I[],
  newItem: PartialOnly<I, 'id'>,
  to?: MoveTarget
): I[] {
  const targetContainer = to?.container ?? null;
  const position = to?.position ?? null;
  const item: I = newItem.id
    ? (newItem as I)
    : ({ ...newItem, id: uniqid() } as I);

  const parentPath = targetContainer ? findPath(items, targetContainer) : [];
  if (!parentPath) {
    throw new Error(
      `Tried to add item to non-existent container "${targetContainer}"`
    );
  }

  const path = targetContainer ? [...parentPath, targetContainer] : parentPath;

  return modifyChildrenAt(items, path, (items) => {
    if (items.some((existing) => existing.id === item.id)) {
      throw new Error(
        `Attempted to add DND item "${item.id}" to container "${
          targetContainer ?? ''
        }" that already contains it`
      );
    }

    if (position === null) {
      return [...items, item];
    }

    return [...items.slice(0, position), item, ...items.slice(position)];
  });
}

export function removeItem<C, I extends DndItem<C> = DndItem<C>>(
  items: I[],
  itemId: UniqueIdentifier
): I[] {
  const parentPath = findPath(items, itemId);
  if (!parentPath) {
    throw new Error(`Tried to remove non-existent item ${itemId}`);
  }

  return modifyChildrenAt(items, parentPath, (items) => {
    return items.filter((item) => item.id !== itemId);
  });
}

export function moveItem<C, I extends DndItem<C> = DndItem<C>>(
  items: I[],
  itemId: UniqueIdentifier,
  target: MoveTarget
): I[] {
  const found = findItem(items, itemId);
  if (!found) {
    throw new Error(`Tried to move non-existent item ${itemId}`);
  }
  const { item, path: currentPath } = found;

  const targetPath = target.container
    ? findPath(items, target.container)
    : currentPath;
  if (!targetPath) {
    throw new Error(`Tried to move item to non-existent target ${targetPath}`);
  }
  if (target.container) {
    targetPath.push(target.container);
  }

  if (pathsEqual(currentPath, targetPath)) {
    const newPos = target.position;
    if (typeof newPos !== 'number') {
      throw new Error('Tried to move item without new position');
    }

    return modifyChildrenAt(items, currentPath, (items) => {
      return moveWithinArray(items, itemId, newPos);
    });
  }

  return addItem(removeItem(items, itemId), item, target);
}

export function replaceItem<C, I extends DndItem<C> = DndItem<C>>(
  items: I[],
  itemId: UniqueIdentifier,
  replace: ReplaceItem<C, I>
): I[] {
  const item = findItem(items, itemId);
  if (!item) {
    throw new Error(`Unable to find item to replace "${itemId}"`);
  }

  return modifyChildrenAt(items, item.path, (prev) => {
    return prev.map((item) => {
      if (item.id === itemId) {
        return typeof replace === 'function' ? replace(item) : replace;
      }

      return item;
    });
  });
}

type Primitive = string | number | null | boolean | bigint | undefined | symbol;
export function pathsEqual<T extends Primitive>(a1: T[], a2: T[]): boolean {
  if (a1.length !== a2.length) {
    return false;
  }

  for (let i = 0; i < a1.length; ++i) {
    if (a1[i] !== a2[i]) {
      return false;
    }
  }

  return true;
}

export function moveWithinArray<C, I extends DndItem<C> = DndItem<C>>(
  items: I[],
  itemId: UniqueIdentifier,
  toPos: number
): I[] {
  if (toPos > items.length - 1) {
    throw new Error('Tried to move item outside of array bounds');
  }
  const fromPos = items.findIndex((item) => item.id === itemId);
  if (fromPos === -1) {
    throw new Error(`Item ${itemId} not found`);
  }
  if (fromPos === toPos) {
    return items;
  }

  const newArray = [...items];

  const target = newArray[fromPos];
  const inc = toPos < fromPos ? -1 : 1;

  for (let i = fromPos; i !== toPos; i += inc) {
    newArray[i] = newArray[i + inc];
  }

  newArray[toPos] = target;

  return newArray;
}
