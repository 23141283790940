import { TicketType } from 'app/Modules/Ticketing/Assets/js/types';

import { getTypes } from '../../../Api/type/types';
import { FilterOption } from './TypeSelect';

export const loadOptions = async (search: string) => {
  const { data } = await getTypes({
    filters: {
      $search: search,
    },
    fields: ['ID', 'name'],
  });

  return toFilterVal(data);
};

const toFilterVal = (data: TicketType[]): FilterOption[] => {
  return data.map((item) => ({ label: item.name, value: item.ID }));
};
