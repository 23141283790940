import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { TicketState } from '../../types';

interface LimitNullGetListingParams extends Omit<GetListingParams, 'limit'> {
  limit?: number | null;
}
export const getTicketStatesForType = async <T = TicketState>(
  typeID: number,
  params: LimitNullGetListingParams = {}
) => {
  const { data } = await Api.get<T[]>(`/api/ticketing/types/${typeID}/states`, {
    params,
  });

  return data;
};
