import { isArray, isEmpty, isObject, pickBy } from 'lodash-es';

import { FilterExpression, FilterTypesWithGroup, FilterValue } from './types';

async function getExpression(
  filterTypes: FilterTypesWithGroup,
  filterVal: FilterValue
): Promise<FilterExpression | null> {
  const filter = filterTypes[filterVal.filter];
  if (!filter) {
    console.error('Nein filter, nein: ', { filterTypes, filterVal });
    throw new Error(`Unknown filter type ${filterVal.filter}`);
  }
  let expr = await Promise.resolve(filter.toFilter(filterVal, filterTypes));

  let empty = false;
  if (isObject(expr) && !Array.isArray(expr)) {
    expr = pickBy(expr, (val) => val !== undefined);
  }

  if (isArray(expr) || isObject(expr)) {
    empty = isEmpty(expr);
    if (empty) {
      console.warn('Skipping empty filter expression: ', {
        filter: filterVal.filter,
        expression: expr,
      });
    }
  }

  return expr && !empty ? expr : null;
}

function filterIsEmpty(filter: FilterExpression | null): boolean {
  if (filter === null || filter === undefined) {
    return true;
  }

  const groups = ['$and', '$or', '$not'];

  for (const group of groups) {
    const groupVal = (filter as Record<string, unknown>)[group] ?? null;
    if (groupVal) {
      return Array.isArray(groupVal) && groupVal.length === 0;
    }
  }

  return false;
}

export async function filterExpression(
  filterTypes: FilterTypesWithGroup,
  filterValues: FilterValue[],
  searchString: string
): Promise<FilterExpression[]> {
  const expressions = await Promise.all(
    filterValues.map((filterVal) => getExpression(filterTypes, filterVal))
  );
  if (searchString && searchString.trim().length) {
    expressions.push({ $search: searchString.trim() });
  }

  return expressions.filter(
    (expr): expr is FilterExpression => !filterIsEmpty(expr)
  );
}
