import { ReactElement } from 'react';
import { Badge, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { WithSeparator } from '@bq/components/WithSeparator';

import { useTicketMutationContext } from '../../TicketingForm/FormWrapper/TicketMutationContext';
import { ChangeArrow } from './ChangeArrow';
import { ChangedItem } from './ChangedItem';
import { ChangeFrom } from './ChangeFrom';
import { ChangeTo } from './ChangeTo';
import { CustomFormChanges } from './CustomFormChanges';
import { TicketChangesDiff } from './types';
import { customerName, tagNames } from './util';

export const EventCardContent = (diff: TicketChangesDiff): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { customFields } = useTicketMutationContext();

  return (
    <VStack bg="whiteAlpha.100" spacing="3">
      {diff.title && (
        <ChangedItem label={t('Ticketing:form.title')}>
          <ChangeFrom>{diff.title.from}</ChangeFrom>
          <ChangeArrow />
          <ChangeTo>{diff.title.to}</ChangeTo>
        </ChangedItem>
      )}
      {diff.state && (
        <ChangedItem label={t('Ticketing:form.state')}>
          <Badge color="white" bg={diff.state.from.color}>
            {diff.state.from.name}
          </Badge>
          <ChangeArrow />
          <Badge color="white" bg={diff.state.to.color}>
            {diff.state.to.name}
          </Badge>
        </ChangedItem>
      )}
      {diff.priority && (
        <ChangedItem label={t('Ticketing:form.priority')}>
          <ChangeFrom>{diff.priority.from.name}</ChangeFrom>
          <ChangeArrow />
          <ChangeTo>{diff.priority.to.name}</ChangeTo>
        </ChangedItem>
      )}
      {diff.group && (
        <ChangedItem label={t('Ticketing:form.group')}>
          <ChangeFrom>{diff.group.from.name}</ChangeFrom>
          <ChangeArrow />
          <ChangeTo>{diff.group.to.name}</ChangeTo>
        </ChangedItem>
      )}
      {diff.assignedUser && (
        <ChangedItem label={t('Ticketing:form.agent')}>
          <ChangeFrom>{diff.assignedUser.from?.username}</ChangeFrom>
          <ChangeArrow />
          <ChangeTo>{diff.assignedUser.to?.username}</ChangeTo>
        </ChangedItem>
      )}
      {diff.reportMethod && (
        <ChangedItem label={t('Ticketing:form.report_method')}>
          <ChangeFrom>{diff.reportMethod.from?.name}</ChangeFrom>
          <ChangeArrow />
          <ChangeTo>{diff.reportMethod.to?.name}</ChangeTo>
        </ChangedItem>
      )}
      {diff.customer && (
        <ChangedItem label={t('Ticketing:form.customer')}>
          <ChangeFrom>{customerName(diff.customer.from)}</ChangeFrom>
          <ChangeArrow />
          <ChangeTo>{customerName(diff.customer.to)}</ChangeTo>
        </ChangedItem>
      )}
      {diff.contact && (
        <ChangedItem label={t('Ticketing:form.contact')}>
          <ChangeFrom>{customerName(diff.contact?.from?.contact)}</ChangeFrom>
          <ChangeArrow />
          <ChangeTo>{customerName(diff.contact?.to?.contact)}</ChangeTo>
        </ChangedItem>
      )}
      {diff.tags && (
        <ChangedItem label={t('Ticketing:tags')}>
          <ChangeFrom>
            <WithSeparator separator=", ">
              {tagNames(diff.tags.from)}
            </WithSeparator>
          </ChangeFrom>
          <ChangeArrow />
          <ChangeTo>
            <WithSeparator separator=", ">
              {tagNames(diff.tags.to)}
            </WithSeparator>
          </ChangeTo>
        </ChangedItem>
      )}
      {customFields && diff.data && (
        <CustomFormChanges
          customFields={customFields}
          from={diff.data.from}
          to={diff.data.to}
        />
      )}
    </VStack>
  );
};
