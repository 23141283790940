import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ChangeType, WithChange } from '@bq/components/SettingsCRUD/types';
import { uniqid } from 'app/assets/js/tsutil';

import { StateFormFields } from './StateFormFields';
import { TicketStateExtended } from './types';

interface Props {
  isOpen: boolean;
  selected: WithChange<TicketStateExtended> | null;
  onClose: () => void;
  onChange: (modifications: WithChange<TicketStateExtended>) => void;
}
export const StateModalForm = ({
  onClose,
  isOpen,
  selected,
  onChange,
}: Props) => {
  const { t } = useTranslation();
  const methods = useForm<WithChange<TicketStateExtended>>({
    defaultValues: selected || {},
  });

  return (
    <FormProvider {...methods}>
      <Modal isOpen={isOpen} onClose={onClose}>
        <form
          data-ignore-form
          onSubmit={methods.handleSubmit((data) => {
            const change: ChangeType = selected ? 'upd' : 'add';
            const ID = selected ? data.ID : uniqid();
            onChange({ ...data, change, ID });
            onClose();
          })}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalHeader>{t('Ticketing:form.state')}</ModalHeader>
            <ModalBody>
              <StateFormFields />
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme="red"
                mr={3}
                onClick={onClose}
                variant="outline"
              >
                {t('global:close')}
              </Button>
              <Button type="submit" colorScheme="green">
                {t('global:save')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </form>
      </Modal>
    </FormProvider>
  );
};
