import { ReactElement } from 'react';

export const BargeIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M172.5 122.6v10.8h-57v-10.8a5.6 5.6 0 00-9.6-4l-20 20.2a5.6 5.6 0 000 7.9l20 20.1a5.6 5.6 0 009.6-4v-10.7h57v10.8a5.6 5.6 0 009.6 4l20-20.2a5.6 5.6 0 000-7.9l-20-20.1a5.6 5.6 0 00-9.6 4zM42 143.3a24 24 0 10-24-24 24 24 0 0024 24zm17 4.9h-3.2a32.8 32.8 0 01-27.4 0h-3.1A25.3 25.3 0 000 173.4v7.8a9 9 0 009 9h66.2a9 9 0 009-9v-7.8a25.3 25.3 0 00-25.3-25.2zM247.8 143.9a24 24 0 10-24-24 24 24 0 0024 24zm16.9 4.9h-3.1a32.8 32.8 0 01-27.4 0H231a25.3 25.3 0 00-25.2 25.2v7.8a9 9 0 009 9h66.1a9 9 0 009-9V174a25.3 25.3 0 00-25.2-25.3z"
    />
    <path
      fill="currentColor"
      d="M144 95.5A12.4 12.4 0 10131.6 83 12.4 12.4 0 00144 95.5zm8.7 2.5H151a16.9 16.9 0 01-14.2 0h-1.6a13 13 0 00-13 13v4a4.7 4.7 0 004.7 4.7h34a4.7 4.7 0 004.7-4.7v-4a13 13 0 00-13-13z"
    />
  </svg>
);
