import { chakra, shouldForwardProp } from '@chakra-ui/react';
import isValidHTMLProp from '@emotion/is-prop-valid';

import { Radio } from './Radio';
import { RadioGroup } from './RadioGroup';

export const RadioCard = chakra(Radio, {
  shouldForwardProp: (prop) => {
    // don't forward Chakra's props
    const isChakraProp = !shouldForwardProp(prop);
    if (isChakraProp) {
      return false;
    }

    // forward valid HTML props
    const isValidProp = isValidHTMLProp(prop);
    if (isValidProp) {
      return true;
    }

    // else, only forward `sample` prop
    return ['value'].includes(prop);
  },
  baseStyle: {
    px: 2,
    py: 10,
    rounded: 'lg',
    border: '2px solid gray',
    color: 'brand.fontDark',
    bg: 'brand.background',
    _dark: {
      bg: 'brand.backgroundDark',
      color: 'white',
      _checked: {
        bg: 'brand.600',
        color: 'brand.font',
        borderColor: 'brand.900',
      },
      _hover: {
        bg: 'brand.200',
        color: 'brand.font',
      },
    },
    _checked: {
      bg: 'brand.400',
      color: 'brand.font',
      borderColor: 'brand.900',
    },
    _hover: {
      bg: 'brand.200',
      color: 'brand.font',
      borderColor: 'brand.900',
    },
    cursor: 'pointer',
  },
});
export const RadioCardGroup = chakra(RadioGroup, {
  shouldForwardProp: (prop) => {
    const isChakraProp = !shouldForwardProp(prop);
    if (isChakraProp) {
      return false;
    }

    const isValidProp = isValidHTMLProp(prop);
    if (isValidProp) {
      return true;
    }

    return ['defaultVal', 'onChange', 'children'].includes(prop);
  },
  baseStyle: {
    gridGap: [2, 4, 6, 8],
  },
});
