import { useCallback } from 'react';

import { useModal } from '../UseModal';
import { ConfirmationModalContent } from './ConfirmationModalContent';
import { ConfirmModalProps } from './types';

export const useConfirmationModal = () => {
  const { addModal } = useModal();

  return useCallback(
    (props: ConfirmModalProps) => {
      addModal({
        children: <ConfirmationModalContent {...props} />,
        componentProps: { modal: { size: 'sm' } },
      });
    },
    [addModal]
  );
};
