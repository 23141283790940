import { ReactElement } from 'react';
import { AsyncCreatableSelect } from 'chakra-react-select';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  chakraSelectStyles,
  formatCreateLabel,
  itemToSelectOption,
  selectItemToValue,
  SelectOption,
  valueToSelectItem,
} from 'BootQuery/Assets/components/Select';
import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { ListItem } from '../types';
import { PersonFormData } from './types';

async function searchCompanyDepartments(
  companyID: number,
  search: string
): Promise<SelectOption[]> {
  const {
    data: { data: departments },
  } = await Api.get<ListingResponse<ListItem>>(
    `/api/phonebook/companies/${companyID}/departments`,
    {
      params: {
        filters: search ? { $search: search } : {},
        fields: ['ID', 'name'],
      },
    }
  );

  return departments.map(itemToSelectOption);
}

export const CompanyDepartmentInput = (): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const { control } = useFormContext<PersonFormData>();
  const company = useWatch({ control, name: 'company' });
  const getResults = (search: string): Promise<SelectOption[]> => {
    if (!company || !('ID' in company)) {
      return Promise.resolve([]);
    }

    return searchCompanyDepartments(company.ID, search);
  };

  return (
    <Controller
      control={control}
      name="companyDepartment"
      render={({ field }) => (
        <AsyncCreatableSelect
          cacheOptions
          defaultOptions
          isClearable
          loadOptions={getResults}
          value={valueToSelectItem(field.value)}
          onChange={(val: SelectOption | null) => {
            field.onChange(selectItemToValue(val));
          }}
          formatCreateLabel={formatCreateLabel}
          placeholder={t('Phonebook:department')}
          isDisabled={!company}
          styles={chakraSelectStyles}
          selectedOptionColorScheme="brand"
        />
      )}
    />
  );
};
