import { AgentContactDisplay } from '../../../TicketingContactDisplay/AgentContactDisplay';
import { TicketUser } from '../../../types';

interface Props {
  user?: TicketUser | null;
}
export const TicketCreatorCell = ({ user }: Props) => {
  if (user) {
    const { ID, username, person } = user;

    return (
      <AgentContactDisplay
        valueFontWeight="normal"
        contactID={ID}
        contactName={person?.fullName ?? username}
        contactType="user"
      />
    );
  }

  return <>-</>;
};
