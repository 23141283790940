import Fuse from 'fuse.js';
import Countries from 'i18n-iso-countries';
import CountriesDE from 'i18n-iso-countries/langs/de.json';
import CountriesEN from 'i18n-iso-countries/langs/en.json';
import CountriesHR from 'i18n-iso-countries/langs/hr.json';

import i18n from 'BootQuery/Assets/js/i18n';

Countries.registerLocale(CountriesHR);
Countries.registerLocale(CountriesEN);
Countries.registerLocale(CountriesDE);

Countries.getNames('hr');
export interface CountryInfo {
  iso: string;
  name: string;
}

export interface ValType {
  iso: string;
}

export type Lang = 'hr' | 'en' | 'de';

const searchers: Partial<Record<Lang, Fuse<CountryInfo>>> = {};
export function getCurrentLanguage(): Lang {
  return (i18n.language ?? 'hr') as Lang;
}
export function getCountries(lang: Lang): CountryInfo[] {
  const countryMap = Countries.getNames(lang);

  return Object.entries(countryMap).map(([iso, name]) => ({ iso, name }));
}
export function getCountry(lang: Lang, code: string): CountryInfo {
  const country = Countries.getName(code, lang);

  return { iso: code, name: country };
}
export function getSearcher(lang: Lang): Fuse<CountryInfo> {
  let searcher = searchers[lang];
  if (!searcher) {
    searcher = new Fuse(getCountries(lang), {
      keys: ['iso', 'name'],
    });

    searchers[lang] = searcher;
  }

  return searcher;
}
