import { useMemo } from 'react';
import { GroupBase } from 'chakra-react-select';

import { OBJ, Primitive } from 'BootQuery/Assets/js/globalTypes';

import {
  OptionGetter,
  OptionGetterAsync,
  OptionGetterInMem,
  OptionsType,
  SearchOptionFn,
  SelectOption,
} from './types';
import { searchStaticOptions } from './utils';

export const useOptionGetters = <
  ValType extends Primitive = Primitive,
  AdditionalData extends OBJ = OBJ,
  Option extends SelectOption = SelectOption<ValType, AdditionalData>,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  options: OptionsType<ValType, AdditionalData, Option, Group>
) => {
  const optionsGetter: OptionGetter<ValType, AdditionalData, Option, Group> =
    useMemo(() => {
      if (typeof options === 'function') {
        const async: OptionGetterAsync<ValType, AdditionalData, Option, Group> =
          { type: 'async', fn: options };

        return async;
      }

      const inmem: OptionGetterInMem<ValType, AdditionalData, Option, Group> = {
        fn: searchStaticOptions as unknown as SearchOptionFn<
          ValType,
          AdditionalData,
          Option,
          Group
        >,
        opts: options,
        type: 'inmem',
      };

      return inmem;
    }, [options]);

  return optionsGetter;
};
