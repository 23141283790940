import { ReactElement } from 'react';

export const SurveyIcon = (): ReactElement => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M288 257.1V31A30.9 30.9 0 00257.1 0H31A30.9 30.9 0 000 30.9V257A30.9 30.9 0 0030.9 288H257a30.9 30.9 0 0030.9-30.9zM72 103a10.3 10.3 0 01-10.3-10.3V72A10.3 10.3 0 0172 61.7h82.3A10.3 10.3 0 01164.6 72v20.6a10.3 10.3 0 01-10.3 10.3zm0 61.7a10.3 10.3 0 01-10.3-10.3v-20.6A10.3 10.3 0 0172 123.4h144a10.3 10.3 0 0110.3 10.3v20.6a10.3 10.3 0 01-10.3 10.3zm0 61.7A10.3 10.3 0 0161.7 216v-20.6A10.3 10.3 0 0172 185.1h41.1a10.3 10.3 0 0110.3 10.3V216a10.3 10.3 0 01-10.3 10.3z"
    />
  </svg>
);
