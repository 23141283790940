import i18n from 'BootQuery/Assets/js/i18n';

import { ItemColumn } from '../types';

export const priceColumns = (): ItemColumn[] => [
  currencyColumn(),
  taxRateColumn(),
  wholesalePriceColumn(),
  retailPriceColumn(),
  purchasePriceColumn(),
  actionPricesColumn(),
];

const currencyColumn = (): ItemColumn => ({
  key: 'currency',
  title: i18n.t('Products:currency'),
  order: 300,
});
const taxRateColumn = (): ItemColumn => ({
  key: 'taxRate',
  title: i18n.t('Products:tax_rate'),
  order: 301,
});
const wholesalePriceColumn = (): ItemColumn => ({
  key: 'wholesalePrice',
  title: i18n.t('Products:wholesale_price'),
  order: 302,
  getValue: (props) => {
    return props.row.price?.wholesalePrice ?? '-';
  },
});
const retailPriceColumn = (): ItemColumn => ({
  key: 'retailPrice',
  title: i18n.t('Products:retail_price'),
  order: 303,
  getValue: (props) => {
    return props.row.price?.retailPrice ?? '-';
  },
});
const purchasePriceColumn = (): ItemColumn => ({
  key: 'purchasePrice',
  title: i18n.t('Products:purchase_price'),
  order: 304,
  getValue: (props) => {
    return props.row.price?.purchasePrice ?? '-';
  },
});

export const contractPriceColumn = (): ItemColumn => ({
  key: 'contractPrice',
  title: i18n.t('Products:contract_price'),
  order: 305,
  getValue: (props) => {
    return props.row.price?.contractPrice ?? '-';
  },
});

const actionPricesColumn = (): ItemColumn => ({
  key: 'actionPrices',
  title: i18n.t('Products:action_prices'),
  order: 306,
});
