import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';
import { Api } from 'BootQuery/Assets/js/api';

export async function getOverviews(): Promise<IOverviewEditorItem[]> {
  const { data } = await Api.get<IOverviewEditorItem[]>(
    '/api/events/overviews'
  );

  return data;
}

export function useOverviews(): UseQueryResult<IOverviewEditorItem[]> {
  return useQuery({
    queryKey: ['Events.overviews'],
    queryFn: getOverviews,
  });
}
