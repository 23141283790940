import { ReactElement } from 'react';
import { Button } from '@chakra-ui/react';
import { FaEdit } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface EditButtonProps {
  href: string;
}

export const EditButton = ({ href }: EditButtonProps): ReactElement => (
  <Button as={Link} variant="ghost" size="md" to={href} verticalAlign="middle">
    <FaEdit size={18} />
  </Button>
);
