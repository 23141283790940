import { ReactNode } from 'react';
import { BoxProps, Center, Text, useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

interface Props extends BoxProps {
  children?: ReactNode;
}

export const NoDataMessage = ({ children }: Props) => {
  const { t } = useTranslation();
  if (!children) {
    return (
      <NoDataMessageWrapper>
        {t('global:there_is_no_data')}
      </NoDataMessageWrapper>
    );
  }

  return <NoDataMessageWrapper>{children}</NoDataMessageWrapper>;
};

const NoDataMessageWrapper = ({ children }: Props) => {
  const color = useColorModeValue('gray.300', 'gray.500');

  return (
    <Center m={0} mb={4} mt={8} py={16}>
      <Text
        textAlign="center"
        fontWeight="bold"
        fontSize="xl"
        m={0}
        color={color}
      >
        {children}
      </Text>
    </Center>
  );
};
