import { ReactElement } from 'react';

export const TimeIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    fill="currentColor"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="M500 0C223.79 0 0 223.79 0 500s223.79 500 500 500 500-223.79 500-500S776.21 0 500 0Zm0 903.23C277.22 903.23 96.77 722.78 96.77 500S277.22 96.77 500 96.77 903.23 277.22 903.23 500 722.78 903.23 500 903.23Zm124.6-210.49L453.43 568.35a24.36 24.36 0 0 1-9.88-19.56v-331a24.27 24.27 0 0 1 24.19-24.19h64.52a24.27 24.27 0 0 1 24.19 24.19v285.64l134.68 98a24.18 24.18 0 0 1 5.24 33.87l-37.9 52.22a24.36 24.36 0 0 1-33.87 5.24Z" />
  </svg>
);
