import { useCallback, useState } from 'react';

import { uniqid } from './FormEditor/util';

export const useUniqueID = (): [saveID: string, setSaveID: () => void] => {
  const [saveID, setSaveID] = useState(uniqid());
  const resetSaveID = useCallback(() => {
    setSaveID(uniqid());
  }, []);

  return [saveID, resetSaveID];
};
