import { ReactElement, useCallback, useEffect, useState } from 'react';
import {
  chakra,
  Flex,
  HStack,
  IconButton,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

import { FilterBarSearch } from './FilterbarSearch';
import { FilterBarProps, FilterTypesWithGroup, FilterValue } from './types';
import { useInputStyle } from './use-input-style';
import {
  getDefaultFilterValue,
  nameStr,
  operatorsVal,
  removeValueAt,
  setValueAt,
} from './util';

interface FilterBarPropsInternal extends FilterBarProps {
  filterTypes: FilterTypesWithGroup;
}

export const FilterBarInput = ({
  filterTypes,
  value: filterValues,
  onChange,
  onSearchChange,
  search: inSearch = '',
}: FilterBarPropsInternal): ReactElement => {
  const { t } = useTranslation();
  const [search, setSearchVal] = useState<string>(inSearch);
  const [hasNew, setHasNew] = useState(false);

  const setSearch = useCallback(
    (newVal: string) => {
      setSearchVal(newVal);
      if (onSearchChange) {
        onSearchChange(newVal);
      }
    },
    [setSearchVal, onSearchChange]
  );

  useEffect(() => {
    if (inSearch !== search) {
      setSearch(inSearch);
    }
  }, [inSearch, setSearch, search]);

  const clearFilters = () => {
    setSearch('');
    onChange([]);
  };

  const addFilter = (filter: string) => {
    const filterType = filterTypes[filter];
    if (!filterType) {
      throw new Error(`Tried to add unknown filter ${filter}`);
    }

    const operators = operatorsVal(filterType.operators);
    const operator = operators[0]?.operator ?? null;
    const value = getDefaultFilterValue(filterType);
    setHasNew(true);
    onChange([...filterValues, { value, operator, filter }]);
    // The input change event is emitted after selection, wait for it and only then clear search
    setTimeout(() => setSearch(''));
  };
  useEffect(() => {
    if (hasNew) {
      setHasNew(false);
    }
  }, [hasNew]);

  return (
    <InputOuter {...useInputStyle()} borderColor="brand.600">
      <HStack flex="1 0 auto">
        {filterValues.map((value, idx) => {
          const filter = filterTypes[value.filter] ?? null;
          if (!filter) {
            throw new Error(`Unknown filter type ${value.filter}`);
          }
          const FilterComponent = filter.tagComponent;

          return (
            <FilterComponent
              isNew={idx === filterValues.length - 1 && hasNew}
              key={idx}
              name={nameStr(filter.name)}
              value={value.value}
              operator={value.operator}
              operators={operatorsVal(filter.operators)}
              filterTypes={filterTypes}
              onChange={(newVal) => {
                const setVal = (val: FilterValue) => ({
                  ...val,
                  value: newVal,
                });
                onChange(setValueAt(filterValues, idx, setVal));
              }}
              onOperatorChange={(newOp) => {
                const setOp = (val: FilterValue) => ({
                  ...val,
                  operator: newOp,
                });
                onChange(setValueAt(filterValues, idx, setOp));
              }}
              onRemove={() => {
                onChange(removeValueAt(filterValues, idx));
              }}
              {...(filter.extraProps ?? {})}
            />
          );
        })}
      </HStack>
      <InputGroup>
        <FilterBarSearch
          filters={filterTypes}
          value={search}
          onChange={setSearch}
          onSelect={addFilter}
        />
        <InputRightElement height="8">
          <Tooltip label={t('global:filters.clear_filters')} hasArrow>
            <IconButton
              aria-label={t('global:filters.clear_filters')}
              size="sm"
              variant="link"
              icon={<FaTimes />}
              onClick={clearFilters}
            />
          </Tooltip>
        </InputRightElement>
      </InputGroup>
    </InputOuter>
  );
};

const InputOuter = chakra(Flex, {
  baseStyle: {
    borderRadius: 'md',
    borderRightRadius: 0,
    px: '1',
    py: '0.125rem',
    flex: '1 1 auto',
    alignItems: 'center',
  },
});
