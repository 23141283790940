import { ReactElement } from 'react';
import { Text } from '@chakra-ui/react';

export const PauseStateDisplay = ({
  pause,
}: {
  pause: string;
}): ReactElement => (
  <Text as="div" color="yellow.400" textAlign="center" flexGrow={1}>
    {pause || 'Pauza'}
  </Text>
);
