import { ReactElement, useState } from 'react';
import { Flex, IconButton, useColorModeValue } from '@chakra-ui/react';
import { FaTimes } from 'react-icons/fa';

import { useAutosizingInput } from '../../../use-autosizing-input';
import { FilterName } from '../../FilterName';
import { FilterTagOperator } from '../../FilterOperator';
import { FilterComboboxInput } from './ComboboxInput';
import { useValueToItem } from './items';
import { ComboboxFilterProps, ValBase } from './types';

export const ComboboxFilterTag = <
  ItemType extends ValBase,
  ValueType extends ValBase,
>(
  props: ComboboxFilterProps<ItemType, ValueType>
): ReactElement => {
  const {
    name,
    operator,
    operators,
    onOperatorChange,
    onRemove,
    value,
    itemToString,
    valueToItem,
    cacheKey,
  } = props;
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.200');
  const { item } = useValueToItem(value, valueToItem, cacheKey);
  let initialInputVal = '';
  if (item && item !== null) {
    initialInputVal = typeof item === 'string' ? item : itemToString(item);
  }
  const [inputVal, setInputVal] = useState<string>(initialInputVal);
  const inputRef = useAutosizingInput(inputVal, 96);
  const isNullOperator = operator === 'null' || operator === 'notnull';

  return (
    <Flex>
      <FilterName name={name} />
      {operators.length > 0 && (
        <FilterTagOperator
          operators={operators}
          value={operator ?? 'eq'}
          onChange={onOperatorChange}
        />
      )}
      <Flex background={bgColor} height="8" borderRightRadius="md">
        {!isNullOperator && (
          <FilterComboboxInput<ItemType, ValueType>
            {...props}
            inputRef={inputRef}
            onInputValueChange={setInputVal}
            inputStyle={{
              minWidth: '64px',
              border: 'none',
              background: 'none',
              borderLeftRadius: '0',
              size: 'sm',
              height: '8',
            }}
            buttonStyle={{
              borderRadius: 0,
              size: 'sm',
            }}
          />
        )}
        <IconButton
          onClick={onRemove}
          variant="link"
          aria-label="Close"
          icon={<FaTimes />}
          size="xs"
        />
      </Flex>
    </Flex>
  );
};
