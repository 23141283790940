import { mapValues, omitBy } from 'lodash-es';
import { create as zustand } from 'zustand';

import { sleep } from 'app/assets/js/tsutil';
import { Api } from 'BootQuery/Assets/js/api';
import reusePromise from 'BootQuery/Assets/js/reuse-promise';
import { makeListener } from 'BootQuery/Assets/js/socket-event-listener';

const eventListener = makeListener('deviceStates');
let initialised = false;

export type DeviceState =
  | 'unknown'
  | 'unavailable'
  | 'idle'
  | 'ringing'
  | 'busy';

interface DeviceStateObj {
  state: DeviceState;
  protocol: string | null;
}

interface ExtenState {
  devices: Record<string, DeviceStateObj>;
}

export type ExtenStates = Record<string, ExtenState>;

type LoadingState = 'pending' | 'loading';

interface StateEvent {
  device: string;
  state: DeviceState;
}

export interface DeviceStateStore {
  init: () => void;
  subscribe: (dev: string | string[]) => void;
  deviceStates: ExtenStates;
  loadingStates: Record<string, LoadingState>;
}

export const deviceState = zustand<DeviceStateStore>((set, get) => {
  const fetch = async (devs: string[]) => {
    set({
      loadingStates: mapValues(get().loadingStates, (state, dev) => {
        if (devs.includes(dev)) {
          return 'loading';
        }

        return state;
      }),
    });
    const { data: states } = await Api.get<ExtenStates>(
      '/api/telephony/deviceStates',
      {
        params: { ext: devs },
      }
    );

    const newLoadingStates = omitBy(get().loadingStates, (_dev, devId) => {
      return devs.includes(devId);
    });
    const newDeviceStates = Object.entries(states).reduce(
      (states, [devId, state]) => ({
        ...states,
        [devId]: state,
      }),
      get().deviceStates
    );

    set({ deviceStates: newDeviceStates, loadingStates: newLoadingStates });
  };

  const scheduleFetch = reusePromise(async () => {
    // Wait for one event loop to complete for all requests to be added so we can batch them.
    await sleep(0);

    const { loadingStates } = get();
    const toFetch = Object.entries(loadingStates).reduce<string[]>(
      (toFetch, [devId, loadingState]) => {
        if (loadingState === 'pending') {
          return [...toFetch, devId];
        }

        return toFetch;
      },
      []
    );

    if (toFetch.length > 0) {
      fetch(toFetch);
    }
  });

  const onDevState = (ev: StateEvent) => {
    console.log('Device state change: ', ev);

    const { deviceStates } = get();
    Object.entries(deviceStates).forEach(([exten, extenState]) => {
      const dev = extenState.devices[ev.device];
      if (dev) {
        const newDevices = { ...extenState.devices };
        newDevices[ev.device].state = ev.state;
        set({
          deviceStates: {
            ...deviceStates,
            [exten]: { devices: newDevices },
          },
        });
      }
    });
  };

  return {
    deviceStates: {},
    loadingStates: {},
    init: () => {
      if (initialised) {
        return;
      }
      initialised = true;
      eventListener().subscribeWebSocket(
        'telephony/simpleDeviceState',
        onDevState
      );
    },
    subscribe: (dev) => {
      if (!initialised) {
        get().init();
      }

      const devs = typeof dev === 'string' ? [dev] : dev;
      const { deviceStates, loadingStates } = get();

      const newDevs: Record<string, 'pending'> = {};
      devs.forEach((dev) => {
        // Check if device already added.
        if (dev in deviceStates || dev in loadingStates || dev in newDevs) {
          return;
        }

        newDevs[dev] = 'pending';
      });
      if (Object.keys(newDevs).length > 0) {
        set({ loadingStates: { ...loadingStates, ...newDevs } });
        scheduleFetch();
      }
    },
  };
});
