/* eslint-disable @typescript-eslint/no-explicit-any */
import { uniqid } from 'app/assets/js/tsutil';

import { softphoneInstance } from './softphone-instance';
import Softphone from './softphone/softphone';
import {
  SessionInterface,
  SoftphoneInterface,
  SoftphoneSettingsInterface,
} from './types';

type Func<ARGT extends Array<unknown>, RETT> = (...args: ARGT) => RETT;

type KeyOfType<T, U> = { [P in keyof T]: T[P] extends U ? P : never }[keyof T];
export type FuncType<C> = KeyOfType<C, Func<Array<any>, any>>;

type ActionWaiters = Record<string, (val: unknown) => void>;

export const softphoneActionWaiters: ActionWaiters = {};

export function proxySoftphoneMethod<F extends FuncType<SoftphoneInterface>>(
  methodName: F
): (...args: Parameters<Softphone[F]>) => Promise<ReturnType<Softphone[F]>> {
  const fn = (...args: Parameters<Softphone[F]>) => {
    const { softphone } = softphoneInstance;
    console.log('Dispatch: ', methodName, args);
    if (softphone) {
      const ret = (softphone[methodName] as any)(...args);

      return Promise.resolve(ret);
    }

    const actionId = uniqid();
    const cmdObj = { actionId, args, cmd: methodName };
    const waitResponse: Promise<ReturnType<Softphone[F]>> = new Promise(
      (resolve) => {
        softphoneActionWaiters[actionId] = resolve as any;
      }
    );
    localStorage.setItem('softphoneCmd', JSON.stringify(cmdObj));
    localStorage.removeItem('softphoneCmd');
    console.log('Set respond waiter: ', cmdObj);

    return waitResponse;
  };

  return fn;
}

export function proxySessionMethod(
  sessionId: string,
  methodName: FuncType<SessionInterface>
): any {
  const fn = (...args: unknown[]) => {
    const { softphone } = softphoneInstance;
    console.log('Session dispatch: ', sessionId, methodName, ...args);
    if (softphone) {
      const session = softphone.sessions.find(
        (sess) => sess.sessionId === sessionId
      );
      if (!session) {
        console.warn('Tried to execute command on missing session: ', session);

        return;
      }

      (session[methodName] as any)(...args);
    } else {
      const cmdObj = { sessionId, args, cmd: methodName };
      localStorage.setItem('softphoneSessionCmd', JSON.stringify(cmdObj));
      localStorage.removeItem('softphoneSessionCmd');
    }
  };

  return fn as any;
}

export function proxySettingsMethod(
  methodName: FuncType<SoftphoneSettingsInterface>
): any {
  const fn = (...args: any[]) => {
    const { softphone } = softphoneInstance;
    console.log('Dispatch settings: ', methodName, ...args);
    if (softphone) {
      (softphone.settings[methodName] as any)(...args);
    } else {
      const cmdObj = { args, cmd: methodName };
      localStorage.setItem('softphoneSettingsCmd', JSON.stringify(cmdObj));
      localStorage.removeItem('softphoneSettingsCmd');
    }
  };

  return fn as any;
}
