import { ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { EditorFields } from '../../EditorFields';
import {
  BaseSettings,
  EditorFieldComponentProps,
  FieldValue,
} from '../../types';
import { useFieldType } from '../../use-field-type';

interface ListFieldSettings extends BaseSettings {
  subfields: FieldValue[];
}

export const ListFieldEditorOuter = (
  props: EditorFieldComponentProps<ListFieldSettings>
): ReactElement => {
  const { content: value } = props;
  const fieldType = useFieldType(value.type);

  return (
    <Box w="full" px="2">
      <Flex flex="1 0 auto" py="2">
        <fieldType.icon />
        &nbsp;&nbsp;
        {fieldType.name}
      </Flex>
      <EditorFields
        {...props}
        w="full"
        minHeight="100px"
        rounded="base"
        bg="#A0AEC022"
      />
    </Box>
  );
};
