import { ReactElement, useCallback, useMemo, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { DndItemWithChildren, removeItem, replaceItem } from '../dnd-util';
import { BaseItemBase, DndContainersProvider } from '../DndContainers';
import { cloneHandler } from './clone-handler';
import { EditorFields } from './EditorFields';
import { getFieldTypes } from './field-types';
import { FieldList } from './FieldList';
import { FormEditorContext, ModifyField } from './FormEditorContext';
import { FormEditorDragOverlay } from './FormEditorDragOverlay';
import { fieldFromEditorValue } from './from-editor-value';
import { useEditorState } from './to-editor-value';
import { EditorItem, EditorValue, FieldValue } from './types';
import { assertIsField } from './util';

interface Props {
  value: FieldValue[];
  onChange: (fields: FieldValue[]) => void;
}

type WithChildren = DndItemWithChildren<EditorValue, BaseItemBase<EditorValue>>;

export const FormEditor = ({ value, onChange }: Props): ReactElement => {
  const fieldTypes = useMemo(getFieldTypes, []);
  const [ev, setEditorValue] = useState(useEditorState(value, fieldTypes));
  const editorValue = ev as [WithChildren, WithChildren];
  const [fieldListItems, fieldValues] = editorValue;

  useChangeEffect(editorValue, () => {
    console.log('Val changeo: ', value, editorValue);
    onChange(
      editorValue[1].children.map((item) => {
        assertIsField(item);

        return fieldFromEditorValue(item, fieldTypes);
      })
    );
  }, [onChange, fieldTypes]);

  const modifyField: ModifyField = useCallback((id, change) => {
    const replace = (prev: EditorItem): EditorItem => {
      if (prev.content.itemType !== 'fieldValue') {
        throw new Error('Can only change field values');
      }

      const content =
        typeof change === 'function'
          ? change(prev.content)
          : { ...prev.content, ...change };

      return {
        ...prev,
        content,
      };
    };

    return setEditorValue((fields) => replaceItem(fields, id, replace));
  }, []);
  const removeField = useCallback((id: string | number) => {
    setEditorValue((fields) => removeItem(fields, id));
  }, []);

  return (
    <DndContainersProvider
      items={editorValue}
      onChange={setEditorValue}
      cloneHandler={cloneHandler}
    >
      <FormEditorContext.Provider
        value={{
          fieldTypes,
          modifyField,
          removeField,
        }}
      >
        <Flex w="full" maxW="6xl">
          <Box w="25%">
            <FieldList items={fieldListItems.children} />
          </Box>
          <Box borderLeftWidth="medium" flexGrow={1}>
            <EditorFields pb="8" h="full" {...fieldValues} />
          </Box>
        </Flex>
        <FormEditorDragOverlay />
      </FormEditorContext.Provider>
    </DndContainersProvider>
  );
};
