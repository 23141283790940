import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { getCompany } from '../api';
import { CompanyDepartment } from '../CompanyDepartmentList/types';
import { ListItem } from '../types';
import { CompanyDepartmentBackendData } from './types';

export const getCompanyListItem = async (
  companyID: string
): Promise<ListItem> => {
  const data = await getCompany(companyID, { fields: ['ID', 'name'] });

  return data;
};

export const getDepartmentInfo = async (departmentID: string) => {
  const { data } = await Api.get<CompanyDepartmentBackendData>(
    `/api/phonebook/departments/${departmentID}`,
    {
      params: {
        fields: [
          'ID',
          'name',
          [
            'phoneNumbers',
            ['phoneNumber.phoneNumberE164', 'numberType.ID', 'isReadonly'],
          ],
          'emails.email.email',
          'data',
          ['parentDepartment', ['ID', 'name']],
          ['company', ['ID', 'name']],
        ],
      },
    }
  );

  return data;
};

export const searchDepartments = async ({
  companyID,
  search,
}: {
  companyID: number | string;
  search: string;
}) => {
  const {
    data: { data },
  } = await Api.get<ListingResponse<CompanyDepartment>>(
    `/api/phonebook/companies/${companyID}/departments`,
    {
      params: {
        fields: ['ID', 'name', ['parentDepartment', ['ID', 'name']]],
        limit: 'null',
        filters: {
          'name:contains:ci': search,
        },
      },
    }
  );

  return data;
};
