import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Good } from '../../../types';
import { FieldProps } from '../../types';

export const WarrantyMonthsField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    register,
    formState: { errors },
  } = useFormContext<Good>();

  return (
    <FormControl
      isInvalid={!!errors.good?.warrantyMonths}
      {...formControlProps}
    >
      <FormLabel htmlFor="warrantyMonths">
        {t('Products:warranty_months')}
      </FormLabel>
      <Input
        size={['md', 'md', 'sm']}
        type="number"
        {...register('good.warrantyMonths', { valueAsNumber: true })}
        isInvalid={!!errors.good?.warrantyMonths}
        placeholder={t('Products:warranty_months')}
        min={0}
      />
    </FormControl>
  );
};
