import { ReactElement } from 'react';

export const QueueIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M212.07 57.66a50 50 0 01-.38 16.86c10.83 13.19 28.3 15.09 41.49 4.26A30.9 30.9 0 10214 31c-4.51 3.71-5.88 5.62-7.72 11.17 2.72 3.74 4.96 10.64 5.79 15.49zM163.21 26c-14 0-22 5.18-29.46 17 9.13 7.36 13.47 20.11 14.77 31.73a65.78 65.78 0 01-3.43 27.51c19 12.16 40.26 5.61 52.41-13.39A40.84 40.84 0 00163.21 26z"
    />
    <circle fill="currentColor" cx="87.1" cy="83.19" r="49.45" />
    <path
      fill="currentColor"
      d="M159.48 167.82a6.64 6.64 0 01.1-1c-9.51-15-20.06-24.16-37.86-24.16h-6.45a67.27 67.27 0 01-56.33 0h-6.45A51.94 51.94 0 00.57 194.6v16.07a18.55 18.55 0 0018.55 18.54h136a18.55 18.55 0 0018.55-18.54l-6-10.8c-6.67-8.87-8.38-21.26-8.19-32.05zM223.2-184.2a64.75 64.75 0 00-64.8 64.72v.08a64.75 64.75 0 0064.72 64.8h.08a64.75 64.75 0 0064.8-64.72v-.08a64.75 64.75 0 00-64.72-64.8zm28.8 67.64a4.4 4.4 0 01-4.37 4.36h-27.27a4.4 4.4 0 01-4.36-4.36V-151a4.4 4.4 0 014.36-4.37H226a4.4 4.4 0 014.4 4.37v24.43h17.23a4.4 4.4 0 014.37 4.37v5.64zm-108-2.84a79 79 0 018.19-34.87 59.64 59.64 0 00-11.07-1.13h-7.52a78.34 78.34 0 01-32.8 7.2 78.56 78.56 0 01-32.8-7.2h-7.52A60.5 60.5 0 000-94.92v18.72a21.6 21.6 0 0021.6 21.6h156.2a79.18 79.18 0 01-33.8-64.8zm-43.2-50.4a57.58 57.58 0 0057.6-57.58A57.6 57.6 0 00100.8-285a57.6 57.6 0 00-57.6 57.6 57.6 57.6 0 0057.6 57.6zM229.25 112a57.06 57.06 0 10.08 0zm25.37 59.6a3.88 3.88 0 01-3.84 3.84h-24a3.88 3.88 0 01-3.85-3.84v-30.38a3.88 3.88 0 013.85-3.84h5a3.87 3.87 0 013.85 3.84v21.54h15.19a3.88 3.88 0 013.84 3.84v5z"
    />
  </svg>
);
