import { ReactElement, useCallback } from 'react';
import {
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  VStack,
} from '@chakra-ui/react';
import { keepPreviousData } from '@tanstack/react-query';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { useImportPreview } from '../Api/import-preview';
import { CsvOptions, ImportConfig, ImportSetupForm } from '../types';
import { CsvSettingsFields } from './CsvSettingsFields';
import { PreviewTable } from './PreviewTable';
import { usePreviewConfig } from './use-preview-config';
import { useSyncColumns } from './use-sync-columns';
import { useWatchWithDefaults } from './use-watch-with-defaults';
import {
  formToConfig,
  genEmptyColumns,
  splitAndTruncatePreviewRows,
} from './util';

export interface Props {
  /** The form element's id */
  id?: string;
  uploadId: string;
  defaultName?: string | null;
  onSubmit?: (data: ImportConfig) => void | Promise<void>;
}

export const ImportSetup = ({
  id,
  uploadId,
  defaultName,
  onSubmit,
}: Props): ReactElement => {
  const { t } = useTranslation('Datasets');

  const isCsv = uploadId.endsWith('.csv');

  const formMethods = useForm<ImportSetupForm>({
    defaultValues: {
      name: defaultName ?? '',
      // The 6 columns will be used as Table's isLoading placeholder values
      // until we get some rows from the backend.
      //
      // Deep partial seems to mess up the array types
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      columns: genEmptyColumns(6) as any,
      firstRowIsHeader: true,
      csvOptions: isCsv ? csvDefaults : {},
    },
  });

  const handleSuccesfullSubmit = useCallback(
    (data: ImportSetupForm) => {
      onSubmit?.(formToConfig(uploadId, data));
    },
    [uploadId, onSubmit]
  );

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = formMethods;

  const firstRowIsHeader = useWatchWithDefaults(
    formMethods,
    'firstRowIsHeader'
  );
  const columns = useWatchWithDefaults(formMethods, 'columns');

  const config = usePreviewConfig(uploadId, formMethods);
  const { data: preview, fetchStatus } = useImportPreview(config, {
    placeholderData: keepPreviousData,
  });
  const isLoading = fetchStatus === 'fetching';

  const { rows, header } = splitAndTruncatePreviewRows(
    preview,
    firstRowIsHeader
  );

  // Set/clear columns header data when `firstRowIsHeader` is toggled
  // or column count changes
  useSyncColumns({ rows, header, formMethods });

  return (
    <VStack
      as="form"
      id={id}
      alignItems="stretch"
      spacing="6"
      data-ignore-form-save
      onSubmit={handleSubmit(handleSuccesfullSubmit)}
    >
      <FormProvider {...formMethods}>
        <FormControl px="6" isInvalid={!!errors.name}>
          <FormLabel fontWeight="bold">{t('global:name')}:</FormLabel>
          <Input
            {...register('name', { required: true })}
            isInvalid={!!errors.name}
            placeholder={t('global:name')}
          />
          {errors.name && (
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          )}
        </FormControl>
        <Flex px="6" justifyContent="space-between">
          <FormControl>
            <FormLabel fontWeight="bold">
              {t('Datasets:first_row_is_header')}:
            </FormLabel>
            <Checkbox {...register('firstRowIsHeader')} />
          </FormControl>
          {isCsv && <CsvSettingsFields />}
        </Flex>
        <PreviewTable columns={columns} rows={rows} isLoading={isLoading} />
      </FormProvider>
    </VStack>
  );
};

const csvDefaults: CsvOptions = {
  delimiter: ',',
  enclosure: '"',
  encoding: 'UTF-8',
};
