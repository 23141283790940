import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type OpenItemsMap = Record<string, number[]>;
interface OpenItemsCtxProps {
  openItems: OpenItemsMap;
  setOpenItems: Dispatch<SetStateAction<OpenItemsMap>>;
}
const Ctx = createContext<OpenItemsCtxProps | null>(null);

export const OpenItems = ({ children }: PropsWithChildren) => {
  const [openItems, setOpenItems] = useState<OpenItemsMap>({});

  return (
    <Ctx.Provider value={{ openItems, setOpenItems }}>{children}</Ctx.Provider>
  );
};

export const useOpenItems = () => {
  const ctx = useContext(Ctx);
  if (!ctx) {
    throw Error('Open Items Context not initalized');
  }

  return ctx;
};
