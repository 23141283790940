import {
  createContext,
  memo,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import {
  Box,
  Button,
  Container,
  Drawer,
  DrawerBody,
  DrawerContent,
  Flex,
  Heading,
  HStack,
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Select,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  FaChevronDown,
  FaDownload,
  FaPause,
  FaPlay,
  FaTimes,
  FaVolumeUp,
} from 'react-icons/fa';
import { useAudio } from 'react-use';

export interface RecordingMedia {
  type: 'audio' | 'video' | 'voicemail';
  url: string;
  filename: string;
  onPlay?: () => void;
}

export interface IPlayerContext {
  recordings: RecordingMedia[];
  isOpen: boolean;
  toggleOpen: (open?: boolean) => void;
  setRecordings: (recordings: RecordingMedia[]) => void;
  recording: RecordingMedia | null;
  setRecording: (recording: RecordingMedia) => void;
}

export const PlayerContext = createContext<IPlayerContext>({
  recordings: [],
  setRecordings: () => {
    /* Default value */
  },
  isOpen: false,
  toggleOpen: () => {
    /* Default value */
  },
  recording: null,
  setRecording: () => {
    /* Default value */
  },
});

function formatDuration(rawSecs: number): string {
  const hours = Math.floor(rawSecs / 3600);
  const minutes = Math.floor((rawSecs - hours * 3600) / 60);
  const seconds = Math.round(rawSecs % 60);

  const segments = hours > 0 ? [hours, minutes, seconds] : [minutes, seconds];

  return segments.map((seg) => seg.toString().padStart(2, '0')).join(':');
}

interface ProgressProps {
  duration: number;
  currentTime: number;
  onSeek?: (time: number) => void;
}

const PlayerProgress = ({
  duration,
  currentTime,
  onSeek,
}: ProgressProps): ReactElement => (
  <Slider
    defaultValue={0}
    min={0}
    step={0.1}
    max={duration}
    value={currentTime}
    onChange={(time) => onSeek && onSeek(time)}
  >
    <SliderTrack>
      <SliderFilledTrack />
    </SliderTrack>
    <SliderThumb />
  </Slider>
);

interface VolumeProps {
  volume: number;
  onChange?: (volume: number) => void;
}

const VolumeSlider = ({ volume, onChange }: VolumeProps): ReactElement => (
  <Slider
    defaultValue={100}
    min={0}
    max={100}
    step={1}
    value={volume}
    onChange={(time) => onChange && onChange(time)}
  >
    <SliderTrack>
      <SliderFilledTrack />
    </SliderTrack>
    <SliderThumb />
  </Slider>
);

const VolumeBtn = memo((props: VolumeProps): ReactElement => {
  const colorScheme = useColorModeValue('whiteAlpha', 'gray');

  return (
    <Popover closeOnBlur={false}>
      <PopoverTrigger>
        <IconButton
          colorScheme={colorScheme}
          aria-label="Adjust volume"
          icon={<FaVolumeUp />}
        />
      </PopoverTrigger>
      <PopoverContent p={3} shadow="base">
        <VolumeSlider {...props} />
      </PopoverContent>
    </Popover>
  );
});
VolumeBtn.displayName = 'VolumeBtn';

export const Player = ({ url }: { url: string }): ReactElement => {
  const btnColorScheme = useColorModeValue('whiteAlpha', 'gray');
  const [audioEl, state, controls] = useAudio({
    src: url,
    autoPlay: true,
  });

  return (
    <HStack spacing={8} justifyContent="center">
      <Box display="none">{audioEl}</Box>
      {state.paused ? (
        <IconButton
          onClick={controls.play}
          colorScheme={btnColorScheme}
          aria-label="Play"
          icon={<FaPlay />}
        />
      ) : (
        <IconButton
          onClick={controls.pause}
          colorScheme={btnColorScheme}
          aria-label="Pause"
          icon={<FaPause />}
        />
      )}
      <HStack spacing={2} flexGrow={1} justifyContent="center">
        <Box>{formatDuration(state.time)}</Box>
        <Box flexGrow={1}>
          <Box ml="7px">
            <PlayerProgress
              currentTime={state.time}
              duration={state.duration}
              onSeek={(time) => controls.seek(time)}
            />
          </Box>
        </Box>
        <Box>{formatDuration(state.duration)}</Box>
      </HStack>
      <VolumeBtn
        volume={state.volume * 100}
        onChange={(vol: number) => controls.volume(vol / 100)}
      />
    </HStack>
  );
};

export const RecordingPlayer = (): ReactElement => {
  const { t } = useTranslation('Telephony');

  const btnColorScheme = useColorModeValue('whiteAlpha', 'gray');
  const menuColor = useColorModeValue('gray.900', 'white');

  const { isOpen, toggleOpen, recordings, recording, setRecording } =
    useContext(PlayerContext);
  const onClose = () => {
    toggleOpen(false);
  };
  const setRecordingByUrl = (recordingUrl: string) => {
    const newRec = recordings.find((rec) => rec.url === recordingUrl);
    if (!newRec) {
      throw new Error(`Unable to find recording by url: ${recordingUrl}`);
    }

    setRecording(newRec);
  };

  return (
    <Drawer
      blockScrollOnMount={false}
      closeOnOverlayClick={false}
      placement="bottom"
      onClose={onClose}
      isOpen={isOpen}
      autoFocus={true}
      trapFocus={false}
      variant="alwaysOpen"
    >
      <DrawerContent bgColor="gray.800" color="white">
        <DrawerBody py={5}>
          <Flex>
            <VStack flexBasis="250px" alignSelf="end">
              <Heading
                as="h3"
                size="sm"
                flexGrow={1}
                mb={0}
                textAlign="left"
                w="100%"
              >
                {t('Telephony:player.select_recording')}
                {recordings.length > 1 && ` (${recordings.length})`}:
              </Heading>
              <HStack>
                <Select
                  value={recording?.url}
                  onChange={(ev) => setRecordingByUrl(ev.target.value)}
                  size="sm"
                  colorScheme="blackAlpha"
                  rounded="base"
                  required
                >
                  {recordings.map(({ url, filename }) => (
                    <option key={url} value={url}>
                      {filename}
                    </option>
                  ))}
                </Select>
                <Menu isLazy colorScheme="white">
                  <MenuButton
                    as={Button}
                    colorScheme={btnColorScheme}
                    rightIcon={<FaChevronDown />}
                  >
                    <Box mr={3}>
                      <FaDownload />
                    </Box>
                  </MenuButton>
                  <MenuList color={menuColor}>
                    <MenuItem
                      as={Link}
                      download
                      href={recordings[0]?.url}
                      icon={<FaDownload />}
                    >
                      {t('global:download')} <strong>{'(WAV)'}</strong>
                    </MenuItem>
                    <MenuItem
                      as={Link}
                      download
                      href={recordings[0]?.url}
                      icon={<FaDownload />}
                    >
                      {t('global:download')} <strong>{'(MP3)'}</strong>
                    </MenuItem>
                  </MenuList>
                </Menu>
              </HStack>
            </VStack>
            <Container maxW="container.lg">
              <Heading
                as="h3"
                size="md"
                flexGrow={1}
                textAlign="center"
                alignSelf="center"
              >
                {t('Telephony:player.play_recordings')}
              </Heading>
              {recording ? (
                <Player url={recording.url} />
              ) : (
                <span>{t('Telephony:player.select_recording')}</span>
              )}
            </Container>
            <Flex flexBasis="250px" justifyContent="flex-end">
              <IconButton
                icon={<FaTimes />}
                aria-label="Close player"
                colorScheme={btnColorScheme}
                size="xs"
                onClick={onClose}
              />
            </Flex>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export const PageWithPlayer = ({
  children,
}: PropsWithChildren): ReactElement => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [recordings, setRecordings] = useState<RecordingMedia[]>([]);
  const [recording, setRecording] = useState<RecordingMedia | null>(null);
  const toggleOpen = useCallback(
    (newOpen?: boolean) => {
      if (newOpen === undefined) {
        setIsOpen(!isOpen);
      } else {
        setIsOpen(newOpen);
      }
    },
    [isOpen]
  );

  useEffect(() => {
    if (recording && recording.onPlay) {
      recording.onPlay();
    }
  }, [recording]);

  return (
    <PlayerContext.Provider
      value={{
        isOpen,
        toggleOpen,
        recordings,
        setRecordings,
        recording,
        setRecording,
      }}
    >
      <RecordingPlayer />
      {children}
    </PlayerContext.Provider>
  );
};
