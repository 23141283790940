import { ReactElement, useMemo } from 'react';
import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { EventCellComponent } from 'app/Modules/Events/Assets/components';
import { contactLink } from 'app/Modules/Phonebook/Assets/js/util';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

import { Address as IAddress } from '../../../js/types';
import { MailEvent } from './types';

export const MailContactColumn: EventCellComponent<MailEvent> = ({
  row: { data: message },
}) => {
  const addresses = useMemo(() => {
    const from: IAddress | null = message.fromMailAddress
      ? { email: message.fromMailAddress, contact: message.fromContact }
      : null;

    const allAddresses = [
      from,
      ...message.addresses.map((addr) => addr.mailAddress),
    ];

    return allAddresses
      .filter((addr): addr is IAddress => !!addr)
      .sort(addrCompare);
  }, [message.fromContact, message.fromMailAddress, message.addresses]);

  return (
    <WithSeparator separator=", ">
      {addresses.map((address) => (
        <Address key={address.email} {...address} />
      ))}
    </WithSeparator>
  );
};

const Address = ({ email, contact }: IAddress): ReactElement => {
  const link = contact ? contactLink(contact) : null;
  if (contact && link) {
    return (
      <Link as={RouterLink} to={link}>
        {contact.name}
      </Link>
    );
  }

  return <Link href={`mailto:${email}`}>{email}</Link>;
};

function addrCompare(a: IAddress, b: IAddress): number {
  if (a.contact && !b.contact) {
    return -1;
  }
  if (!a.contact && b.contact) {
    return 1;
  }

  return 0;
}
