import { Flex } from '@chakra-ui/react';

import { EventCellComponent } from 'app/Modules/Events/Assets/components';

import { CustomEventMenu } from '../CustomEventMenu';
import { CustomEventItem } from '../types';

export const CustomActionsColumn: EventCellComponent<CustomEventItem> = ({
  row: event,
}) => (
  <Flex justifyContent="end">
    <CustomEventMenu {...event} />
  </Flex>
);
