import { menuAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, cssVar } from '@chakra-ui/react';

import { BrandColors } from 'BootQuery/Assets/js/brand-colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

const $bg = cssVar('menu-bg');

const baseStyle = (brandColors: BrandColors) => {
  return definePartsStyle({
    groupTitle: {
      [$bg.variable]: brandColors.background,
      color: brandColors.fontStaticDark,
      _dark: {
        color: brandColors.fontStaticLight,
        [$bg.variable]: brandColors.backgroundDark,
      },
    },
    list: {
      [$bg.variable]: brandColors.background,
      color: brandColors.fontStaticDark,
      _dark: {
        color: brandColors.fontStaticLight,
        [$bg.variable]: brandColors.backgroundDark,
      },
    },
    item: {
      color: brandColors.fontStaticDark,
      [$bg.variable]: brandColors.background,
      _dark: {
        color: brandColors.fontStaticLight,
        [$bg.variable]: brandColors.backgroundDark,
      },
      _hover: {
        color: brandColors.fontLight,
        [$bg.variable]: brandColors[700],
        _dark: {
          color: brandColors.fontDark,

          [$bg.variable]: brandColors[100],
        },
      },
      _focus: {
        color: brandColors.fontLight,
        [$bg.variable]: brandColors[700],
        _dark: {
          color: brandColors.fontDark,
          [$bg.variable]: brandColors[100],
        },
      },
    },
  });
};

export const menuTheme = (brandColors: BrandColors) => {
  return defineMultiStyleConfig({ baseStyle: baseStyle(brandColors) });
};
