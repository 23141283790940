import { ModuleEventTypeProviders } from 'app/Modules/Events/Assets/components';

import { TicketChangeEventCard } from './TicketChangeEventCard';

export * from './types';
export { TicketChangeEventCard };

const noVal = { getValue: () => '' };

export const ticketChangeEventProvider: ModuleEventTypeProviders = {
  ticketChange: {
    components: {
      Card: TicketChangeEventCard,
    },
    columns: {
      type: noVal,
      event: noVal,
      user: noVal,
      contact: noVal,
    },
  },
};
