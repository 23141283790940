import { memo, ReactElement } from 'react';
import { Skeleton, Td, Tr } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { tdStyle } from './styles';

const PlaceholderRow = memo(
  ({ colCount }: { colCount: number }): ReactElement => {
    const { t } = useTranslation();
    const cols = Array(colCount).fill(null);

    return (
      <Tr>
        {cols.map((_el, idx) => (
          <Td className={tdStyle} key={idx}>
            <Skeleton>{t('global:loading')}...</Skeleton>
          </Td>
        ))}
      </Tr>
    );
  }
);
PlaceholderRow.displayName = 'PlaceholderRow';

export { PlaceholderRow };
