import { ModalHeader } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const CasesBulkFormModalHeader = () => {
  const { t } = useTranslation('Sales');

  return (
    <ModalHeader>{t('Sales:campaigns.create_case_from_phonebook')}</ModalHeader>
  );
};
