import { ReactElement } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
  Stack,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  GeneratedForm,
  useFormDefinition,
} from 'BootQuery/Assets/components/FormEditor';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';

import { FormAddressList } from '../FormAddressList';
import { FormMails } from '../FormMails';
import { FormPhoneNumbers } from '../FormPhoneNumbers';
import { NationalNumberField } from '../NationalNumber';
import { CompanyDepartmentInput } from './CompanyDepartmentInput';
import { CompanyInput } from './CompanyInput';
import { CompanyLocationInput } from './CompanyLocationInput';
import { CompanyPositionInput } from './CompanyPositionInput';
import { PersonFormData } from './types';

export const PersonFormFields = (): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const {
    register,
    getValues,
    formState: { errors },
  } = useFormContext<PersonFormData>();

  const customFields = useFormDefinition('Phonebook.person');

  const validateName = (): boolean => {
    const { firstName, lastName } = getValues();

    return (
      (firstName ?? '').trim().length > 0 || (lastName ?? '').trim().length > 0
    );
  };

  return (
    <Box mb="72px">
      <Stack
        direction={{ base: 'column', lg: 'row' }}
        spacing="3"
        mb="5"
        bg="transparent"
      >
        <FormControl>
          <FormLabel fontWeight="bold">{t('Phonebook:first_name')}:</FormLabel>
          <Input
            {...register('firstName', {
              validate: validateName,
              deps: ['lastName'],
            })}
            isInvalid={!!errors.firstName}
            placeholder={t('Phonebook:first_name')}
          />
        </FormControl>
        <FormControl>
          <FormLabel fontWeight="bold">{t('Phonebook:last_name')}:</FormLabel>
          <Input
            {...register('lastName', {
              validate: validateName,
              deps: ['firstName'],
            })}
            isInvalid={!!errors.lastName}
            placeholder={t('Phonebook:last_name')}
          />
        </FormControl>
      </Stack>
      <FormControl mb="5">
        <FormLabel fontWeight="bold">
          {t('Phonebook:national_number')}:
        </FormLabel>
        <NationalNumberField />
      </FormControl>
      <Grid templateColumns="1fr 1fr" gap="16px">
        <FormControl mb="5">
          <FormLabel fontWeight="bold">{t('Phonebook:company')}:</FormLabel>
          <CompanyInput />
        </FormControl>
        <FormControl mb="5">
          <FormLabel fontWeight="bold">{t('Phonebook:position')}:</FormLabel>
          <CompanyPositionInput />
        </FormControl>
      </Grid>
      <Grid templateColumns="1fr 1fr" gap="16px">
        <FormControl mb="5">
          <FormLabel fontWeight="bold">{t('Phonebook:department')}:</FormLabel>
          <CompanyDepartmentInput />
        </FormControl>
        <FormControl mb="5">
          <FormLabel fontWeight="bold">
            {t('Phonebook:company_location')}:
          </FormLabel>
          <CompanyLocationInput />
        </FormControl>
      </Grid>
      <Divider />
      <Box mb="5">
        <FormAddressList />
      </Box>
      <Divider />

      <Box mb="5">
        <FormMails />
      </Box>
      <Divider />

      <Box mb="5">
        <FormPhoneNumbers />
      </Box>
      <Box mb="5">
        {customFields ? (
          <GeneratedForm fields={customFields} />
        ) : (
          <LoadingPage />
        )}
      </Box>
    </Box>
  );
};
