import { useCallback, useMemo } from 'react';

import { mimeTypes } from '@bq/components/mime-types';

import { getMimeSearcher } from './mime-searcher';
import { AllowTypesSelectOptions } from './types';

export const useMimesAsSelectOptions = () => {
  const allExts = useMemo(() => {
    const mimes = Object.entries(mimeTypes);

    return mimes.reduce((all: AllowTypesSelectOptions[], current) => {
      const items: AllowTypesSelectOptions[] = current[1].map((item) => ({
        label: `.${item}`,
        value: current[0],
      }));

      return [...all, ...items];
    }, []);
  }, []);

  const searcher = getMimeSearcher(allExts);

  const searchMime = useCallback(
    (search: string) => {
      const results = searcher.search(search, { limit: 10 });

      return results;
    },
    [searcher]
  );

  return { allExts, searchMime };
};
