import {
  ChangeEvent,
  memo,
  MouseEvent,
  ReactElement,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { Flex, Input, useColorModeValue } from '@chakra-ui/react';

import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';

import { useSelectedPath } from './selected-path';
import { SelectFieldSettingsContext } from './SelectFieldSettingsContext';
import { SelectItem } from './types';

interface SelectEditItemProps {
  selectId: string;
  value: SelectItem;
}

function useIsSelected(selectId: string, itemId: string): boolean {
  const path = useSelectedPath();

  return useMemo(() => {
    return path.some((seg) => {
      return seg.selectId === selectId && seg.itemId === itemId;
    });
  }, [path, selectId, itemId]);
}

const SelectEditItem = memo(
  ({ selectId, value }: SelectEditItemProps): ReactElement => {
    const {
      setSelectedItem,
      methods: { updateItem, deleteItem },
    } = useContext(SelectFieldSettingsContext);

    const hoveredItemBg = useColorModeValue('blue.50', 'blue.900');

    const isSelected = useIsSelected(selectId, value.key);
    const selectedItemBg = useColorModeValue('blue.200', 'blue.800');
    const itemBg = isSelected ? selectedItemBg : undefined;

    const inputBg = useColorModeValue('white', 'gray.800');

    const onDelete = useCallback(
      (ev: MouseEvent<HTMLButtonElement>) => {
        ev.stopPropagation();
        deleteItem(selectId, value.key);
      },
      [selectId, value.key, deleteItem]
    );

    const onRename = useCallback(
      (ev: ChangeEvent<HTMLInputElement>) => {
        const name = ev.target.value;
        updateItem(selectId, { ...value, name });
      },
      [selectId, updateItem, value]
    );

    const onSelect = useCallback(() => {
      setSelectedItem({ selectId, itemId: value.key });
    }, [setSelectedItem, selectId, value.key]);

    return (
      <Flex
        borderBottomWidth="thin"
        borderStyle="solid"
        bg={itemBg}
        _hover={{ background: hoveredItemBg }}
        px="2"
        py="1"
        onClick={onSelect}
      >
        <Input
          size="sm"
          bg={inputBg}
          flexGrow={1}
          value={value.name}
          onChange={onRename}
        />
        <DeleteButton
          size="sm"
          variant="ghost"
          flexShrink={1}
          onClick={onDelete}
        />
      </Flex>
    );
  }
);
SelectEditItem.displayName = 'SelectEditItem';

export { SelectEditItem };
