import { memo, ReactElement } from 'react';
import { MenuItemOption } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { Column, useVisibleColumns } from 'BootQuery/Assets/components/Table';

import { ScrollableMenuOptionGroup } from './ScrollableMenuOptionGroup';
import { ColumnMap } from './types';

interface Props {
  columns: Column[];
  value: ColumnMap;
  onChange: (value: ColumnMap) => void;
}

const ColumnSelector = memo(
  ({ columns, value, onChange }: Props): ReactElement => {
    const { t } = useTranslation();
    const visibleColumns = useVisibleColumns(columns, value).map(
      (col) => col.key
    );

    const columnList = columns
      .filter((column) => !column.alwaysVisible && !!column.title)
      .map((column) => ({
        ...column,
        visible: column.alwaysVisible || visibleColumns.includes(column.key),
      }));

    const handleClick = (key: string, visible: boolean) => {
      onChange({
        ...value,
        [key]: visible,
      });
    };

    return (
      <ScrollableMenuOptionGroup
        title={t('global:shown_columns')}
        type="checkbox"
        value={visibleColumns}
      >
        {columnList.map(({ key, title, visible }) => (
          <MenuItemOption
            key={key}
            value={key}
            isChecked={visible}
            onClick={() => handleClick(key, !visible)}
          >
            {title}
          </MenuItemOption>
        ))}
      </ScrollableMenuOptionGroup>
    );
  }
);
ColumnSelector.displayName = 'ColumnSelector';

export { ColumnSelector };
