import { useMemo } from 'react';
import { Editor } from '@tiptap/react';

import { wrapAction } from '../../utils';

export const insertTable = (editor: Editor) => {
  editor.commands.insertTable({ rows: 3, cols: 3, withHeaderRow: true });
};

export const deleteTable = (editor: Editor) => {
  editor.commands.deleteTable();
};

export const insertColumnBefore = (editor: Editor) => {
  editor.commands.addColumnBefore();
};

export const insertColumnAfter = (editor: Editor) => {
  editor.commands.addColumnAfter();
};

export const deleteColumn = (editor: Editor) => {
  editor.commands.deleteColumn();
};

export const insertRowBefore = (editor: Editor) => {
  editor.commands.addRowBefore();
};

export const insertRowAfter = (editor: Editor) => {
  editor.commands.addRowAfter();
};

export const deleteRow = (editor: Editor) => {
  editor.commands.deleteRow();
};

export const mergeCells = (editor: Editor) => {
  editor.commands.mergeCells();
};

export const splitCells = (editor: Editor) => {
  editor.commands.splitCell();
};

export const useTableToggles = (editor: Editor | null) => {
  return useMemo(
    () => ({
      insertTable: wrapAction(insertTable, editor),
      deleteTable: wrapAction(deleteTable, editor),
      insertColumnBefore: wrapAction(insertColumnBefore, editor),
      insertColumnAfter: wrapAction(insertColumnAfter, editor),
      deleteColumn: wrapAction(deleteColumn, editor),
      insertRowBefore: wrapAction(insertRowBefore, editor),
      insertRowAfter: wrapAction(insertRowAfter, editor),
      deleteRow: wrapAction(deleteRow, editor),
      mergeCells: wrapAction(mergeCells, editor),
      splitCells: wrapAction(splitCells, editor),
    }),
    [editor]
  );
};
