import { PropsWithChildren, ReactElement, useMemo } from 'react';

import { CreateCompanyModal } from './CreateCompanyModal';
import { CreatePersonModal } from './CreatePersonModal';
import { PhonebookFormsContext } from './PhonebookFormsContext';
import { OnCompanyCreate, OnPersonCreate } from './types';
import { useCreateCompanyModal } from './use-create-company-modal';
import { useCreatePersonModal } from './use-create-person-modal';

interface Props {
  onPersonCreate?: OnPersonCreate;
  onCompanyCreate?: OnCompanyCreate;
}

type AllProps = PropsWithChildren<Props>;

export const PhonebookFormsProvider = ({
  children,
  onPersonCreate,
  onCompanyCreate,
}: AllProps): ReactElement => {
  const { createPerson, modalProps: personModalProps } = useCreatePersonModal({
    onCreate: onPersonCreate,
  });
  const { createCompany, modalProps: companyModalProps } =
    useCreateCompanyModal({ onCreate: onCompanyCreate });

  const ctxValue = useMemo(
    () => ({ createPerson, createCompany }),
    [createPerson, createCompany]
  );

  return (
    <PhonebookFormsContext.Provider value={ctxValue}>
      {children}
      <CreateCompanyModal {...companyModalProps} />
      <CreatePersonModal {...personModalProps} />
    </PhonebookFormsContext.Provider>
  );
};
