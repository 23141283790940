import { ReactElement, useEffect } from 'react';
import { Spinner } from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { AsyncCreatableSelect as Select } from 'chakra-react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  chakraSelectStyles,
  formatCreateLabel,
  itemToSelectOption,
  selectItemToValue,
  SelectOption,
  selectStyles,
  valueToSelectItem,
} from 'BootQuery/Assets/components/Select';
import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { getCompany } from '../api';
import { CompanyApiData, ListItem } from '../types';
import { PersonFormData } from './types';

type CompanyResponse = ListingResponse<ListItem>;

async function searchCompanies(search: string): Promise<SelectOption[]> {
  const {
    data: { data: companies },
  } = await Api.get<CompanyResponse>('/api/phonebook/companies', {
    params: {
      filters: search ? { $search: search } : {},
      fields: ['ID', 'name'],
    },
  });

  return companies.map(itemToSelectOption);
}

interface CompanyInputProps {
  isRequired?: boolean;
  companyID?: string | number;
}
export const CompanyInput = ({
  isRequired = false,
  companyID,
}: CompanyInputProps): ReactElement => {
  const { t } = useTranslation('Phonebook');

  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext<PersonFormData>();

  const { data: companyData, isLoading } = useQuery({
    queryKey: ['companyLocationSelect', companyID],
    queryFn: () => {
      if (companyID) {
        return getCompanyInfo(companyID);
      }

      return null;
    },
  });

  useEffect(() => {
    if (companyData) {
      setValue('company', companyData);
    }
  }, [companyData, setValue]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Controller
      control={control}
      rules={{ required: isRequired }}
      name="company"
      render={({ field }) => (
        <Select<SelectOption, false>
          styles={chakraSelectStyles}
          chakraStyles={selectStyles}
          isInvalid={!!errors.company}
          cacheOptions
          defaultOptions
          isClearable
          loadOptions={searchCompanies}
          value={valueToSelectItem(field.value)}
          onChange={(val: SelectOption | null) => {
            field.onChange(selectItemToValue(val));
          }}
          formatCreateLabel={formatCreateLabel}
          placeholder={t('Phonebook:company')}
          selectedOptionColorScheme="brand"
        />
      )}
    />
  );
};

// FUCK YOU, YOU PUT IT SOMEWHERE ELSE IF U HAVE PROBLEM WITH ME EXPORTING FROM HERE
export function getCompanyInfo(
  companyID: number | string
): Promise<CompanyApiData> {
  return getCompany(companyID, {
    fields: ['ID', 'name'],
  });
}
