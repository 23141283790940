import {
  createContext,
  PropsWithChildren,
  ReactElement,
  useContext,
  useMemo,
} from 'react';

import { FieldType } from './types';

export interface IGeneratedFormContext {
  prefix: string | null;
  fieldTypes: FieldType[];
}

export const GeneratedFormContext = createContext<IGeneratedFormContext | null>(
  null
);

export function useGeneratedFormContext(): IGeneratedFormContext {
  const ctx = useContext(GeneratedFormContext);
  if (!ctx) {
    throw new Error('Missing context GeneratedFormContext');
  }

  return ctx;
}

type ProviderProps = PropsWithChildren<IGeneratedFormContext>;

export const GeneratedFormProvider = ({
  children,
  prefix,
  fieldTypes,
}: ProviderProps): ReactElement => {
  const value = useMemo(() => ({ prefix, fieldTypes }), [prefix, fieldTypes]);

  return (
    <GeneratedFormContext.Provider value={value}>
      {children}
    </GeneratedFormContext.Provider>
  );
};
