import {
  makeDateFilter,
  makeDateToFilter,
} from '@bq/components/FilterBar/filters/DateFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const ticketFilterScheduledFor = makeDateFilter({
  name: () => i18n.t('Ticketing:form.scheduled_for'),
  toFilter: makeDateToFilter('scheduledFor'),
});
