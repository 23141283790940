import { lazyImport } from '@bq/components/lazy-import';

import 'BootQuery/Assets/js/i18n';

import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

const Dashboard = lazyImport(() => import('../pages/Dashboard'), 'Dashboard');
const Calls = lazyImport(() => import('../pages/Calls'), 'Calls');
const PcOperator = lazyImport(
  () => import('../pages/PcOperator'),
  'PcOperator'
);
const Announcements = lazyImport(
  () => import('../pages/Announcements'),
  'Announcements'
);
const CallReports = lazyImport(() => import('../pages/Reports'), 'Reports');
const Dial = lazyImport(() => import('../pages/Dial'), 'Dial');

export const ReactRoutes: ReactRoute[] = [
  { path: '/telephony/dashboard', component: Dashboard },
  { path: '/telephony/operator', component: PcOperator },
  { path: '/telephony/calls', component: Calls },
  { path: '/telephony/calls/:overviewSlug', component: Calls },
  { path: '/telephony/announcements', component: Announcements },
  { path: '/telephony/reports', component: CallReports },
  { path: '/telephony/dial', component: Dial },
];
