import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Good } from '../../../types';
import { FieldProps } from '../../types';

export const ModelNumberField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    register,
    formState: { errors },
  } = useFormContext<Good>();

  return (
    <FormControl
      isInvalid={!!errors.good?.modelNumber}
      mb="5"
      {...formControlProps}
    >
      <FormLabel>{t('Products:model_number')}</FormLabel>
      <Input
        size={['md', 'md', 'sm']}
        {...register('good.modelNumber')}
        isInvalid={!!errors.good?.modelNumber}
        placeholder={t('Products:model_number')}
      />
    </FormControl>
  );
};
