import { ReactElement } from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

interface FilterNameProps {
  name: string;
}

export const FilterName = ({ name }: FilterNameProps): ReactElement => {
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.200');

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      px="3"
      height="8"
      background={bgColor}
      borderLeftRadius="md"
      flexShrink={0}
    >
      <Text mb="0" fontSize="sm" fontWeight="bold">
        {name}
      </Text>
    </Box>
  );
};
