import { stringify as stringifyQuery } from 'qs';
import { useLocation } from 'react-use';

import { FilterValue } from '../FilterBar';

function simplifyFilter(filter: FilterValue) {
  const clean = { ...filter };
  if (clean.operator === null) {
    delete clean.operator;
  }

  return clean;
}

/**
 *
 * @param {FilterValue|FilterValue[]} filter - Filter Value that you want to use
 * @param {string} path - Redirect to other place by default /
 * @returns
 */

export function useFilteredLink(
  filter: FilterValue | FilterValue[],
  path?: string
): string {
  const location = useLocation();
  const filters = (Array.isArray(filter) ? filter : [filter]).map(
    simplifyFilter
  );

  const currPath = path ?? location.pathname ?? '/';

  const query = stringifyQuery({ filters }, { addQueryPrefix: false });

  return `${currPath}?${query}`;
}
