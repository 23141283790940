import { ReactElement } from 'react';
import { FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { LoadingPage } from '@bq/components/LoadingPage';

import { useSettingsCaseTypes } from '../CaseTypes/use-settings-case-types';
import { CaseTypeField } from './CaseTypeField';
import { CaseCampaignFormBase } from './types';

interface Props {
  mode: string;
}

export const CampaignFormFields = ({ mode }: Props): ReactElement => {
  const { t } = useTranslation();
  const { register } = useFormContext<CaseCampaignFormBase>();

  const { data: caseTypes } = useSettingsCaseTypes();

  return (
    <VStack>
      <FormControl>
        <FormLabel>{t('Sales:campaign.name')}</FormLabel>
        <Input {...register('name')} />
      </FormControl>
      <FormControl>
        <FormLabel>{t('Sales:form.case_type')}</FormLabel>

        {caseTypes ? (
          <CaseTypeField<CaseCampaignFormBase>
            name="caseTypeID"
            isDisabled={mode === 'edit'}
            caseTypes={caseTypes}
          />
        ) : (
          <LoadingPage />
        )}
      </FormControl>
    </VStack>
  );
};
