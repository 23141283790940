import { EventCellComponent } from 'app/Modules/Events/Assets/components';

import { CallActions } from '../CallActions';
import { useFormattedCall } from '../CallList/format-call';
import { CallEvent } from './types';

export const CallActionsColumn: EventCellComponent<CallEvent> = ({
  row: { data },
}) => {
  const call = useFormattedCall(data);

  return <CallActions call={call} />;
};
