import { useCallback, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { DeepPartial } from 'react-hook-form';

import { useFormState } from 'BootQuery/Assets/components/form-state';
import { loadValues } from 'BootQuery/Assets/components/FormEditor';

import { createCustomEvent, getCustomEvent, updateCustomEvent } from '../api';
import { getCustomEventForm } from '../custom-forms';
import { AddCustomEvent, EditCustomEvent } from '../EventList/EventListContext';
import { fromBackend, toBackend } from '../form-util';
import { CustomEvent, CustomEventFormData } from '../types';
import { CustomEventModalProps } from './CustomEventModal';

type FormData = DeepPartial<CustomEventFormData>;

export interface UseCustomEventModalProps {
  onAdded?: (id: number) => void;
  onUpdated?: (event: CustomEvent) => void;
}

type ModalState =
  | { mode: 'new'; defaults: FormData }
  | { mode: 'edit'; eventID: number; isLoading: boolean };

export interface UseCustomEventModalResult {
  addCustomEvent: AddCustomEvent;
  editCustomEvent: EditCustomEvent;
  modalProps: CustomEventModalProps;
}

export const useCustomEventModal = ({
  onAdded,
  onUpdated,
}: UseCustomEventModalProps): UseCustomEventModalResult => {
  const [state, setState] = useState<ModalState | null>(null);
  const [data, setData] = useState<FormData>({});
  const [formState, setFormState] = useFormState();
  const queryClient = useQueryClient();

  const onCancel = useCallback(() => {
    setState(null);
    setData({});
  }, []);

  const onSubmit = useCallback(
    async (data: CustomEventFormData) => {
      if (!state) {
        throw new Error('Cannot submit event modal while nothing to add/edit');
      }

      setFormState('saving');

      const event = toBackend(data);
      if (state.mode === 'new') {
        const { ID } = await createCustomEvent(event);
        if (onAdded) {
          onAdded(ID);
        }
      } else {
        await updateCustomEvent(state.eventID, event);
        if (onUpdated) {
          onUpdated({ ID: state.eventID, ...event });
        }
      }
      setFormState(null);
      onCancel();
    },
    [state, setFormState, onCancel, onAdded, onUpdated]
  );

  const addCustomEvent: AddCustomEvent = useCallback((defaults) => {
    console.log('With defaults: ', addCustomEvent);
    setData(defaults);
    setState({ mode: 'new', defaults });
  }, []);

  const editCustomEvent: EditCustomEvent = useCallback(
    (eventID) => {
      setState({ mode: 'edit', eventID, isLoading: true });
      getCustomEvent(eventID).then(async (event) => {
        const eventForm = fromBackend(event);
        const formDef = await getCustomEventForm(queryClient, event.typeID);
        eventForm.data = loadValues(formDef, eventForm.data);

        setData(eventForm);
        setState({ mode: 'edit', eventID, isLoading: false });
      });
    },
    [queryClient]
  );

  return {
    addCustomEvent,
    editCustomEvent,
    modalProps: {
      isOpen: state !== null,
      isLoading: state?.mode === 'edit' && state.isLoading,
      onSubmit,
      initial: data,
      formState,
      onCancel,
    },
  };
};
