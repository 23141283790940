import { FaSms } from 'react-icons/fa';
import { NavigateFunction } from 'react-router-dom';

import { BulkAction } from 'BootQuery/Assets/components/Table';
import i18n from 'BootQuery/Assets/js/i18n';

import { SMSContact } from '../PersonList/person-list-bulk-actions';
import { PersonApiData } from '../types';
import { parseSmsSelection } from '../utils';

export const sendSms = (
  navigate: NavigateFunction
): BulkAction<PersonApiData, SMSContact> => ({
  name: i18n.t('global:bulk_actions:send_sms'),
  icon: FaSms({}),
  isAllowed: (selection) => selection.length > 0,
  handler: async (selection) => {
    navigate('/sms/conversations/all/editor/on', {
      state: { action: selection },
    });
  },
  parseSelection(selection) {
    return parseSmsSelection(selection).map(({ phoneNumber, contact }) => ({
      phoneNumber,
      ID: contact.ID,
      name: `${contact.firstName ?? ''} ${contact.lastName ?? ''}`.trim(),
    }));
  },
  disabledTooltip: i18n.t('global:disabled_action_tooltips.no_sms_contact'),
  displayText: true,
});
