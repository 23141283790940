import { ReactElement } from 'react';

export const SeparatorIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 852.69 566"
    fill="currentColor"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="M169.79 138.54h504.04v4.91H169.79z" />
    <path d="M675.69 116H167.9a20.65 20.65 0 0 0-20.65 20.65v8.7A20.66 20.66 0 0 0 167.9 166h507.79a20.66 20.66 0 0 0 20.65-20.65v-8.7A20.65 20.65 0 0 0 675.69 116ZM169.79 418.91h504.04v4.91H169.79z" />
    <path d="M675.69 396.37H167.9a20.65 20.65 0 0 0-20.65 20.65v8.7a20.66 20.66 0 0 0 20.65 20.65h507.79a20.66 20.66 0 0 0 20.65-20.65v-8.7a20.65 20.65 0 0 0-20.65-20.65ZM168.92 22.54h504.04v4.91H168.92z" />
    <path d="M674.85 0H167.03a20.64 20.64 0 0 0-20.65 20.65v8.7A20.65 20.65 0 0 0 167.03 50h507.82a20.65 20.65 0 0 0 20.65-20.65v-8.7A20.64 20.64 0 0 0 674.85 0ZM169.79 538.54h504.04v4.91H169.79z" />
    <path d="M675.69 516H167.9a20.65 20.65 0 0 0-20.65 20.65v8.7A20.66 20.66 0 0 0 167.9 566h507.79a20.66 20.66 0 0 0 20.65-20.65v-8.7A20.65 20.65 0 0 0 675.69 516ZM46.62 282.06h774.13v1.88H46.62z" />
    <path d="M823.44 250.12H43.94a29.26 29.26 0 0 0-29.25 29.26v7.24a29.25 29.25 0 0 0 29.25 29.25h779.5a29.25 29.25 0 0 0 29.25-29.25v-7.24a29.26 29.26 0 0 0-29.25-29.26Z" />
    <path d="M12.81 207.05h25.06v149.32H12.81z" />
    <path d="M38.95 194.24H11.74A11.74 11.74 0 0 0 0 205.98v151.47a11.74 11.74 0 0 0 11.74 11.79h27.21a11.74 11.74 0 0 0 11.74-11.79V205.98a11.73 11.73 0 0 0-11.74-11.74ZM814.81 207.05h25.06v149.32h-25.06z" />
    <path d="M840.95 194.24h-27.21A11.74 11.74 0 0 0 802 205.98v151.47a11.74 11.74 0 0 0 11.74 11.79h27.21a11.74 11.74 0 0 0 11.74-11.79V205.98a11.73 11.73 0 0 0-11.74-11.74Z" />
  </svg>
);
