import { ReactElement, useState } from 'react';
import { Flex, VStack } from '@chakra-ui/react';

import { LoadingPage } from '@bq/components/LoadingPage';
import { WithChange } from '@bq/components/SettingsCRUD';
import { useSettingsCrud } from '@bq/components/SettingsCRUD/use-settings-crud';

import { AddCaseTypeButton } from './AddCaseTypeButton';
import { CaseTypeItem } from './CaseTypeItem';
import { CaseTypesFormBase } from './types';
import { useSettingsCaseTypes } from './use-settings-case-types';

export const CaseTypes = (): ReactElement => {
  const { data: types } = useSettingsCaseTypes();

  return types ? <CaseTypesContent types={types} /> : <LoadingPage />;
};

interface CaseTypesContentProps {
  types: CaseTypesFormBase[];
}

const CaseTypesContent = ({ types }: CaseTypesContentProps): ReactElement => {
  useState<WithChange<CaseTypesFormBase> | null>(null);
  const [modifiedTypes, modifyItem] = useSettingsCrud<CaseTypesFormBase>({
    parent: '#settings-form',
    inputName: 'sales[caseTypes]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: types ?? [],
  });

  return (
    <VStack>
      {modifiedTypes.map((type) => (
        <CaseTypeItem key={type.ID} caseType={type} modify={modifyItem} />
      ))}

      <Flex w="full" justify="end">
        <AddCaseTypeButton modify={modifyItem} />
      </Flex>
    </VStack>
  );
};
