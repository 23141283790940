import { ReactElement, useMemo } from 'react';
import { HStack, useRadioGroup } from '@chakra-ui/react';

import { ButtonRadioOption } from './ButtonRadioOption';
import { Option, OptionType } from './types';

interface ButtonRadioProps {
  name: string;
  value: string;
  onChange: (value: string) => void;
  options: OptionType[];
}

export const ButtonRadio = ({
  value,
  onChange,
  name,
  options: inOptions,
}: ButtonRadioProps): ReactElement => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name,
    value,
    onChange,
  });
  const options = useMemo(() => fullOptions(inOptions), [inOptions]);

  const group = getRootProps();

  return (
    <HStack spacing="0.5" justifyContent="center" {...group}>
      {options.map((option) => (
        <ButtonRadioOption
          key={option.id}
          {...option}
          getRadioProps={getRadioProps}
        />
      ))}
    </HStack>
  );
};

function fullOptions(options: OptionType[]): Option[] {
  return options.map((option) => {
    if (typeof option === 'string') {
      return { id: option, title: option };
    }

    return option;
  });
}
