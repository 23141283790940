import { QueryClient } from '@tanstack/react-query';

import { DefinitionType, getCustomFormDefinition } from './api';
import { FieldValue } from './types';

export async function getFormDefinition(
  queryClient: QueryClient,
  name: string,
  mode: DefinitionType = 'use'
): Promise<FieldValue[]> {
  const res = await queryClient.fetchQuery({
    queryKey: [`useCustomFormDefinition:${name}:${mode}`],
    queryFn: () => getCustomFormDefinition(name, mode),
  });

  return res.data;
}
