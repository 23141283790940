import { Api } from 'BootQuery/Assets/js/api';

import { ContactSelectOption } from '../types';

export const createCompany = async (
  value: string
): Promise<ContactSelectOption> => {
  const { data } = await Api.post<{ ID: number }>('/api/phonebook/companies', {
    name: value,
  });

  return { type: 'company', value: data.ID, label: value };
};
