import { useCallback, useState } from 'react';

import { useFormState } from 'BootQuery/Assets/components/form-state';

import { insertCompany } from '../api';
import { CompanyFormData } from '../CompanyForm';
import {
  CompanyCreate,
  CreateCompanyModalProps,
  OnCompanyCreate,
} from './types';

type FormData = Partial<CompanyFormData>;

export interface UseCreateCompanyModalProps {
  onCreate?: OnCompanyCreate;
}

export interface UseCreateCompanyModalResult {
  createCompany: CompanyCreate;
  modalProps: CreateCompanyModalProps;
}

interface ModalState {
  editing: FormData;
  onCreate?: OnCompanyCreate;
}

export function useCreateCompanyModal({
  onCreate,
}: UseCreateCompanyModalProps): UseCreateCompanyModalResult {
  const [modalState, setModalState] = useState<ModalState | null>(null);
  const [formState, setFormState] = useFormState();

  const createCompany: CompanyCreate = useCallback(
    (defaults, onCreate) => {
      setModalState({
        onCreate,
        editing: {
          nationalNumber: { type: 'HR-oib', nationalNumber: '' },
          ...defaults,
        },
      });
      setFormState(null);
    },
    [setFormState]
  );

  const onClose = useCallback(() => {
    setModalState(null);
    setFormState(null);
  }, [setFormState]);

  const onSave = useCallback(
    async (formData: FormData) => {
      setFormState('saving');

      const companyID = await insertCompany(formData);

      if (modalState?.onCreate) {
        modalState.onCreate(companyID, formData);
      }
      if (onCreate) {
        onCreate(companyID, formData);
      }
      setFormState('saved');

      onClose();
    },
    [setFormState, onCreate, onClose, modalState]
  );

  return {
    createCompany,
    modalProps: {
      editing: modalState?.editing ?? null,
      formState,
      onClose,
      onSave,
    },
  };
}
