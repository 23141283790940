import { memo, ReactElement } from 'react';
import { ButtonGroup, IconButton, Tooltip } from '@chakra-ui/react';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

const Actions = memo(
  (): ReactElement => (
    <ButtonGroup>
      <Tooltip hasArrow label="Add">
        <IconButton
          as={Link}
          to="/phonebook/companies/create"
          variant="outline"
          colorScheme="green"
          aria-label="Add"
          icon={<FaPlus />}
        />
      </Tooltip>
    </ButtonGroup>
  )
);
Actions.displayName = 'Actions';

export { Actions };
