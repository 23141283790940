import { useMemo } from 'react';
import { Editor } from '@tiptap/react';

import { wrapAction } from '../../utils';

export const toggleAlignLeft = (editor: Editor) => {
  editor.commands.setTextAlign('left');
};

export const toggleAlignRight = (editor: Editor) => {
  editor.commands.setTextAlign('right');
};

export const toggleAlignCenter = (editor: Editor) => {
  editor.commands.setTextAlign('center');
};

export const toggleAlignJustify = (editor: Editor) => {
  editor.commands.setTextAlign('justify');
};

export const useAlignToggle = (editor: Editor | null) => {
  return useMemo(
    () => ({
      toggleAlignLeft: wrapAction(toggleAlignLeft, editor),
      toggleAlignRight: wrapAction(toggleAlignRight, editor),
      toggleAlignCenter: wrapAction(toggleAlignCenter, editor),
      toggleAlignJustify: wrapAction(toggleAlignJustify, editor),
    }),
    [editor]
  );
};
