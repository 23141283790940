import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { get, has, set } from 'lodash-es';

import { useUserSettingsContext } from '../../components/UserSettingsContext';
import { getUserSetting, setUserSetting } from '../userSettings';
import { isFetched, setFetched as setIsFetched } from './fetched';

/**
 * A hook that acts like useState, but reads/writes the value as user settings transparently
 *
 * @param settingKey Path to the setting.
 * For example "Module.category.key" or ["Module", "category", "key"]
 *
 * @param defaultSetting Default value for the setting. Must supply something,
 * either an empty state or in case of not using automatic fetching,
 * the value manually fetched by other methods.
 *
 * @param fetch Defaults to true. Should the value be fetched automatically?
 * If not, you should supply it using the defaultSetting param.
 * Disabling this is useful when using a single getSetting call to fetch all values.
 * When this is, the loaded param of return
 *
 * @returns [setting, setSetting, loaded]
 */
export function useUserSetting<T>(
  key: string | string[],
  defaultSetting: T,
  fetch = true
): [setting: T, setSetting: Dispatch<SetStateAction<T>>, loaded: boolean] {
  const settingKey = useMemo(
    () => (typeof key === 'string' ? key.split('.') : key),
    [key]
  );
  const { settings, setSettings, fetched, setFetched } =
    useUserSettingsContext();

  const loaded = useMemo(
    () => !fetch || has(settings, settingKey) || isFetched(fetched, settingKey),
    [fetch, settings, settingKey, fetched]
  );

  // Since settingKey can be an array, and defaultSetting can be an array or an object,
  // using them directly would cause an infinite refetch loop since useEffect does a strict
  // equality comparison, always detecting changes because {} !== {} and [] !== [].
  //
  // Both of these should be serialisable since settingKey is either string or string[],
  // and defaultSetting is stored as JSON so the backend couldn't
  // handle anything not serialisable anyway.
  const fetchHookDeps = [
    fetch,
    setSettings,
    loaded,
    JSON.stringify(settingKey),
    JSON.stringify(defaultSetting),
  ];

  // Fetch and set loaded state, but only if fetching enabled
  // If settingKey changes, the new value should be fetched
  useEffect(
    () => {
      if (!fetch || loaded) {
        return;
      }

      getUserSetting<T | null>(settingKey).then((val) => {
        // Non-existent values come back as null from the API, so keep default in that case
        const newVal = val === null ? defaultSetting : val;
        setSettings((prev) => set({ ...prev }, settingKey, newVal));
        setFetched((prev) => setIsFetched(prev, settingKey));
      });
    },
    fetchHookDeps // eslint-disable-line react-hooks/exhaustive-deps
  );

  const store: Dispatch<SetStateAction<T>> = useCallback(
    (val) => {
      setSettings((prev) => {
        const currentVal = get(prev, settingKey);
        const newVal =
          typeof val === 'function'
            ? (val as Dispatch<SetStateAction<T>>)(currentVal)
            : val;

        setUserSetting(settingKey, newVal);

        return set({ ...prev }, settingKey, newVal);
      });
    },
    [settingKey, setSettings]
  );

  const setting = useMemo(
    () => get(settings, settingKey, defaultSetting),
    [settings, settingKey, defaultSetting]
  );

  return [setting, store, loaded];
}

export function useLoadUserSettings<T>(
  settingKey: string | string[],
  defaultSetting: T
): boolean {
  const [, , loaded] = useUserSetting(settingKey, defaultSetting, true);

  return loaded;
}
