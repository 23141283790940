import { useContext } from 'react';

import { TicketingContext } from './TicketingContext';
import { ITicketingContext } from './types';

export function useTicketingContext(): ITicketingContext {
  const ctx = useContext(TicketingContext);
  if (!ctx) {
    throw new Error('Missing Ticketing Context');
  }

  return ctx;
}
