import { ReactElement } from 'react';
import { Box, Flex, SkeletonText, Text } from '@chakra-ui/react';
import { format as dateFormat } from 'date-fns';
import { hr } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from 'BootQuery/Assets/components/Card';

import { useCustomEventForm } from '../custom-forms';
import { CustomEventCardContacts } from '../CustomEventCardContacts';
import { CustomEventContent } from '../CustomEventContent';
import { CustomEventMenu } from '../CustomEventMenu';
import { useCustomEvents } from '../CustomEventsContext';
import { useEventList } from '../EventList/EventListContext';
import { CustomEventItem, CustomEventType, EventCardComponent } from '../types';

export const CustomEventCard: EventCardComponent<CustomEventItem> = (
  event
): ReactElement => {
  const { t } = useTranslation('Events');
  const { data } = event;
  const formDef = useCustomEventForm(data.typeID);
  const eventType = useEventType(event.data.typeID);
  const { viewOnly } = useEventList();

  if (!eventType) {
    return <Card></Card>;
  }

  return (
    <Card>
      <CardHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Flex alignItems="center">
          <Text as="strong" fontWeight="bold">
            {eventType.name}:
          </Text>
          &nbsp;{data.title}
        </Flex>
        <Flex alignItems="center">
          <Box>{formatDate(event.timestamp)}</Box>
          {!viewOnly && <CustomEventMenu {...event} />}
        </Flex>
      </CardHeader>
      {formDef ? (
        <CustomEventContent data={data.data} formDefinition={formDef} />
      ) : (
        <CardBody>
          <SkeletonText />
        </CardBody>
      )}
      <CardFooter>
        <Text as="strong" fontWeight="bold">
          {t('Events:creator')}{' '}
        </Text>
        <Text as="span">{data.user?.username ?? '-'}</Text>
        {' | '}
        <Text as="strong" fontWeight="bold">
          {t('Events:contact')}{' '}
        </Text>
        <CustomEventCardContacts contacts={data.contacts} />
      </CardFooter>
    </Card>
  );
};

const useEventType = (typeID: number): CustomEventType | null => {
  const { customEventTypes } = useCustomEvents();
  if (!customEventTypes) {
    return null;
  }

  const eventType = customEventTypes.find((type) => type.ID === typeID);
  if (!eventType) {
    throw new Error(`Unable to find custom event type with ID ${typeID}`);
  }

  return eventType;
};

const formatDate = (date: Date | string | number | null): string => {
  if (!date) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return dateFormat(dateObj, 'Ppp', { locale: hr });
};
