import { ReactElement, useCallback, useState } from 'react';
import { Box, Button, Collapse, HStack, Icon, Text } from '@chakra-ui/react';
import { Trans, useTranslation } from 'react-i18next';
import { FaChevronRight, FaEdit } from 'react-icons/fa';

import { Card, CardBody, CardHeader } from '@bq/components/Card';
import { useIsMobile } from '@bq/components/use-is-mobile';
import { EventCardComponent } from 'app/Modules/Events/Assets/components/types';

import { EventCardContent } from './EventCardContent';
import { LegacyEventCardContent } from './LegacyEventCardContent';
import {
  LegacyTicketChangesDiff,
  TicketChangeEvent,
  TicketChangesDiff,
} from './types';
import { formatDate } from './util';

export const TicketChangeEventCard: EventCardComponent<TicketChangeEvent> = ({
  timestamp,
  data,
}): ReactElement => {
  const isShort = Object.entries(data.diff).length < 4;
  const { t } = useTranslation('Ticketing');
  const [isExpanded, setIsExpanded] = useState(isShort);
  const isMobile = useIsMobile();
  const toggleIsExpanded = useCallback(() => {
    setIsExpanded((expanded) => !expanded);
  }, []);
  const arrowRotation = isExpanded ? 90 : 0;

  return (
    <Card>
      <CardHeader
        w="full"
        h="max-content"
        type="button"
        as={Button}
        display="flex"
        flexDirection={['column', 'column', 'column', 'row']}
        alignItems="flex-start"
        onClick={toggleIsExpanded}
        bg="transparent"
        transition="border-radius 0.3s"
        roundedBottom={isExpanded ? 0 : 'base'}
      >
        <HStack maxW="full">
          <FaChevronRight
            style={{
              transition: 'all 0.3s',
              transform: `rotate(${arrowRotation}deg)`,
            }}
          />
          &nbsp;
          {!isMobile ? (
            <Text whiteSpace="nowrap" textOverflow="ellipsis" overflow="hidden">
              {data.user ? (
                <Trans
                  ns="Ticketing"
                  i18nKey="ticket_modified_by_user"
                  components={{
                    BoldText: <Text as="strong" fontWeight="bold" />,
                  }}
                  values={{
                    user: data.user?.person?.fullName ?? data.user.username,
                  }}
                  defaults="Korisnik <BoldText>{{ user }}</BoldText> modificirao je ticket"
                />
              ) : (
                t('Ticketing:ticket_modified_automatically')
              )}
            </Text>
          ) : (
            <HStack>
              <Icon as={FaEdit} />
              <Text as="strong" fontWeight="bold">
                {data.user?.person?.fullName ?? data.user?.username ?? ''}
              </Text>
            </HStack>
          )}
        </HStack>
        <Box mt={[2, 2, 0]}>
          <Text ml="auto" as="span" fontWeight="normal">
            {formatDate(timestamp)}
          </Text>
        </Box>
      </CardHeader>
      <Collapse in={isExpanded}>
        <CardBody whiteSpace="pre-wrap">
          {isNewFormat(data.diff) ? (
            <EventCardContent {...data.diff} />
          ) : (
            <LegacyEventCardContent {...data.diff} />
          )}
        </CardBody>
      </Collapse>
    </Card>
  );
};

function isNewFormat(
  diff: TicketChangesDiff | Partial<LegacyTicketChangesDiff>
): diff is TicketChangesDiff {
  return 'diffVersion' in diff && diff.diffVersion === 2;
}
