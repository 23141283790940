import { formatNumber } from 'app/assets/js/tsutil';
import { Api } from 'BootQuery/Assets/js/api';

import { CompanyLocationApiData } from '../types';
import { CompanyLocationFormData } from './types';

export function companyLocationFormFromApi(
  apiData: CompanyLocationApiData
): Partial<CompanyLocationFormData> {
  return {
    name: apiData.name,
    data: apiData.data,
    phoneNumbers: apiData.phoneNumbers.map((numRow) => ({
      ...numRow,
      phoneNumber: formatNumber(numRow.phoneNumber.phoneNumberE164),
      type: numRow.numberType.ID.toString(),
    })),
    emails: apiData.emails.map((mailRow) => ({
      ...mailRow,
      email: mailRow.email.email,
    })),
    address: {
      country: apiData.address?.country?.name ?? '',
      city: apiData.address?.city?.name ?? '',
      street: apiData.address?.street?.name ?? '',
      streetNumber: apiData.address?.streetNumber ?? '',
    },
    company: {
      ID: apiData.company.ID,
      name: apiData.company.name,
    },
  };
}

export async function getCompanyLocationFormData(
  locationID: string
): Promise<Partial<CompanyLocationFormData>> {
  const { data } = await Api.get<CompanyLocationApiData>(
    `/api/phonebook/companyLocations/${locationID}`,
    {
      params: {
        fields: [
          'ID',
          'name',
          'companyID',
          ['company', ['ID', 'name']],
          [
            'phoneNumbers',
            ['phoneNumber.phoneNumberE164', 'numberType.ID', 'isReadonly'],
          ],
          [
            'address',
            [
              'country.name',
              'city.name',
              'street.name',
              'postalCode.code',
              'streetNumber',
            ],
          ],
          'emails.email.email',
          'data',
        ],
      },
    }
  );

  return companyLocationFormFromApi(data);
}
