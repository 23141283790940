import { useParams } from 'react-router-dom';

import { IOverviewItem } from 'BootQuery/Assets/components/Overviews/types';

type RouterParams = {
  overviewSlug?: string;
};

export function useSelectedOverview(
  overviews: IOverviewItem[]
): IOverviewItem | null {
  const { overviewSlug } = useParams<RouterParams>();

  if (overviewSlug) {
    const selected = overviews.find((ov) => ov.slug === overviewSlug);
    if (selected) {
      return selected;
    }
  }

  return overviews[0] ?? null;
}
