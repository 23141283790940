import {
  createRef,
  CSSProperties,
  HTMLAttributes,
  memo,
  ReactElement,
  RefObject,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Box,
  BoxProps,
  Table as ChakraTable,
  TableProps as ChakraTableProps,
  TableRowProps,
  Tbody,
  Td,
  Tfoot,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { css } from '@emotion/css';
import { isEqual } from 'lodash-es';

import { KeysWithValuesOfType } from '../type-util';
import { PlaceholderRows } from './PlaceholderRows';
import { ActionGroup, TableBulkActionMenu } from './TableBulkActionMenu';
import { TableHeader } from './TableHeader';
import { TableRow } from './TableRow';
import {
  Column,
  OBJ,
  Row,
  RowLinkGenerator,
  SortState,
  TableDisplayMode,
} from './types';
import { useResizableHeaders } from './use-resizable-headers';

type KeyProp<RowType> = KeysWithValuesOfType<RowType, string | number>;
type KeyFn<RowType> = (row: RowType, rowIdx: number) => string | number;

interface Props<RowType extends OBJ = Row> extends ChakraTableProps {
  columns: Column<RowType>[];
  rows: RowType[];
  /** Key of field that will be used as row field or function that takes the
   * row and returns a key. If not specified, the row index will be used.
   */
  rowKey?: KeyProp<RowType> | KeyFn<RowType> | null;
  resizable?: boolean;
  style?: CSSProperties;
  className?: string;
  displayMode?: TableDisplayMode;
  createRowLink?: RowLinkGenerator<RowType> | null;
  rowLinkTextSelect?: boolean;
  footer?: ReactElement | null;
  isLoading?: boolean;
  placeholderRows?: number;
  rowProps?: TableRowProps | ((row: RowType, idx: number) => TableRowProps);
  sort?: SortState;
  isStatic?: boolean;
  bulkActions?: ActionGroup<RowType>[];
  containerProps?: BoxProps;
}

export type TableProps<RowType extends OBJ = Row> = Props<RowType> &
  HTMLAttributes<HTMLTableElement>;

type HeaderRef = RefObject<HTMLTableCellElement>;

interface Header<RowType = Row> extends Column<RowType> {
  ref: HeaderRef;
}

function createHeaders<RowType = Row>(
  columns: Column<RowType>[],
  headerRefs: HeaderRef[]
): Header<RowType>[] {
  return columns.map((col, idx) => ({
    ...col,
    ref: headerRefs[idx],
  }));
}

function generateGridColumnSizes<RowType>(
  columns: Column<RowType>[],
  manualSizes: number[] | null
): string[] {
  return columns.map((column, idx) => {
    const manualWidth = (manualSizes ?? [])[idx] ?? null;
    const manualWidthStr = manualWidth ? `${manualWidth}fr` : null;
    const width = manualWidthStr ?? column.width ?? '1fr';
    const minWidth = column.minWidth ?? 50;

    return `minmax(${minWidth}px, ${width})`;
  });
}

function generateThStyles<RowType>(
  columns: Column<RowType>[],
  manualSizes: number[] | null
): CSSProperties[] {
  let totalWidth: number | null = null;
  if (manualSizes?.length === columns.length) {
    totalWidth = manualSizes.reduce((total, size) => total + size, 0);
  }

  return columns.map((column, idx) => {
    let { width } = column;

    const manualWidth = (manualSizes ?? [])[idx] ?? null;
    if (manualWidth && totalWidth) {
      const manualWidthPercent = (manualWidth / totalWidth) * 100;
      width = `${manualWidthPercent}%`;
    }

    const minWidth = `${column.minWidth ?? 50}px`;

    return { minWidth, width };
  });
}

const displayContents = css({
  display: 'contents',
});

function Table<RowType extends OBJ = Row>({
  columns,
  rows,
  className,
  createRowLink,
  footer,
  rowLinkTextSelect = false,
  displayMode = 'table',
  resizable = true,
  isLoading = false,
  placeholderRows = 10,
  rowProps,
  sort,
  bulkActions,
  isStatic = false,
  containerProps,
  rowKey = null,
  ...otherProps
}: TableProps<RowType>): ReactElement {
  const { size } = otherProps;
  const [headerRefs, setHeaderRefs] = useState<HeaderRef[]>([]);
  const theadRef = useRef<HTMLTableSectionElement>(null);
  const [height, setHeight] = useState<number>(0);
  const colCount = columns.length;
  useEffect(() => {
    // Make sure we always have enough refs for all headers
    const newRefs = Array(colCount)
      .fill(null)
      .map((_ref, idx) => headerRefs[idx] ?? createRef());
    setHeaderRefs(newRefs);

    // The warning is ignored because if we specify headerRefs as a dependency, we create
    // an infinite loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colCount]);

  const headers = createHeaders(columns, headerRefs);
  const resizableHeaders = headers.map(({ ref, minWidth }) => ({
    ref,
    minWidth,
  }));

  const [columnWidths, setColumnWidths, onResizerPointerDown] =
    useResizableHeaders(resizableHeaders);

  // Reset sizes when column count changes
  // Otherwise the newly added columns will be squeezed at the end
  // because all the space is taken already
  useEffect(() => {
    setColumnWidths(null);
  }, [colCount, setColumnWidths]);

  useEffect(() => {
    setHeight(theadRef.current?.offsetHeight ?? 0);
  }, [size]);
  let colStyles: CSSProperties[] | undefined;
  let colWidths: string | undefined;

  if (displayMode === 'grid') {
    colWidths = generateGridColumnSizes(columns, columnWidths).join(' ');
  } else {
    colStyles = generateThStyles(columns, columnWidths);
  }

  const sizeStyle = css({
    display: displayMode,
    tableLayout: 'fixed',
    gridTemplateColumns: colWidths,
    borderRightWidth: '1px',
  });
  const displayClass = displayMode === 'grid' ? displayContents : undefined;
  const tableClass = [className ?? '', sizeStyle, className]
    .filter(Boolean)
    .join(' ');

  return (
    <Box
      borderBottom="2px"
      borderColor="brand.500"
      position="relative"
      {...containerProps}
    >
      <ChakraTable
        className={tableClass}
        {...otherProps}
        fontWeight="normal"
        fontSize="sm"
        position="relative"
        style={isStatic ? { tableLayout: 'auto' } : { tableLayout: 'fixed' }}
      >
        <Thead
          className={displayClass}
          borderBottom="2px"
          borderColor="brand.500"
          ref={theadRef}
        >
          <Tr className={displayClass}>
            {headers.map((header, idx) => (
              <TableHeader<RowType>
                key={header.key}
                thRef={headerRefs[idx]}
                column={header as Column<RowType>}
                hasResizer={resizable && idx !== headers.length - 1}
                onResizerPointerDown={(ev) => onResizerPointerDown(ev, idx)}
                style={(colStyles ?? [])[idx]}
                sort={sort}
                tableSize={size}
                isStatic={isStatic}
              />
            ))}
          </Tr>
        </Thead>
        <Tbody className={displayClass}>
          {isLoading && placeholderRows > 0 ? (
            <PlaceholderRows
              rowCount={placeholderRows}
              colCount={columns.length}
            />
          ) : (
            <>
              {rows.map((row, rowIdx) => {
                const trProps =
                  typeof rowProps === 'function'
                    ? rowProps(row, rowIdx)
                    : (rowProps ?? {});

                return (
                  <TableRow
                    isStatic={isStatic}
                    className={displayClass}
                    key={getRowKey(row, rowIdx, rowKey)}
                    columns={columns}
                    rowData={row}
                    rowIdx={rowIdx}
                    rowLink={createRowLink && createRowLink(row)}
                    rowLinkTextSelect={rowLinkTextSelect}
                    tableSize={size}
                    {...trProps}
                  />
                );
              })}
            </>
          )}
        </Tbody>
        {footer && (
          <Tfoot>
            <Tr>
              <Td colSpan={colCount}>{footer}</Td>
            </Tr>
          </Tfoot>
        )}
      </ChakraTable>

      {bulkActions && (
        <TableBulkActionMenu
          tableSize={size}
          height={getMinH(size, height)}
          actionGroups={bulkActions}
        />
      )}
    </Box>
  );
}

function getRowKey<RowType extends OBJ>(
  row: RowType,
  rowIdx: number,
  keyProp: KeyProp<RowType> | KeyFn<RowType> | null
): string | number {
  if (!keyProp) {
    return rowIdx;
  }

  if (typeof keyProp === 'function') {
    return keyProp(row, rowIdx);
  }

  return row[keyProp] as string | number;
}

const getMinH = (size: TableProps['size'], height: number): number => {
  if (height !== 0) {
    return height;
  }

  if (typeof size === 'string') {
    return minHeight[size];
  }

  return 20;
};
const minHeight: Record<string, number> = {
  sm: 20,
  md: 40,
  lg: 50,
};

const TableMemo = memo(Table, isEqual) as typeof Table;

export { TableMemo as Table };
