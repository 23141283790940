import { useMemo } from 'react';

import { useFormEditorContext } from './FormEditorContext';
import { BaseSettings, FieldType } from './types';
import { findType } from './util';

type BS = BaseSettings;
export function useFieldType<ST extends BS = BS>(type: string): FieldType<ST> {
  const { fieldTypes } = useFormEditorContext();
  const fieldType = useMemo(
    () => findType(fieldTypes, type) as unknown as FieldType<ST>,
    [fieldTypes, type]
  );

  return fieldType;
}
