import type { PropsWithChildren, ReactElement } from 'react';
import { Box, Text } from '@chakra-ui/react';

interface Props {
  field: string;
}

export const ChangedField = ({
  field,
  children,
}: PropsWithChildren<Props>): ReactElement => (
  <Box w="full">
    <Text as="strong" fontWeight="bold">
      {field}:
    </Text>{' '}
    {children}
  </Box>
);
