import { formatNumber } from 'app/assets/js/tsutil';
import { Api } from 'BootQuery/Assets/js/api';

import { NationalNumberApiData, PersonApiData } from '../types';
import { PersonFormData } from './types';

function nationalNumberTypeFromApi(
  type?: NationalNumberApiData['type'] | null
): string | null {
  return type ? `${type.countryISO}-${type.type}` : null;
}

export function personFormFromApi(
  apiData: PersonApiData
): Partial<PersonFormData> {
  return {
    ...apiData,
    phoneNumbers: apiData.phoneNumbers.map((numRow) => ({
      ...numRow,
      phoneNumber: formatNumber(numRow.phoneNumber.phoneNumberE164),
      type: numRow.numberType.ID.toString(),
    })),
    emails: apiData.emails.map((mailRow) => ({
      ...mailRow,
      email: mailRow.email.email,
    })),
    company: apiData.company ?? undefined,
    companyLocation: apiData.companyLocation ?? undefined,
    companyPosition: apiData.companyPosition ?? undefined,
    companyDepartment: apiData.department ?? undefined,
    addresses: apiData.addresses.map((address) => ({
      addressType: address.addressType,
      country: address.address.country?.name ?? '',
      city: address.address.city?.name ?? '',
      street: address.address.street?.name ?? '',
      streetNumber: address.address.streetNumber ?? '',
      postalCode: address.address.postalCode?.code ?? '',
    })),
    nationalNumber: {
      type: nationalNumberTypeFromApi(apiData.nationalNumber?.type) ?? 'HR-oib',
      nationalNumber: apiData.nationalNumber?.nationalNumber ?? '',
    },
  };
}

export async function getPersonFormData(
  personID: string
): Promise<Partial<PersonFormData>> {
  const { data } = await Api.get<PersonApiData>(
    `/api/phonebook/people/${personID}`,
    {
      params: {
        fields: [
          'ID',
          'firstName',
          'lastName',
          ['company', ['ID', 'name']],
          ['department', ['ID', 'name']],
          ['companyPosition', ['ID', 'name']],
          ['companyLocation', ['ID', 'name']],
          [
            'phoneNumbers',
            ['phoneNumber.phoneNumberE164', 'numberType.ID', 'isReadonly'],
          ],
          'emails.email.email',
          [
            'addresses',
            [
              ['addressType', ['name', 'ID']],
              [
                'address',
                [
                  'country.name',
                  'city.name',
                  'street.name',
                  'postalCode.code',
                  'streetNumber',
                ],
              ],
            ],
          ],
          [
            'nationalNumber',
            [['type', ['type', 'countryISO']], 'nationalNumber'],
          ],
          'data',
        ],
      },
    }
  );

  return personFormFromApi(data);
}
