import { ReactElement } from 'react';
import { Box, Divider, FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  GeneratedForm,
  useFormDefinition,
} from 'BootQuery/Assets/components/FormEditor';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';

import { FormAddressList } from '../FormAddressList';
import { FormMails } from '../FormMails';
import { FormPhoneNumbers } from '../FormPhoneNumbers';
import { NationalNumberField } from '../NationalNumber';
import { CompanyFormData } from './types';

export const CompanyFormFields = (): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const {
    register,
    formState: { errors },
  } = useFormContext<CompanyFormData>();

  const customFields = useFormDefinition('Phonebook.company');

  return (
    <>
      <FormControl mb="5">
        <FormLabel fontWeight="bold">{t('Phonebook:name')}:</FormLabel>
        <Input
          {...register('name', { required: true })}
          isInvalid={!!errors.name}
          placeholder={t('Phonebook:name')}
        />
      </FormControl>
      <FormControl mb="5">
        <FormLabel fontWeight="bold">
          {t('Phonebook:national_number')}:
        </FormLabel>
        <NationalNumberField />
      </FormControl>
      <Divider />
      <Box mb="5">
        <FormAddressList />
      </Box>
      <Divider />
      <Box mb="5">
        <FormMails />
      </Box>
      <Divider />
      <Box mb="5">
        <FormPhoneNumbers />
      </Box>
      <Box mb="5">
        {customFields ? (
          <GeneratedForm fields={customFields} />
        ) : (
          <LoadingPage />
        )}
      </Box>
    </>
  );
};
