import { ReactElement } from 'react';
import { Box, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
  FaArrowLeft,
  FaArrowRight,
  FaArrowsAltH,
  FaRegHourglass,
  FaTimes,
} from 'react-icons/fa';

import { CallDirection, CallListCall } from './CallList/types';

export type CallStatusProps = Pick<
  CallListCall,
  'direction' | 'endAt' | 'answered'
>;

export const CallStatus = (call: CallStatusProps): ReactElement => {
  const [blue, green, red] = useColorModeValue(
    ['blue.600', 'green.600', 'red.600'],
    ['blue.400', 'green.400', 'red.400']
  );

  const { label, color, Icon } = getDisplayProps(call, { red, green, blue });

  return (
    <Box textOverflow="clip">
      <Tooltip hasArrow label={label} placement="right">
        <Text as="span" color={color}>
          <Icon />
        </Text>
      </Tooltip>
    </Box>
  );
};

const iconByDirection: Record<CallDirection | 'other', IconType> = {
  outgoing: FaArrowRight,
  incoming: FaArrowLeft,
  internal: FaArrowsAltH,
  other: FaTimes,
};

const directionLabels: Record<CallDirection, string> = {
  outgoing: 'Outgoing',
  incoming: 'Incoming',
  internal: 'Internal',
};

interface CallStatusDisplay {
  Icon: IconType;
  color: string;
  label: string;
}

function labelForCall(
  dir: CallDirection | null,
  answered: boolean
): string | null {
  const dirLabel = dir ? directionLabels[dir] : null;
  const answeredLabel = answered ? 'Answered' : 'Not answered';

  return [dirLabel, answeredLabel].filter(Boolean).join(', ');
}

function getDisplayProps(
  call: CallStatusProps,
  colors: { red: string; green: string; blue: string }
): CallStatusDisplay {
  if (!call.endAt) {
    return {
      label: 'In progress',
      color: colors.blue,
      Icon: FaRegHourglass,
    };
  }

  const color = call.answered ? colors.green : colors.red;
  const Icon = iconByDirection[call.direction ?? 'other'];
  const label = labelForCall(call.direction, call.answered) ?? 'Unknown';

  return { label, color, Icon };
}
