import { ReactElement } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ReportAgentsPermissionModal } from '@bq/components/ReportAgentsPermissions';

import { ActionsPermission } from './ActionsPermission';
import { useOpenCallFormPermissionsSettings } from './CallFormPermissions/use-open-call-form-permissions-settings';
import { CallVisibilityModal } from './CallPermissionsModal';
import { OperatorSpeedDialModal } from './OperatorSpeedDialModal';
import { RecordingPermissionsModal } from './RecordingPermissionsModal';
import { ReportAgentlessPermission } from './ReportAgentlessPermission';
import { ReportPermission } from './ReportPermission';
import {
  useAgentsPermissions,
  useSetAgentPermissions,
} from './use-agents-permissions';

export const PermissionSettings = (): ReactElement => {
  const { t } = useTranslation('Telephony');

  const groupID = window.Bootstrap.bootquery.parameters.groupID as string;

  const visibilityState = useDisclosure();
  const recordingsState = useDisclosure();
  const speedDialState = useDisclosure();
  const openCallFormPermissions = useOpenCallFormPermissionsSettings(groupID);

  const agentsModalState = useDisclosure();
  const agentsPermissions = useAgentsPermissions(groupID);
  const setAgentsPermissions = useSetAgentPermissions(groupID);

  return (
    <div className="mt-5">
      <h3>
        <span className="fas fa-phone"></span> {t('Telephony:telephony_title')}
      </h3>
      <hr />
      <div className="flex">
        <button
          type="button"
          className="btn btn-primary"
          onClick={visibilityState.onOpen}
        >
          <span className="fas fa-eye"></span>{' '}
          {t('Telephony:permissions.call_visibility')}
        </button>{' '}
        <button
          type="button"
          className="btn btn-primary"
          onClick={recordingsState.onOpen}
        >
          <span className="fas fa-eye"></span>{' '}
          {t('Telephony:permissions.recording_visibility')}
        </button>{' '}
        <button
          type="button"
          className="btn btn-primary"
          onClick={speedDialState.onOpen}
        >
          <span className="fas fa-eye"></span>{' '}
          {t('Telephony:permissions.operator_pinned_contacts')}
        </button>{' '}
        <button
          type="button"
          className="btn btn-primary"
          onClick={openCallFormPermissions}
        >
          <span className="fas fa-file-alt"></span>{' '}
          {t('Telephony:permissions.call_form')}
        </button>
        <div className="mt-3 d-flex">
          <ReportPermission groupID={groupID} />
          <div className="ml-3 d-flex">
            <ReportAgentlessPermission groupID={groupID} />
          </div>
          <button
            type="button"
            className="btn btn-primary ml-3"
            onClick={agentsModalState.onOpen}
          >
            <span className="fas fa-edit"></span>{' '}
            {t('global:report_agents_permissions')}
          </button>{' '}
        </div>
        <div className="mt-3">
          <ActionsPermission groupID={groupID} />
        </div>
      </div>
      <CallVisibilityModal
        groupID={groupID}
        isOpen={visibilityState.isOpen}
        onClose={visibilityState.onClose}
      />
      <RecordingPermissionsModal
        groupID={groupID}
        isOpen={recordingsState.isOpen}
        onClose={recordingsState.onClose}
      />
      <OperatorSpeedDialModal
        groupID={groupID}
        isOpen={speedDialState.isOpen}
        onClose={speedDialState.onClose}
      />
      <ReportAgentsPermissionModal
        isOpen={agentsModalState.isOpen}
        onClose={agentsModalState.onClose}
        agentsPermissions={agentsPermissions}
        setAgentsPermissions={setAgentsPermissions}
      />
    </div>
  );
};
