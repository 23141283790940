import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { CompaniesResponse, getCompanies } from '../api';

export async function getCardCompanies(
  params: GetListingParams
): Promise<CompaniesResponse> {
  return getCompanies({
    fields: [
      'ID',
      'name',
      ['phoneNumbers', ['phoneNumber.phoneNumberE164', 'numberType.ID']],
      'emails.email.email',
      [
        'addresses',
        [
          ['addressType', ['name', 'ID']],
          [
            'address',
            [
              'country.name',
              'city.name',
              'street.name',
              'postalCode.code',
              'streetNumber',
            ],
          ],
        ],
      ],
      ['nationalNumber', [['type', ['type', 'countryISO']], 'nationalNumber']],
      'data',
    ],
    ...params,
  });
}
