import 'BootQuery/Assets/js/i18n';

import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

import { ReportList } from '../pages/ReportList';
import { ViewReport } from '../pages/ViewReport';

export const ReactRoutes: ReactRoute[] = [
  { path: '/reporting/reports', component: ReportList },
  { path: '/reporting/reports/:reportKey', component: ViewReport },
];
