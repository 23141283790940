import { ReactElement } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCheck } from 'react-icons/fa';

export const SaveButton = (props: ButtonProps): ReactElement => {
  const { t } = useTranslation('global');

  return (
    <Button
      colorScheme="green"
      variant="solid"
      bg="#34CB7F"
      {...props}
      rightIcon={<FaCheck />}
    >
      {t('global:save')}
    </Button>
  );
};
