import {
  FieldPath,
  useController,
  UseControllerProps,
  UseControllerReturn,
} from 'react-hook-form';

import { SimpleFormSalesCase } from '../../schemas';
import { useCasesBulkFormProvider } from './CasesBulkFormWrapper';

export function useCaseBulkFormController<
  K extends FieldPath<SimpleFormSalesCase>,
>(
  name: K,
  props?: Omit<UseControllerProps<SimpleFormSalesCase, K>, 'control' | 'name'>
): UseControllerReturn<SimpleFormSalesCase, K> {
  const {
    methods: { control },
  } = useCasesBulkFormProvider();

  return useController<SimpleFormSalesCase, K>({ ...props, control, name });
}
