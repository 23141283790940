import { ReactNode } from 'react';
import { Trans } from 'react-i18next';

import { ContactOption } from './types';

export const OptionLabel = (opt: ContactOption): ReactNode => {
  if (opt.value === '$new-company') {
    return (
      <Trans
        ns="Phonebook"
        i18nKey="create_new_company_option"
        values={{ search: opt.search?.trim() }}
        defaults='Kreiraj tvrtku <strong>"{{ search }}"</strong>...'
      />
    );
  }
  if (opt.value === '$new-person') {
    return (
      <Trans
        ns="Phonebook"
        i18nKey="create_new_person_option"
        values={{ search: opt.search?.trim() }}
        defaults='Kreiraj osobu <strong>"{{ search }}"</strong>...'
      />
    );
  }

  return opt.label;
};
