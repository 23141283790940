import { createContext, PropsWithChildren, useContext, useMemo } from 'react';

import { useFormDefinitionMultiple } from '@bq/components/FormEditor/use-form-definition-multi';
import { LoadingPage } from '@bq/components/LoadingPage';

import { useSettings } from './api';
import { SettingsProvider } from './types';

const TicketingSettingsContext = createContext<SettingsProvider | null>(null);

export const TicketingSettingsProvider = ({ children }: PropsWithChildren) => {
  const { data: settings } = useSettings();
  const customFormDefinitions = useFormDefinitionMultiple(
    settings?.types.map((item) => `TicketTypes.${item.ID}`) ?? [],
    'use',
    { enabled: !!settings?.types }
  );

  const customFields = useMemo(() => {
    return customFormDefinitions
      ? Object.values(customFormDefinitions).flat()
      : null;
  }, [customFormDefinitions]);

  if (!settings || !customFields) {
    return <LoadingPage />;
  }

  return (
    <TicketingSettingsContext.Provider value={{ ...settings, customFields }}>
      {children}
    </TicketingSettingsContext.Provider>
  );
};

export const useTicketingSettingsContext = () => {
  const ctx = useContext(TicketingSettingsContext);
  if (ctx === null) {
    throw Error('Ticket settings context not inited');
  }

  return ctx;
};
