import { ReactElement, useContext } from 'react';
import { Flex, FlexProps, useColorModeValue } from '@chakra-ui/react';
import { cloneDeep, isEqual } from 'lodash-es';
import { useDrop } from 'react-dnd';

import { IOverviewEditorItemId } from '../types';
import { OverviewEditorContext } from './OverviewEditorContext';
import { OverviewEditorItem } from './OverviewEditorItem';
import {
  addOverview,
  findOverviewPath,
  overviewsAtPath,
  removeOverview,
} from './util';

export interface DropAreaProps extends FlexProps {
  path?: string[];
}

export const DropArea = ({
  path = [],
  ...flexProps
}: DropAreaProps): ReactElement => {
  const { tempOverviews, setTempOverviews } = useContext(OverviewEditorContext);
  const overviews = overviewsAtPath(tempOverviews, path);
  const bg = useColorModeValue('brand.background', 'brand.backgroundDark');
  const colorValue = useColorModeValue('brand.darkGray', 'brand.lightGray');
  const [, dropRef] = useDrop(
    () => ({
      accept: 'overviewEditor',
      drop(item: IOverviewEditorItemId, monitor) {
        console.log('Droppeded: ', { item, dropped: monitor.getDropResult() });
      },
      collect: (monitor) => ({
        itemOver: monitor.isOver(),
        itemDirectlyOver: monitor.isOver({ shallow: true }),
      }),
      hover(item, monitor) {
        const isDirectlyOver = monitor.isOver({ shallow: true });
        if (isDirectlyOver) {
          const overviewPath = findOverviewPath(tempOverviews, item.id);
          if (overviewPath === null) {
            console.error('NANI?', tempOverviews, item.id);
            throw new Error(`Failed to find overview by id: ${item.id}`);
          }

          if (!isEqual(overviewPath.slice(0, -1), path)) {
            console.log('NEW Move: ', { overviewPath, path });
            let swapped = removeOverview(tempOverviews, overviewPath);
            console.log('[DA] Post RM: ', cloneDeep(swapped));
            swapped = addOverview(swapped, path, item);
            console.log('[DA] Post ADD: ', cloneDeep(swapped));
            console.log('DropArea swap: ', {
              swapped: cloneDeep(swapped),
              tempOverviews: cloneDeep(tempOverviews),
            });
            setTempOverviews(swapped);
          }
        }
      },
    }),
    [path, tempOverviews]
  );

  return (
    <Flex
      direction="column"
      bg={bg}
      color={colorValue}
      {...flexProps}
      ref={dropRef}
    >
      {overviews.map((item) => (
        <OverviewEditorItem key={item.id} {...item} path={[...path, item.id]} />
      ))}
    </Flex>
  );
};
