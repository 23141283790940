import { ReactElement, ReactNode } from 'react';
import { Button, ButtonProps, Link } from '@chakra-ui/react';
import { FaFilter } from 'react-icons/fa';

export interface FilterLinkProps extends ButtonProps {
  href: string;
  children?: ReactNode;
}

export const FilterLink = ({
  href,
  children,
  ...btnProps
}: FilterLinkProps): ReactElement => (
  <Button
    as={Link}
    href={href}
    display="flex"
    alignItems="center"
    {...btnProps}
  >
    <FaFilter />
    &nbsp;
    {children}
  </Button>
);
