import { ReactElement, useCallback, useMemo } from 'react';
import { AsyncSelect } from 'chakra-react-select';

import { getContacts } from '@bq/components/FormFields/Contact/API/getContacts';
import { modalSelect } from 'BootQuery/Assets/components/Select/select-styles';

import { ContactItemContact } from '../../HopsOperator/HopsContacts/types';

interface Props {
  value: ContactItemContact | null;
  onChange: (newItem: ContactItemContact | null) => void;
}

export const ContactNumberSelect = ({
  value,
  onChange,
}: Props): ReactElement => {
  const contact = useMemo(() => valueToSelect(value), [value]);

  const handleChange = useCallback(
    (val: ContactSelectVal | null) => {
      onChange(selectToValue(val));
    },
    [onChange]
  );

  return (
    <AsyncSelect<ContactSelectVal>
      cacheOptions
      defaultOptions
      isClearable
      loadOptions={getResults}
      value={contact}
      onChange={handleChange}
      menuPortalTarget={document.body}
      styles={modalSelect}
    />
  );
};

interface ContactSelectVal {
  label: string;
  value: string;
  contact: ContactItemContact;
}

function valueToSelect(
  value: ContactItemContact | null
): ContactSelectVal | null {
  if (!value) {
    return null;
  }

  return {
    value: `${value.type}-${value.ID}-${value.phoneNumber}`,
    label: `${value.name} (${value.phoneNumber})`,
    contact: value,
  };
}

function selectToValue(
  select: ContactSelectVal | null
): ContactItemContact | null {
  if (!select) {
    return null;
  }

  return select.contact;
}

async function getResults(search: string): Promise<ContactSelectVal[]> {
  const results = await getContacts(search, ['person', 'company']);
  const contacts = results.reduce<ContactItemContact[]>((results, contact) => {
    const mapped: ContactItemContact[] = (contact.phoneNumbers ?? []).map(
      (num) => {
        if (contact.type !== 'person' && contact.type !== 'company') {
          throw new Error('Unsupported contact type');
        }

        return {
          phoneNumber: num.phoneNumber.phoneNumberE164,
          type: contact.type,
          name: contact.name,
          ID: contact.ID,
        };
      }
    );

    return [...results, ...mapped];
  }, []);

  const options = contacts.map((contact) => {
    const val = valueToSelect(contact);
    if (!val) {
      throw new Error('Somehow got null contact value');
    }

    return val;
  });

  return options;
}
