import { ChakraProvider, ColorModeProviderProps } from '@chakra-ui/react';

import { brandColors, useBrandColor } from 'BootQuery/Assets/js/brand-colors';

import 'BootQuery/Assets/js/i18n';

import { ReactElement, ReactNode, Suspense, useMemo } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

import { colorModeStore } from '../js/store/color-mode';
import { ThemeSync } from './ThemeSync';
import { UserSettingsContextProvider } from './UserSettingsContext';

import '@fontsource/roboto/400.css';
import '@fontsource/roboto/900.css';
import '@fontsource/roboto/700.css';

import { genTheme } from './ChakraTheming/gen-theme';
import { ErrorFallback } from './ErrorFallback';
import { ModalProvider } from './UseModal/use-modal';

const colorModeManager: ColorModeProviderProps['colorModeManager'] = {
  get: () => colorModeStore.getState().colorMode,
  set: () => {
    /* */
  },
  type: 'localStorage',
};

interface Props {
  children?: ReactNode;
}

export const RootNode = ({ children }: Props): ReactElement => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        throwOnError: true,
      },
    },
  });

  const brandColor = useBrandColor();
  const themeColors = useMemo(() => brandColors(brandColor), [brandColor]);
  const theme = useMemo(() => genTheme(themeColors), [themeColors]);

  return (
    <ChakraProvider
      theme={theme}
      colorModeManager={colorModeManager}
      portalZIndex={8000}
    >
      <UserSettingsContextProvider>
        <ThemeSync>
          <BrowserRouter>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              {/* An "empty" suspense to handle unexpected stuff like loading translations */}
              <Suspense fallback={<></>}>
                <QueryClientProvider client={queryClient}>
                  <ModalProvider>{children}</ModalProvider>
                </QueryClientProvider>
              </Suspense>
            </ErrorBoundary>
          </BrowserRouter>
        </ThemeSync>
      </UserSettingsContextProvider>
    </ChakraProvider>
  );
};
