import { BasicField } from 'BootQuery/Assets/js/globalTypes';

import { getActionPriceReasons } from './getActionPriceReasons';

interface SearchActionPriceReasons {
  search?: string;
}
export const searchActionPriceReasons = async ({
  search,
}: SearchActionPriceReasons): Promise<BasicField[]> => {
  const products = await getActionPriceReasons({
    params: { filters: { $search: search } },
  });

  return products.data;
};
