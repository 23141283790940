import { LoadingModal } from '@bq/components/UseModal/LoadingModal';
import { useCompanyDepartmentFormData } from 'app/Modules/Phonebook/Assets/components/CompanyDepartmentForm/utils';

import { CompanyDepartmentModalContent } from './CompanyDepartmentModalContent';

export const EditCompanyDepartmentModalContent = ({ ID }: { ID: number }) => {
  const { data } = useCompanyDepartmentFormData({
    type: 'edit',
    departmentID: ID.toString(),
  });

  if (!data) {
    return <LoadingModal />;
  }

  return (
    <CompanyDepartmentModalContent
      mode="edit"
      data={data}
      CompanyDepartmentID={ID}
    />
  );
};
