import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { TicketTag } from '../../types';

export const getTags = async (params: GetListingParams = {}) => {
  const data = await Api.get<ListingResponse<TicketTag>>(
    '/api/ticketing/tags',
    {
      params,
    }
  );

  return data;
};
