import { ReactElement } from 'react';

export const TextIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1002 1000"
    fill="currentColor"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="M951.9 0H50.1C22.43 0 0 16 0 35.71V268c0 19.72 22.43 35.71 50.1 35.71h70.2c27.67 0 50.1-16 50.1-35.71V166.57h205.35v702.57H250.5c-27.67 0-50.1 16-50.1 35.72v59.43c0 19.72 22.43 35.71 50.1 35.71h501c27.67 0 50.1-16 50.1-35.71v-59.43c0-19.73-22.43-35.72-50.1-35.72H626.25V166.57H831.6V268c0 19.72 22.43 35.71 50.1 35.71h70.2c27.67 0 50.1-16 50.1-35.71V35.71C1002 16 979.57 0 951.9 0Z" />
  </svg>
);
