import { ReactElement, useMemo } from 'react';
import { Button, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { useToggle } from 'react-use';

import { Attachment as IAttachment } from '../../js/types';
import { Attachment } from './Attachment';

interface Props {
  messageID: number;
  attachments: IAttachment[];
}

export const AttachmentList = ({
  attachments,
  messageID,
}: Props): ReactElement => {
  const { t } = useTranslation('Mails');
  const [inline, toggleInline] = useToggle(false);
  const { inlineAttachments, visibleAttachments } = useGroupedAttachments(
    attachments,
    inline
  );

  return (
    <VStack spacing="3" alignItems="start">
      <HStack flexWrap="wrap" alignItems="stretch">
        {visibleAttachments.map((attachment) => (
          <Attachment
            key={attachment.ID}
            attachment={attachment}
            messageID={messageID}
          />
        ))}
      </HStack>
      {inlineAttachments.length > 0 && (
        <Button variant="link" onClick={toggleInline}>
          {inline ? <FaEyeSlash /> : <FaEye />}&nbsp;
          {inline
            ? t('Mails:hide_inline_attachments')
            : t('Mails:show_inline_attachments')}
          &nbsp; ({inlineAttachments.length})
        </Button>
      )}
    </VStack>
  );
};

function useGroupedAttachments(
  attachments: IAttachment[],
  showInline: boolean
) {
  return useMemo(() => {
    const explicitAttachments = attachments.filter(
      (attachment) => !attachment.isInline
    );
    const inlineAttachments = attachments.filter(
      (attachment) => attachment.isInline
    );

    const visibleAttachments = showInline
      ? [...explicitAttachments, ...inlineAttachments]
      : explicitAttachments;

    return { explicitAttachments, inlineAttachments, visibleAttachments };
  }, [attachments, showInline]);
}
