import { useCallback, useMemo } from 'react';
import { parse as parseQuery, stringify as stringifyQuery } from 'qs';
import { useLocation, useNavigate } from 'react-router-dom';

import {
  FilterTypes,
  FilterValue,
  useFilterTypesWithGroup,
} from '@bq/components/FilterBar';

import { UseFiltersResult } from './types';
import { filtersFromQueryStr, simplifyFilter } from './util';

export function useQueryFilters(filterTypes: FilterTypes): UseFiltersResult {
  const navigate = useNavigate();
  const location = useLocation();
  const allFilterTypes = useFilterTypesWithGroup(filterTypes);

  const { filters, search } = useMemo(
    () => filtersFromQueryStr(location.search, allFilterTypes),
    [allFilterTypes, location.search]
  );

  const setFilters = useCallback(
    (filters: FilterValue[]) => {
      const simplified = filters.map(simplifyFilter);

      const prevQuery = parseQuery(window.location.search, {
        ignoreQueryPrefix: true,
      });
      const query = stringifyQuery(
        { ...prevQuery, filters: simplified },
        { addQueryPrefix: false }
      );

      navigate({ search: query }, { replace: true });
    },
    [navigate]
  );

  const setSearch = useCallback(
    (newSearch: string) => {
      const search = newSearch === '' ? undefined : newSearch;

      const prevQuery = parseQuery(window.location.search, {
        ignoreQueryPrefix: true,
      });
      const query = stringifyQuery(
        { ...prevQuery, search },
        { addQueryPrefix: false }
      );

      navigate({ search: query }, { replace: true });
    },
    [navigate]
  );

  return [filters, setFilters, search, setSearch];
}
