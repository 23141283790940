import i18n from 'BootQuery/Assets/js/i18n';

import { StaticCheckboxCell } from '../Cells/StaticCheckboxCell';
import { ItemColumn } from '../types';

export const productColumns = (): ItemColumn[] => [
  packagingAmountColumn(),
  warrantyMonthsColumn(),
  producerColumn(),
  countryOfOriginColumn(),
  barcodeColumn(),
  isComissionColumn(),
];

const packagingAmountColumn = (): ItemColumn => ({
  key: 'packagingAmount',
  title: i18n.t('Products:packaging_amount'),
  order: 209,
  getValue: (props) => {
    if (props.row.productType === 'good') {
      return props.row.good.packagingAmount ?? '-';
    }

    return '-';
  },
});
const warrantyMonthsColumn = (): ItemColumn => ({
  key: 'warrantyMonths',
  title: i18n.t('Products:warranty_months'),
  order: 210,
  getValue: (props) => {
    if (props.row.productType === 'good') {
      return props.row.good.warrantyMonths ?? '-';
    }

    return '-';
  },
});
export const producerColumn = (): ItemColumn => ({
  key: 'producer',
  title: i18n.t('Products:producer'),
  order: 211,
});
const countryOfOriginColumn = (): ItemColumn => ({
  key: 'countryOfOrigin',
  title: i18n.t('Products:country_of_origin'),
  order: 213,
  getValue: (props) => {
    if (props.row.productType === 'good') {
      return props.row.good.countryOfOriginIso ?? '-';
    }

    return '-';
  },
});
const barcodeColumn = (): ItemColumn => ({
  key: 'barcode',
  title: i18n.t('Products:barcodes'),
  order: 250,
  getValue: (props) => {
    if (props.row.productType === 'good') {
      return props.row.good.barcodes?.join(', ') ?? '-';
    }

    return '-';
  },
});

/** Put it after all codes */
const isComissionColumn = (): ItemColumn => ({
  key: 'isComission',
  title: i18n.t('Products:is_comission'),
  order: 270,
  Cell: (props) => {
    if (props.row.productType === 'good') {
      return (
        <StaticCheckboxCell value={props?.row?.good?.isComission ?? false} />
      );
    }

    return <>-</>;
  },
});
