type FileInfo = Partial<Pick<File, 'type' | 'name'>>;

export function matchFileType(
  accept: string | string[],
  fileInfo: FileInfo | string
): boolean {
  if (typeof fileInfo === 'string') {
    fileInfo = { type: fileInfo };
  }

  if (typeof accept !== 'string') {
    return accept.some((match) => matchFileType(match, fileInfo));
  }

  if (fileInfo.type && matchMimeType(accept, fileInfo.type)) {
    return true;
  }
  if (fileInfo.name && matchFilename(accept, fileInfo.name)) {
    return true;
  }

  return false;
}

function matchFilename(acceptFilename: string, filename: string): boolean {
  if (acceptFilename.startsWith('.')) {
    return filename.endsWith(acceptFilename);
  }

  return false;
}

function matchMimeType(acceptMime: string, fileMime: string): boolean {
  if (fileMime === acceptMime) {
    return true;
  }

  if (acceptMime.endsWith('*')) {
    const prefix = acceptMime.replace('*', '');

    return fileMime.startsWith(prefix);
  }

  return false;
}
