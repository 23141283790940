import { ReactElement } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { FormActions } from 'BootQuery/Assets/components/FormActions';

import { PersonFormFields, PersonFormWrapper } from '../PersonForm';
import { CreatePersonModalProps } from './types';

const formId = 'phonebook-create-person';

export const CreatePersonModal = ({
  editing,
  onClose,
  onSave,
  formState,
}: CreatePersonModalProps): ReactElement => {
  const { t } = useTranslation('Phonebook');

  return (
    <Modal
      size="6xl"
      scrollBehavior="inside"
      isOpen={editing !== null}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          <FaPlus style={{ display: 'inline' }} />
          &nbsp;
          {t('Phonebook:create_person')}
        </ModalHeader>
        <ModalBody>
          {editing && (
            <PersonFormWrapper
              formId={formId}
              defaultValues={editing}
              onSubmit={onSave}
            >
              <PersonFormFields />
            </PersonFormWrapper>
          )}
        </ModalBody>
        <ModalFooter>
          <FormActions form={formId} state={formState} onCancel={onClose} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
