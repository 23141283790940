import { ReactElement } from 'react';

import { CellProps } from 'BootQuery/Assets/components/Table';

import { CallActions } from '../CallActions';
import { CallListCall } from './types';

type Props = CellProps<CallListCall>;

export const CallActionCell = ({ row: call }: Props): ReactElement => (
  <CallActions call={call} />
);
