import { useCallback, useRef, useState } from 'react';

import { FileUpload } from 'BootQuery/Assets/js/file-upload';

export interface UseUploadResult {
  upload: (file: File) => Promise<string | null>;
  cancel: () => void;
  isUploading: boolean;
  progress: number;
}

export function useUpload(): UseUploadResult {
  const [isUploading, setIsUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  const uploader = useRef<FileUpload | null>();
  const cancel = useCallback(() => {
    if (uploader.current) {
      uploader.current.cancel();
      uploader.current = null;
    }
    setIsUploading(false);
  }, []);
  const upload = useCallback(
    async (file: File) => {
      cancel();
      uploader.current = new FileUpload(file);

      setIsUploading(true);
      try {
        uploader.current.events.on('progress', (progress) => {
          setProgress(progress);
        });
        const uploadId = await uploader.current.start();
        setIsUploading(false);

        return uploadId;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (err: any) {
        if (typeof err === 'object' && err.message === 'canceled') {
          console.log('Upload cancelled: ', err);
          setIsUploading(false);
        } else {
          throw err;
        }
      }

      return null;
    },
    [cancel]
  );

  return {
    upload,
    cancel,
    isUploading,
    progress,
  };
}
