import { ReactElement } from 'react';
import {
  Box,
  HStack,
  LinkBox,
  LinkOverlay,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaDownload, FaFile } from 'react-icons/fa';

import { formatFileSize } from 'app/assets/js/tsutil';
import { IconButton } from 'BootQuery/Assets/components/IconButton';

import { Attachment as IAttachment } from '../../js/types';

interface Props {
  messageID: number;
  attachment: IAttachment;
}

export const Attachment = ({ messageID, attachment }: Props): ReactElement => {
  const hoverColor = useColorModeValue('gray.50', 'gray.900');

  const link = `/api/mails/messages/${messageID}/attachments/${attachment.ID}`;

  return (
    <LinkBox
      borderStyle="solid"
      borderWidth="thin"
      borderRadius="base"
      px="1"
      py="0.5"
      _hover={{ bg: hoverColor }}
    >
      <HStack spacing="3">
        <FaFile />
        <Box>
          <LinkOverlay href={link} target="_blank">
            <Text as="strong" fontWeight="bold">
              {attachment.filename || <>&nbsp;</>}
            </Text>
          </LinkOverlay>
          <Box fontSize="sm" color="gray.500">
            {formatFileSize(attachment.size)}
          </Box>
        </Box>
        <IconButton
          variant="ghost"
          as="a"
          href={`${link}?download=true`}
          download
          target="_blank"
          label="Download"
          icon={<FaDownload />}
          size="sm"
        />
      </HStack>
    </LinkBox>
  );
};
