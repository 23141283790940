import { pick } from 'lodash-es';

import { BaseItem } from '../DndContainers';
import { FieldType, FieldValue, ValueItem } from './types';
import { findType } from './util';

export function fieldFromEditorValue(
  item: BaseItem<ValueItem>,
  fieldTypes: FieldType[]
): FieldValue {
  const type = findType(fieldTypes, item.content.type);
  if (type.fromEditorValue) {
    return type.fromEditorValue(item, fieldTypes);
  }

  return pick(item.content, 'id', 'type', 'settings');
}
