import { Dispatch, SetStateAction, useCallback } from 'react';

import { applySetState } from './apply-set-state';

// Using a Record prevents us from using interfaces for some reason
//
// eslint-disable-next-line @typescript-eslint/ban-types
type OBJ = {};

/**
 * Returns a setState function that modifies a subset of an object.
 *
 * @param onChange The setState function for the whole object `T`
 * @param property Property name
 * @returns A setState function to modify `property` of `T`
 */
export function useSetPropertyState<T extends OBJ, K extends keyof T>(
  onChange: Dispatch<SetStateAction<T>>,
  property: K
): Dispatch<SetStateAction<T[K]>> {
  return useCallback(
    (change: SetStateAction<T[K]>) => {
      onChange((prev) => ({
        ...prev,
        [property]: applySetState(prev[property], change),
      }));
    },
    [onChange, property]
  );
}
