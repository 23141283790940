import { lazyImport } from '@bq/components/lazy-import';

import 'BootQuery/Assets/js/i18n';

import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

const Sales = lazyImport(() => import('../pages/Sales'), 'Sales');

export const ReactRoutes: ReactRoute[] = [
  { path: '/sales/*', component: Sales },
];
