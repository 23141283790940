import { HStack } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

import { ItemType, Service } from '../../../types';
import { SupplierField } from '../ItemBaseFields/SupplierField';
import { IsThirdPartyService } from './IsThirdParyServiceField';

interface Props {
  type?: ItemType;
}
export const ServiceSupplier = ({ type }: Props) => {
  const { watch } = useFormContext<Service>();
  const isThirdPartyService = watch('service.isThirdPartyService');

  if (type === 'service') {
    return (
      <HStack w="full" alignItems="flex-end" h="auto">
        <IsThirdPartyService formControlProps={{ flex: '0 1 20rem' }} />
        <SupplierField
          isDisabled={!isThirdPartyService}
          formControlProps={{ flex: '1 1 auto' }}
        />
      </HStack>
    );
  }

  return <></>;
};
