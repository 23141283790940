import { memo, ReactElement, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaWindowRestore } from 'react-icons/fa';

import { FilterEditorModal } from './FilterEditor';
import { FilterBarProps, FilterTypesWithGroup } from './types';

interface FilterBarPropsInternal extends FilterBarProps {
  filterTypes: FilterTypesWithGroup;
}

const FilterBarAdvancedButton = memo(
  ({ filterTypes, ...props }: FilterBarPropsInternal): ReactElement => {
    const { t } = useTranslation();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const close = () => {
      setIsOpen(false);
    };
    const open = () => {
      setIsOpen(true);
    };

    return (
      <>
        <FilterEditorModal
          {...props}
          filterTypes={filterTypes}
          isOpen={isOpen}
          onClose={close}
        />
        <IconButton
          colorScheme="brand"
          color="brand.font"
          borderLeftRadius="none"
          borderRightRadius="none"
          aria-label={t('global:filters.advanced_filters')}
          icon={<FaWindowRestore />}
          onClick={open}
        />
      </>
    );
  }
);
FilterBarAdvancedButton.displayName = 'FilterBarAdvancedButton';

export { FilterBarAdvancedButton };
