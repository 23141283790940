import { useCallback, useState } from 'react';
import {
  Box,
  chakra,
  CircularProgress,
  Flex,
  Image,
  StyleProps,
  useColorModeValue,
} from '@chakra-ui/react';
import { useDrop, useDropArea } from 'react-use';

import { pickFiles } from 'BootQuery/Assets/js/pick-files';

import { ResetButton } from './ResetButton';
import { LogoValue } from './types';
import { useUpload } from './use-upload';

interface Props {
  value?: LogoValue | null;
  onChange?: (newVal: LogoValue | null) => void;
  defaultValue: string;
}
type AllProps = Props & StyleProps;

export const LogoInput = ({
  value = null,
  onChange,
  defaultValue,
  ...styleProps
}: AllProps) => {
  const { upload, isUploading, progress } = useUpload();
  const [imageSrc, setImageSrc] = useState<string | null>(
    srcFromValue(value, defaultValue)
  );

  const { over: isDragging } = useDrop();
  const onFiles = useCallback(
    (files: File[] | null) => {
      if (!files || !files.length) {
        return;
      }

      const file = files[0];
      if (!ALLOWED_TYPES.includes(file.type)) {
        return;
      }

      const previewSrc = window.URL.createObjectURL(file);
      setImageSrc(previewSrc);
      upload(file).then((uploadId) => {
        if (uploadId && onChange) {
          onChange({ type: 'uploaded', uploadId });
        }
      });
    },
    [upload, onChange]
  );
  const [bond, { over }] = useDropArea({ onFiles });
  const wrapperStyle = useWrapperStyle({ over, isDragging });

  const openPicker = useCallback(() => {
    pickFiles({
      accept: ALLOWED_TYPES,
      multiple: false,
    }).then(onFiles);
  }, [onFiles]);

  const reset = useCallback(() => {
    if (onChange) {
      onChange(null);
    }
    setImageSrc(srcFromValue(null, defaultValue));
  }, [onChange, defaultValue]);

  return (
    <Flex sx={{ gap: '16px' }} alignItems="center">
      <FileInputWrapper
        {...wrapperStyle}
        {...styleProps}
        {...bond}
        onClick={openPicker}
      >
        <Image src={imageSrc ?? '/app/assets/img/clickvox_logo.svg'} />
        {isUploading && (
          <UploadingOverlay>
            <CircularProgress value={progress} />
          </UploadingOverlay>
        )}
      </FileInputWrapper>
      <ResetButton onClick={reset} />
    </Flex>
  );
};

const ALLOWED_TYPES = ['image/png', 'image/svg+xml'];

const FileInputWrapper = chakra(Box, {
  baseStyle: {
    borderRadius: 'md',
    padding: '1',
    width: 'auto',
    cursor: 'pointer',
    position: 'relative',
    borderStyle: 'dashed',
    borderWidth: 'medium',
    borderColor: 'gray.200',
    _hover: {
      borderColor: 'blue.200',
    },
  },
});

const UploadingOverlay = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    opacity: 0.5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: 'rgba(0, 0, 0, 0.5)',
  },
});

function useWrapperStyle({
  isDragging,
  over,
}: {
  isDragging: boolean;
  over: boolean;
}) {
  const [draggingColor, hoveredColor] = useColorModeValue(
    ['blue.200', 'blue.500'],
    ['blue.800', 'blue.500']
  );

  if (over) {
    return { borderColor: hoveredColor, borderStyle: 'solid' };
  }
  if (isDragging) {
    return { borderColor: draggingColor };
  }

  return {};
}

function srcFromValue(value: LogoValue | null, defaultValue: string): string {
  if (!value) {
    return defaultValue;
  }

  return value.type === 'existing'
    ? value.url
    : `/api/uploads/${value.uploadId}`;
}
