import { ReactElement, useCallback } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormProvider, useForm } from 'react-hook-form';

import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';

import { useMediaDevices } from '../../softphone/use-media-devices';
import { softphoneState } from '../../store/softphone';
import { DevicePresets } from '../../types';
import { PresetFormRow } from './PresetFormRow';

export interface PresetSettingsProps {
  isOpen: boolean;
  onClose: () => void;
}

export const PresetSettings = ({
  isOpen,
  onClose,
}: PresetSettingsProps): ReactElement => {
  const { presets, setPresets } = softphoneState((state) => state.settings);
  const { data: mediaDevices } = useMediaDevices();

  const formMethods = useForm<DevicePresets>({
    defaultValues: presets,
    mode: 'onBlur',
  });

  const cancel = useCallback(() => {
    formMethods.reset();
    onClose();
  }, [formMethods, onClose]);
  const save = useCallback(
    (data: DevicePresets) => {
      console.log('Save presets: ', data);
      setPresets(data);
      onClose();
    },
    [setPresets, onClose]
  );

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={cancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <FormProvider {...formMethods}>
            <form
              id="devicePresets"
              data-ignore-form
              onSubmit={formMethods.handleSubmit(save)}
            >
              {mediaDevices ? (
                <>
                  <PresetFormRow
                    preset="handphone"
                    mediaDevices={mediaDevices}
                  />
                  <PresetFormRow
                    preset="headphones"
                    mediaDevices={mediaDevices}
                  />
                  <PresetFormRow preset="speaker" mediaDevices={mediaDevices} />
                </>
              ) : (
                <LoadingPage />
              )}
            </form>
          </FormProvider>
        </ModalBody>
        <ModalFooter>
          <FormActions form="devicePresets" onCancel={cancel} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
