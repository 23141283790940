import { ReactElement } from 'react';
import { Box, Grid, Input } from '@chakra-ui/react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { getNumberTypeTitleById } from './number-types';
import { NumberTypeInput } from './NumberTypeInput';
import { NationalNumberFormData } from './types';
import { validateNationalNumber } from './validate-national-number';

interface FormWithNationalNumber {
  nationalNumber: NationalNumberFormData;
}

const defaultNumberType = 'HR-oib';

export const NationalNumberField = (): ReactElement => {
  const {
    control,
    register,
    getValues,
    formState: { errors },
  } = useFormContext<FormWithNationalNumber>();

  const type = useWatch({ name: 'nationalNumber.type' });
  const numberTypeTitle = getNumberTypeTitleById(type ?? '') ?? undefined;
  const isInvalid = !!errors.nationalNumber;

  return (
    <Grid
      flexWrap="wrap"
      flexDir={{ base: 'column', md: 'row' }}
      sx={{ gap: '16px' }}
      templateColumns="1fr 1fr"
    >
      <Box minW={{ md: 'sm' }}>
        <Controller
          control={control}
          name="nationalNumber.type"
          render={({ field }) => (
            <NumberTypeInput
              defaultValue={defaultNumberType}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Box>
      <Box flex="1 1 auto">
        <Input
          isInvalid={isInvalid}
          {...register('nationalNumber.nationalNumber', {
            required: false,
            validate: () => {
              const { type, nationalNumber } = getValues().nationalNumber;

              return validateNationalNumber(type, nationalNumber);
            },
          })}
          placeholder={numberTypeTitle}
        />
      </Box>
    </Grid>
  );
};
