import { get, isObject, set } from 'lodash-es';

import { Api } from './api';
import * as LocalStorage from './localStorage';

function isLoggedIn(): boolean {
  return !!get(window.Bootstrap, 'bootquery.session.userID');
}

function settingURLPath(key: string[]): string {
  const pathUri = key.map(encodeURIComponent).join('/');

  return `/api/userSettings/${pathUri}`;
}

export async function getUserSetting<RET = unknown>(
  key: string | string[]
): Promise<RET> {
  if (typeof key === 'string') {
    key = key.split('.');
  }

  // Get setting from db for logged in users
  if (isLoggedIn()) {
    const { data } = await Api.get<RET>(settingURLPath(key));

    return data;
  }

  // For anonymous users, settings are stored in local storage
  const [localStorageKey, ...path] = key;
  const rootVal = LocalStorage.get(localStorageKey);

  return path.length ? get(rootVal, path) : rootVal;
}

export async function setUserSetting(
  key: string | string[],
  value: unknown
): Promise<void> {
  if (typeof key === 'string') {
    key = key.split('.');
  }

  // Get setting from db for logged in users
  if (isLoggedIn()) {
    await Api.put(settingURLPath(key), value);
  } else {
    const [localStorageKey, ...path] = key;
    let rootVal = LocalStorage.get<Record<string, unknown>>(localStorageKey);
    if (path.length) {
      if (!isObject(rootVal)) {
        rootVal = {};
      }
      rootVal = set(rootVal || {}, path, value);
    }
    LocalStorage.set(localStorageKey, rootVal);
  }
}
