import { ReactElement, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';

import { RichText } from '@bq/components/RichText';
import { useItemPlaceholders } from '@bq/components/RichText/Placeholders';

import { getTemplateElements } from '../../Offers/Templating/TemplateElements';
import { Template } from './types';

export const TemplateField = (): ReactElement => {
  const { control } = useFormContext<Partial<Template>>();
  const { field } = useController({
    control,
    name: 'template',
  });
  const templateElements = useMemo(() => getTemplateElements(), []);

  const itemPlaceHolders = useItemPlaceholders(templateElements, {});

  return (
    <Box w="full">
      <RichText
        extensions={[itemPlaceHolders]}
        content={field.value ?? ''}
        onChange={field.onChange}
      />
    </Box>
  );
};
