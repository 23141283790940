import { memo, ReactElement, useCallback, useMemo } from 'react';
import { Box, IconButton, Tooltip } from '@chakra-ui/react';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { FaPlus } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';

import { useConfirmationModal } from '@bq/components/ConfirmationModal/use-confirmation-modal';
import { useListingSettings } from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { defaultVisibleColumns } from 'app/Modules/Ticketing/Assets/components/TicketTable/Columns/defaults';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  Table,
  TableSelectionProvider,
} from 'BootQuery/Assets/components/Table';

import { phonebookDisplayModeOptions } from '../CompanyEmployeeList/display-settings';
import { CompanyLocationApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { columns } from './columns';
import {
  deleteItem,
  sendSms,
  sendSmsEmployees,
} from './company-locations-bulk-actions';
import { filterTypes } from './filter-types';
import { pickCorrectQuery } from './utils';

interface Props {
  companyID?: number;
  customFields?: FieldValue[];
}

const AddButton = memo(({ companyID }: Props): ReactElement => {
  const buttonLink = companyID
    ? `/phonebook/companies/${companyID}/locations/create`
    : '/phonebook/company-locations/create';

  return (
    <Tooltip hasArrow label="Add">
      <IconButton
        as={Link}
        to={buttonLink}
        variant="outline"
        colorScheme="green"
        aria-label="Add"
        icon={<FaPlus />}
      />
    </Tooltip>
  );
});
AddButton.displayName = 'AddButton';
export const CompanyLocationsTableList = ({
  companyID,
  customFields,
}: Props): ReactElement => {
  const overview = useSelectedOverview('companyLocations');
  const confirmAction = useConfirmationModal();
  const listingSettings = useListingSettings<CompanyLocationApiData>({
    listingName: companyID
      ? 'Phonebook.CompanyLocationListEmbed'
      : 'Phonebook.CompanyLocationList',
    viewName: 'table',
    filterTypes,
    columns: columns(),
    otherFilters: overview?.filters ?? [],
    defaults: { visibleColumns: defaultVisibleColumns },
    customFields,
  });

  const { filters, columnsToShow, limit, density, page } = listingSettings;

  const { data, refetch } = useQuery({
    queryKey: ['companyLocations', companyID, page, limit, filters],
    queryFn: () => pickCorrectQuery({ page, limit, filters }, companyID),
    enabled: filters !== undefined,
    placeholderData: keepPreviousData,
  });
  const editLink = useCallback((row: CompanyLocationApiData) => {
    return `/phonebook/company-locations/${row.ID}`;
  }, []);
  const navigate = useNavigate();
  const bulkActions = useMemo(
    () => [
      {
        actions: [sendSms(navigate), sendSmsEmployees(navigate)],
        styleProps: { colorScheme: 'brand', size: 'sm' },
      },
      {
        actions: [deleteItem(refetch, confirmAction)],
        styleProps: {
          colorScheme: 'red',
          variant: 'outline',
          style: {
            marginLeft: 'auto',
          },
        },
      },
    ],

    [navigate, refetch, confirmAction]
  );

  const itemCount = data?.meta.count ?? 0;
  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count: itemCount,
    displayModeOptions: phonebookDisplayModeOptions,
  });

  if (!data) {
    return <LoadingPage />;
  }

  const {
    data: locations,
    meta: { count },
  } = data;

  return (
    <Box>
      <Box
        mt={!companyID ? 9 : 4}
        mb={!companyID ? 14 : 6}
        px={!companyID ? 5 : 2}
      >
        <ListHeading
          {...headingProps}
          count={count}
          limit={limit}
          Actions={<AddButton companyID={companyID} />}
        />
      </Box>
      <TableSelectionProvider<CompanyLocationApiData>
        page={page}
        idColumn="ID"
        selectAll={async () => {
          const { data } = await pickCorrectQuery(
            {
              page: 1,
              limit: 99999,
              filters,
              fields: [
                'ID',
                'name',
                'phoneNumbers.phoneNumber.phoneNumberE164',
              ],
            },
            companyID
          );

          return data;
        }}
        selectPage={() => locations}
      >
        <Table<CompanyLocationApiData>
          columns={columnsToShow}
          rows={locations}
          createRowLink={editLink}
          bulkActions={bulkActions}
          size={density}
        />
      </TableSelectionProvider>
    </Box>
  );
};
