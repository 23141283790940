import { forwardRef, lazy, ReactElement, ReactNode, Suspense } from 'react';
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { FaEye } from 'react-icons/fa';

import { IconButton, IconButtonProps } from '@bq/components/IconButton';
import { Portal } from '@bq/components/Portal';
import { getContactInfo } from 'app/Modules/Phonebook/Assets/js/contact-info';
import { ContactType } from 'app/Modules/Phonebook/Assets/js/types';
import { FixedPopoverContent } from 'BootQuery/Assets/components/FixedPopoverContent';
import { LoaderScale } from 'BootQuery/Assets/components/Loader';

interface ContactDisplayProps {
  contactID: number;
  contactType: ContactType;
  children?: ReactNode;
}

function useContactInfo(ID: number, type: ContactType) {
  return useQuery({
    queryKey: ['contactInfo', type, ID],
    queryFn: () => getContactInfo(type, ID),
  });
}

const ContactDetails = lazy(
  () => import('BootQuery/Assets/components/ContactDisplay/ContactDetails')
);

const ContactPopoverContent = ({
  contactID,
  contactType,
  children,
}: Omit<ContactDisplayProps, 'contactName'>): ReactElement => {
  const { data: contactInfo } = useContactInfo(contactID, contactType);

  return (
    <Suspense fallback={<LoaderScale />}>
      {contactInfo && (
        <ContactDetails contact={contactInfo}>{children}</ContactDetails>
      )}
    </Suspense>
  );
};

export const ContactSelectDetailsButton = forwardRef(
  (props: Partial<IconButtonProps>, ref): ReactElement => {
    const { t } = useTranslation('global');

    return (
      <IconButton
        ref={ref}
        icon={<FaEye />}
        label={`${t('global:details')}`}
        {...props}
      />
    );
  }
);
ContactSelectDetailsButton.displayName = 'ContactSelectDetailsButton';

export const ContactSelectDetails = ({
  contactID,
  contactType,
  children,
}: ContactDisplayProps): ReactElement => (
  <Popover isLazy>
    <PopoverTrigger>
      <ContactSelectDetailsButton />
    </PopoverTrigger>
    <Portal>
      <FixedPopoverContent w="auto">
        <PopoverArrow />
        <PopoverBody>
          <ContactPopoverContent
            contactID={contactID}
            contactType={contactType}
          >
            {children}
          </ContactPopoverContent>
        </PopoverBody>
      </FixedPopoverContent>
    </Portal>
  </Popover>
);
