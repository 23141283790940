import { useCallback, useMemo } from 'react';
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query';

import { EventFiltersParam, listEvents } from '../api';
import { useEventsQueryUtils } from './events-query-utils';
import {
  EventsFetchResponse,
  InfiniteEventParams,
  UseEventsResult,
} from './types';

export function useEvents(
  params: InfiniteEventParams = { perPage: 5 }
): UseEventsResult {
  const queryClient = useQueryClient();
  const queryKey = useMemo(() => ['Events.useEvents', params], [params]);
  const { data, isFetching, fetchNextPage, refetch } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam }) => getEvents(params, pageParam?.toString()),
    getNextPageParam: ({ events, hasMore }): Date | null => {
      if (events.length === 0 || !hasMore) {
        return null;
      }

      return events[events.length - 1].timestamp;
    },
    initialPageParam: null as Date | null,
  });

  const { hasMore, events } = useMemo(() => {
    const pages = data?.pages ?? [];
    const [lastPage] = pages.slice(-1);
    const hasMore = lastPage?.hasMore ?? false;
    const events = pages.map((page) => page.events).flat();

    return { hasMore, events };
  }, [data]);

  const removeQuery = useCallback(() => {
    queryClient.removeQueries({ queryKey });
  }, [queryClient, queryKey]);

  return {
    events,
    isFetching,
    hasMore,
    refetch,
    queryKey,
    removeQuery,
    fetchMore: fetchNextPage,
    ...useEventsQueryUtils(queryKey),
  };
}

async function getEvents(
  { perPage = 16, ...params }: InfiniteEventParams,
  before?: string | null
): Promise<EventsFetchResponse> {
  const data = await listEvents({
    ...params,
    limit: perPage + 1,
    filters: makeFilterParams(params, before),
  });

  const hasMore = data.length > perPage;
  const events = data.slice(0, perPage);

  return { events, hasMore };
}

function makeFilterParams(
  params: InfiniteEventParams,
  before: string | null | undefined
): EventFiltersParam {
  const filters = params.filters ?? {};

  return before ? { ...filters, before } : filters;
}
