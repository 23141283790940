import React from 'react';
import {
  Button,
  ButtonProps,
  chakra,
  forwardRef,
  Text,
} from '@chakra-ui/react';

import { PopoverIcon } from './PopoverIcon';

interface Prop {
  valueFontWeight?: string | undefined;
}

export const PopoverButton = forwardRef<ButtonProps & Prop, 'button'>(
  ({ children, valueFontWeight, ...props }, ref) => {
    return (
      <ButtonWrapper
        fontWeight={valueFontWeight}
        variant="link"
        {...props}
        ref={ref}
      >
        <PopoverIcon />
        <Text as="span" overflow="hidden" textOverflow="ellipsis">
          {children}
        </Text>
      </ButtonWrapper>
    );
  }
);

const ButtonWrapper = chakra(Button, {
  baseStyle: {
    color: 'inherit',
    whiteSpace: 'nowrap',
    fontWeight: 'normal',
    display: 'flex',
    flexDir: 'row-reverse',
    justifyContent: 'start',
    minWidth: 0,
    lineHeight: 'inherit',
  },
});
