import { useMemo } from 'react';
import { StyleProps, SystemStyleObject } from '@chakra-ui/react';

type ItemStyleObject = StyleProps & { _hover: SystemStyleObject };

export const itemBaseStyle: ItemStyleObject = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  px: '4',
  py: '2',
  pl: '8',
  bg: 'transparent',
  color: 'white',
  fontWeight: 'semibold',
  fontSize: 'sm',
  _hover: {
    color: 'white',
    bg: '#777778',
    textDecoration: 'none',
  },
  w: '100%',
  borderRadius: 0,
};

interface UseItemStyleConfig {
  isActive?: boolean;
}

export const useItemStyle = (config?: UseItemStyleConfig): ItemStyleObject => {
  return useMemo(
    () => ({
      ...itemBaseStyle,
      bg: config?.isActive ? '#777778' : 'transparent',
    }),
    [config?.isActive]
  );
};
