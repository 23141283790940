import { useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaPlus, FaTrash, FaUndo } from 'react-icons/fa';

import { ChangeBadge } from '@bq/components/ChangeBadge';
import { WithChange } from '@bq/components/SettingsCRUD/types';
import { useSettingsCrud } from '@bq/components/SettingsCRUD/use-settings-crud';

import { useTicketingSettingsContext } from '../TicketSettingsContext';
import { TicketTypesForm } from './types';
import { TypesModalForm } from './TypesModalForm';

export const TicketTypes = () => {
  const { types } = useTicketingSettingsContext();
  const { t } = useTranslation('Ticketing');
  const [selected, setSelected] = useState<WithChange<TicketTypesForm> | null>(
    null
  );
  const [modifiedTypes, modifyItem] = useSettingsCrud<TicketTypesForm>({
    parent: '#settings-form',
    inputName: 'ticketing[types]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: types,
  });
  const { isOpen, onClose, onOpen } = useDisclosure();

  return (
    <VStack>
      {modifiedTypes.map((type) => (
        <HStack key={type.ID} w="full">
          <HStack w="full">
            <Box
              fontWeight="bold"
              mr={2}
              textDecor={type.change === 'del' ? 'line-through' : 'none'}
            >
              {type.name}
            </Box>
            <ChangeBadge type={type.change} />
          </HStack>
          <HStack>
            {type.change && (
              <IconButton
                aria-label={t('global:undo_changes')}
                icon={<FaUndo />}
                variant="ghost"
                size="sm"
                onClick={() => {
                  modifyItem.undo(type.ID);
                }}
              />
            )}
            <IconButton
              aria-label={t('global:edit')}
              icon={<FaEdit />}
              variant="ghost"
              size="sm"
              onClick={() => {
                setSelected(type);
                onOpen();
              }}
            />
            <IconButton
              aria-label={t('global:delete')}
              isDisabled={type.change === 'del'}
              size="sm"
              icon={<FaTrash />}
              variant="ghost"
              colorScheme="red"
              onClick={() => {
                modifyItem.del(type.ID);
              }}
            />
          </HStack>
        </HStack>
      ))}
      <Flex w="full" justify="end">
        <IconButton
          aria-label={t('global:add')}
          mr={2}
          icon={<FaPlus />}
          colorScheme="green"
          size="sm"
          onClick={() => {
            setSelected(null);
            onOpen();
          }}
        />
      </Flex>
      {isOpen && (
        <TypesModalForm
          isOpen={isOpen}
          selected={selected}
          onClose={onClose}
          onChange={(modifications): void => {
            if (modifications.change === 'add') {
              modifyItem.add(modifications);
            }
            if (modifications.change === 'upd') {
              modifyItem.upd(modifications);
            }
          }}
        />
      )}
    </VStack>
  );
};
