import { memo, ReactElement } from 'react';

import { Sidebar as SidebarInner } from '@bq/components/Sidebar';
import { TreeOverviews } from '@bq/components/TreeOverviews';
import { MobileOverviewWrapper } from '@bq/components/TreeOverviews/MobileOverviewsWrapper';

import { useOverviews } from './use-overviews';

const Sidebar = memo((): ReactElement => {
  const { data: overviews } = useOverviews();

  return (
    <SidebarInner>
      <MobileOverviewWrapper>
        <TreeOverviews path="events" overviews={overviews ?? []} />
      </MobileOverviewWrapper>
    </SidebarInner>
  );
});

Sidebar.displayName = 'Sidebar';
export { Sidebar };
