import {
  Box,
  FormControl,
  FormLabel,
  Heading,
  IconButton,
  VStack,
} from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { uniqid } from 'app/assets/js/tsutil';

import { PartialPrices } from '../../../types';
import { FieldProps } from '../../types';
import { ActionPriceField } from './ActionPriceField';

export const ActionPriceFields = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    formState: { errors },
    control,
  } = useFormContext<PartialPrices>();
  const { append, fields, remove } = useFieldArray<PartialPrices>({
    name: 'actionPrices',
    control,
  });

  return (
    <FormControl isInvalid={!!errors.actionPrices} {...formControlProps}>
      <Heading size="md" fontWeight="bold">
        <FormLabel htmlFor="actionPrices">
          {t('Products:action_prices')}
        </FormLabel>
      </Heading>
      <VStack spacing={4}>
        {fields.map((field, idx) => (
          <ActionPriceField idx={idx} key={field.id} remove={remove} />
        ))}
      </VStack>
      <Box mt={4}>
        <IconButton
          aria-label={t('global:add')}
          icon={<FaPlus />}
          colorScheme="green"
          onClick={() => {
            append({ ID: uniqid() });
          }}
        />
      </Box>
    </FormControl>
  );
};
