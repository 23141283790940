import { DropdownValue } from 'BootQuery/Assets/components/Select';

import {
  APICustomEvent,
  CustomEvent,
  CustomEventFormData,
  FormContactValue,
} from './types';

interface FilledEvent {
  event: DropdownValue;
}
interface FilledTicket {
  ticket: DropdownValue;
}
interface FilledContact {
  contact: FormContactValue;
}

export const toBackend = (
  formEvent: CustomEventFormData
): Omit<CustomEvent, 'ID'> => ({
  typeID: parseInt(formEvent.type.value, 10),
  eventAt: formEvent.eventAt,
  title: formEvent.title,
  data: formEvent.data,
  user: formEvent.user,
  tickets: (formEvent.tickets ?? [])
    .filter((link): link is FilledTicket => !!link.ticket)
    .map(({ ticket }) => {
      if ('$new' in ticket) {
        throw new Error("Inserting new values shouldn't be possible here");
      }

      return { ID: ticket.ID, title: ticket.name };
    }),
  contacts: (formEvent.contacts ?? [])
    .filter((link): link is FilledContact => !!link.contact)
    .map(({ contact }) => {
      const [type, contactID] = contact.ID.split('-');

      return {
        type,
        ID: parseInt(contactID, 10),
        name: contact.name,
      };
    }),
  events: (formEvent.events ?? [])
    .filter((event): event is FilledEvent => !!event?.event)
    .map(({ event }) => {
      if ('$new' in event) {
        throw new Error("Inserting new values shouldn't be possible here");
      }

      return { ID: event.ID, title: event.name };
    }),
});

export const fromBackend = (
  event: Omit<APICustomEvent, 'ID'>
): CustomEventFormData => ({
  type: { label: event.type.name, value: event.type.ID.toString() },
  eventAt: event.eventAt,
  title: event.title,
  data: event.data,
  user: event.user,
  tickets: event.tickets.map((ticket) => ({
    ticket: { ID: ticket.ID, name: ticket.title },
  })),
  contacts: event.contacts.map((contact) => ({
    contact: {
      ID: `${contact.type}-${contact.ID}`,
      name: contact.name,
    },
  })),
  events: event.events.map((event) => ({
    event: { ID: event.ID, name: event.title },
  })),
});
