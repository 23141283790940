import { ReactElement, useContext } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { format } from 'date-fns';
import hr from 'date-fns/locale/hr';
import { get } from 'lodash-es';

import { StaticFormContext } from '../../StaticFormContext';
import { FieldValue } from '../../types';

function formatDate(date: unknown): string {
  if (!date || !(date instanceof Date || typeof date === 'string')) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return format(dateObj, 'P', { locale: hr });
}

export const StaticDateField = ({
  id,
  settings,
  type,
}: FieldValue): ReactElement => {
  const { formData } = useContext(StaticFormContext);

  const value = get(formData, id);

  return (
    <Flex alignItems="center">
      <Text as="span" fontWeight="bold">
        {settings.name}:
      </Text>
      &nbsp;
      {formatDate(value)}
    </Flex>
  );
};
