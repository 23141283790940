import { useMemo } from 'react';

import { RichTextExtensions } from '@bq/components/RichText/types';
import { OBJ } from 'BootQuery/Assets/js/globalTypes';

import { ItemPlaceholders } from './ItemPlaceholderExtension';
import { ItemPlaceHolders } from './ItemPlaceHolders';
import { TemplateElement } from './types';

export const useItemPlaceholders = <T extends OBJ = OBJ>(
  templateElements: TemplateElement<T>[],
  values: OBJ,
  showValues = false
): RichTextExtensions => {
  return useMemo(
    () => ({
      key: 'itemPlaceholders',
      extension: ItemPlaceholders.configure({
        templateElements: templateElements as TemplateElement<OBJ>[],
        values,
        defaultShowValues: showValues,
      }),
      component: <ItemPlaceHolders elements={templateElements} />,
    }),
    [showValues, templateElements, values]
  );
};
