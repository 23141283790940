import { ReactElement } from 'react';

export const DateIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 875 1000"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M289.06 562.5h-78.12a23.51 23.51 0 0 1-23.44-23.44v-78.12a23.51 23.51 0 0 1 23.44-23.44h78.12a23.51 23.51 0 0 1 23.44 23.44v78.12a23.51 23.51 0 0 1-23.44 23.44ZM500 539.06v-78.12a23.51 23.51 0 0 0-23.44-23.44h-78.12A23.51 23.51 0 0 0 375 460.94v78.12a23.51 23.51 0 0 0 23.44 23.44h78.12A23.51 23.51 0 0 0 500 539.06Zm187.5 0v-78.12a23.51 23.51 0 0 0-23.44-23.44h-78.12a23.51 23.51 0 0 0-23.44 23.44v78.12a23.51 23.51 0 0 0 23.44 23.44h78.12a23.51 23.51 0 0 0 23.44-23.44ZM500 726.56v-78.12A23.51 23.51 0 0 0 476.56 625h-78.12A23.51 23.51 0 0 0 375 648.44v78.12A23.51 23.51 0 0 0 398.44 750h78.12A23.51 23.51 0 0 0 500 726.56Zm-187.5 0v-78.12A23.51 23.51 0 0 0 289.06 625h-78.12a23.51 23.51 0 0 0-23.44 23.44v78.12A23.51 23.51 0 0 0 210.94 750h78.12a23.51 23.51 0 0 0 23.44-23.44Zm375 0v-78.12A23.51 23.51 0 0 0 664.06 625h-78.12a23.51 23.51 0 0 0-23.44 23.44v78.12A23.51 23.51 0 0 0 585.94 750h78.12a23.51 23.51 0 0 0 23.44-23.44ZM875 218.75v687.5a93.77 93.77 0 0 1-93.75 93.75H93.75A93.77 93.77 0 0 1 0 906.25v-687.5A93.77 93.77 0 0 1 93.75 125h93.75V23.44A23.51 23.51 0 0 1 210.94 0h78.12a23.51 23.51 0 0 1 23.44 23.44V125h250V23.44A23.51 23.51 0 0 1 585.94 0h78.12a23.51 23.51 0 0 1 23.44 23.44V125h93.75A93.77 93.77 0 0 1 875 218.75Zm-93.75 675.78v-582H93.75v582a11.75 11.75 0 0 0 11.75 11.72h664a11.75 11.75 0 0 0 11.75-11.72Z"
    />
  </svg>
);
