import { PropsWithChildren } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  IconButton,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import { FaList } from 'react-icons/fa';

import { useIsMobile } from '@bq/components/use-is-mobile';

export const MobileOverviewWrapper = ({ children }: PropsWithChildren) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bg = useColorModeValue('blackAlpha', 'gray');
  const isMobile = useIsMobile();

  return isMobile ? (
    <>
      <IconButton
        colorScheme={bg}
        onClick={onOpen}
        icon={<FaList />}
        aria-label="open-menu"
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="full">
        <DrawerOverlay />
        <DrawerContent background="#424343" color="#F5F5F5" px={0}>
          <DrawerCloseButton />
          <DrawerBody px={0}>{children}</DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  ) : (
    <>{children}</>
  );
};
