import { useMemo } from 'react';
import { mapValues } from 'lodash-es';

import { EventProviderMap, ModuleEventTypeProviders } from './types';

export const getEventTypes = (): EventProviderMap => {
  return getEventTypeProviders().reduce<EventProviderMap>(
    (providers, provided) => ({
      ...providers,
      ...addTypes(provided),
    }),
    {}
  );
};

function getEventTypeProviders(): ModuleEventTypeProviders[] {
  return window.BootQuery.getProviders<ModuleEventTypeProviders>('eventTypes');
}

function addTypes(raw: ModuleEventTypeProviders): EventProviderMap {
  return mapValues(raw, (provider, type) => ({
    ...provider,
    type,
  }));
}

export const useEventTypes = (): EventProviderMap => useMemo(getEventTypes, []);
