import { useMemo } from 'react';
import { useQuery } from '@tanstack/react-query';
import { TFunction, useTranslation } from 'react-i18next';

import {
  itemToSelectOption,
  SelectOption,
} from 'BootQuery/Assets/components/Select';
import { Api } from 'BootQuery/Assets/js/api';

import { ListItem } from './types';

export async function getAddressTypes(): Promise<SelectOption[]> {
  const { data: addressTypes } = await Api.get<ListItem[]>(
    '/api/phonebook/addressTypes',
    {
      params: {
        fields: ['ID', 'name'],
        limit: 100,
      },
    }
  );

  return addressTypes.map(itemToSelectOption);
}

export function useAddressTypes(): SelectOption[] | null {
  const { data } = useQuery({
    queryKey: ['phonebookAddressTypes'],
    queryFn: () => getAddressTypes(),
  });
  const translated = useTranslatedAddressTypes(data ?? []);

  return data ? translated : null;
}

export function translateAddressType(
  t: TFunction<'Phonebook', undefined>,
  addressType: string
): string {
  switch (addressType) {
    case 'headquarters':
      return t('Phonebook:address_types.headquarters');
    case 'billing':
      return t('Phonebook:address_types.billing');
    case 'shipping':
      return t('Phonebook:address_types.shipping');
    default:
      return addressType;
  }
}

export function translateAddressTypeOption(
  t: TFunction<'Phonebook', undefined>,
  addressType: SelectOption
): SelectOption {
  return {
    ...addressType,
    label: translateAddressType(t, addressType.label),
  };
}

export function useTranslatedAddressTypes(
  addressTypes: SelectOption[]
): SelectOption[] {
  const { t } = useTranslation('Phonebook');

  return useMemo(
    () => addressTypes.map((type) => translateAddressTypeOption(t, type)),
    [addressTypes, t]
  );
}
