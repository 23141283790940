import { ReactElement } from 'react';
import { Button, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { softphoneStateColors, useSoftphoneStateLabels } from './labels-colors';
import { PauseStateDisplay } from './PauseStateDisplay';
import { dialerStore, getCurrentPause } from './store/pbx';
import { softphoneState } from './store/softphone';

export const SoftphoneStateDisplay = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const state = softphoneState((state) => state.status);
  const currentPause = dialerStore(getCurrentPause);
  const softphoneStateLabels = useSoftphoneStateLabels();

  if (state === 'usedElsewhere') {
    const useHere = () => {
      softphoneState.getState().switchHere();
    };

    return (
      <Flex justifyContent="center" flexGrow={1}>
        <Button size="sm" colorScheme="blue" onClick={useHere}>
          {t('Telephony:use_softphone_here')}
        </Button>
      </Flex>
    );
  }

  if (state === 'ready' && currentPause !== null) {
    return <PauseStateDisplay pause={currentPause.name} />;
  }

  return (
    <Text
      as="div"
      color={softphoneStateColors[state]}
      textAlign="center"
      flexGrow={1}
    >
      {softphoneStateLabels[state]}
    </Text>
  );
};
