import { useMemo } from 'react';
import { Box, ButtonGroup, HStack } from '@chakra-ui/react';
import { chakraComponents, MultiValueProps } from 'chakra-react-select';
import { useTranslation } from 'react-i18next';
import { FaEdit } from 'react-icons/fa';

import { useContactCreateModal } from '@bq/components/ContactModals/useContactCreateModal';
import { useContactEditModal } from '@bq/components/ContactModals/useContactEditModal';
import { IconButton } from '@bq/components/IconButton';

import { ContactSelectOption } from '../types';
import { CSIconForContactType, isEditableType } from '../utils';
import { ContactSelectDetails } from './ContactSelectDetails';
import { useMultiContactSelectCtx } from './MultiContactSelectCtx';

export const ContactMultiValue = (
  props: MultiValueProps<ContactSelectOption>
) => {
  const { value, type } = props.data;
  const Icon = useMemo(
    () => (type ? CSIconForContactType(type) : null),
    [type]
  );
  const { hasDetails, hasEditModal, size, contactDetilsChildren } =
    useMultiContactSelectCtx();
  const { t } = useTranslation('');
  const callEditModal = useContactEditModal();
  const callCreateModal = useContactCreateModal();

  if (props) {
    return (
      <chakraComponents.MultiValue {...props}>
        <HStack w="full">
          <Box>{Icon}</Box>
          <Box>{props.data.label}</Box>
          <ButtonGroup isAttached size={size} variant="ghost">
            {hasEditModal && isEditableType(type) && (
              <IconButton
                label={t('global:edit')}
                icon={<FaEdit />}
                onClick={() => {
                  if (typeof value === 'number') {
                    callEditModal({
                      ID: value,
                      type,
                      closeCallback: () => {
                        /** */
                      },
                    });
                  } else if (type === 'person') {
                    const splitNew = value.split(' ');
                    const half = Math.ceil(splitNew.length / 2);
                    const firstHalf = splitNew.slice(0, half).join(' ');
                    const secondHalf = splitNew.slice(half).join(' ');
                    callCreateModal({
                      type: 'person',
                      defaults: { firstName: firstHalf, lastName: secondHalf },
                      closeCallback: () => {
                        /** */
                      },
                    });
                  } else {
                    callCreateModal({
                      type,
                      defaults: { name: value },
                      closeCallback: () => {
                        /** */
                      },
                    });
                  }
                }}
              />
            )}
            {hasDetails && typeof value === 'number' && type !== 'manual' && (
              <ContactSelectDetails contactID={value} contactType={type}>
                {contactDetilsChildren}
              </ContactSelectDetails>
            )}
          </ButtonGroup>
        </HStack>
      </chakraComponents.MultiValue>
    );
  }

  return <></>;
};
