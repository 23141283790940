import { FormControl, FormLabel, IconButton } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { Good } from '../../../types';
import { FieldProps } from '../../types';
import { BarcodeField } from './BarcodeField';

export const BarcodeFields = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    formState: { errors },
    control,
  } = useFormContext<Good>();
  const { append, fields, remove } = useFieldArray<Good>({
    name: 'good.barcodes',
    control,
  });

  return (
    <FormControl isInvalid={!!errors.good?.barcodes} {...formControlProps}>
      <FormLabel htmlFor="good.barcodes">{t('Products:barcodes')}</FormLabel>
      {fields.map((field, idx) => (
        <BarcodeField idx={idx} key={field.id} remove={remove} />
      ))}
      <IconButton
        aria-label={t('global:add')}
        icon={<FaPlus />}
        colorScheme="green"
        onClick={() => {
          append({ barcode: '', type: '' });
        }}
      />
    </FormControl>
  );
};
