import { Api } from 'BootQuery/Assets/js/api';

export interface CallListPermissions {
  reports: boolean;
}

export async function callListPermissions(): Promise<CallListPermissions> {
  const { data: reports } = await Api.get<boolean>(
    '/api/telephony/canAccessReports'
  );

  return { reports };
}
