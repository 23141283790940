import { FilterTypes } from 'BootQuery/Assets/components/FilterBar';

import { baseFilters } from './base-filters';
import { logisticalDataFilters } from './logistical-data-filters';
import { priceFilters } from './price-filters';
import { productFilters } from './product-filters';
import { serviceFilters } from './service-filters';

export const filterTypes: FilterTypes = {
  ...baseFilters,
  ...productFilters,
  ...serviceFilters,
  ...priceFilters,
  ...logisticalDataFilters,
};
