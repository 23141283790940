import { ReactElement } from 'react';

export const NumberIcon = (): ReactElement => (
  <svg
    viewBox="0 0 1000 1000"
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="m664.25 389.16 10.72-60A18 18 0 0 0 657.25 308H545l22-122.84A18 18 0 0 0 549.25 164H488.3a18 18 0 0 0-17.72 14.84L447.51 308H299.56l21.93-122.84A18 18 0 0 0 303.78 164h-60.95a18 18 0 0 0-17.72 14.84L202 308H83.4a18 18 0 0 0-17.72 14.84l-10.68 60A18 18 0 0 0 72.68 404H184.9l-34.29 192H32a18 18 0 0 0-17.72 14.84l-10.71 60A18 18 0 0 0 21.25 692h112.22l-21.93 122.84A18 18 0 0 0 129.25 836h60.95a18 18 0 0 0 17.72-14.84L231 692h148l-22 122.84A18 18 0 0 0 374.73 836h60.94a18 18 0 0 0 17.72-14.84L476.46 692H595.1a18 18 0 0 0 17.72-14.84l10.72-60A18 18 0 0 0 605.82 596H493.6l34.29-192h118.64a18 18 0 0 0 17.72-14.84ZM396.08 596H248.13l34.29-192h148Zm378.404-181.447h74.707V202.517l-76.684 15.82v-57.568l76.245-15.82h80.42v269.604h74.707V473H774.484Zm-7.69 426.196v-60.645q20.215 9.449 38.672 14.283Q823.923 799 841.94 799q37.793 0 58.887-20.874 21.094-21.094 24.83-62.402-14.942 10.986-31.861 16.48-16.92 5.492-36.694 5.492-50.318 0-81.3-29.223-30.761-29.444-30.761-77.344 0-52.954 34.277-84.815 34.497-31.86 92.286-31.86 64.16 0 99.316 43.286 35.156 43.286 35.156 122.388 0 81.299-41.089 127.88-41.089 46.363-112.72 46.363-23.07 0-44.164-3.516-21.094-3.295-41.31-10.107zm104.37-157.983q22.192 0 33.399-14.283 11.206-14.502 11.206-43.286 0-28.564-11.206-43.066-11.207-14.502-33.399-14.502t-33.398 14.502q-11.206 14.502-11.206 43.066 0 28.784 11.206 43.286 11.206 14.283 33.398 14.283z" />
  </svg>
);
