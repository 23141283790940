import { ReactElement } from 'react';

export const MailboxIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M261.9 120.8L63.9 3.7c-16.1-9.5-40.7-.3-40.7 23.2V261a27 27 0 0040.7 23.2l198-117a27 27 0 000-46.4zm-86.7 62.9h-97a39.7 39.7 0 1133-17.7h31a39.7 39.7 0 1133 17.7z"
    />
    <circle
      fill="currentColor"
      cx="78.3"
      cy="144"
      r="22"
      transform="rotate(-13.3 78.3 144)"
    />
    <circle
      fill="currentColor"
      cx="175.2"
      cy="144"
      r="22"
      transform="rotate(-45 175.2 144)"
    />
  </svg>
);
