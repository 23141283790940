import { Box, Center } from '@chakra-ui/react';

import { CellProps } from '@bq/components/Table';

import { Ticket } from '../../../types';

export const TicketStatus = (props: CellProps<Ticket>) => {
  if (props.row.state) {
    const { name, color } = props.row.state;

    return (
      <Center>
        <Box
          color="white"
          textAlign="center"
          bg={color}
          rounded="sm"
          py="0.5"
          w="full"
          minW={20}
          fontWeight="bold"
          fontSize="xs"
        >
          {name}
        </Box>
      </Center>
    );
  }

  return <>-</>;
};
