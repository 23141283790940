import { lazyImport } from '@bq/components/lazy-import';
import { ReportsProvider } from 'app/Modules/Reporting/Assets/js/types';
import i18n from 'BootQuery/Assets/js/i18n';

const UserReports = lazyImport(() => import('./UserReports'), 'UserReports');

export const ProvidedReports: ReportsProvider = {
  users: {
    categoryTitle: () => i18n.t('User:user_title'),
    title: () => i18n.t('User:user_reports'),
    component: UserReports,
  },
};
