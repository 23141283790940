import { useQuery } from '@tanstack/react-query';
import { AsyncSelect, SingleValue } from 'chakra-react-select';

import { getType } from '../../../Api/type/type';
import { loadOptions } from './utils';

export interface FilterOption {
  label: string;
  value: number;
}
interface Props {
  onChange: (newValue: SingleValue<FilterOption>) => void;
  value: FilterOption | number | null;
}
export const TypeSelect = ({ onChange, value }: Props) => {
  const valueIsNumber = typeof value === 'number';
  const { data: loadedData } = useQuery({
    queryKey: ['typeSelectLoadOne', value],
    queryFn: async () => {
      if (valueIsNumber) {
        const { data } = await getType(value);

        return { label: data.name, value: data.ID };
      }
      throw Error('Unkown error');
    },
    enabled: valueIsNumber,
  });

  return (
    <AsyncSelect<FilterOption>
      size="sm"
      useBasicStyles
      value={valueIsNumber ? loadedData : value}
      cacheOptions
      defaultOptions
      onChange={(newVal) => {
        onChange(newVal);
      }}
      loadOptions={loadOptions}
      selectedOptionColorScheme="brand"
    />
  );
};
