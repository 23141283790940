import { ReactElement } from 'react';
import { Link, Tag, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';

import { ChangedField } from './ChangedField';
import { LegacyCustomFormChanges } from './LegacyCustomFormChanges';
import { LegacyTicketChangesDiff } from './types';
import { formatDate } from './util';

type Props = Partial<LegacyTicketChangesDiff>;

export const LegacyEventCardContent = (diff: Props): ReactElement => {
  const { t } = useTranslation('Ticketing');

  return (
    <VStack spacing="3">
      {diff.title !== undefined && (
        <ChangedField field={t('Ticketing:form.title')}>
          {diff.title}
        </ChangedField>
      )}
      {diff.stateName !== undefined && (
        <ChangedField field={t('Ticketing:form.state')}>
          <Tag background={diff.stateColor}>{diff.stateName}</Tag>
        </ChangedField>
      )}
      {diff.priorityName !== undefined && (
        <ChangedField field={t('Ticketing:form.priority')}>
          {diff.priorityName}
        </ChangedField>
      )}
      {diff.ticketGroup !== undefined && (
        <ChangedField field={t('Ticketing:form.group')}>
          {diff.ticketGroup}
        </ChangedField>
      )}
      {diff.assignedToDisplayName !== undefined && (
        <ChangedField field={t('Ticketing:form.agent')}>
          {diff.assignedToDisplayName ?? '-'}
        </ChangedField>
      )}
      {diff.customerPersonID !== undefined && (
        <ChangedField field={t('Ticketing:form.customer')}>
          <Link
            as={RouterLink}
            to={`/phonebook/people/${diff.customerPersonID}`}
          >
            {diff.customerPersonName ?? '-'}
          </Link>
        </ChangedField>
      )}
      {diff.customerCompanyID !== undefined && (
        <ChangedField field={t('Ticketing:form.customer')}>
          <Link
            as={RouterLink}
            to={`/phonebook/companies/${diff.customerCompanyID}`}
          >
            {diff.customerCompanyName ?? '-'}
          </Link>
        </ChangedField>
      )}
      {diff.reportMethodName !== undefined && (
        <ChangedField field={t('Ticketing:form.report_method')}>
          {diff.reportMethodName ?? '-'}
        </ChangedField>
      )}
      {diff.reportedAt !== undefined && (
        <ChangedField field={t('Ticketing:form.report_time')}>
          {formatDate(diff.reportedAt)}
        </ChangedField>
      )}
      {diff.data && <LegacyCustomFormChanges {...diff.data} />}
    </VStack>
  );
};
