import { Box, HStack, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaComments } from 'react-icons/fa';

import { Card, CardBody, CardHeader } from '@bq/components/Card';
import {
  EventCardComponent,
  EventMenu,
} from 'app/Modules/Events/Assets/components';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';

import { CustomerDisplay } from './CustomerDisplay';
import { ChatEvent } from './types';
import { useAverageScore } from './use-average-score';

export const ChatEventCard: EventCardComponent<ChatEvent> = (props) => {
  const { timestamp, data: chat } = props;
  const { t } = useTranslation('CustomerChat');

  const score = useAverageScore(chat.feedback);

  return (
    <Card>
      <CardHeader
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <Text as="span">
          <FaComments style={{ display: 'inline' }} />
          &nbsp;
          {'Chat'} ({chat.group.name})
        </Text>
        <Text as="span">
          {formatDate(timestamp, DateType.DateTime)}
          <EventMenu {...props} />
        </Text>
      </CardHeader>
      <CardBody>
        <HStack w="full" spacing="4">
          <Box flex="1 1 0">
            <Text as="strong" fontWeight="bold">
              {t('CustomerChat:customer')}:
            </Text>{' '}
            {chat.customer && <CustomerDisplay {...chat.customer} />}
          </Box>
          <Box flex="1 1 0">
            <Text as="strong" fontWeight="bold">
              {t('CustomerChat:agent')}:
            </Text>{' '}
            {chat.agent?.person?.fullName || chat.agent?.username}
          </Box>
          <Box flex="1 1 0">
            {t('CustomerChat:message_count')}: {chat.messageCount}
          </Box>
          <Box flex="1 1 0">
            {t('CustomerChat:rating')}: {score !== null ? score : '-'}
          </Box>
        </HStack>
      </CardBody>
    </Card>
  );
};
