import { ReactElement, useCallback, useState } from 'react';

import { makeListener } from 'BootQuery/Assets/js/socket-event-listener';
import { useSocketEvents } from 'BootQuery/Assets/js/use-socket-events';

import { Call, CallEndEvent } from '../../types/call';
import { CallCard, CallCardProps } from './CallCard';

export const RealTimeCallCard = ({
  call: origCall,
  rightMenu,
}: CallCardProps): ReactElement => {
  const [call, setCall] = useState<Call>(origCall);

  useSocketEvents(
    listener(),
    ['telephony/callUpdate', 'telephony/callStart'],
    useCallback(
      (ev: Call) => {
        if (ev.callId === call.callId) {
          setCall(ev);
        }
      },
      [call.callId]
    )
  );
  useSocketEvents(
    listener(),
    'telephony/callEnd',
    useCallback(
      (ev: CallEndEvent) => {
        if (call.callId === ev.callId) {
          const endAt = new Date().toISOString();
          setCall({ ...call, endAt, hangupCause: ev.hangupCause });
        }
      },
      [call]
    )
  );

  return <CallCard call={call} rightMenu={rightMenu} />;
};

const listener = makeListener('callEvent');
