import { ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import {
  FaCircle,
  FaExchangeAlt,
  FaHandsHelping,
  FaMicrophoneAlt,
  FaMicrophoneAltSlash,
  FaPause,
  FaPlay,
  FaPlus,
  FaUsers,
} from 'react-icons/fa';

import { ActionButton } from './ActionButton';
import { useDialerContext } from './DialerContext';
import { useRecording } from './hooks';
import { dialerStore, getCurrentCall as getCurrentPbxCall } from './store/pbx';
import { CallDisplayProps, SessionInterface } from './types';
import { useCurrentCallActions } from './use-current-call-actions';

const UnholdButtonIcon = (): ReactElement => (
  <Text as="span" color="yellow.400">
    <FaPlay />
  </Text>
);

interface MuteButtonProps {
  call: SessionInterface;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  buttonColor?: string;
}

const SessionMuteButton = ({
  call,
  size,
  buttonColor = 'whiteAlpha',
}: MuteButtonProps): ReactElement => {
  const { t } = useTranslation('Telephony');
  const label = call.muted ? t('Telephony:unmute') : t('Telephony:mute');
  const color = call.muted ? 'red.400' : undefined;

  return (
    <ActionButton
      colorScheme={buttonColor}
      label={label}
      icon={
        <Text as="span" color={color}>
          {call.muted ? <FaMicrophoneAltSlash /> : <FaMicrophoneAlt />}
        </Text>
      }
      onClick={() => call.toggleMute()}
      size={size}
    />
  );
};

interface CallActionsProps {
  callInfo: CallDisplayProps;
  size?: 'lg' | 'md' | 'sm' | 'xs';
  buttonColorScheme?: string;
}

export const CurrentCallActions = ({
  callInfo,
  size = 'sm',
  buttonColorScheme,
}: CallActionsProps): ReactElement => {
  const { t } = useTranslation('Telephony');
  const { selectedAction } = useDialerContext();
  const currentPbxCall = dialerStore(getCurrentPbxCall);

  const {
    canHold,
    isOnHold,
    holdCall,
    unholdCall,
    canBlindTransfer,
    blindTransfer,
    canAttendedTransfer,
    attendedTransfer,
    canAddToConference,
    addToConference,
    newCall,
  } = useCurrentCallActions();

  const { isRecording, canRecord, toggleRecording } =
    useRecording(currentPbxCall);

  return (
    <Flex justifyContent="center">
      <ActionButton
        colorScheme={buttonColorScheme}
        label={t('Telephony:blind_transfer')}
        icon={<FaExchangeAlt />}
        onClick={blindTransfer}
        isDisabled={!canBlindTransfer}
        isActive={selectedAction === 'blind'}
        size={size}
      />
      <ActionButton
        colorScheme={buttonColorScheme}
        label={t('Telephony:attended_transfer')}
        icon={<FaHandsHelping />}
        onClick={attendedTransfer}
        isDisabled={!canAttendedTransfer}
        isActive={selectedAction === 'attended'}
        size={size}
      />
      <ActionButton
        colorScheme={isRecording ? 'red' : buttonColorScheme}
        label={t('Telephony:record')}
        icon={<FaCircle />}
        onClick={toggleRecording}
        size={size}
        isActive={isRecording}
        isDisabled={!canRecord}
      />
      <ActionButton
        colorScheme={buttonColorScheme}
        label={t('Telephony:add_to_conference')}
        icon={<FaUsers />}
        onClick={addToConference}
        isDisabled={!canAddToConference}
        size={size}
      />
      <ActionButton
        colorScheme={buttonColorScheme}
        label={t('Telephony:new_call')}
        icon={<FaPlus />}
        onClick={newCall}
        isActive={selectedAction === 'newCall'}
        size={size}
      />
      {isOnHold ? (
        <ActionButton
          colorScheme={buttonColorScheme}
          label={t('Telephony:unhold')}
          icon={<UnholdButtonIcon />}
          onClick={unholdCall}
          size={size}
        />
      ) : (
        <ActionButton
          colorScheme={buttonColorScheme}
          label={t('Telephony:hold')}
          icon={<FaPause />}
          onClick={holdCall}
          isDisabled={!canHold}
          size={size}
        />
      )}
      {callInfo.type === 'softphone' && (
        <SessionMuteButton
          call={callInfo.call}
          size={size}
          buttonColor={buttonColorScheme}
        />
      )}
    </Flex>
  );
};
