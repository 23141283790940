import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';

import { BackendReqestProductData } from './types';

interface PostProductParams {
  data: Omit<BackendReqestProductData, 'ID'>;
  params?: GetOneParams;
}

export const postProduct = async <ReturnDataType = { ID: number }>({
  data,
  params,
}: PostProductParams) => {
  const { data: apiData } = await Api.post<ReturnDataType>(
    '/api/products',
    data,
    { params }
  );

  return apiData;
};
