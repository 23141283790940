import { Api } from 'BootQuery/Assets/js/api';

export async function getCurrentPermissions<T>(
  permissionKey: string,
  groupID: number | string
): Promise<T> {
  const saved = document.querySelector<HTMLInputElement>(
    `input[name="userPermissions[${permissionKey}]"]`
  );
  if (saved) {
    return JSON.parse(saved.value) as T;
  }

  const { data } = await Api.get<T>(
    `/api/user/userGroupPermissions/${groupID}/${permissionKey}`
  );

  return data;
}

export function setInputValue(permissionKey: string, value: unknown) {
  const name = `userPermissions[${permissionKey}]`;
  let el = document.querySelector<HTMLInputElement>(`input[name="${name}"]`);
  if (!el) {
    el = document.createElement('input');
    el.type = 'hidden';
    el.name = name;
    document.querySelector('#group-modify-container')?.append(el);
  }

  el.value = JSON.stringify(value);
}
