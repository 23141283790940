import { ReactElement, StrictMode } from 'react';
import { createRoot, Root } from 'react-dom/client';

import { uniqid } from 'app/assets/js/tsutil';

import { RootNode } from '../components/ReactRoot';

export const reactRoots: Record<string, Root> = {};

export function renderReact<PROPS extends JSX.IntrinsicAttributes>(
  container: HTMLElement,
  Component: (props: PROPS) => ReactElement,
  props: PROPS
): void {
  if (container.dataset.reactRoot) {
    return; // Don't double-render
  }

  const rootId = uniqid();
  container.dataset.reactRoot = rootId;

  const root = createRoot(container);
  reactRoots[rootId] = root;
  root.render(
    // StrictMode is here to avoid React devtools warning about not using StrictMode on RootNode
    <StrictMode>
      <RootNode>
        <Component {...props} />
      </RootNode>
    </StrictMode>
  );

  container.dataset.reactRoot = rootId;
}

export function purgeReact(root: HTMLElement): void {
  root.querySelectorAll('[data-react-root]').forEach((container) => {
    if (!(container instanceof HTMLElement)) {
      return;
    }
    const rootId = container.dataset.reactRoot;
    if (!rootId) {
      return;
    }
    const root = reactRoots[rootId];
    if (!root) {
      console.warn('Unable to find root to unmount: ', rootId, reactRoots);

      return;
    }

    root.unmount();
    delete reactRoots[rootId];
    delete container.dataset.reactRoot;
  });
}
