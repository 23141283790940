import 'BootQuery/Assets/js/i18n';

import { lazyImport } from '@bq/components/lazy-import';
import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

const UserReports = lazyImport(
  () => import('../components/Reports/UserReports'),
  'UserReports'
);

export const ReactRoutes: ReactRoute[] = [
  { path: '/user/reports/*', component: UserReports },
];
