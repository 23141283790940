import { ReactElement, useCallback } from 'react';
import { IconType } from 'react-icons';

import {
  IconButton,
  IconButtonProps,
} from 'BootQuery/Assets/components/IconButton';

import { softphoneState } from '../store/softphone';
import { DevicePresets } from '../types';
import { usePresetIcon, usePresetLabel } from './presets';

export interface PresetButtonProps extends Partial<IconButtonProps> {
  preset: keyof DevicePresets | null;
}

export const PresetButton = ({
  preset,
  colorScheme,
  ...iconProps
}: PresetButtonProps): ReactElement => {
  const { isActive, setActive, label, Icon } = usePresetButton(preset);

  return (
    <IconButton
      label={label}
      icon={<Icon />}
      colorScheme={isActive ? 'gray.600' : colorScheme}
      onClick={setActive}
      {...iconProps}
    />
  );
};

export interface UsePresetButtonResult {
  isActive: boolean;
  setActive: () => void;
  label: string;
  Icon: IconType;
}

export const usePresetButton = (
  preset: keyof DevicePresets | null
): UsePresetButtonResult => {
  const { selectedPreset, setPreset } = softphoneState(({ settings }) => ({
    selectedPreset: settings.preset,
    setPreset: settings.setPreset,
  }));

  const isActive = !selectedPreset ? !preset : selectedPreset === preset;

  const setActive = useCallback(() => {
    if (!preset) {
      setPreset(null);
    } else {
      setPreset(preset);
    }
  }, [setPreset, preset]);

  const label = usePresetLabel(preset);
  const Icon = usePresetIcon(preset);

  return {
    isActive,
    setActive,
    label,
    Icon,
  };
};
