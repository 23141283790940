import { ReactElement } from 'react';

export const AnnouncementIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M288 136.18a31.84 31.84 0 00-16-27.53V32.32c0-4.37-3.48-16-16-16a16 16 0 00-10 3.51l-42.45 34a121.21 121.21 0 01-75.4 26.44H32.33a32 32 0 00-31.95 32v47.94a31.94 31.94 0 0032 31.95h16.78a122.18 122.18 0 00-1.09 16 126.49 126.49 0 0012.77 55.4A15.67 15.67 0 0075 272h37.09c13 0 20.82-14.9 12.93-25.25A63.58 63.58 0 01112 208.08a60.21 60.21 0 012.21-16h14a121.22 121.22 0 0175.39 26.44l42.46 34a16 16 0 0010 3.5c12.44 0 16-11.37 16-16v-76.31A31.84 31.84 0 00288 136.18zm-47.94 70.62l-16.5-13.21a153.23 153.23 0 00-95.35-33.44v-47.94a153.24 153.24 0 0095.35-33.45l16.5-13.2z"
    />
  </svg>
);
