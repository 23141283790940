import { omit } from 'lodash-es';
import slugify from 'slugify';

import {
  IOverviewEditorItem,
  IOverviewEditorItemId,
  IOverviewItem,
  OverviewList,
} from '../types';

export function overviewsAtPath(
  overviews: OverviewList,
  path: string[]
): OverviewList {
  const [key, ...restOfPath] = path;

  if (!key) {
    return overviews;
  }

  const subOverviews =
    overviews.find((overview) => overview.id === key)?.overviews ?? [];

  return overviewsAtPath(subOverviews, restOfPath);
}

export function findOverviewIdx(overviews: OverviewList, id: string): number {
  const idx = overviews.findIndex((overview) => overview.id === id);
  if (idx === -1) {
    console.error(`Unable to find overview ${id} in `, overviews);
    throw new Error(`Failed to find overview ${id}`);
  }

  return idx;
}

export function getOverview(
  overviews: OverviewList,
  path: string[]
): IOverviewEditorItemId {
  const parentPath = path.slice();
  const id = parentPath.pop();

  const overviewList = overviewsAtPath(overviews, parentPath);

  const overview = overviewList.find((overview) => overview.id === id);
  if (!overview) {
    throw new Error(`Failed to find overview at ${path.join('.')}`);
  }

  return overview;
}

export function modifyOverviewsAtPath(
  overviews: OverviewList,
  path: string[],
  callback: (overviews: OverviewList) => OverviewList
): OverviewList {
  const [id, ...restOfPath] = path;
  if (!id) {
    // End of path, modify this collection
    return callback(overviews);
  }

  return overviews.map((overview) => {
    if (overview.id === id) {
      return {
        ...overview,
        overviews: modifyOverviewsAtPath(
          overview.overviews ?? [],
          restOfPath,
          callback
        ),
      };
    }

    return overview;
  });
}

export function removeOverview(
  overviews: OverviewList,
  path: string[]
): OverviewList {
  const { id, pathTo } = splitPathAndId(path);

  return modifyOverviewsAtPath(overviews, pathTo, (overviews) => {
    return overviews.filter((overview) => overview.id !== id);
  });
}

export function addOverview(
  overviews: OverviewList,
  atPath: string[],
  overview: IOverviewEditorItemId,
  atIdx: number | null = null
): OverviewList {
  return modifyOverviewsAtPath(overviews, atPath, (overviews) => {
    if (atIdx === null) {
      return [...overviews, overview];
    }

    return [...overviews.slice(atIdx, 1), overview, ...overviews.slice(atIdx)];
  });
}

export function arraySwap<T>(arr: T[], idxA: number, idxB: number): T[] {
  const clone = arr.slice();

  clone[idxA] = arr[idxB];
  clone[idxB] = arr[idxA];

  return clone;
}

export function splitPathAndId(path: string[]): {
  id: string;
  pathTo: string[];
} {
  const pathTo = path.slice();
  const id = pathTo.pop();
  if (!id) {
    throw new Error("Can't split empty path");
  }

  return { id, pathTo };
}

export function indexPathTo(overviews: OverviewList, path: string[]): number[] {
  if (path.length === 0) {
    return [];
  }

  const idxPath: number[] = [];
  let curOverviews = overviews;
  for (let i = 0; i < path.length; ++i) {
    const id = path[i];
    const idx = findOverviewIdx(curOverviews, id);

    idxPath.push(idx);
    curOverviews = curOverviews[idx].overviews ?? [];
  }

  return idxPath;
}

export function comparePaths(pathA: number[], pathB: number[]): 0 | -1 | 1 {
  const len = Math.min(pathA.length, pathB.length);

  // Compare paths while they match
  for (let i = 0; i < len; ++i) {
    if (pathA[i] > pathB[i]) {
      return 1;
    }
    if (pathA[i] < pathB[i]) {
      return -1;
    }
  }

  // After done, check if one path is nested deeper
  if (pathA.length > pathB.length) {
    return 1;
  }
  if (pathB.length < pathA.length) {
    return -1;
  }

  // If not caught until now, the paths are equal
  return 0;
}

export function findOverviewPath(
  overviews: OverviewList,
  id: string
): string[] | null {
  for (let i = 0; i < overviews.length; ++i) {
    const overview = overviews[i];
    if (overview.id === id) {
      return [id];
    }

    const subPath = findOverviewPath(overview.overviews ?? [], id);
    if (subPath) {
      return [overview.id, ...subPath];
    }
  }

  return null;
}

export function findOverview(
  overviews: OverviewList,
  id: string
): IOverviewEditorItemId | null {
  const path = findOverviewPath(overviews, id);
  if (!path) {
    return null;
  }

  const parentPath = path.slice(0, -1); // Without our id

  return (
    overviewsAtPath(overviews, parentPath).find(
      (overview) => overview.id === id
    ) ?? null
  );
}

interface FlatOverview extends Omit<IOverviewEditorItemId, 'overviews'> {
  fullSlug: string;
  parentId: string | null;
}

type FlatOverviews = FlatOverview[];

export function flattenOverviews(
  overviews: OverviewList,
  parentSlug: string | null = null,
  parentId: string | null = null
): FlatOverviews {
  return overviews.reduce((overviews: FlatOverviews, overview) => {
    const fullSlug = [parentSlug, overview.slug]
      .filter((slg) => !!slg)
      .join('-');

    const { overviews: sub, ...cleanOverview } = overview;
    const subOverviews = flattenOverviews(sub ?? [], fullSlug, overview.id);

    return [
      ...overviews,
      { ...cleanOverview, fullSlug, parentId },
      ...subOverviews,
    ];
  }, []);
}

const strIsInt = /^\d+$/;

export function genSlug(
  overviews: OverviewList,
  title: string,
  id: string | null = null,
  prefix = ''
): string {
  const flatOverviews = flattenOverviews(overviews);

  const cleanTitle = title.replace(/[.()]/g, ' ');
  let slug = slugify(cleanTitle, { lower: true });
  slug = `${prefix}${slug}`;
  let fullSlug = slug;

  if (id) {
    const existing = flatOverviews.find((ow) => ow.id === id);
    if (!existing) {
      throw new Error(
        `Unable to find existing overview ${id} in flattened overview list`
      );
    }

    const { parentId } = existing;
    if (parentId) {
      const parent = flatOverviews.find((ow) => ow.id === parentId);
      if (!parent) {
        throw new Error(
          `Unable to find parent item ${parentId} in flat overviews`
        );
      }
      fullSlug = `${parent.slug}-${slug}`;
    }
  }

  const fullNumberedPrefix = `${fullSlug}-`;
  const dedupNumber = flatOverviews.reduce((dedupNumber, overview) => {
    if (id && id === overview.id) {
      return dedupNumber;
    }

    let newMax = 0;
    if (overview.slug === slug) {
      newMax = 1;
    } else if (overview.slug.startsWith(fullNumberedPrefix)) {
      const numPart = overview.slug.substr(fullNumberedPrefix.length);
      if (strIsInt.test(numPart)) {
        newMax = parseInt(numPart, 10) + 1;
      }
    }

    return Math.max(dedupNumber, newMax);
  }, 0);

  return dedupNumber ? `${fullSlug}-${dedupNumber}` : fullSlug;
}

export function setOverview(
  overviews: OverviewList,
  id: string,
  newOverview: IOverviewEditorItemId
): OverviewList {
  const path = findOverviewPath(overviews, id);
  if (!path) {
    throw new Error(`Unable to find overview to modify: ${id}`);
  }

  return modifyOverviewsAtPath(
    overviews,
    path.slice(0, -1), // Parent path if nested
    (overviews) => {
      return overviews.map((overview) => {
        return overview.id === id ? newOverview : overview;
      });
    }
  );
}

export function uniqueId(): string {
  return Math.random().toString(36).substring(2);
}

export function addId(overview: IOverviewItem): IOverviewEditorItemId {
  const subOverviews = overview.overviews
    ? overview.overviews.map(addId)
    : overview.overviews;

  return {
    ...overview,
    overviews: subOverviews,
    id: uniqueId(),
    global: true,
    visible: true,
  };
}

export function removeId(overview: IOverviewEditorItemId): IOverviewEditorItem {
  return omit(overview, ['id']);
}
