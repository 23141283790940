import { MouseEvent, ReactElement, ReactNode, useCallback } from 'react';
import {
  IconButton,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaDownload, FaEllipsisV, FaPrint } from 'react-icons/fa';

import { Portal } from '@bq/components/Portal';
import { printUrl } from 'BootQuery/Assets/js/print-url';

import { Message } from '../../js/types';

interface MessageMenuProps {
  message: Message;
  itemsAfter?: ReactNode;
}

export const MessageMenu = ({
  message,
  itemsAfter,
}: MessageMenuProps): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const printTargetUrl = `/api/mails/message/${message.ID}/print`;
  const print = useCallback(
    (ev: MouseEvent) => {
      ev.preventDefault();

      printUrl(printTargetUrl);
    },
    [printTargetUrl]
  );

  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        size="sm"
        variant="ghost"
        aria-label="Call Actions"
        icon={<FaEllipsisV />}
      />
      <Portal>
        <MenuList>
          <MenuItem
            as={Link}
            href={printTargetUrl}
            target="_blank"
            onClick={print}
          >
            <FaPrint />
            &nbsp;
            {t('global:print')}
          </MenuItem>
          <MenuItem
            as={Link}
            href={`/api/mails/messages/${message.ID}/download`}
            download
          >
            <FaDownload />
            &nbsp;
            {t('global:download')}
          </MenuItem>
          {itemsAfter}
        </MenuList>
      </Portal>
    </Menu>
  );
};
