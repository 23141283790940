import {
  TicketGroup,
  TicketType,
} from 'app/Modules/Ticketing/Assets/components/types';
import { Api } from 'BootQuery/Assets/js/api';

export async function getTicketingGroupsIfEnabled(): Promise<TicketGroup[]> {
  if (!hasTicketing()) {
    return [];
  }

  const { data } = await Api.get('/api/ticketing/groups');

  return data;
}

export async function getTicketingTypesIfEnabled(): Promise<TicketType[]> {
  if (!hasTicketing()) {
    return [];
  }

  const { data } = await Api.get('/api/ticketing/types');

  return data;
}

function hasTicketing(): boolean {
  return 'Ticketing' in window.Bootstrap.modules;
}
