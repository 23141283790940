import 'BootQuery/Assets/js/i18n';

import { lazyImport } from '@bq/components/lazy-import';
import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

const Ticketing = lazyImport(() => import('../pages/Ticketing'), 'Ticketing');

export const ReactRoutes: ReactRoute[] = [
  { path: '/ticketing/*', component: Ticketing },
];
