import { ReactElement } from 'react';
import {
  BoxProps,
  Flex,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react';

const Card = forwardRef(
  (props: BoxProps, ref): ReactElement => (
    <Flex
      flexDir="column"
      ref={ref}
      color={useColorModeValue('brand.darkGray', 'brand.lightGray')}
      bg={useColorModeValue('brand.background', 'brand.backgroundDark')}
      rounded="base"
      shadow="lg"
      borderBottom="1px solid"
      borderTop="1px solid"
      borderColor="brand.500"
      {...props}
    />
  )
);

Card.displayName = 'Card';
export { Card };
