import { MouseEvent, ReactElement, useCallback, useContext } from 'react';
import { Box, Flex, Text, useColorModeValue } from '@chakra-ui/react';
import { isEqual } from 'lodash-es';
import { useDrag, useDrop } from 'react-dnd';
import { FaEyeSlash, FaGripVertical } from 'react-icons/fa';

import { DeleteButton } from '../../DeleteButton';
import { IOverviewEditorItemId } from '../types';
import { DropArea } from './DropArea';
import { OverviewEditorContext } from './OverviewEditorContext';
import {
  addOverview,
  arraySwap,
  findOverviewIdx,
  findOverviewPath,
  modifyOverviewsAtPath,
  removeOverview,
} from './util';

export interface OverviewEditorItemProps extends IOverviewEditorItemId {
  path: string[];
}

export const OverviewEditorItem = (
  props: OverviewEditorItemProps
): ReactElement => {
  const bg = useColorModeValue('brand.background', 'brand.backgroundDark');
  const colorValue = useColorModeValue('brand.darkGray', 'brand.lightGray');
  const { title, type, id, path, visible } = props;
  const { setTempOverviews, tempOverviews, selectedId, setSelectedId } =
    useContext(OverviewEditorContext);
  const [{ isDragging }, draggableRef, previewRef] = useDrag(
    () => ({
      type: 'overviewEditor',
      item: () => props,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }),
    [props]
  );

  const [, outerDropRef] = useDrop(
    () => ({
      accept: 'overviewEditor',
      canDrop: () => false,
      hover(item: OverviewEditorItemProps) {
        // console.log('Hoverered: ', item);
        if (item.id !== id) {
          const ourPath = findOverviewPath(tempOverviews, id);
          const otherPath = findOverviewPath(tempOverviews, item.id);
          if (ourPath === null || otherPath === null) {
            return;
          }

          const ourPathPrefix = ourPath.slice(0, -1);
          const otherPathPrefix = otherPath.slice(0, -1);
          // Same parent item
          if (isEqual(ourPathPrefix, otherPathPrefix)) {
            const swapped = modifyOverviewsAtPath(
              tempOverviews,
              ourPathPrefix,
              (overviews) => {
                return arraySwap(
                  overviews,
                  findOverviewIdx(overviews, item.id),
                  findOverviewIdx(overviews, id)
                );
              }
            );
            setTempOverviews(swapped);
          } else {
            let swapped = removeOverview(tempOverviews, item.path);
            swapped = addOverview(swapped, ourPathPrefix, item);
            setTempOverviews(swapped);
          }
        }
      },
    }),
    [tempOverviews, id]
  );

  const select = useCallback(() => {
    setSelectedId(id);
  }, [setSelectedId, id]);
  const deleteYourself = useCallback(
    (ev: MouseEvent<HTMLButtonElement>) => {
      ev.stopPropagation();

      const path = findOverviewPath(tempOverviews, id);
      if (!path) {
        return;
      }
      setTempOverviews(removeOverview(tempOverviews, path));
    },
    [tempOverviews, setTempOverviews, id]
  );

  return (
    <Box ref={previewRef} opacity={isDragging ? 0.7 : 1.0}>
      <Flex
        ref={outerDropRef}
        alignItems="center"
        onClick={select}
        px="2"
        py="1"
        bg={bg}
        color={selectedId === id ? 'blue.500' : colorValue}
        fontWeight={selectedId === id ? 'bold' : 'normal'}
        borderBottom="1px solid"
        borderBottomColor="gray.200"
      >
        <Flex alignItems="center" flex="1 0 auto">
          <Box ref={draggableRef} cursor="move">
            <FaGripVertical />
          </Box>
          <Text as={visible ? 'span' : 'del'} noOfLines={1}>
            {title}
          </Text>
          {visible || (
            <>
              &nbsp;
              <FaEyeSlash />
            </>
          )}
        </Flex>
        <DeleteButton
          onClick={deleteYourself}
          flex="0 1 auto"
          variant="outline"
          size="sm"
        />
      </Flex>
      {type === 'group' && !isDragging && (
        <DropArea
          path={path}
          direction="column"
          ml="1"
          pl="2"
          pb="3"
          borderLeft="1px solid"
          borderLeftColor="gray.300"
        />
      )}
    </Box>
  );
};
