import { ReactElement } from 'react';
import { Box, Text, Tooltip, useColorModeValue } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
  FaArrowsAltH,
  FaCheck,
  FaExclamationTriangle,
  FaPhoneSquare,
  FaRegHourglass,
  FaTimes,
} from 'react-icons/fa';
import { ImPhoneHangUp } from 'react-icons/im';

import { CallDirection, CallListCall } from '../../CallList/types';

export type CallStatusProps = Pick<
  CallListCall,
  'direction' | 'endAt' | 'answered'
>;

export const CallStatus = (call: CallStatusProps): ReactElement => {
  const { label, color, Icon } = useDisplayProps(call);

  return (
    <Box textOverflow="clip">
      <Tooltip hasArrow label={label} placement="right">
        <Text as="span" fontSize="lg" color={color}>
          <Icon />
        </Text>
      </Tooltip>
    </Box>
  );
};

const directionLabels: Record<CallDirection, string> = {
  outgoing: 'Outgoing',
  incoming: 'Incoming',
  internal: 'Internal',
};

interface CallStatusDisplay {
  Icon: IconType;
  color: string;
  label: string;
}

function labelForCall(
  dir: CallDirection | null,
  answered: boolean
): string | null {
  const dirLabel = dir ? directionLabels[dir] : null;
  const answeredLabel = answered ? 'Answered' : 'Not answered';

  return [dirLabel, answeredLabel].filter(Boolean).join(', ');
}

function useDisplayProps(call: CallStatusProps): CallStatusDisplay {
  const [blue, green, red, yellow] = useColorModeValue(
    ['blue.600', 'green.600', 'red.600', 'yellow.600'],
    ['blue.400', 'green.400', 'red.400', 'yellow.400']
  );

  if (!call.endAt) {
    return {
      label: 'In progress',
      color: blue,
      Icon: FaRegHourglass,
    };
  }

  const label = labelForCall(call.direction, call.answered) ?? 'Unknown';
  let color = call.answered ? green : red;
  let Icon = FaTimes;

  if (call.direction === 'internal') {
    Icon = FaArrowsAltH;
  } else if (call.direction === 'outgoing') {
    Icon = call.answered ? FaPhoneSquare : ImPhoneHangUp;
  } else if (call.direction === 'incoming') {
    if (call.answered) {
      Icon = FaCheck;
    } else {
      color = yellow;
      Icon = FaExclamationTriangle;
    }
  }

  if (!call.answered && call.direction === 'incoming') {
    color = yellow;
    Icon = FaExclamationTriangle;
  }

  return { label, color, Icon };
}
