import { ReactElement } from 'react';

export const FreeTextIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    fill="currentColor"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="M964.29 857.14H35.71A35.71 35.71 0 0 0 0 892.86v71.43A35.71 35.71 0 0 0 35.71 1000h928.58a35.71 35.71 0 0 0 35.71-35.71v-71.43a35.71 35.71 0 0 0-35.71-35.72Zm-214-285.71H35.71A35.71 35.71 0 0 0 0 607.14v71.43a35.71 35.71 0 0 0 35.71 35.72h714.58A35.71 35.71 0 0 0 786 678.57v-71.43a35.71 35.71 0 0 0-35.71-35.71Zm152-285.72H35.71A35.71 35.71 0 0 0 0 321.43v71.43a35.71 35.71 0 0 0 35.71 35.71h866.58A35.71 35.71 0 0 0 938 392.86v-71.43a35.71 35.71 0 0 0-35.71-35.72ZM594.29 0H35.71A35.71 35.71 0 0 0 0 35.71v71.43a35.71 35.71 0 0 0 35.71 35.72h558.58A35.71 35.71 0 0 0 630 107.14V35.71A35.71 35.71 0 0 0 594.29 0Z" />
  </svg>
);
