import { ReactElement } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';

interface Props {
  value: boolean;
}

export const StaticCheckboxCell = ({ value }: Props): ReactElement => {
  return (
    <Flex alignItems="center">
      <Text as="span" fontWeight="bold">
        {value ? (
          <FaRegCheckSquare style={svgStyle} />
        ) : (
          <FaRegSquare style={svgStyle} />
        )}
      </Text>
    </Flex>
  );
};

const svgStyle = { display: 'inline' };
