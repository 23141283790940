import { useCallback } from 'react';
import { QueryKey, useQueryClient } from '@tanstack/react-query';

import { Event } from '../types';
import { EventMatcher, EventsQuery, UseEventQueryUtilsResult } from './types';

export function useEventsQueryUtils(
  queryKey: QueryKey
): UseEventQueryUtilsResult {
  const queryClient = useQueryClient();

  const addEvent = useCallback(
    (event: Event) => {
      queryClient.setQueriesData({ queryKey }, (prev?: EventsQuery) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          pages: [{ events: [event], hasMore: true }, ...prev.pages],
        };
      });
    },
    [queryClient, queryKey]
  );

  const removeEvents = useCallback(
    (matcher: EventMatcher) => {
      queryClient.setQueriesData({ queryKey }, (prev?: EventsQuery) => {
        if (!prev) {
          return prev;
        }

        return {
          ...prev,
          pages: prev.pages.map((page) => ({
            ...page,
            events: page.events.filter((ev) => !matcher(ev)),
          })),
        };
      });
    },
    [queryClient, queryKey]
  );

  const removeEvent = useCallback(
    (type: string, id: string) => {
      removeEvents((ev) => ev.id === id && ev.type === type);
    },
    [removeEvents]
  );

  return { addEvent, removeEvent, removeEvents };
}
