import { useEffect, useRef } from 'react';

import SocketEventListener, {
  eventHandlerCb,
  RawSubscription,
} from './socket-event-listener';

export function useSocketEvents<EVT = unknown>(
  listener: SocketEventListener,
  events: RawSubscription,
  cb: eventHandlerCb<EVT>
): void {
  const subscriptionHandle = useRef<string | null>(null);

  useEffect(() => {
    subscriptionHandle.current = listener.subscribeWebSocket(events, cb);

    return () => {
      if (subscriptionHandle.current) {
        listener.unsubscribeWebSocket(subscriptionHandle.current);
        subscriptionHandle.current = null;
      }
    };
  }, [listener, events, cb]);
}
