import { Api } from 'BootQuery/Assets/js/api';

import { MailInfo } from './mails';

export const getEmailOneForGroups = async <T = MailInfo,>(
  ID: number,
  fields: string[] = ['ID', 'email']
) => {
  const data = await Api.get<T>(`/api/mails/searchAccounts/${ID}`, {
    params: { fields },
  });

  return data;
};
