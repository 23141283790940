import { useMemo } from 'react';
import { NodeViewWrapper } from '@tiptap/react';

import {
  ItemPlaceholderOptions,
  ItemPlaceholderStorage,
  TemplateNodeViewRendererProps,
} from './types';

export const ItemPlaceholdersComponent = (
  props: TemplateNodeViewRendererProps
) => {
  const { editor, node } = props;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const storage = editor.storage as Record<string, any> & {
    itemPlaceholder: ItemPlaceholderStorage;
  };
  const options = props.extension.options as ItemPlaceholderOptions;

  const foundElem = useMemo(() => {
    return options.templateElements.find(
      (item) => item.key === node.attrs['data-key']
    );
  }, [node.attrs, options.templateElements]);

  const title = useMemo(() => {
    if (foundElem?.title) {
      if (typeof foundElem.title === 'function') {
        return foundElem.title();
      }

      return foundElem.title;
    }

    return node.attrs['data-key'];
  }, [foundElem, node.attrs]);

  if (storage.itemPlaceholder.showValues) {
    return <ItemRealValueComponent {...props} />;
  }

  return (
    <NodeViewWrapper className="item-placeholder">
      <div contentEditable={false}>{title}</div>
    </NodeViewWrapper>
  );
};

export const ItemRealValueComponent = (
  props: TemplateNodeViewRendererProps
) => {
  const { node } = props;

  const { attrs } = node;

  const options = props.extension.options as ItemPlaceholderOptions;

  const foundElem = useMemo(() => {
    return options.templateElements.find(
      (item) => item.key === attrs['data-key']
    );
  }, [attrs, options.templateElements]);

  const foundValue = useMemo(() => {
    return options.values[attrs['data-key']] ?? '';
  }, [attrs, options.values]);

  const foundConfig = useMemo(() => {
    const { 'data-config': dc } = attrs;
    let fcf;
    if (dc) {
      try {
        fcf = JSON.parse(attrs['data-config']);
      } catch (e) {
        console.error('Item Placeholder config was not a JSON', dc);
      }
    }

    return fcf;
  }, [attrs]);

  const foundComponent = useMemo(() => {
    if (foundElem) {
      if (foundElem.Cell) {
        return foundElem.Cell({
          ...props,
          value: options.values,
          config: foundConfig,
        });
      }
      if (foundElem.getValue) {
        return foundElem.getValue(options.values, foundConfig);
      }

      return options.values[attrs['data-key']] ?? '';
    }

    return foundValue;
  }, [attrs, foundConfig, foundElem, foundValue, options.values, props]);

  return (
    <NodeViewWrapper className="item-placeholder-value">
      <div contentEditable={false}>{foundComponent}</div>
    </NodeViewWrapper>
  );
};
