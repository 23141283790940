import {
  lowerFirst,
  setWith,
  has,
  upperFirst,
  forEach,
  some,
  assign,
  reduce,
  isArray,
  flow,
  pickBy,
  mapKeys,
  isFunction,
  isEqual,
} from 'lodash-es';
// eslint-disable-next-line import/no-cycle
import refreshFilters from 'app/assets/js/filters';
import {
  popoverForTrigger,
  getURLComponents,
  splitNestedInputName,
  flattenFormData,
  toUnderscoreCase,
  wsUrlBase,
  handlebarsRender,
  printPHPDebugs,
} from 'app/assets/js/util';
// eslint-disable-next-line import/no-cycle
import createModal from 'app/assets/js/modals';
import moment from 'moment';
import urlParse from 'url-parse';
import qs from 'qs';
import { tabs } from 'app/Modules/Telephony/Assets/store/tabs';
import { WidgetsRoot } from 'BootQuery/Assets/components/ReactWidgets';
import { waitTranslationsReady } from './i18n';
import { purgeReact, renderReact } from './render-react';
import Socket from './socket';
import { newGetTemplate } from './templates/handlebars';
import * as Api from './apiRequest';
// eslint-disable-next-line import/no-cycle
import { initDatatables, deinitDatatables } from './datatable';
import tr from './translate';
import { globalEventBus } from './global-event-bus';

import 'BootQuery/Assets/css/fontawesome.scss';

import { syncBrandColorCssVars } from './brand-colors';
import { activateReact } from './activate-react';
import { bindUserActivityHandler } from './user-activity';
import { getIdentityToken } from './identity-token';

window.window.targetElement = '.bq-content';
window.isModal = false;

function linkWithDefault(link) {
  if (link === '/' && window.Bootstrap.bootquery.defaultUrl) {
    return `${window.Bootstrap.bootquery.defaultUrl}/`;
  }

  return link;
}

/* ---------------------------- */
/*      Utility functions		*/
/* ----------------------------	*/

// Parse links and append a hashbang before the rest of the URL
export function parseLinks() {
  const noParseHrefRegex = /^(?:#|^\w+:)/; // Match #anchor and proto: links
  $(document).ev('click', 'a[href]:not(.noparse, [data-toggle], [download])', (e) => {
    // Don't do anything if another listener already handled the click
    const originalEv = e.originalEvent || e; // Unwrap jQuery-wrapped event
    if (originalEv.defaultPrevented) {
      return;
    }

    const elem = $(e.currentTarget);
    const link = linkWithDefault(elem.attr('href'));

    // Don't do anything if target specified (let the browser open in new window)
    if (elem.attr('target') && elem.attr('target') !== '_self') {
      return;
    }

    // Modals
    if (elem.is('a[data-modal-link]')) {
      e.preventDefault();

      const properties = elem.data();
      properties.modalSummoner = elem;
      createModal(properties);
      return;
    }

    // Let the browser handle #anchor and proto: links
    if (link.match(noParseHrefRegex)) {
      return;
    }

    // Allow for regular page load on logout, to clear everything
    if (link.startsWith('/user/logout')) {
      return;
    }

    e.preventDefault();

    const isMac = navigator.platform.toUpperCase().indexOf('MAC') !== -1;
    if (e.which === 2 || e.ctrlKey || (e.metaKey && isMac)) {
      window.open(link, '_blank');
      return;
    }

    if (elem.data('target')) {
      window.targetElement = elem.data('target');
    } else if (elem.closest('.result-pagination').length > 0) {
      window.targetElement = '.paginated';
    } else {
      window.targetElement = '.bq-content';
    }

    if (elem.closest('.modal').length === 0 || elem.data('target')) {
      const historyObj = getURLComponents(link);
      delete historyObj.hash;

      if (!isEqual(historyObj, window.history.state) && !elem.hasClass('nofollow')) {
        window.history.pushState(historyObj, null, link);
      } else {
        window.targetElement = '.embedable';
      }

      if (elem.closest('.modal-body').length > 0) {
        elem.closest('.modal').modal('hide');
      }
    } else {
      window.targetElement = '.embedable';
    }

    bootstrap(e, 'load');
  });
}

export function setTargetLoadingState(targetElement, isLoading) {
  const target = $(targetElement);
  if (isLoading) {
    target.addClass('clickvox-loading-target');
    target.each((_i, el) => {
      if ($(el).find('.clickvox-loading-spinner').length) {
        return;
      }
      const loadingOverlay = $(
        '<div class="clickvox-loading-spinner spinner-grow text-secondary"></div>'
      );
      $(el).append(loadingOverlay);
    });
  } else {
    target.find('.clickvox-loading-spinner').remove();
    target.removeClass('clickvox-loading-target');
  }
}

/**
 * Calls getAutocomplete on server.
 * @param  {String}  table            Name of the table to query
 * @param  {Array}   filters          Filters to pass to the query
 * @param  {Object}  params           Parameter to pass to the query
 * @param  {Function(result, status)} callback function to call when done. Status is boolean.
 * @return {Promise}
 */
export function getAutocomplete(controller, table, filters, params) {
  if (!controller) {
    ({ controller } = window.Bootstrap.bootquery);
  }
  return Api.post('/api/getSelectOptions', {
    controller,
    filters,
    params,
    tableName: table,
  });
}

export function loadModules(data) {
  if (has(data, 'modules')) {
    forEach(data.modules, (module, name) => {
      const { controller, method } = data.bootquery;
      const route = `${controller}/${method}`.toLowerCase();
      if (route === 'user/login' && !module.activate_on_login_screen) {
        return;
      }
      const instance = window.BootQuery.getModuleInstance(name);
      if (instance && !instance.$initialised) {
        instance.init(data);
        instance.$initialised = true;
      }
    });
  }
}

export function activatePopovers(target) {
  target
    .findElement('*[data-toggle="popover"]')
    .ev('click', (e) => {
      e.preventDefault();
    })
    .popover({
      content() {
        if ($(this).data('popover-content-element')) {
          const $contentElement = $(this)
            .parent()
            .children('.popover-content-element');
          $(this).one('hidden.bs.popover', (e) => {
            popoverForTrigger(e.currentTarget)
              .find('.popover-body')
              .children()
              .appendTo($contentElement);
          });
          return $contentElement.children();
        }
        return $(this).data('popover-content');
      },
    })
    .ev('inserted.bs.popover', (e) => {
      popoverForTrigger(e.currentTarget).addClass('popover-opening');
    })
    .ev('shown.bs.popover', (e) => {
      const $popover = popoverForTrigger(e.currentTarget);
      $popover.removeClass('popover-opening');
      $popover.find('[data-dismiss=popover]').ev('click.popoverDismiss', (e) => {
        $(e.currentTarget)
          .closest('.popover')
          .popover('hide');
      });
    });

  $(document)
    .add('body')
    .ev('click.popoverDismiss', (e) => {
      const $tgt = $(e.target);
      let $popovers = $('.popover');
      if ($tgt.is('[data-trigger=focus]')) {
        $popovers = $popovers.not(popoverForTrigger($tgt));
      }
      if ($popovers.length) {
        if ($tgt.is('a, button') || $tgt.closest('a, button').length) {
          $popovers.not('.popover-opening').popover('hide');
        } else {
          const $clickedPopover = $tgt.closest($popovers);
          $popovers
            .not($clickedPopover)
            .not('.popover-opening')
            .popover('hide');
        }
      }
    });
}

export function activateElements(target, data) {
  if (typeof target === 'undefined' || target == null) {
    target = 'body';
  }
  target = $(target);

  data = data && data.bootquery ? data : window.Bootstrap;

  refreshFilters(target, data);

  target
    .findElement('.print-btn')
    .addClass('noparse')
    .off('click')
    .on('click', (e) => {
      e.stopPropagation();
      e.preventDefault();

      const iframe = $('<iframe>', {
        src: $('.print-btn').attr('href'),
      }).appendTo('body');
      iframe.hide();
      iframe.get(0).contentWindow.print();
    });

  target
    .findElement('.delete-action')
    .off('click')
    .on('click', (e) => {
      e.stopPropagation();
      e.preventDefault();
      const originalEvent = e;
      const deleteTriggerer = $(e.currentTarget);
      const modal = $('.delete-action-modal');
      $(modal).modal('show');
      $(modal)
        .find('.delete-action-cancel')
        .off('click')
        .on('click', (e) => {
          e.preventDefault();
          $(modal).modal('hide');
        });

      $(modal)
        .find('.delete-action-confirm')
        .off('click')
        .on('click', (e) => {
          e.preventDefault();
          e.stopPropagation();

          if (deleteTriggerer.is('button, input')) {
            let form = null;
            if (deleteTriggerer.attr('form')) {
              form = $(`form[id="${deleteTriggerer.attr('form')}"]`);
            }
            if (!form) {
              form = deleteTriggerer.closest('form');
            }
            $(deleteTriggerer).trigger('submit');
            if ($(deleteTriggerer).attr('name')) {
              $(form).append(
                `<input type="hidden" name="${$(deleteTriggerer).attr('name')}" />`,
              );
            }
            submitForm(form, data);
            $(modal).modal('hide');
          } else if ($(deleteTriggerer).is('a[href]')) {
            navigate(originalEvent);
          }

          return false;
        });
    });

  // Popovers, they suck by default
  activatePopovers(target);

  target.findElement('*[data-toggle="tooltip"]').tooltip();

  target.findElement('.pickle').each((_i, el) => {
    const $el = $(el);
    if ($el.data('pickleSource')) {
      const params = flow(
        (val) => pickBy(val, (_value, key) => key.indexOf('pickle') === 0),
        (val) => mapKeys(val, (_value, key) => {
          const withoutPrefix = key.replace(/^pickle/, '');
          return lowerFirst(withoutPrefix);
        }),
      )($el.data());

      $el.pickle({
        results(searchString, results, callback) {
          const $el = $(this);
          if ($el.data('isQuerying')) {
            return;
          }
          $el.data('currentSearchString', searchString);
          if ($el.data('lastSearchString') === searchString) {
            $el.data('isQuerying', false);
            callback(results, searchString.length);
            return;
          }

          const fetchParams = assign(params, {
            filters: [],
            value: $el.val(),
          });
          if (searchString.length) {
            fetchParams.filters.push({
              key: `${fetchParams.textColumn}_like_fulltext`,
              value: searchString,
            });
            if (fetchParams.sortby && !fetchParams.sort) {
              fetchParams.sortby = {
                $similarity_fulltext: {
                  column: fetchParams.textColumn,
                  value: searchString,
                },
              };
            }
          }
          $el.data('isQuerying', true);
          Api.get('/api/getSelectOptions', {
            controller: data.bootquery.controller,
            ...fetchParams,
          }).then((results) => {
            const options = results.map((option) => ({
              id: option[data.idColumn],
              text: option[data.textColumn],
              rowData: option,
            }));
            $el.data('isQuerying', false);
            $el.data('lastSearchString', searchString);
            callback(options, searchString.length);
            if (
              $el.data('currentSearchString', searchString)
							!== $el.data('lastSearchString')
            ) {
              $el.pickle('research');
            }
          });
        },
      });
    } else {
      $el.pickle();
    }
  });

  target
    .findElement('input[type="time"]')
    .attr('type', 'text')
    .addClass('timepicker');
  target.findElement('.datetimepicker').activateDateTimePicker();
  target.findElement('.timepicker').activateDateTimePicker();
  target.findElement('.datepicker').activateDateTimePicker();
  target.findElement('.durationpicker').activateDateTimePicker();

  const dateElements = target.findElement(
    '.timepicker, .datetimepicker, .durationpicker, .datepicker',
  );
  dateElements.off('dp.show').on('dp.show', (e) => {
    const element = $(e.currentTarget);
    const minElementName = element.find('input').data('date-min-value-of');
    const maxElementName = element.find('input').data('date-max-value-of');

    if (minElementName && minElementName.length) {
      const minInput = $(`input[name="${minElementName}"]`);
      const min = minInput.val();

      if (min && min.length) {
        const minFormat = minInput
          .closest('.input-group')
          .data('DateTimePicker')
          .format();
        const minDate = moment(min, minFormat);
        element.data('DateTimePicker').minDate(minDate);
      } else {
        element.data('DateTimePicker').minDate(false);
      }
    }

    if (maxElementName && maxElementName.length) {
      const maxInput = $(`input[name="${maxElementName}"]`);
      const max = maxInput.val();

      if (max && max.length) {
        const maxFormat = maxInput
          .closest('.input-group')
          .data('DateTimePicker')
          .format();
        const maxDate = moment(max, maxFormat);
        element.data('DateTimePicker').maxDate(maxDate);
      } else {
        element.data('DateTimePicker').maxDate(false);
      }
    }
  });

  target.findElement('textarea').on('keyup', (e) => {
    const el = e.currentTarget;
    el.style.overflow = 'hidden';
    el.style.height = 0;
    el.style.height = `${el.scrollHeight}px`;
  });

  // Data table selections
  $(document).ev('click', '.datatable > tbody > tr', (e) => {
    const el = $(e.currentTarget);
    if (el.hasClass('checkbox-row-select')) {
      return;
    }
    e.stopPropagation();
    const checkbox = el.find('input[name*="rowselect"]');
    let btns = $([]);
    const tableID = el.closest('table').attr('id');
    if (tableID.indexOf('-') !== -1) {
      const tableName = tableID.split('-')[0];
      btns = el.closest('table').find(`[data-table-action][data-table="${tableName}"]`);
    }

    if (e.shiftKey) {
      let firstPos = $('tr').index(el[0]);
      let secondPos = firstPos;
      const lastClicked = $('tr.table-active.lastClicked');

      if (lastClicked.length > 0) {
        if ($('tr').index(lastClicked) > $('tr').index(el[0])) {
          secondPos = $('tr').index(lastClicked);
        } else {
          firstPos = $('tr').index(lastClicked);
        }
      }

      if (e.ctrlKey === false) {
        $('tr.table-active').removeClass('table-active');
      }

      $('tr')
        .slice(firstPos, secondPos + 1)
        .addClass('table-active');
    } else if (e.ctrlKey) {
      el.toggleClass('table-active');
    } else {
      $('tr.table-active').removeClass('table-active');
      el.addClass('table-active');
    }

    if (el.hasClass('table-active')) {
      $('tr.lastClicked').removeClass('lastClicked');
      el.addClass('lastClicked');
    }

    if (checkbox.length > 0) {
      $('.datatable > tbody > tr input[name*=rowselect]').val('false');
      $('.datatable > tbody > tr.table-active input[name*=rowselect]').val('true');
    }
    btns.prop('disabled', !checkbox.length);

    $(document).trigger('datatableSelection', [$('.datatable > tbody > tr.table-active')]);
  });

  $(document).ev('change', '.datatable input[type=checkbox].checkbox-row-select-all', (e) => {
    const table = $(e.currentTarget).closest('table');
    const checked = $(e.currentTarget).prop('checked');
    const checkboxen = table.find(
      'tbody > tr.checkbox-row-select > td.row-select-col input[type=checkbox]',
    );
    checkboxen.prop('checked', checked).trigger('change');
  });

  $(document).ev('click', 'tr.checkbox-row-select > td.row-select-col', (e) => {
    if (e.target === e.currentTarget) {
      $(e.currentTarget)
        .find('input[type=checkbox]')
        .click();
    }
  });

  $(document).ev(
    'change',
    'tr.checkbox-row-select > td.row-select-col input[type=checkbox]',
    (e) => {
      const checkbox = $(e.currentTarget);
      const table = checkbox.closest('table');
      const checkboxen = table.find(
        'tr.checkbox-row-select > td.row-select-col input[type=checkbox]',
      );
      const checkedCheckboxen = checkboxen.filter(':checked');
      const btns = table.find('[data-table-action]');
      btns.prop('disabled', checkedCheckboxen.length === 0);

      if (checkboxen.length > 0) {
        const checkAll = table.find('input[type=checkbox].checkbox-row-select-all');
        checkAll.prop('checked', checkedCheckboxen.length === checkboxen.length);
      }
    },
  );

  // After closing modal check if there are other open modals
  // and add modal-open class to body in order to fix scrolling inside them
  $(document).ev('hidden.bs.modal.bq', () => {
    if ($('body > .modal.show').length > 0) {
      $('body').addClass('modal-open');
    }
  });

  registerFormHandler(target, data);
  setTimeout(() => initDatatables(target, data), 0);

  forEach(data.forms, (form, formName) => {
    const selector = `form[data-form="${formName}"]`;
    let $formEl = target.findElement(selector);
    if (!$formEl.length) {
      $formEl = target.findElement(`[data-form="${formName}"]`);
    }
    if ($formEl.length) {
      $formEl.form({ formDefinition: form });
    } else {
      console.warn(
        `Tried to activate form ${formName}, didn't find element, selector was: ${selector}`,
      );
    }
  });

  activateReact(target);
  $(document).trigger('activateElements', [target, data]);
}

// Look for target data in the clicked link and set refresh target to the referenced element
export function setTarget(e) {
  if (e) {
    let targetData = $(e.target).data('target');

    if (!$(targetData).length) {
      window.isModal = targetData === 'modal';
      window.target = $(this).closest('.bq-content');

      if (!window.target) {
        targetData = '.bq-default';
      } else {
        targetData = '.bq-content';
      }
    } else {
      $('.bq-target').removeClass('bq-target');
      $(targetData).addClass('bq-target');
      targetData = '.bq-target';
      window.isModal = false;
    }
    window.targetElement = targetData;
  }
}

export function setFormSaveStatus(formElement, status, customText, customColorClass) {
  const id = $(formElement).attr('id') || '';
  const saveStatusElement = $(formElement)
    .find('.save-status')
    .add($(`.save-status[data-form="${id}"]`));
  saveStatusElement.prop('hidden', false);
  let statusText;
  switch (status) {
    case 'saving':
      statusText = tr('label.saving_in_progress');
      if ($(formElement).data('status-text-saving')) {
        statusText = $(formElement).data('status-text-saving');
      }
      saveStatusElement.html(statusText);
      break;

    case 'validating':
      saveStatusElement.html(tr('label.validation_in_progress'));
      break;

    case 'saved':
      statusText = tr('label.saved');
      if ($(formElement).data('status-text-saved')) {
        statusText = $(formElement).data('status-text-saved');
      }
      saveStatusElement.html(`<span class="text-success">${statusText}</span>`);
      break;

    case 'validation-error':
      saveStatusElement.html(
        `<span class="text-danger">${tr('label.validation_error')}</span>`,
      );
      break;

    case 'custom':
      saveStatusElement.html(`<span class="${customColorClass}">${customText}</span>`);
      break;

    default:
      saveStatusElement.html('');
      saveStatusElement.prop('hidden', true);
      break;
  }

  const doingSomething = status === 'saving' || status === 'validating';
  const btn = saveStatusElement.siblings('button[type=submit]');
  btn.prop('disabled', doingSomething);
  if (doingSomething) {
    btn.findElement('.fa.fa-check')
      .removeClass('fa fa-check')
      .addClass('spinner-border spinner-border-sm');
  } else {
    btn.findElement('.spinner-border')
      .removeClass('spinner-border spinner-border-sm')
      .addClass('fa fa-check');
  }
}

export function getFormData(formElement, options = {}) {
  const inputElements = $(formElement)
    .find('input, textarea, select')
    .not(':disabled');
  const formData = {};
  inputElements.each((_index, input) => {
    const $input = $(input);
    const name = $input.attr('name');
    const type = $input.attr('type');
    let value = $input.val();

    if (!name) {
      return;
    }
    if ((type === 'checkbox' || type === 'radio') && !$input.is(':checked')) {
      return;
    }
    if ($input.is('select')) {
      if (value === 'null') {
        value = null;
      } else if (options.getSelectText) {
        value = $input.children(`option[value="${value}"]`).text().trim();
      }
    }
    if (type === 'file') {
      value = $input.data('tmpName');
    }

    const namePath = splitNestedInputName(name);
    setWith(formData, namePath, value, Object);
  });
  return formData;
}

export function setFormData(formElement, data) {
  const $formElement = $(formElement);
  const flattened = flattenFormData(data);
  forEach(flattened, (value, elName) => {
    const $input = $formElement.find(`[name="${elName}"]`);
    if ($input.is('select')) {
      $input.pickle('select', value);
    } else if ($input.is('[type=checkbox]')) {
      const $theCheckboxOne = $input.filter('[type=checkbox]');
      $theCheckboxOne.prop('checked', value === 'true');
    } else {
      $input.val(value);
    }
  });
}

export function submitForm(formElement, data) {
  $(formElement).trigger('beforeSubmit');
  const url = urlParse($(formElement).attr('action'), true);
  const action = url.pathname.split('/').filter((part) => part !== '');
  const controller = action.shift();
  const method = action.join('/');
  let type = $(formElement).attr('method');
  let parameters = url.query;

  parameters = $.extend(parameters, getFormData(formElement));

  if ($(formElement).is('.filter-form')) {
    const action = $(formElement).data('submittedByAction');
    if (action) {
      $(formElement).removeData('submittedByAction');
      const tableName = $(formElement).data('table');
      const tableEl = $(`#${tableName}-table`);
      parameters = $.extend(parameters, getFormData(tableEl));
      type = 'POST';
      parameters[`${tableName}-action`] = action;
    }
  }

  // Get response JSON
  getJSON(type, controller, method, parameters, true, (formdata) => {
    printPHPDebugs(formdata);
    let queryString = '';
    const formID = $(formElement).attr('id') || '';
    if (!formdata) {
      return;
    }
    if (formdata.error) {
      console.error(formdata.error);
      if (formID && formID.length) {
        let errText = tr('label.saving_error');
        if (formdata.error && formdata.error.type) {
          errText = tr(`error.${toUnderscoreCase(formdata.error.type)}`);
        }

        const formEl = $(`form#${formID}`);
        setFormSaveStatus(formEl, 'custom', errText, 'text-danger');
      }
      return;
    }

    const module = null;
    let embedded = false;
    let modal = false;

    // For GET requests, create a query string from parameters
    if (type === 'get') {
      queryString = `?${$.param(formdata.bootquery.parameters)}`;
    } else {
      queryString = '';
      if (action.length) {
        queryString = url.search || '';
      }
    }

    if ($(formElement).closest('.modal').length) {
      modal = true;
      embedded = true;
    }

    // If form destination is the same as the current page find any alert elements and show them

    if (formdata.bootquery && data.bootquery) {
      const { controller: newController, method: newMethod } = formdata.bootquery;
      const { controller: prevController, method: prevMethod } = data.bootquery;
      const noTarget = !formdata.bootquery.target;
      if (noTarget && newController === prevController && newMethod === prevMethod) {
        if (formdata.bootquery.success == true) {
          $('.alert-success').show();
        } else {
          $('.alert-danger').show();
        }
      }
    }

    if (formdata.bootquery.submit_info && formdata.bootquery.submit_info.success == true) {
      $(formElement).trigger({
        type: 'succesfull-submit',
        form: formElement,
        formdata: parameters,
        submit_info: formdata.bootquery.submit_info,
      });
    }

    const selectToUpdate = $(formElement)
      .closest('.modal')
      .data('modal-select-update');

    if (selectToUpdate && modal && has(formdata, 'bootquery.submit_info.success')) {
      if (formdata.bootquery.submit_info.success) {
        const formInsertID = formdata.bootquery.submit_info.mainFormID;
        const selectToUpdateElement = $(formElement)
          .closest('.modal')
          .data('modalSummoner')
          .closest('.form-group')
          .find(`select[name*="${selectToUpdate}"]`);
        const controlData = $(selectToUpdateElement).data('controlData');

        if (controlData.table && controlData.option_text && controlData.option_value) {
          getAutocomplete(
            formdata.bootquery.controller,
            controlData.table,
            [{ key: `${controlData.option_value}_eq`, value: formInsertID }],
            { limit: 1 },
          ).then((data, status) => {
            if (status && data.length > 0) {
              const text = data[0][controlData.option_text];
              const id = data[0][controlData.option_value];
              selectToUpdateElement.pickle('option', id, { id, text, row: data[0] });
              selectToUpdateElement.pickle('select', id);
            } else {
              console.error('Unable to retrieve new option');
            }
          });
        }
      }
    }

    // Default target to render in
    window.targetElement = '.bq-content';

    if ($(formElement).is('.filter-form') && $(formElement).closest('.datatable').length) {
      window.targetElement = '.datatable';
    }

    // If we had a data-target attribute in the form,
    // respect the target and reload into the referenced container
    if (formdata && modal) {
      const $modalEl = $(formElement).closest('.modal');
      window.targetElement = $modalEl.data('modal-target') || window.targetElement;
      // console.log('modal target el: ', window.targetElement);
    } else if (formdata && formdata.bootquery.target) {
      window.targetElement = formdata.bootquery.target;
    }

    // Update the history entry
    if (!embedded) {
      if (
        !(
          lowerFirst(formdata.bootquery.controller)
						== lowerFirst(data.bootquery.controller)
					&& lowerFirst(formdata.bootquery.method) == lowerFirst(data.bootquery.method)
					&& type == 'post'
        )
      ) {
        const currentURLHash = window.location.hash;

        console.warn('Handling form!');
        console.warn(`queryString: ${queryString}`);
        const historyObj = {
          controller: lowerFirst(formdata.bootquery.controller),
          method: lowerFirst(formdata.bootquery.method),
          parameters: formdata.bootquery.parameters,
          hash: currentURLHash,
        };

        if (!isEqual(historyObj, window.history.state)) {
          window.history.pushState(
            historyObj,
            null,
            `/${formdata.bootquery.controller}/${
              formdata.bootquery.method
            }/${queryString}${currentURLHash}`,
          );
        }
      }
    } else if (modal) {
      $(formElement)
        .closest('.modal')
        .on('hidden.bs.modal', (e) => {
          $(e.target).remove();
        });

      $(formElement)
        .closest('.modal')
        .modal('hide');
    }

    // Render the result
    formdata.bootquery.isModal = embedded;
    renderControllerFromData(
      formdata,
      module,
      () => {
        if (formID && formID.length) {
          setFormSaveStatus($(`form#${formID}`), 'saved');
        }
      },

      () => {
        if (formID && formID.length) {
          setFormSaveStatus($(`form#${formID}`), 'error');
        }
      },
    );

    $(formElement).trigger('submitted', formdata);

    // Refresh the filters
    refreshFilters(formdata);
  });
}

// Register a handler for form submission
export function registerFormHandler(target, data) {
  // TODO: find real source, attempted hack:
  data.bootquery.controller = lowerFirst(data.bootquery.controller);

  $(target).ev('submit', 'form:not(#login-form, [data-ignore-form], [data-ignore-form-save])', (e) => {
    // All react instances are either rendered inside an element with a
    // data-react-root="id" or are portaled from there to a .chakra-portal
    const isInsideReact = $(e.currentTarget)
      .closest('[data-react-root], .chakra-portal')
      .length > 0;

    if (isInsideReact) {
      console.error('Ignoring form submit inside react');

      return false;
    }

    e.stopPropagation();
    e.preventDefault();

    const form = $(e.currentTarget).closest('form');
    setFormSaveStatus(form, 'saving');
    submitForm(form, data);
    return false;
  });
}

export function setUserSetting(name, value) {
  name = name.replace(/\./g, '/');
  return $.ajax({
    url: `/api/userSettings/${name}`,
    type: 'PUT',
    data: JSON.stringify(value),
  }).fail((err) => console.error(`Failed to set user setting '${name}' to `, value, ', error: ', err));
}

export function getUserSetting(name) {
  name = name.replace(/\./g, '/');
  return $.get(`/api/userSettings/${name}`).fail((err) => console.error(`Failed to get user setting ${name}, error: `, err));
}

export function getMonitorJSON(method, params) {
  return $.ajax({
    type: 'get',
    async: true,
    dataType: 'json',
    timeout: 10000,
    url: `/api/asterisk/${method}`,
    data: params,
  });
}

// Get JSON from a controller
export function getJSON(requestType, controller, method, parameters, _isAsync, callback) {
  let ret = null;
  const uriParts = [controller, method].filter((part) => !!part);
  $.ajax({
    type: requestType,
    async: true,
    dataType: 'json',
    // timeout: 10000,
    url: `/${uriParts.join('/')}.json`,
    data: { paramsJSON: JSON.stringify(parameters) },
    beforeSend(x) {
      if (x && x.overrideMimeType) {
        x.overrideMimeType('application/json;charset=UTF-8');
      }
    },
  })
    .done((data, textStatus, jqXHRs) => {
      if (typeof data === 'string') {
        data = $.parseJSON(data);
      }

      ret = data;
      printPHPDebugs(ret);

      if (isFunction(callback)) {
        callback(ret);
      }
    })
    .fail((jqXHR, textStatus, errorThrown) => {
      console.error(
        `Error while getting JSON for ${controller}/${method}:${textStatus} : ${errorThrown}`,
      );
      console.log('jqXHR: ', jqXHR);
      if (jqXHR.responseJSON) {
        printPHPDebugs(jqXHR.responseJSON);
        console.error('Error: ', jqXHR.responseJSON);

        ret = jqXHR;
        if (isFunction(callback)) {
          callback(jqXHR.responseJSON);
        }
      } else if ($.parseHTML(jqXHR.responseText)) {
        console.warn(
          'Got HTML instead of JSON, assuming wrong return type from the server.',
        );
        console.warn(jqXHR.responseText);
        $('body').html(jqXHR.responseText);
        $('body').css({ opacity: 1.0 });
      }
    });

  return ret;
}

export async function loginCheck() {
  const methodAllowed = some(window.Bootstrap.modules, (module) => {
    if (!module.login_exceptions) {
      return false;
    }
    return some(module.login_exceptions, (whitelistedRoute) => {
      // Lower case for case-insensitivity
      const currentRoute = window.location.pathname.toLowerCase();
      return currentRoute.startsWith(whitelistedRoute.toLowerCase());
    });
  });

  if (methodAllowed) {
    return;
  }

  try {
    const checkResult = await Api.get('/api/sessionCheck');
    if (!checkResult.isLoggedIn) {
      window.location.reload();
    }
  } catch (err) {
    console.error('Failed to check if logged in: ', err);
  }
}

export function getTemplate(templateName, moduleName) {
  if (!moduleName) {
    ({ moduleName } = window.Bootstrap.bootquery);
  }

  if (isArray(templateName)) {
    const loaded = reduce(
      templateName,
      (templates, template) => {
        templates[template] = newGetTemplate(template, moduleName);
        return templates;
      },
      {},
    );
    return Promise.resolve(loaded);
  }
  const template = newGetTemplate(templateName, moduleName);
  return Promise.resolve(template);
}

// Render the template with data into target container (if specified) and return rendered HTML.
export function renderTemplate(template, data, target, callback) {
  let rendered = handlebarsRender(template, data, true);

  let newElement = null;

  if (target && typeof target !== 'undefined' && target != null) {
    const parsed = new DOMParser().parseFromString(rendered, 'text/html');
    rendered = $(parsed);
    if (target == '.modal-body' && rendered.findElement('.bq-content').length) {
      newElement = rendered.findElement('.bq-content');
    } else if (rendered.findElement(target).length) {
      newElement = rendered.findElement(target);
    } else {
      if (data && data.bootquery) {
        throw new Error(
          `Failed to render template for ${data.bootquery.controller}/${
            data.bootquery.method
          } into element ${target}`,
        );
      }
      throw new Error(
        'Failed to render template',
        rendered,
        'into element',
        target,
        'with data',
        data,
      );
    }
  }

  if (newElement) {
    rendered = newElement.contents();
  }

  if (target && typeof target !== 'undefined' && target != null) {
    if (data && data.bootquery && data.bootquery.isModal) {
      target = `.modal ${target}`;
    }

    const tgt = $(target);

    tgt.find('[data-vue-boundary]').each((_i, el) => {
      const vueInstance = el.__vue__; // eslint-disable-line no-underscore-dangle
      if (vueInstance) {
        vueInstance.$destroy();
      }
    });
    $(tgt).each((_i, el) => {
      purgeReact(el);
    });

    if (tgt) {
      rendered = $(rendered);
      setTargetLoadingState(tgt, true);
      tgt.removeClass('paginated');
      if (newElement.hasClass('paginated')) {
        tgt.addClass('paginated');
      }

      if (target === '.bq-content' || target === 'body') {
        loadModules(data);
      }
      activateElements(rendered, data);

      tgt.html(rendered);
      setTargetLoadingState(tgt, false);
      window.targetElement = '.bq-content';

      if (typeof callback === 'function') {
        callback(true);
      }
    } else {
      if (data && data.bootquery) {
        console.error(
          `Failed to render template for ${data.bootquery.controller}/${
            data.bootquery.method
          } into element ${target}`,
        );
      } else {
        console.error(`Failed to render template ${template} into element ${target}`);
      }

      console.error('Target specified, but not found');
      if (typeof callback === 'function') {
        callback(false);
      }
    }
  }

  return rendered;
}

// Render the controller
export function renderController(
  requestType,
  controller,
  method,
  parameters,
  module,
  onSuccess,
  onError,
) {
  const route = `/${lowerFirst(controller)}/${method}`;
  const handled = window.BootQuery.handleRouteManually(route, {
    controller,
    method,
    parameters,
  });
  if (handled) {
    deinitDatatables(); // Actually used for unloading them
    setTargetLoadingState(window.targetElement, false);
  } else {
    setTargetLoadingState(window.targetElement, true);
    getJSON(requestType, controller, method, parameters, true, (data) => {
      renderControllerFromData(data, module, onSuccess, onError);
    });
  }
}

export function renderControllerFromData(data, module, onSuccess, onError) {
  $('#php-debugs').empty();
  if (typeof module === 'undefined') {
    module = upperFirst(data.bootquery.controller); // Not sure what to do really
  }

  const { controller, method } = data.bootquery;
  const { controller: prevController, method: prevMethod } = window.Bootstrap.bootquery;

  const route = `/${controller}/${method}`.toLowerCase();
  const prevRoute = `/${prevController}/${prevMethod}`.toLowerCase();

  if ([route, prevRoute].includes('/user/login')) {
    window.targetElement = '#content-wrapper';
    // eslint-disable-next-line no-self-assign
    window.location = window.location; // Hack: do a full reload
  }

  const historyObj = {
    controller: lowerFirst(window.Bootstrap.bootquery.controller),
    method: lowerFirst(window.Bootstrap.bootquery.method),
    parameters: window.Bootstrap.bootquery.parameters,
  };
  window.Bootstrap = data;

  const template = data.bootquery.template || data.bootquery.method;

  let loadInto = window.targetElement;
  let shouldRedirect = true;
  if (data.bootquery.isModal) {
    loadInto = `.modal ${window.targetElement}`;
    shouldRedirect = false;
  } else {
    const title = data.bootquery?.title ?? null;
    const { appTitle } = window.Bootstrap.bootquery;
    document.title = title ? `${title} | ${appTitle}` : appTitle;
  }
  setTargetLoadingState(loadInto, true);

  if (shouldRedirect && data.redirectedTo) {
    let queryString = qs.stringify(data.redirectedTo.params);
    if (queryString.length) {
      queryString = `?${queryString}`;
    }
    const newUrl = `/${data.redirectedTo.controller}/${
      data.redirectedTo.method
    }/${queryString}`;
    window.history.replaceState(historyObj, null, newUrl);
  }

  getTemplate(template, module).then((template) => {
    renderTemplate(template, data, window.targetElement, (status) => {
      if (status === true) {
        registerFormHandler($('body').find('form:first'), data);
        if (typeof onSuccess === 'function') {
          onSuccess(data);
        }

        $(document).trigger('renderController', [window.targetElement, data]);
      } else if (typeof onError === 'function') {
        onError(data);
      }
    });
  });
}

export function navigate(event, callback) {
  let newWindow = false;
  let isModal = false;
  let url;
  let route;

  if (event.type == 'click') {
    if ($(event.currentTarget).hasClass('nofollow')) { return; }

    if ($(event.currentTarget).is('[data-dismiss="modal"]')) {
      return;
    }

    if ($(event.currentTarget).closest('.modal').length > 0) {
      window.targetElement = '.embedable';
      isModal = true;
    }

    url = $(event.target).attr('href');

    if (typeof url === 'undefined') {
      url = $(event.target)
        .closest('a')
        .attr('href');
    }

    url = linkWithDefault(url);
    route = getURLComponents(url);

    if (
      event.ctrlKey
			|| (event.metaKey && navigator.platform.toUpperCase().indexOf('MAC') != -1)
    ) {
      newWindow = true;
    }

    if (event.which == 2) {
      newWindow = true;
    }
  } else {
    route = getURLComponents();
  }

  if (newWindow) {
    window.open(url, '_blank');
  } else {
    callback = callback || activateElements;
    let loadInto = window.targetElement;
    if (isModal) {
      loadInto = `.modal ${window.targetElement}`;
    }
    setTargetLoadingState(loadInto, true);

    if (!route.controller) {
      route.controller = '';
      route.method = '';
    }
    renderController(
      'get',
      route.controller,
      route.method,
      route.parameters,
      upperFirst(route.controller),
    );
  }
}

export function checkFormChanged(target) {
  const forms = $(target).find('form:not(.filter-form, .datatable-form, #login-form, [data-ignore-form])');
  if ($(target).is('form:not(.filter-form, .datatable-form, #login-form, [data-ignore-form])')) {
    $(forms).add(target);
  }

  let hasChanges = false;
  $(forms).each((_i, el) => {
    const manualDirtyFlag = $(el).data('formDirty');
    if (typeof manualDirtyFlag === 'boolean') {
      hasChanges = manualDirtyFlag;

      return false;
    }

    $(el)
      .find('input, textarea, select')
      .each((_i, el) => {
        let defaultValue = $(el).attr('data-default-value');

        if ($(el).is(':disabled')) {
          return true;
        }

        if ($(el).is('[type="checkbox"]')) {
          // TODO: Find out what goes here
        } else {
          if (typeof defaultValue === 'undefined') {
            defaultValue = $(el).prop('defaultValue');
          }

          const value = $(el).val();
          if (
            $(el).closest('.timepicker, .datepicker, .durationpicker, .datetimepicker')
              .length
          ) {
            return true;
          }

          if (typeof defaultValue !== 'undefined') {
            const valueEmpty = value === null || value === '';
            const defaultEmpty = defaultValue === null || defaultValue === '';
            if (value != defaultValue && !(valueEmpty && defaultEmpty)) {
              hasChanges = true;
              return false;
            }
          }
        }
        return true;
      });
    return true;
  });

  return hasChanges;
}

// Changing page
export function bootstrap(event, callback) {
  event.preventDefault();

  if ($(event.currentTarget).closest('.modal').length) {
    navigate(event, callback);
    return;
  }

  const hasChanges = checkFormChanged('body');
  if (hasChanges) {
    if ($('.custom-modal').length == 0) {
      const warningModal = $(
        '<div class="custom-modal modal fade" tabindex="-1" role="dialog" aria-hidden="true"'
					+ '	style="z-index: 10000">'
					+ '	<div class="modal-dialog">'
					+ '	<div class="modal-content panel panel-default">'
					+ '	<div class="modal-header panel-heading">'
					+ '		Nespremljeni podaci <button type="button" class="close" data-dismiss="modal" aria-hidden="true">×</button>'
					+ '	</div>'
					+ '	<div class="modal-body">'
					+ '		<strong>Podaci nisu spremljeni. Želite li spremiti podatke?</strong>'
					+ '	</div>'
					+ '	<div class="modal-footer">'
					+ '		<button class="btn btn-default custom-modal-cancel" data-dismiss="modal"></span> Odustani</button>'
					+ '		<button class="btn btn-danger custom-modal-no" data-dismiss="modal"> Nemoj spremiti</button>'
					+ '		<button class="btn btn-success custom-modal-yes" data-dismiss="modal"> Spremi</button>'
					+ '	</div>'
					+ '	</div>'
					+ '	</div>'
					+ '</div>',
      );

      $('body').append(warningModal);
    }

    $('.custom-modal').modal('show');

    $('.custom-modal-yes').ev('click', (e) => {
      $('.custom-modal').modal('hide');

      const formEl = document.querySelector('form');
      formEl.requestSubmit();
    });

    $('.custom-modal-cancel')
      .off('click')
      .on('click', (e) => {
        /* var historyObj = {
				controller: lowerFirst(window.Bootstrap.bootquery.controller),
				method: lowerFirst(window.Bootstrap.bootquery.method),
				parameters: window.Bootstrap.bootquery.parameters
			};

			if(historyObj != history.state) {
				window.history.pushState(historyObj, null, generateQueryString());
			} */
        $('.custom-modal').modal('hide');
      });

    $('.custom-modal-no')
      .off('click')
      .on('click', (e) => {
        $('.custom-modal').modal('hide');
        navigate(event, callback);
      });
  } else {
    navigate(event, callback);
  }
}

$(() => {
  // Page fully loaded
  setTarget(null);
  window.target = $('.bq-default'); // Load into the default container

  const route = getURLComponents();

  moment.updateLocale('en', {
    week: { dow: 1 },
  });

  let Bootstrap = null;
  const initialJSONElement = document.getElementById('initial_json');

  if (initialJSONElement && initialJSONElement.innerHTML) {
    Bootstrap = JSON.parse(initialJSONElement.innerHTML);
  }

  if (Bootstrap) {
    window.Bootstrap = Bootstrap;
    window.build_manifest = window.Bootstrap.build_manifest;
    printPHPDebugs(window.Bootstrap);

    syncBrandColorCssVars(Bootstrap.bootquery.brandColor);

    initWithData(window.Bootstrap);
  } else {
    getJSON('get', route.controller, route.method, route.parameters, true, (data) => {
      window.Bootstrap = data;
      window.build_manifest = window.Bootstrap.build_manifest;
      initWithData(data);
    });
  }

  $('#initial_json').remove();

  window.onpopstate = (e) => {
    bootstrap(e, 'load');
  };

  document.dispatchEvent(new CustomEvent('clickvoxLoad'));

  $('#global-warning-message')
    .toast({ delay: 60000 })
    .removeClass('d-none')
    .toast('show');

  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register(
      new URL('./service-worker', import.meta.url),
      { scope: '/' },
      /* webpackChunkName: "service-worker" */
    );
  }
});

function setupSocket() {
  window.socket = new Socket(`${wsUrlBase()}/ws`);
  // Should be enough for all modules and datatables,
  // if we hit this, it might really be a bug
  window.socket.setMaxListeners(64);

  window.socket.on('connect', async () => {
    console.log('Socket connected, auth?');
    const token = await getIdentityToken();
    window.socket.send('authenticate', { token });
  });

  window.socket.connect();
}

export function initWithData(data) {
  if (window.Bootstrap?.bootquery?.session?.userID ?? null) {
    tabs.getState().init();
    setupSocket();
  }

  parseLinks();
  loadModules(data);
  activateElements('body', data);
  setInterval(loginCheck, 60 * 1000);

  const widgetsRoot = document.querySelector('#react-widgets-root');
  if (widgetsRoot) {
    waitTranslationsReady().then(() => {
      renderReact(widgetsRoot, WidgetsRoot, {});
    });
  }
  bindUserActivityHandler();
  $(document).ev('activateElements.globalEventBus', (e, target, data) => {
    globalEventBus.emit('activateElements', { target, data });
  });
}
