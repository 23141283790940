import { ReactElement } from 'react';

export const ConferenceIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M24.36 62l20.5 32.81a8.77 8.77 0 0010.61 3.49l41-16.41a8.67 8.67 0 005.42-8.89l-2.82-28.27a134.38 134.38 0 0190.84 0L187.09 73a8.67 8.67 0 005.41 8.94l41 16.41a8.83 8.83 0 0010.67-3.49L264.67 62a8.68 8.68 0 00-1.23-10.76 168.17 168.17 0 00-237.85 0A8.69 8.69 0 0024.36 62z"
    />
    <path
      fill="currentColor"
      d="M44.63 173.85a28.54 28.54 0 10-28.54-28.54 28.57 28.57 0 0028.54 28.54zm199.77 0a28.54 28.54 0 10-28.54-28.54 28.56 28.56 0 0028.54 28.54zm14.26 14.27h-28.53a28.43 28.43 0 00-20.13 8.29 65.22 65.22 0 0133.48 48.78h29.43a14.25 14.25 0 0014.27-14.27v-14.26a28.57 28.57 0 00-28.52-28.54zm-114.15 0a49.94 49.94 0 10-49.94-49.94 49.92 49.92 0 0049.94 49.94zm34.25 14.27h-3.7a69 69 0 01-61.09 0h-3.7a51.38 51.38 0 00-51.37 51.36v12.85A21.41 21.41 0 0080.3 288h128.42a21.41 21.41 0 0021.41-21.4v-12.85a51.38 51.38 0 00-51.37-51.36zm-99.75-6a28.43 28.43 0 00-20.11-8.29H30.36a28.56 28.56 0 00-28.53 28.56v14.26a14.24 14.24 0 0014.26 14.27h29.39A65.4 65.4 0 0179 196.41z"
    />
  </svg>
);
