import { ReactElement } from 'react';

export const ListIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 812.5"
    fill="currentColor"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="M156.25 625h-125A31.25 31.25 0 0 0 0 656.25v125a31.25 31.25 0 0 0 31.25 31.25h125a31.25 31.25 0 0 0 31.25-31.25v-125A31.25 31.25 0 0 0 156.25 625Zm0-625h-125A31.25 31.25 0 0 0 0 31.25v125a31.25 31.25 0 0 0 31.25 31.25h125a31.25 31.25 0 0 0 31.25-31.25v-125A31.25 31.25 0 0 0 156.25 0Zm0 312.5h-125A31.25 31.25 0 0 0 0 343.75v125A31.25 31.25 0 0 0 31.25 500h125a31.25 31.25 0 0 0 31.25-31.25v-125a31.25 31.25 0 0 0-31.25-31.25Zm812.5 343.75h-625a31.25 31.25 0 0 0-31.25 31.25V750a31.25 31.25 0 0 0 31.25 31.25h625A31.25 31.25 0 0 0 1000 750v-62.5a31.25 31.25 0 0 0-31.25-31.25Zm0-625h-625A31.25 31.25 0 0 0 312.5 62.5V125a31.25 31.25 0 0 0 31.25 31.25h625A31.25 31.25 0 0 0 1000 125V62.5a31.25 31.25 0 0 0-31.25-31.25Zm0 312.5h-625A31.25 31.25 0 0 0 312.5 375v62.5a31.25 31.25 0 0 0 31.25 31.25h625A31.25 31.25 0 0 0 1000 437.5V375a31.25 31.25 0 0 0-31.25-31.25Z" />
  </svg>
);
