import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaSubscript, FaSuperscript } from 'react-icons/fa';

import { useRichText } from '../../RichtextContex';
import RichTextButton from '../RichTextButton';
import { useNotationToggles } from './ToggleHandlers';

const NotationButtonGroup = () => {
  const { t } = useTranslation();
  const { editor } = useRichText();
  const { toggleSubscript, toggleSuperscript } = useNotationToggles(editor);

  return (
    <>
      <RichTextButton
        key="subscript"
        label={t('global:richtext.subscript')}
        icon={<FaSubscript />}
        action={toggleSubscript}
        property="subscript"
      />
      <RichTextButton
        key="superscript"
        label={t('global:richtext.superscript')}
        icon={<FaSuperscript />}
        action={toggleSuperscript}
        property="superscript"
      />
    </>
  );
};

export const Notation = memo(NotationButtonGroup);
