import { ReactElement } from 'react';
import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { CellProps } from 'BootQuery/Assets/components/Table';

import { PersonApiData } from '../../types';

export const CompanyCell = ({
  row: person,
}: CellProps<PersonApiData>): ReactElement => {
  if (!person.company) {
    return <>-</>;
  }

  return (
    <Link
      as={RouterLink}
      to={`/phonebook/companies/${person.company.ID}`}
      fontWeight="normal"
    >
      {person.company.name}
    </Link>
  );
};
