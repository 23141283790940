import {
  createContext,
  Dispatch,
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useContext,
  useState,
} from 'react';

import { FetchedSettings, Settings } from '../js/user-settings';

export interface IUserSettingsContext {
  settings: Settings;
  setSettings: Dispatch<SetStateAction<Settings>>;
  fetched: FetchedSettings;
  setFetched: Dispatch<SetStateAction<FetchedSettings>>;
}

export const UserSettingsContext = createContext<IUserSettingsContext | null>(
  null
);

export const UserSettingsContextProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const [settings, setSettings] = useState<Settings>({});
  const [fetched, setFetched] = useState<FetchedSettings>({});

  return (
    <UserSettingsContext.Provider
      value={{ settings, setSettings, fetched, setFetched }}
    >
      {children}
    </UserSettingsContext.Provider>
  );
};

export function useUserSettingsContext(): IUserSettingsContext {
  const context = useContext(UserSettingsContext);
  if (!context) {
    throw new Error('Missing UserSettingsContext');
  }

  return context;
}
