import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { TicketType } from '../components/types';

export async function getTicketTypes(
  params: GetListingParams = {}
): Promise<TicketType[]> {
  const { data: types } = await Api.get('/api/ticketing/types', {
    params,
  });

  return types;
}

export function useTicketTypes(
  params: GetListingParams = {}
): UseQueryResult<TicketType[]> {
  const key = ['Ticketing.ticketTypes', params];

  return useQuery({
    queryKey: key,
    queryFn: () => getTicketTypes(params),
  });
}
