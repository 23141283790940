import { useMemo } from 'react';

import { uniqid } from 'app/assets/js/tsutil';

import { EditorItem, FieldType, FieldValue } from './types';
import { findType } from './util';

export function useEditorState(
  fields: FieldValue[],
  fieldTypes: FieldType[]
): EditorItem[] {
  return useMemo(
    () => [
      {
        id: 'fieldTypes',
        hasChildren: true,
        children: fieldTypes.map(fieldTypeToEditorValue),
        content: { itemType: 'container' },
      },
      {
        id: 'fields',
        hasChildren: true,
        children: fields.map((field) => fieldToEditorValue(field, fieldTypes)),
        content: { itemType: 'container' },
      },
    ],
    [fields, fieldTypes]
  );
}

export function fieldTypeToEditorValue(type: FieldType): EditorItem {
  return {
    id: uniqid(),
    mode: 'clone',
    content: { itemType: 'fieldType', ...type },
  };
}

export function fieldToEditorValue(
  field: FieldValue,
  fieldTypes: FieldType[]
): EditorItem {
  const type = findType(fieldTypes, field.type);
  if (type.toEditorValue) {
    return type.toEditorValue(field, fieldTypes);
  }

  return {
    id: uniqid(),
    content: { itemType: 'fieldValue', ...field },
    hasChildren: false,
  };
}
