import { ChangeEvent } from 'react';
import { FormControl, FormLabel, Input, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BaseSettings, EditorFieldComponentProps } from '../../types';
import { useFieldSettings } from '../../util';

export const SeparatorFieldSettings = (
  props: EditorFieldComponentProps<BaseSettings>
) => {
  const { setSetting } = useFieldSettings(props);

  const { t } = useTranslation();

  const setName = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('name', ev.target.value);
  };

  return (
    <VStack alignItems="start">
      <FormControl>
        <FormLabel>{t('global:name')}:</FormLabel>
        <Input
          placeholder="Name"
          value={props.content.settings.name}
          onChange={setName}
          size="sm"
        />
      </FormControl>
    </VStack>
  );
};
