import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { keepPreviousData } from '@tanstack/react-query';

import { ExportMenuItem, ExportModalProvider } from '@bq/components/Export';
import { useListingSettings } from '@bq/components/ListingSettings';
import { useHeadingProps } from '@bq/components/ListingSettings/use-heading-props';
import { defaultVisibleColumns } from 'app/Modules/Ticketing/Assets/components/TicketTable/Columns/defaults';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { ListHeading } from 'BootQuery/Assets/components/ListHeading';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import {
  Table,
  TableSelectionProvider,
} from 'BootQuery/Assets/components/Table';
import { useDebouncedQuery } from 'BootQuery/Assets/js/use-debounced-query';

import { phonebookDisplayModeOptions } from '../CompanyEmployeeList/display-settings';
import { PhonebookListContext } from '../PhonebookListContext';
import { Permissions, PersonApiData } from '../types';
import { useSelectedOverview } from '../use-selected-overview';
import { Actions } from './Actions';
import { columns } from './columns';
import { personFilterTypes } from './filter-types';
import { generateExport } from './generate-export';
import { getCardPeople } from './get-people';
import { usePersonBulkActions } from './person-list-bulk-actions';

interface Props {
  customFields?: FieldValue[];
  permissions: Permissions;
}

export const PersonTableList = ({
  permissions,
  customFields = [],
}: Props): ReactElement => {
  const selectedOverview = useSelectedOverview('people');

  const listingSettings = useListingSettings<PersonApiData>({
    listingName: 'Phonebook.PersonList',
    viewName: 'table',
    filterTypes: personFilterTypes,
    columns: columns(permissions),
    otherFilters: selectedOverview?.filters ?? [],
    defaults: { visibleColumns: defaultVisibleColumns },
    customFields,
  });

  const { columnsToShow, page, limit, density, filters, sort, setSort } =
    listingSettings;

  const { data, refetch } = useDebouncedQuery({
    queryKey: ['Phonebook.PersonList.table', page, filters, limit, sort],
    queryFn: () => {
      return getCardPeople({
        page,
        filters,
        limit,
        sort: sort.map((item) => `${item.sortBy}:${item.direction}`),
      });
    },
    enabled: filters !== undefined,
    placeholderData: keepPreviousData,
    duration: 1000,
  });

  const bulkActions = usePersonBulkActions(refetch);
  const itemCount = data?.meta.count ?? 0;

  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    displayModeOptions: phonebookDisplayModeOptions,
    count: itemCount,
    menuChildren: <ExportMenuItem />,
  });

  if (!data || !customFields) {
    return <LoadingPage />;
  }

  const { data: people } = data;

  return (
    <PhonebookListContext.Provider value={{ refetch }}>
      <TableSelectionProvider<PersonApiData>
        page={page}
        idColumn="ID"
        selectAll={async () => {
          const { data } = await getCardPeople({
            fields: [
              'ID',
              'firstName',
              'lastName',
              [
                'phoneNumbers',
                ['phoneNumber.phoneNumberE164', 'numberType.ID'],
              ],
            ],

            limit: 99999,
            filters,
          });

          return data;
        }}
        selectPage={() => people}
      >
        <Box my={9} px={5}>
          <ExportModalProvider
            onExport={generateExport}
            rowCount={itemCount}
            filterExpression={filters ?? []}
          >
            <ListHeading {...headingProps} Actions={<Actions />} />
          </ExportModalProvider>
        </Box>
        <Table<PersonApiData>
          columns={columnsToShow}
          rows={people}
          createRowLink={creatRowLink}
          size={density}
          sort={{ value: sort, onChange: setSort }}
          bulkActions={bulkActions}
        />
      </TableSelectionProvider>
    </PhonebookListContext.Provider>
  );
};

function creatRowLink(person: PersonApiData): string {
  return `/phonebook/people/${person.ID}`;
}
