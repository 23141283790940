import { chakra, shouldForwardProp } from '@chakra-ui/react';
import isValidHTMLProp from '@emotion/is-prop-valid';

import { Radio } from './Radio';
import { RadioGroup } from './RadioGroup';

export const RadioButton = chakra(Radio, {
  shouldForwardProp: (prop) => {
    // don't forward Chakra's props
    const isChakraProp = !shouldForwardProp(prop);
    if (isChakraProp) {
      return false;
    }

    // forward valid HTML props
    const isValidProp = isValidHTMLProp(prop);
    if (isValidProp) {
      return true;
    }

    return ['value'].includes(prop);
  },
  baseStyle: {
    color: 'brand.fontDark',
    bg: 'brand.background',
    _dark: {
      bg: 'brand.backgroundDark',
      color: 'white',
      _checked: {
        bg: 'brand.600',
        color: 'brand.font',
        borderColor: 'brand.900',
      },
      _hover: {
        bg: 'brand.200',
        color: 'brand.font',
      },
    },
    _checked: {
      bg: 'brand.400',
      color: 'brand.font',
      borderColor: 'brand.900',
    },
    _hover: {
      bg: 'brand.200',
      color: 'brand.font',
      borderColor: 'brand.900',
    },
    cursor: 'pointer',
    p: '2',
  },
});
export const RadioButtonGroup = chakra(RadioGroup, {
  shouldForwardProp: (prop) => {
    const isChakraProp = !shouldForwardProp(prop);
    if (isChakraProp) {
      return false;
    }

    const isValidProp = isValidHTMLProp(prop);
    if (isValidProp) {
      return true;
    }

    return ['defaultVal', 'onChange', 'children'].includes(prop);
  },
  baseStyle: (props) => ({
    border: '1px solid',
    bg: '#e2e8f0',
    borderColor: '#e2e8f0',
    gridGap: '1px',
    rounded: 'lg',
    overflow: 'hidden',
    shadow: 'sm',
    _dark: {
      borderColor: '#4f5765',
      bg: '#4f5765',
    },
  }),
});
