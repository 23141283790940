import { PropsWithChildren, ReactElement } from 'react';
import { Box, useRadio, UseRadioProps } from '@chakra-ui/react';

type Props = PropsWithChildren<UseRadioProps>;

export const ButtonRadioButton = ({
  children,
  ...props
}: Props): ReactElement => {
  const { getInputProps, getRadioProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getRadioProps();

  return (
    <Box
      sx={{
        _first: { '.button-radio-btn': { borderLeftRadius: 'md' } },
        _last: { '.button-radio-btn': { borderRightRadius: 'md' } },
      }}
    >
      <Box as="label" mb="0">
        <input {...input} />
        <Box
          {...checkbox}
          cursor="pointer"
          borderWidth="1px"
          className="button-radio-btn"
          boxShadow="sm"
          _checked={{
            bg: 'brand.600',
            color: 'brand.font',
            borderColor: 'brand.600',
          }}
          _focus={{
            boxShadow: 'outline',
          }}
          _hover={{ bg: 'brand.700', color: 'brand.font' }}
          _dark={{
            _checked: {
              bg: 'brand.100',
              color: 'brand.fontLight',
              borderColor: 'brand.100',
            },
            _focus: {
              boxShadow: 'outline',
            },
            _hover: {
              bg: 'brand.300',
              color: 'brand.fontLight',
            },
          }}
          px="2"
          py="0.5"
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
};
