import { Link } from '@chakra-ui/react';

import { EventCellComponent } from 'app/Modules/Events/Assets/components';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

import { ContactLink, CustomEventItem } from '../types';

export const CustomContactColumn: EventCellComponent<CustomEventItem> = ({
  row: { data: event },
}) => {
  if (!event.contacts.length) {
    return <>-</>;
  }

  return (
    <WithSeparator separator=",&nbsp;">
      {event.contacts.map((contact) => (
        <Link key={`${contact.type}-${contact.ID}`} href={contactLink(contact)}>
          {contact.name}
        </Link>
      ))}
    </WithSeparator>
  );
};

function contactLink(link: ContactLink): string {
  switch (link.type) {
    case 'person':
      return `/phonebook/people/${link.ID}`;
    case 'company':
      return `/phonebook/companies/${link.ID}`;
    default:
      throw new Error(`Unknown contact type ${link.type}`);
  }
}
