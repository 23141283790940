import { drawerAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { BrandColors } from 'BootQuery/Assets/js/brand-colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(drawerAnatomy.keys);

const baseStyle = (brandColors: BrandColors) => {
  return definePartsStyle({
    dialog: {
      bg: brandColors.background,
      color: brandColors.fontStaticDark,
      _dark: {
        bg: brandColors.backgroundDark,
        color: brandColors.fontStaticLight,
      },
    },
  });
};

export const drawerTheme = (brandColors: BrandColors) => {
  return defineMultiStyleConfig({
    baseStyle: baseStyle(brandColors),
    variants: {
      alwaysOpen: {
        // parts: ['dialog, dialogContainer'],
        dialog: {
          pointerEvents: 'auto',
        },
        dialogContainer: {
          pointerEvents: 'none',
        },
      },
    },
  });
};
