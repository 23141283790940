import BulletList from '@tiptap/extension-bullet-list';
import Heading, { Level } from '@tiptap/extension-heading';
import OrderedList from '@tiptap/extension-ordered-list';
import Paragraph from '@tiptap/extension-paragraph';

export const ParagraphExtend = Paragraph.extend({
  addOptions() {
    return {
      ...this.parent?.(),
    };
  },
  addAttributes() {
    return {
      color: {
        default: null,
        parseHTML: (element) => element.style.color,
        renderHTML: renderColorAttributes,
      },
    };
  },
});

export const ColoredUL = BulletList.extend({
  addOptions() {
    return {
      ...this.parent?.(),
    };
  },
  addAttributes() {
    return {
      color: {
        default: null,
        parseHTML: (element) => element.style.color,
        renderHTML: renderColorAttributes,
      },
    };
  },
});

export const ColoredOL = OrderedList.extend({
  addOptions() {
    return {
      ...this.parent?.(),
    };
  },
  addAttributes() {
    return {
      color: {
        default: null,
        parseHTML: (element) => element.style.color,
        renderHTML: renderColorAttributes,
      },
    };
  },
});

const levels: Level[] = [1, 2, 3, 4, 5, 6];
export const ColoredHeading = Heading.extend({
  addOptions() {
    return {
      ...this.parent?.(),
      levels,
    };
  },
  parseHTML() {
    return this.options.levels.map((level: Level) => ({
      tag: `h${level}`,
      attrs: { level },
    }));
  },
  renderHTML({ node, HTMLAttributes }) {
    const hasLevel = this.options.levels.includes(node.attrs.level);
    const level = hasLevel ? node.attrs.level : this.options.levels[0];

    return [`h${level}`, HTMLAttributes, 0];
  },
  addAttributes() {
    return {
      level: {
        default: 1,
        rendered: false,
      },
      color: {
        default: null,
        parseHTML: (element) => element.style.color,
        renderHTML: renderColorAttributes,
      },
    };
  },
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function renderColorAttributes(attributes: Record<string, any>) {
  if (attributes.color) {
    return {
      style: `color: ${attributes.color}`,
    };
  }

  return {};
}
