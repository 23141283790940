import { EventCellComponent } from 'app/Modules/Events/Assets/components';

import { CallEvent } from './types';

export const CallUserColumn: EventCellComponent<CallEvent> = ({
  row: { data: call },
}) => (
  <>
    {
      call.sourcePhonePoint.phonePoint.userPoint?.pbxUser.phoneNumber
        .phoneNumberE164
    }
  </>
);
