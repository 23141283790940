import { ReactElement } from 'react';

export const SignatureIcon = (): ReactElement => (
  <svg
    viewBox="0 0 981.1 495.5"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="M599.66 250.49c-34.28 2.32-83.18 36.2-107.93 47.31-19.25 8.67-35.86 16.15-50.35 16.15-15 0-17.21-10.72-14.1-34.34.73-5.3 7.74-52.41-28.25-50.36-16.61 1-42.55 16.41-112.16 83.38l27.46-68.62c20.11-50.23-35.21-100.25-85.83-68l-36.33 24.42a10.64 10.64 0 0 0-3.24 14.62l11.38 17.87a10.53 10.53 0 0 0 14.63 3.24l38.38-25.74c12.17-7.74 26.93 4.76 21.64 17.93l-65 162.52c-4.49 11.18 1.79 29 19.66 29a20.92 20.92 0 0 0 14.95-6.22c27.93-27.93 102.37-99.72 139.76-129.56-1.46 18.85-1.39 39 13.63 55.45 10.12 11.11 24.68 16.74 43.34 16.74 23.56 0 45-9.66 67.69-19.85 21.84-9.8 65.51-41.43 91.59-43.54a10.62 10.62 0 0 0 10-10.46v-21.2a10.49 10.49 0 0 0-11-10.72Z" />
    <path
      stroke="currentColor"
      strokeWidth="1.00031"
      d="M674.62 137.37V90.713l51.082.058zm239.64-46.675v46.53h20.31c.278 0 .005-.273.005.005v311.73c0 .278.273.005-.004.005H46.53c-.278 0-.004.273-.004-.004V137.25c0-.278-.274-.004.004-.004h628.09V90.714H46.51C21.107 90.725.016 111.808-.006 137.21v311.77c.011 25.41 21.106 46.505 46.516 46.516H934.59c25.41-.01 46.505-21.106 46.516-46.516V137.23c0-25.418-21.098-46.524-46.516-46.535h-.006zm0-.116v46.645l-51.202 1.105z"
    />
    <path
      stroke="currentColor"
      strokeWidth="1.00031"
      d="m787.36 51.058 70.134 70.17-152.296 152.027-62.529 6.83a13.153 13.136 30.514 0 1-14.509-14.527l7.004-62.532zm113.51-10.302L867.94 7.81a26.305 26.271 30.514 0 0-37.203-.042l-31.02 30.965 70.134 70.17 31.02-30.966a26.305 26.271 30.514 0 0 0-37.18z"
    />
  </svg>
);
