import { useMemo } from 'react';

import { Call } from '../../types/call';
import { CallDirection, CallListCall, VoicemailRecording } from './types';

function collectVoicemails(call: Call): VoicemailRecording[] {
  return call.destinations.reduce((vms: VoicemailRecording[], dest) => {
    if (dest.destination.type === 'voicemail') {
      const vm = dest.destination.point;
      if (vm.filename) {
        const vmInfo: VoicemailRecording = {
          ID: vm.ID,
          filename: vm.filename,
          mailbox: vm.mailbox.name,
          listened: vm.listened,
        };

        return [...vms, vmInfo];
      }
    }

    return vms;
  }, []);
}

export function formatCall(call: Call): CallListCall {
  let answered = false;
  let direction: CallDirection | null = null;
  const revDests = call.destinations.slice().sort((a, b) => {
    const aVal = new Date(a.enterAt).getTime();
    const bVal = new Date(b.enterAt).getTime();
    const diff = bVal - aVal;

    return diff / Math.abs(diff);
  });

  // First try to find the last phone destination
  let shownDest = revDests.find((dest) => {
    return ['phonePoint', 'conference'].includes(dest.destination.type);
  });
  if (shownDest) {
    answered = shownDest.states.some((state) => state.callState === 'up');
  } else {
    // If no phone dest, just show last destination
    [shownDest] = revDests;
  }

  if (call.sourcePhonePoint.phonePoint.trunkPoint) {
    direction = 'incoming';
  } else if (shownDest && call.sourcePhonePoint.phonePoint.userPoint) {
    if (
      shownDest.destination.type === 'phonePoint' &&
      shownDest.destination.point.userPoint
    ) {
      direction = 'internal';
    } else {
      direction = 'outgoing';
    }
  }

  const queueDest = revDests.find((dest) => dest.destination.type === 'queue');
  // This is mostly trying to make TS happy
  const queue =
    queueDest && queueDest.destination.type === 'queue'
      ? queueDest.destination.point
      : null;

  return {
    ...call,
    answered,
    direction,
    sourceInfo: {
      source: { type: 'phonePoint', point: call.sourcePhonePoint.phonePoint },
      phoneNumber: call.sourcePhonePoint.phonePoint.phoneNumber,
    },
    destinationInfo: {
      destination: shownDest?.destination ?? null,
      phoneNumber: shownDest?.destCalleeNumber?.phoneNumberE164 ?? null,
    },
    queue: queue?.name ?? null,
    voicemails: collectVoicemails(call),
  };
}

export function useFormattedCall(call: Call): CallListCall {
  return useMemo(() => formatCall(call), [call]);
}
