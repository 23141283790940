import i18n from 'BootQuery/Assets/js/i18n';

import { DropdownIcon } from '../../icons';
import { FieldType } from '../../types';
import { newFieldId } from '../../util';
import { getFilters } from './get-filters';
import { SelectField } from './SelectField';
import { SelectFieldSettingsComponent } from './SelectFieldSettingsComponent';
import { StaticSelectField } from './StaticSelectField';
import { SelectFieldEditorSettings, SelectFieldSettings } from './types';

export type { SelectFieldSettings };

type ValType = { key: string; name: string } | null;

type SelectFieldType = FieldType<
  SelectFieldSettings,
  SelectFieldEditorSettings
>;

export const selectField = (): SelectFieldType => ({
  type: 'select',
  name: i18n.t('global:form_editor.field_select'),
  icon: DropdownIcon,
  components: {
    EditorFieldSettings: SelectFieldSettingsComponent,
    FormField: SelectField,
    StaticFormField: StaticSelectField,
  },
  loadDefinition: (settings) => ({
    ...settings,
    selects: settings.selects.map((select) => ({
      ...select,
      itemChanges: { add: [], del: [], upd: {} },
    })),
  }),
  defaultSettings: () => ({
    name: newDropdownName(),
    required: false,
    selects: [
      {
        id: newFieldId(),
        name: newDropdownName(),
        new: true,
        itemChanges: { add: [], del: [], upd: {} },
      },
    ],
  }),
  getColumns(field) {
    return field.settings.selects.map((select) => ({
      key: `$custom-${field.id}-${select.id}`,
      title: select.name,
      exportValue: `data.${select.id}.name`,
      getValue: ({ row }) => {
        const data = (row.data ?? {}) as Record<string, ValType>;
        const val = data[select.id];

        return val ? val.name : '-';
      },
    }));
  },
  getGroupBy(field) {
    return field.settings.selects.map((select) => ({
      name: select.name,
      key: `data.${select.id}`,
      exportValue: `data.${select.id}.name`,
      columnKey: `$custom-${field.id}-${select.id}`,
    }));
  },
  getFilters,
});

function newDropdownName() {
  const innerName = i18n.t('global:form_editor.untitled_dropdown');

  return `[${innerName}]`;
}
