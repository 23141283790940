import { Box, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaTrash, FaUndo } from 'react-icons/fa';

import { ChangeBadge } from '@bq/components/ChangeBadge';
import { IconButton } from '@bq/components/IconButton';
import { SettingsMethods, WithChange } from '@bq/components/SettingsCRUD';
import { useModal } from '@bq/components/UseModal';

import { CasePriority } from '../../schemas';
import { PrioritiesModal } from './PrioritiesModal';

interface Props {
  priority: WithChange<CasePriority>;
  modify: SettingsMethods<CasePriority>;
}

export const PriorityItem = ({ priority, modify }: Props) => {
  const { t } = useTranslation();
  const { addModal } = useModal();

  return (
    <VStack w="full" pr={0} spacing={0}>
      <HStack key={priority.ID} w="full">
        <HStack w="full">
          <Box
            fontWeight="bold"
            mr={2}
            textDecor={priority.change === 'del' ? 'line-through' : 'none'}
          >
            {priority.name}
          </Box>
          <ChangeBadge type={priority.change} />
        </HStack>
        <HStack>
          {priority.change && (
            <IconButton
              label={t('global:undo_changes')}
              icon={<FaUndo />}
              variant="ghost"
              size="sm"
              onClick={() => {
                modify.undo(priority.ID);
              }}
            />
          )}
          <IconButton
            label={t('global:edit')}
            icon={<FaEdit />}
            variant="ghost"
            size="sm"
            onClick={() => {
              addModal({
                children: <PrioritiesModal mode="edit" data={priority} />,
                preventClose: {
                  defaultPreventState: false,
                },
                componentProps: {
                  modal: { size: '6xl' },
                },
                callback: modify.upd,
              });
            }}
          />
          <IconButton
            label={t('global:delete')}
            isDisabled={priority.change === 'del'}
            size="sm"
            icon={<FaTrash />}
            variant="ghost"
            colorScheme="red"
            onClick={() => {
              modify.del(priority.ID);
            }}
          />
        </HStack>
      </HStack>
    </VStack>
  );
};
