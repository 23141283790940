import { FormControl, FormLabel, Textarea } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ItemBaseFields } from '../../../types';
import { FieldProps } from '../../types';

export const DescriptionField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    register,
    formState: { errors },
  } = useFormContext<ItemBaseFields>();

  return (
    <FormControl isInvalid={!!errors.description} {...formControlProps}>
      <FormLabel htmlFor="description">{t('Products:description')}</FormLabel>
      <Textarea
        size={['md', 'md', 'sm']}
        {...register('description')}
        isInvalid={!!errors.description}
        placeholder={t('Products:description')}
      />
    </FormControl>
  );
};
