import {
  filterExpression,
  FilterExpression,
} from 'BootQuery/Assets/components/FilterBar';
import { filterTypesWithGroup } from 'BootQuery/Assets/components/FilterBar/filters/GroupFilter';
import { Api } from 'BootQuery/Assets/js/api';

import { getFilterTypes } from '../CallList/filter-types';
import {
  ApiPermissionsValue,
  FilterPermissionType,
  PermissionsValue,
  PermissionType,
} from './types';

interface InputPermissionsValue {
  filters: PermissionsValue;
  filterExpression: FilterExpression[] | null;
}

export async function getCurrentPermissions<T>(
  forWhat: PermissionType,
  groupID: number | string
): Promise<T> {
  const saved = document.querySelector<HTMLInputElement>(
    `input[name="telephonyPermissions[${forWhat}]"]`
  );
  if (saved) {
    return JSON.parse(saved.value) as T;
  }

  const { data } = await Api.get<T>(
    `/api/telephony/permissions/${groupID}/${forWhat}`
  );

  return data;
}

type PermissionsRet = ApiPermissionsValue | InputPermissionsValue;
export async function getCurrentFilterPermissions(
  editWhat: FilterPermissionType,
  groupID: number | string
): Promise<PermissionsValue> {
  const permissions = await getCurrentPermissions<PermissionsRet>(
    editWhat,
    groupID
  );

  if (
    permissions &&
    typeof permissions === 'object' &&
    'filters' in permissions
  ) {
    return permissions.filters;
  }

  return permissions;
}

function valueToFilterExpression(
  value: PermissionsValue
): Promise<FilterExpression[] | null> {
  if (value === 'none') {
    return Promise.resolve(null);
  }

  return filterExpression(filterTypesWithGroup(getFilterTypes()), value, '');
}

export function setInputValue(
  permissionName: PermissionType | string,
  value: unknown
) {
  const name = `telephonyPermissions[${permissionName}]`;
  let el = document.querySelector<HTMLInputElement>(`input[name="${name}"]`);
  if (!el) {
    el = document.createElement('input');
    el.type = 'hidden';
    el.name = name;
    document.querySelector('#group-modify-container')?.append(el);
  }

  el.value = JSON.stringify(value);
}

export async function setFilterInputValue(
  editWhat: PermissionType,
  filters: PermissionsValue
) {
  const filterExpression = await valueToFilterExpression(filters);

  const inputVal: InputPermissionsValue = { filters, filterExpression };
  setInputValue(editWhat, inputVal);
}
