import {
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import { filterExpression } from './filter-expression';
import { filterTypesWithGroup } from './filters/GroupFilter';
import { FilterExpression, FilterTypes, FilterValue } from './types';

type QueryOptions = Omit<
  UseQueryOptions<FilterExpression[]>,
  'queryKey' | 'queryFn' | 'initialData'
>;

export function useFilterExpression(
  filterTypes: FilterTypes,
  filterValues: FilterValue[],
  searchString = '',
  queryOptions: QueryOptions = {}
): UseQueryResult<FilterExpression[]> {
  return useQuery({
    ...queryOptions,
    queryKey: ['filterExpression', filterValues, filterTypes, searchString],
    queryFn: () => {
      return filterExpression(
        filterTypesWithGroup(filterTypes),
        [{ filter: '$group', operator: '$and', value: filterValues }],
        searchString
      );
    },
  });
}
