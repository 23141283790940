import mitt from 'mitt';

import { Jsonish } from '../components/type-util';
import { createUseMittEvent } from './use-mitt';

export type SettingsChangedEvent = Jsonish & {
  Settings?: {
    branding?: {
      color?: string | null;
    };
  };
};

export type SettingsEvents = {
  settingsChanged: SettingsChangedEvent;
};

export const settingsEvents = mitt<SettingsEvents>();
export const useSettingsChanged = createUseMittEvent(
  settingsEvents,
  'settingsChanged'
);
