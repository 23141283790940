import { FilterTypes } from '@bq/components/FilterBar';

import { ticketResolveDeadlineExceeded } from './resolve-deadline-exceeded';
import { ticketRespondDeadlineExceeded } from './respond-deadline-exceeded';
import { ticketFilterResolveUntil } from './ticket-filer-resolve-until';
import { ticketFilterAssignedToCurrentUser } from './ticket-filter-assigned-to-current';
import { ticketFilterAssignee } from './ticket-filter-assignee';
import { ticketFilterContact } from './ticket-filter-contact';
import { ticketFilterCreatedAt } from './ticket-filter-created-at';
import { ticketFilterCreator } from './ticket-filter-creator';
import { ticketFilterCustomer } from './ticket-filter-customer';
import { ticketFilterEmail } from './ticket-filter-email';
import { ticketFilterFromEmail } from './ticket-filter-from-email';
import { ticketFilterFromEmailAddress } from './ticket-filter-from-email-address';
import { ticketFilterGroup } from './ticket-filter-group';
import { ticketFilterId } from './ticket-filter-id';
import { ticketFilterIsAssigned } from './ticket-filter-isAssigned';
import { ticketFilterIsResolved } from './ticket-filter-isResolved';
import { ticketFilterMailAttachment } from './ticket-filter-mail-attachment';
import { ticketFilterMailBody } from './ticket-filter-mail-body';
import { ticketFilterMailSubject } from './ticket-filter-mail-subject';
import { ticketFilterPriority } from './ticket-filter-priority';
import { ticketFilterReminderDate } from './ticket-filter-reminder-date';
import { ticketFilterRespondUntil } from './ticket-filter-respond-until';
import { ticketFilterScheduledFor } from './ticket-filter-scheduled-for';
import { ticketFilterSharedWithUser } from './ticket-filter-shared-with-user';
import { ticketFilterStatus } from './ticket-filter-status';
import { ticketFilterTags } from './ticket-filter-tags';
import { ticketFilterTicketType } from './ticket-filter-ticket-type';
import { ticketFilterTitle } from './ticket-filter-title';
import { ticketFilterUpdatedAt } from './ticket-filter-updated-at';
import { ticketHasDependencies } from './ticket-has-dependencies';

export const ticketFilterTypes: FilterTypes = {
  ID: ticketFilterId,
  title: ticketFilterTitle,
  createdAt: ticketFilterCreatedAt,
  updatedAt: ticketFilterUpdatedAt,
  customer: ticketFilterCustomer,
  contact: ticketFilterContact,
  'state.ID': ticketFilterStatus,
  group: ticketFilterGroup,
  ticketType: ticketFilterTicketType,
  priority: ticketFilterPriority,
  createdByUser: ticketFilterCreator,
  assignedUser: ticketFilterAssignee,
  hasAssignedUser: ticketFilterIsAssigned,
  'assignedUser.isCurrentUser': ticketFilterAssignedToCurrentUser,
  'state.isResolved': ticketFilterIsResolved,
  'fromMailAddress.email': ticketFilterFromEmail,
  mailAddress: ticketFilterEmail,
  mailMessageSubject: ticketFilterMailSubject,
  mailMessageBody: ticketFilterMailBody,
  mailAttachment: ticketFilterMailAttachment,
  fromMailAddress: ticketFilterFromEmailAddress,
  tags: ticketFilterTags,
  respondUntil: ticketFilterRespondUntil,
  resolveUntil: ticketFilterResolveUntil,
  scheduledFor: ticketFilterScheduledFor,
  respondDeadlineExceeded: ticketRespondDeadlineExceeded,
  resolveDeadlineExceeded: ticketResolveDeadlineExceeded,
  reminderDate: ticketFilterReminderDate,
  sharedWithUser: ticketFilterSharedWithUser,
  hasDependencies: ticketHasDependencies,
};
