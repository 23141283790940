import { ReactElement } from 'react';

import { DefaultStaticFieldComponent } from './field-types/DefaultStaticFieldComponent';
import { FieldType, FieldValue } from './types';
import { findType } from './util';

interface GeneratedFieldProps {
  fieldTypes: FieldType[];
  field: FieldValue;
  prefix?: string | null;
}

export const StaticGeneratedFormField = ({
  field,
  fieldTypes,
  prefix = 'data',
}: GeneratedFieldProps): ReactElement => {
  const fieldType = findType(fieldTypes, field.type);
  const Component =
    fieldType.components?.StaticFormField ?? DefaultStaticFieldComponent;

  const id = prefix ? `${prefix}.${field.id}` : field.id;

  return <Component {...field} id={id} />;
};
