import { memo, ReactElement } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { Trans } from 'react-i18next';

interface Props {
  count: number | null;
}

export const CallsTableFooter = memo(
  ({ count }: Props): ReactElement => (
    <Flex>
      <Box px={2} ml="auto">
        {count !== null ? (
          <Trans
            ns="Telephony"
            i18nKey="Telephony:callList.total_call_count"
            values={{ count }}
            defaults="Ukupno <strong>{{ count }}</strong> poziva"
          />
        ) : (
          <>...</>
        )}
      </Box>
    </Flex>
  )
);
CallsTableFooter.displayName = 'CallsTableFooter';
