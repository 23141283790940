import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal/use-modal';

import { CompanyModalContent } from './CompanyModalContent';
import { EditCompanyModalContent } from './EditCompanyModalContent';
import {
  CompanyCallbackData,
  CreateCompanyModal,
  EditCompanyModal,
  UseCompanyModalReturn,
} from './types';

export const useCompanyModal = (): UseCompanyModalReturn => {
  const { addModal } = useModal();

  const createCompanyModal: CreateCompanyModal = useCallback(
    (closeCallback, defaults) => {
      addModal<CompanyCallbackData>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: <CompanyModalContent mode="create" data={defaults} />,
      });
    },
    [addModal]
  );
  const editCompanyModal: EditCompanyModal = useCallback(
    (ID, closeCallback) => {
      addModal<CompanyCallbackData>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: <EditCompanyModalContent ID={ID} />,
      });
    },
    [addModal]
  );

  return { createCompanyModal, editCompanyModal };
};
