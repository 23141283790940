import { useQuery } from '@tanstack/react-query';

import { LoadingModal } from '@bq/components/UseModal/LoadingModal';
import { getCompanyLocationFormData } from 'app/Modules/Phonebook/Assets/components/CompanyLocationForm';

import { CompanyLocationModalContent } from './CompanyLocationModalContent';

export const EditCompanyLocationModalContent = ({ ID }: { ID: number }) => {
  const { data } = useQuery({
    queryKey: ['EditCompanyLocationModal', ID],
    queryFn: () => {
      return getCompanyLocationFormData(ID.toString());
    },
  });

  if (!data) {
    return <LoadingModal />;
  }

  return (
    <CompanyLocationModalContent
      mode="edit"
      data={data}
      CompanyLocationID={ID}
    />
  );
};
