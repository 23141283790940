import { TemplateElement } from '@bq/components/RichText/Placeholders';
import { DateType, formatDate } from 'BootQuery/Assets/js/dateUtils';
import i18n from 'BootQuery/Assets/js/i18n';

import { PartialLoadedOffer } from '../../types';

export const offerDateTemplate = (): TemplateElement<PartialLoadedOffer> => ({
  key: 'offerDate',
  title: () => i18n.t('Sales:created_date'),
  getValue: (foundValue) => {
    return formatDate(foundValue.offerDate ?? null, DateType.Date);
  },
});
export const validUntilDateTemplate =
  (): TemplateElement<PartialLoadedOffer> => ({
    key: 'validUntil',
    title: () => i18n.t('Sales:valid_until_date'),
    getValue: (foundValue) => {
      return formatDate(foundValue.validUntil ?? null, DateType.Date);
    },
  });
export const deliveryDateTemplate =
  (): TemplateElement<PartialLoadedOffer> => ({
    key: 'deliveryDate',
    title: () => i18n.t('Sales:delivery_date'),
    getValue: (foundValue) => {
      return formatDate(foundValue.deliveryDate ?? null, DateType.Date);
    },
  });
export const billingStartDateTemplate =
  (): TemplateElement<PartialLoadedOffer> => ({
    key: 'billingStartDate',
    title: () => i18n.t('Sales:billing_start_date'),
    getValue: (foundValue) => {
      return formatDate(foundValue.billingStartDate ?? null, DateType.Date);
    },
  });
