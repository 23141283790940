import { ReactElement, useCallback, useMemo } from 'react';
import { Box, FormControl, FormLabel } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { BrandColorInput } from './BrandColorInput';
import { LogoInput } from './LogoInput';
import { LogoValue } from './types';
import { getBrandingSettings, setInputValue } from './util';

export const BrandingSettings = (): ReactElement => {
  const { t } = useTranslation('Settings');

  const { logo, darkLogo, color } = useMemo(getBrandingSettings, []);
  const handleLogoChange = useCallback((newVal: LogoValue | null) => {
    const value = newVal?.type === 'uploaded' ? newVal.uploadId : null;
    setInputValue('#branding-settings-root', 'branding[logo]', value);
  }, []);
  const handleNavLogoChange = useCallback((newVal: LogoValue | null) => {
    const value = newVal?.type === 'uploaded' ? newVal.uploadId : null;
    setInputValue('#branding-settings-root', 'branding[darkLogo]', value);
  }, []);
  const handleColorChange = useCallback((newVal: string | null) => {
    setInputValue('#branding-settings-root', 'branding[color]', newVal);
  }, []);

  return (
    <Box>
      <FormControl maxW="sm">
        <FormLabel fontWeight="bold">{t('Settings:logo')}:</FormLabel>
        <LogoInput
          defaultValue="/app/assets/img/clickvox_logo.svg"
          value={logo}
          onChange={handleLogoChange}
        />
      </FormControl>
      <FormControl maxW="sm">
        <FormLabel fontWeight="bold">{t('Settings:dark_logo')}:</FormLabel>
        <LogoInput
          defaultValue="/app/assets/img/clickvox_logo_dark.svg"
          bg="black"
          color="white"
          value={darkLogo}
          onChange={handleNavLogoChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontWeight="bold">{t('Settings:brand_color')}:</FormLabel>
        <BrandColorInput value={color} onChange={handleColorChange} />
      </FormControl>
    </Box>
  );
};
