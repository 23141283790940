import mitt, { Emitter } from 'mitt';

import { Api } from './api';

enum UploadState {
  IDLE = 'idle',
  UPLOADING = 'uploading',
  DONE = 'done',
}

type FileUploadEvents = {
  progress: number;
  state: UploadState;
};

interface UploadResponse {
  uploadId: string;
}

/**
 * Handles uploading a file, tracking state and progress.
 *
 * After construction, `start()` needs to be called.
 * `start()` returns a promise you can await to wait for upload completion.
 *
 * Progress and state can be tracked through the `.events` property.
 * Available events are `state ('idle' | 'uploading' | 'done')` and `progress (number)`;
 *
 * Allows cancellation through `cancel()`.
 */
export class FileUpload {
  #progress: number;

  #state: UploadState;

  #abortController: AbortController | null;

  readonly file: File | Blob;

  readonly uploadsRoot: string;

  readonly events: Emitter<FileUploadEvents>;

  constructor(file: File | Blob, uploadsRoot = '/api/uploads') {
    this.file = file;
    this.uploadsRoot = uploadsRoot;
    this.#progress = 0;
    this.#state = UploadState.IDLE;
    this.events = mitt<FileUploadEvents>();
    this.#abortController = null;
  }

  get progress(): number {
    return this.#progress;
  }

  get state(): UploadState {
    return this.#state;
  }

  /**
   * Start uploading the file.
   * @returns Uploaded file id
   */
  async start(): Promise<string> {
    const formData = new FormData();
    formData.append('file', this.file);

    this.setState(UploadState.UPLOADING);

    this.#abortController = new AbortController();
    const {
      data: { uploadId },
    } = await Api.post<UploadResponse>(this.uploadsRoot, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      onUploadProgress: (progressEv) => {
        // Sometimes size is not known beforehand (no Content-Length header)
        // Really nothing to do in that case
        if (!progressEv.total) {
          return;
        }
        this.#progress = (progressEv.loaded / progressEv.total) * 100;
        this.events.emit('progress', this.#progress);
      },
      signal: this.#abortController.signal,
    });

    this.setState(UploadState.DONE);

    return uploadId;
  }

  cancel() {
    this.#abortController?.abort();
    this.setState(UploadState.IDLE);
  }

  private setState(state: UploadState) {
    this.#state = state;
    this.events.emit('state', state);
  }
}

/**
 * Delete the temporary pre-uploaded file from server.
 * @param uploadId The uploadId returned when uploading the file
 * @param apiRoot Root upload API path in case it's not the default one (/api/uploads)
 */
export async function deleteUpload(
  uploadId: string,
  apiRoot = '/api/uploads'
): Promise<void> {
  await Api.delete(`${apiRoot}/${uploadId}`);
}
