import {
  FocusEventHandler,
  ReactElement,
  SetStateAction,
  useCallback,
} from 'react';
import { HStack, Input, Td, Tr } from '@chakra-ui/react';

import { DeleteButton } from '@bq/components/DeleteButton';
import { SettingsMethods } from '@bq/components/SettingsCRUD';
import { applySetState } from 'app/assets/js/apply-set-state';

import { DirectionInput } from './DirectionInput';
import { BlacklistDir, BlacklistItem } from './types';

interface Props {
  item: BlacklistItem;
  modify: SettingsMethods<BlacklistItem>;
}

export const BlacklistRow = ({ item, modify }: Props): ReactElement => {
  const handlePhoneNumberChange: BlurHandler = useCallback(
    (ev) => {
      modify.upd({ ...item, phoneNumber: ev.target.value });
    },
    [item, modify]
  );
  const handleNoteChange: BlurHandler = useCallback(
    (ev) => {
      modify.upd({ ...item, note: ev.target.value });
    },
    [item, modify]
  );
  const handleDirectionChange = useCallback(
    (newVal: SetStateAction<BlacklistDir>) => {
      modify.upd({
        ...item,
        direction: applySetState(item.direction, newVal),
      });
    },
    [item, modify]
  );
  const handleDelete = useCallback(() => {
    modify.del(item.ID);
  }, [modify, item.ID]);

  return (
    <Tr key={item.ID}>
      <Td>
        <Input
          size="sm"
          defaultValue={item.phoneNumber}
          onBlur={handlePhoneNumberChange}
        />
      </Td>
      <Td>
        <Input size="sm" defaultValue={item.note} onBlur={handleNoteChange} />
      </Td>
      <Td>
        <HStack justifyContent="space-between">
          <DirectionInput
            value={item.direction}
            onChange={handleDirectionChange}
          />
          <DeleteButton size="sm" onClick={handleDelete} />
        </HStack>
      </Td>
    </Tr>
  );
};

type BlurHandler = FocusEventHandler<HTMLInputElement>;
