import { ReactElement, useCallback, useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import { ColorInput } from 'BootQuery/Assets/components/ColorInput';

import { ResetButton } from './ResetButton';

interface Props {
  value?: string | null;
  onChange?: (color: string | null) => void;
}

export const BrandColorInput = ({ value, onChange }: Props): ReactElement => {
  const [color, setColor] = useState<string | null>(value ?? null);
  const handleChange = useCallback(
    (color: string | null) => {
      setColor(color);
      if (onChange) {
        onChange(color);
      }
    },
    [onChange]
  );
  const reset = useCallback(() => {
    handleChange(window.Bootstrap.bootquery.defaultBrandColor ?? null);
  }, [handleChange]);

  return (
    <Flex sx={{ gap: '16px' }} alignItems="center">
      <Box flex="0 1 auto">
        <ColorInput clearable value={color} onChange={handleChange} size="12" />
      </Box>
      <ResetButton onClick={reset} />
    </Flex>
  );
};
