import { SetStateAction, useState } from 'react';
import { Grid, Input } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

import { formatFileSizeObject, MemorySizeUnit } from 'app/assets/js/tsutil';

interface SelectOption {
  label: MemorySizeUnit;
  value: number;
}
type FileSizeInputCallback = (val: SetStateAction<number | undefined>) => void;

const options: SelectOption[] = [
  { label: 'B', value: 1 },
  { label: 'kB', value: 1024 },
  { label: 'MB', value: 1048576 },
  { label: 'GB', value: 1073741824 },
];
interface Props {
  value?: number;
  onChange: FileSizeInputCallback;
}
export const FileSizeInput = ({ onChange, value }: Props) => {
  const [unit, setUnit] = useState<SelectOption>(
    getDefaultUnit(value) ?? options[1]
  );

  return (
    <Grid templateColumns="2fr 1fr">
      <Input
        type="number"
        onChange={(event) => {
          const parseNumber = parseInt(event.target.value, 10);
          if (!Number.isNaN(parseNumber)) {
            onChange(parseNumber * unit.value);
          } else {
            onChange(undefined);
          }
        }}
      />
      <Select<SelectOption, false>
        variant="unstyled"
        options={options}
        onChange={(newValue) => {
          if (newValue) {
            const prevUnit = unit;
            const convertUnit = newValue.value / prevUnit.value;
            onChange((prev) => {
              if (!prev) {
                return prev;
              }

              return prev * convertUnit;
            });
            setUnit(newValue);
          }
        }}
        value={unit}
      />
    </Grid>
  );
};

const getDefaultUnit = (value?: number) => {
  const defaultUnit = formatFileSizeObject(value ?? 1025); // default
  const findSelectOption = options.find(
    (item) => item.label === defaultUnit.unit
  );

  return findSelectOption;
};
