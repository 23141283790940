import { Api } from 'BootQuery/Assets/js/api';

import { getFieldTypes } from './field-types';
import { loadDefinitions } from './load-definitions';
import { FieldValue } from './types';

export interface CustomFormDefinitionResult {
  name: string;
  data: FieldValue[];
}

export type DefinitionType = 'use' | 'edit';
export async function getCustomFormDefinition(
  name: string,
  mode: DefinitionType
): Promise<CustomFormDefinitionResult> {
  const { data } = await Api.get<FieldValue[]>(`/api/customForms/${name}`);

  if (mode === 'edit') {
    return {
      name,
      data: loadDefinitions(getFieldTypes(), data),
    };
  }

  return { name, data };
}

export async function setCustomFormDefinition(
  name: string,
  definition: FieldValue[]
): Promise<void> {
  await Api.put<FieldValue[]>(`/api/customForms/${name}`, definition);
}
