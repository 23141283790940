import { ReactElement, useMemo } from 'react';
import {
  PopoverContent,
  PopoverContentProps,
  usePopoverContext,
} from '@chakra-ui/react';

interface FixedPopoverContentProps extends PopoverContentProps {
  isLazy?: boolean;
}

/**
 * Chakra UI's PopoverContent has a border even when empty.
 * When put inside a Portal, this takes 2px of vertical space,
 * causing a tiny scrollbar to appear 100vh pages
 *
 * This sets the border to 0 until the popover is open, fixing the problem
 */
export const FixedPopoverContent = ({
  children,
  isLazy = true,
  ...props
}: FixedPopoverContentProps): ReactElement => {
  const { isOpen } = usePopoverContext();
  const borderWidth = useMemo(
    () => (isOpen ? props.borderWidth : 0),
    [isOpen, props.borderWidth]
  );
  const lazyChildren = isOpen || !isLazy ? children : null;

  return (
    <PopoverContent {...props} borderWidth={borderWidth}>
      {lazyChildren}
    </PopoverContent>
  );
};
