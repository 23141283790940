import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { getAllCompanyLocations, getLocations } from './api';

export const pickCorrectQuery = (
  params: Partial<GetListingParams>,
  companyID?: number
) => {
  if (companyID !== undefined) {
    return getLocations(companyID, params);
  }

  return getAllCompanyLocations(params);
};
