import { useMemo } from 'react';

import { TagOptionObject } from './types';

export function useFullTags(
  inVal: string[],
  options: TagOptionObject[]
): TagOptionObject[] {
  return useMemo(() => {
    return inVal.map((value): TagOptionObject => {
      const option = options.find((opt) => opt.value === value);
      if (!option) {
        console.warn(`Found non-existent tag: ${value}`);

        return { value };
      }

      return option;
    });
  }, [inVal, options]);
}
