import { ReactElement, useContext } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { get } from 'lodash-es';
import { FaRegCheckSquare, FaRegSquare } from 'react-icons/fa';

import { StaticFormContext } from '../../StaticFormContext';
import { FieldValue } from '../../types';

export const StaticCheckboxField = ({
  id,
  settings,
  type,
}: FieldValue): ReactElement => {
  const { formData } = useContext(StaticFormContext);

  const value = get(formData, id);

  return (
    <Flex alignItems="center">
      <Text as="span" fontWeight="bold">
        {settings.name}:{' '}
        {value ? (
          <FaRegCheckSquare style={svgStyle} />
        ) : (
          <FaRegSquare style={svgStyle} />
        )}
      </Text>
    </Flex>
  );
};

const svgStyle = { display: 'inline' };
