import { memo, useMemo } from 'react';
import { IconButton, Tooltip } from '@chakra-ui/react';

import { BulkAction, useTableSelection } from './TableSelection';
import { OBJ } from './types';

interface Props<T extends OBJ> {
  action: BulkAction<T>;
}
const BulkActionStandardButton = <T extends OBJ>({ action }: Props<T>) => {
  const { selection, clearSelected } = useTableSelection<T>();
  const parsedSelection = useMemo(() => {
    return action.parseSelection !== undefined
      ? action.parseSelection(selection)
      : selection;
  }, [action, selection]);

  const allowed = useMemo(
    () => action.isAllowed(parsedSelection),
    [action, parsedSelection]
  );

  return (
    <Tooltip
      key={action.name}
      hasArrow
      label={
        !allowed && action.disabledTooltip ? action.disabledTooltip : undefined
      }
      shouldWrapChildren
    >
      <IconButton
        aria-label="action.name"
        onClick={async () => {
          if (allowed && action.handler) {
            await action.handler(parsedSelection, selection);
            clearSelected();
          }
        }}
        isDisabled={!allowed}
        icon={action.icon}
        size="sm"
      />
    </Tooltip>
  );
};

const BulkActionStandardButtonMemo = memo(
  BulkActionStandardButton
) as typeof BulkActionStandardButton;

export { BulkActionStandardButtonMemo as BulkActionStandardButton };
