import { get } from 'lodash-es';

import { LogoValue } from './types';

export function setInputValue(
  containerSelector: string,
  name: string,
  value: unknown
): void {
  let el = document.querySelector<HTMLInputElement>(`input[name="${name}"]`);
  if (!el) {
    el = document.createElement('input');
    el.type = 'hidden';
    el.name = name;
    document.querySelector(containerSelector)?.append(el);
  }

  el.value = JSON.stringify(value);
}

interface BrandingTabData {
  logo?: string;
  darkLogo?: string;
  color?: string | null;
}
interface BrandingTab {
  key: 'branding';
  data: BrandingTabData;
}

interface BrandingLogoValue {
  logo: LogoValue | null;
  darkLogo: LogoValue | null;
  color: string | null;
}
type Tab = BrandingTab | { key: string };
export function getBrandingSettings(): BrandingLogoValue {
  const tabs = get(window.Bootstrap, 'result.tabs') as Tab[];
  const brandingTab = tabs.find((tab) => tab.key === 'branding') as BrandingTab;

  let logo: LogoValue | null = null;
  let darkLogo: LogoValue | null = null;
  let color: string | null = null;

  if (brandingTab) {
    if (brandingTab.data.logo) {
      logo = { type: 'existing', url: brandingTab.data.logo };
    }
    if (brandingTab.data.darkLogo) {
      darkLogo = { type: 'existing', url: brandingTab.data.darkLogo };
    }
    color = brandingTab.data.color ?? null;
  }

  return { logo, darkLogo, color };
}
