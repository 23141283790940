import { ReactElement } from 'react';

export const FormattedTextIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    fill="currentColor"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="M620 1000h344.29a35.71 35.71 0 0 0 35.71-35.71v-71.43a35.71 35.71 0 0 0-35.71-35.72L703 857s8 128-83 143ZM352.67 857.14h-317A35.71 35.71 0 0 0 0 892.86v71.43A35.71 35.71 0 0 0 35.71 1000h409c-80.38-24-92.04-142.86-92.04-142.86Zm290-285.71h-607A35.71 35.71 0 0 0 0 607.14v71.43a35.71 35.71 0 0 0 35.71 35.72L583 706s-40-53 59.67-134.57ZM1000 384.86v-63.43a35.71 35.71 0 0 0-35.71-35.72H35.71A35.71 35.71 0 0 0 0 321.43v71.43a35.71 35.71 0 0 0 35.71 35.71L807 429s29-31 63.61-57c37.95-28.52 91.39-38 129.39 12.86ZM35.71 0A35.71 35.71 0 0 0 0 35.71v71.43a35.71 35.71 0 0 0 35.71 35.72" />
    <path d="M574.86 751.42c-49.21 3.17-93.87 21.91-119.21 88.69a18.72 18.72 0 0 1-17.89 12.24c-13.63 0-55.76-33.94-67.77-42.14 0 101 46.53 189.79 157 189.79 93 0 157-53.69 157-147.42 0-3.82-.8-7.46-1.19-11.2l-107.95-90ZM931.63 372c-18.59 0-36 8.23-49.32 20.18C631.59 616.15 605.5 621.41 605.5 687.34a113.54 113.54 0 0 0 10.71 47.46l78.28 65.2a112.68 112.68 0 0 0 27.51 3.75c76.18 0 120.34-55.77 259-314.56 9-17.61 17-36.62 17-56.41 0-35.46-31.89-60.78-66.37-60.78Z" />
    <rect width="630" height="142.86" rx="35.71" ry="35.71" />
  </svg>
);
