import { ElementType } from 'react';
import {
  Button,
  Heading as HText,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import { Level } from '@tiptap/extension-heading';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';

import { useFocusProvider } from '../FocusContext';
import { useRichText } from '../RichtextContex';

export const Heading = () => {
  const levelArray = [1, 2, 3, 4, 5, 6];
  const levelArraySizes = ['2xl', 'xl', 'lg', 'md', 'sm', 'xs'];
  const { editor } = useRichText();
  const onClick = (item: number) => {
    editor
      ?.chain()
      .focus()
      .toggleHeading({ level: item as Level })
      .run();
  };
  const toggleNormal = () => {
    editor?.chain().focus().toggleHeading({ level: current }).run();
  };
  const current = editor?.getAttributes('heading').level;
  const isActive = (item: number) => {
    return editor?.isActive('heading', { level: item as Level });
  };
  const retHeading = (item: number) => {
    return `h${item}` as unknown as ElementType<unknown>;
  };

  const defaultColor = useColorModeValue('white', 'transparent');
  const activeColor = useColorModeValue('gray.200', 'gray.500');
  const { t } = useTranslation();
  const { tabIndex, handleBlur, handleEscapingToolbar } = useFocusProvider();

  return (
    <Menu>
      <MenuButton
        as={Button}
        rightIcon={<FaChevronDown />}
        size="sm"
        onKeyDown={(event) => {
          event.stopPropagation();
          if (event.key === 'Escape') {
            handleEscapingToolbar();
          }
        }}
        onBlur={handleBlur}
        tabIndex={tabIndex}
      >
        {`${t('global:richtext.font_size')}: `}
        {typeof current === 'number'
          ? `${t('global:richtext.heading')} ${current}`
          : t('global:richtext.normal')}
      </MenuButton>
      <MenuList>
        {levelArray.map((item) => (
          <MenuItem
            bg={isActive(item) ? activeColor : defaultColor}
            key={item}
            onClick={() => onClick(item)}
          >
            <HText as={retHeading(item)} size={levelArraySizes[item - 1]}>
              {`${t('global:richtext.heading')} ${item}`}
            </HText>
          </MenuItem>
        ))}
        <MenuItem onClick={toggleNormal} bg={defaultColor}>
          {t('global:richtext.normal')}
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
