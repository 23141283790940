import { ReactElement } from 'react';

export const SpyIcon = (): ReactElement => (
  <svg
    data-name="Layer 1"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      d="M234 173.4l13.4-35.2A9 9 0 00239 126h-33a71.3 71.3 0 0010-36v-.2c22.1-4.3 36-10.7 36-17.8 0-7.5-15.3-14.1-39.4-18.6A146 146 0 00189.8 7a18 18 0 00-22.2-5L152 9.7a18 18 0 01-16.1 0l-15.6-7.8a18 18 0 00-22.2 5 147.4 147.4 0 00-22.8 46.5C51.4 58 36 64.5 36 72c0 7 14 13.4 36 17.8v.2a71.4 71.4 0 0010 36H49.7a9 9 0 00-8.3 12.5l14.5 33.9A75.4 75.4 0 0018 237.6v25.2A25.2 25.2 0 0043.2 288h201.6a25.2 25.2 0 0025.2-25.2v-25.2a75.4 75.4 0 00-36-64.2zM117 270L93.6 162l27.9 18 13.5 22.5zm54 0l-18-67.5 13.5-22.5 28-18zm23.5-168c-2.2 6.8-4 14-9.3 18.9-5.7 5.2-27 12.6-36-14-1.6-4.8-8.7-4.8-10.3 0-9.6 28.1-31.5 18.1-36 14-5.4-5-7.2-12.1-9.3-18.8-.5-1.4-3.5-3.2-3.5-3.3v-6a432.2 432.2 0 0054 3.2 422.9 422.9 0 0054-3.2v6c-.1 0-3.2 1.8-3.6 3.3z"
      fill="#010101"
    />
  </svg>
);
