/* eslint-disable @typescript-eslint/no-explicit-any */
import { ComponentProps } from 'react';
import { SystemStyleObject } from '@chakra-ui/react';
import { ChakraStylesConfig, StylesConfig } from 'chakra-react-select';

export const selectStyles: ChakraStylesConfig<any, false | true> & {
  menuPortal: (
    provided: SystemStyleObject,
    state: ComponentProps<any>
  ) => SystemStyleObject;
} = {
  menuPortal: (provided) => ({
    ...provided,
    zIndex: 8000,
  }),
  option: (provided) => ({ ...provided, whiteSpace: 'pre-wrap' }),
};

export const chakraSelectStyles: StylesConfig<any, false | true> = {
  menuPortal: (provided) => ({ ...provided, zIndex: 1042 }),
};
export const modalSelect: StylesConfig<any, false | true> = {
  menuPortal: (provided) => ({ ...provided, zIndex: 8000 }),
};
