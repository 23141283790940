import { ReactElement } from 'react';

export const NoteIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 990.46 866.54"
    fill="currentColor"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="M495.29 0C221.79 0 .13 180.08.13 402.32c0 92.07 38.49 176.41 102.32 244.3C73.63 722.83 13.67 787.43 12.7 788.2a46.44 46.44 0 0 0 33.85 78.34c119 0 212.77-49.71 269.05-89.56a597.14 597.14 0 0 0 179.69 27.66c273.51 0 495.17-180.07 495.17-402.32S768.8 0 495.29 0Zm0 711.8a502.75 502.75 0 0 1-151.64-23.4l-43.91-13.93L262 701.16c-27.65 19.54-65.57 41.4-111.21 56.1 14.12-23.41 27.85-49.71 38.49-77.76l20.5-54.35L170 583c-35-37.33-77-99-77-180.66 0-170.6 180.47-309.48 402.32-309.48s402.3 138.86 402.3 309.46S717.15 711.8 495.29 711.8Z" />
    <path d="M565 546.9a68.5 68.5 0 1 1-68.5-68.5 68.58 68.58 0 0 1 68.5 68.5ZM435.93 198.58l11.64 232.9A20.56 20.56 0 0 0 468.1 451h56.8a20.56 20.56 0 0 0 20.53-19.52l11.64-232.9A20.55 20.55 0 0 0 536.55 177h-80.1a20.55 20.55 0 0 0-20.52 21.58Z" />
  </svg>
);
