import { makeListener } from 'BootQuery/Assets/js/socket-event-listener';

import { Call } from '../../../types/call';
import { CallResponse } from '../types';
import { dialerStore } from './pbx';

const listener = makeListener('dialerUtils');

export async function dial(
  dest: string,
  waitReady = false
): Promise<CallResponse> {
  if (waitReady) {
    await dialerStore.getState().init();
  }

  return dialerStore.getState().call(dest, { waitReady });
}

function matchCall(resp: CallResponse, ev: Call): boolean {
  if (resp.type === 'pbx') {
    return ev.callId === resp.callId;
  }

  return ev.sourcePhonePoint.phonePoint.sipCallId === resp.sipCallId;
}

export function awaitCall(dialed: CallResponse): Promise<Call> {
  return new Promise((resolve, reject) => {
    let subHandle: string;

    const waitFor = dialed.type === 'pbx' ? dialed.timeout : 60;
    const timeout = window.setTimeout(() => {
      listener().unsubscribeWebSocket(subHandle);
      reject(new Error('Timeout waiting for call'));
    }, waitFor * 1000);
    subHandle = listener().subscribeWebSocket(
      ['telephony/callStart', 'telephony/callUpdate'],
      (ev: Call) => {
        if (matchCall(dialed, ev)) {
          window.clearTimeout(timeout);
          listener().unsubscribeWebSocket(subHandle);
          resolve(ev);
        }
      }
    );
  });
}

export function pickup(callId: string): void {
  dialerStore.getState().pickup(callId);
}
export function redirectRinging(callId: string): void {
  dialerStore.getState().redirectRinging(callId);
}

export function spy(userNumber: string): void {
  dialerStore.getState().spy(userNumber);
}
export function whisper(userNumber: string): void {
  dialerStore.getState().whisper(userNumber);
}
export function barge(userNumber: string): void {
  dialerStore.getState().barge(userNumber);
}
