import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { chakraComponents, SingleValueProps } from 'chakra-react-select';

import { ContactSelectOption } from '../types';
import { CSIconForContactType } from '../utils';

export const ContactValue = (props: SingleValueProps<ContactSelectOption>) => {
  const Icon = useMemo(
    () => (props.data.type ? CSIconForContactType(props.data.type) : null),
    [props.data.type]
  );
  if (props) {
    return (
      <chakraComponents.SingleValue {...props}>
        <HStack w="full">
          <Box>{Icon}</Box>
          <Box>{props.data.label}</Box>
        </HStack>
      </chakraComponents.SingleValue>
    );
  }

  return <></>;
};
