import {
  Children,
  createRef,
  Fragment,
  isValidElement,
  ReactNode,
  RefObject,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { Box, Grid, GridProps, useColorModeValue } from '@chakra-ui/react';
import { omit } from 'lodash-es';

import {
  ResizableColumn,
  useResizableHeaders,
} from '../Table/use-resizable-headers';

type ContainerRef = RefObject<HTMLDivElement>;

interface Props extends GridProps {
  children: ReactNode;
}
/**
 * @param children - Any React Component. Wrap it in ListingGridItem if you need to give the
 * component info about min/max/initial widths.
 * if no info about initial width is given will default to 1fr.
 * @returns a grid that renders resizers between components.
 */
export const ListingGrid = ({ children, ...otherProps }: Props) => {
  const bgColor = useColorModeValue('gray.400', 'brand.mediumGray');
  const active = useColorModeValue('gray.600', 'brand.lightGray');
  const childrenProps = useMemo(() => {
    return Children.toArray(children).map((child) => {
      if (isValidElement(child)) {
        return { ...child?.props };
      }

      return { children: child };
    });
  }, [children]);

  const wrapperRefs = useRef<ContainerRef[]>([]);
  if (wrapperRefs.current.length !== childrenProps.length) {
    wrapperRefs.current = Array(childrenProps.length)
      .fill(null)
      .map((_ref, idx) => wrapperRefs.current[idx] ?? createRef());
  }

  const wrapperRefsVal = wrapperRefs.current;
  const wrapperRefParsed: ResizableColumn[] = useMemo(() => {
    return wrapperRefsVal.map((ref, idx) => {
      const OBJ: ResizableColumn = { ref };
      const child = childrenProps[idx] ?? {};
      const { minWidth, maxWidth, initialWidth } = child;
      if (minWidth !== undefined) {
        OBJ.minWidth = minWidth;
      }
      if (maxWidth !== undefined) {
        OBJ.maxWidth = maxWidth;
      }
      if (initialWidth !== undefined) {
        OBJ.initialWidth = initialWidth;
      }

      return OBJ;
    });
  }, [childrenProps, wrapperRefsVal]);

  const [columnWidths, setColumnWidths, onResizerPointerDown, initialColumns] =
    useResizableHeaders(wrapperRefParsed);
  const templateColumns = columnWidths
    ? genTemplateColumns(columnWidths)
    : initialColumns;

  useEffect(() => {
    setColumnWidths(null);
  }, [childrenProps.length, setColumnWidths]);

  return (
    <Grid
      templateColumns={['1fr', '1fr', templateColumns]}
      h="fullNoNavbar"
      maxH="fullNoNavbar"
      w="full"
      {...otherProps}
    >
      {childrenProps.map((props, idx) => {
        const otherProps = omit(props, [
          'minWidth',
          'maxWidth',
          'initialWidth',
        ]);

        return (
          <Fragment key={idx}>
            <Box
              overflowY="auto"
              ref={wrapperRefs.current[idx]}
              {...otherProps}
            />

            {idx < childrenProps.length - 1 && (
              <Box
                display={{ sm: 'none', md: 'block' }}
                h="full"
                cursor="col-resize"
                onPointerDown={(ev) => {
                  onResizerPointerDown(ev, idx);
                }}
                bgColor={bgColor}
                _active={{
                  bgColor: active,
                }}
              />
            )}
          </Fragment>
        );
      })}
    </Grid>
  );
};

function genTemplateColumns(columns: number[]): string {
  return columns
    .map((w) => (Number.isNaN(w) ? '200px' : `${w}fr`))
    .join(' 2px ');
}
