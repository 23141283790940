import {
  Box,
  Button,
  HStack,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { useModalInstance } from './ModalInstanceContext';

interface Props {
  confirmAction: () => void;
  preventActionDesc: string;
}
export const PreventSubmitDefaultModal = ({
  confirmAction,
  preventActionDesc,
}: Props) => {
  const { t } = useTranslation();
  const { closeWithNoCallback } = useModalInstance();

  return (
    <ModalContent>
      <ModalCloseButton />
      <ModalHeader as={HStack} spacing={6}>
        <Box>{`${t('global:confirm_action')}`}</Box>
      </ModalHeader>
      <ModalBody>{preventActionDesc}</ModalBody>
      <ModalFooter>
        <Button
          variant="outline"
          colorScheme="red"
          mr={3}
          leftIcon={<FaTimes />}
          onClick={() => {
            closeWithNoCallback();
          }}
        >
          {t('global:cancel')}
        </Button>
        <Button
          variant="outline"
          leftIcon={<FaCheck />}
          colorScheme="green"
          onClick={() => {
            confirmAction();
            closeWithNoCallback();
          }}
        >
          {t('global:submit')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
