import { createContext } from 'react';

export interface IPhonebookListContext {
  refetch: () => void;
}

export const PhonebookListContext = createContext<IPhonebookListContext>({
  refetch: () => {
    /* No context */
  },
});
