import { ReactElement, useCallback, useState } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaListAlt } from 'react-icons/fa';

import { FormActions } from '@bq/components/FormActions';

import { TicketSelect } from '../TicketSelect';
import { TicketBaseInfo } from '../types';
import { TicketPickerModalProps } from './types';

export const TicketPickerModal = ({
  isOpen,
  onSubmit,
  onCancel,
  options,
  formState,
}: TicketPickerModalProps): ReactElement => {
  const { t } = useTranslation('Ticketing');

  const [selected, setSelected] = useState<TicketBaseInfo | null>(null);

  const handleSubmit = useCallback(() => {
    if (!selected) {
      throw new Error('Tried to submit ticket picker without selected ticket');
    }

    setSelected(null); // Clear selection on close
    onSubmit(selected, false);
  }, [onSubmit, selected]);

  const handleCancel = useCallback(() => {
    setSelected(null); // Clear selection before closing
    onCancel();
  }, [onCancel]);

  return (
    <Modal size="6xl" isOpen={isOpen} onClose={onCancel}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <FaListAlt style={{ display: 'inline' }} />
          &nbsp;
          {options?.title ? options.title : t('Ticketing:pick_ticket')}
        </ModalHeader>
        <ModalBody>
          <TicketSelect
            value={selected}
            onChange={setSelected}
            excludedTicketIds={options?.notTicketId}
          />
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <FormActions
            onSubmit={handleSubmit}
            onCancel={handleCancel}
            state={formState}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
