import { ConvertUnits } from 'app/Modules/Products/Assets/js/use-convert-units';
import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { ApiItem, LoadedItem } from '../../types';

interface GetProductsParams {
  params?: GetListingParams;
}
export const getProducts = async (
  { params }: GetProductsParams,
  convertUnits: ConvertUnits
): Promise<ListingResponse<LoadedItem>> => {
  const { data } = await Api.get<ListingResponse<ApiItem>>('/api/products', {
    params,
  });

  return {
    ...data,
    data: data.data.map((item) => parseApiItem(convertUnits, item)),
  };
};

function parseApiItem(convertUnits: ConvertUnits, item: ApiItem): LoadedItem {
  if (item.productType === 'good') {
    const netWeightKg = convertUnits(
      'mass',
      'kg',
      item.good.netWeightUnit?.symbol ?? 'kg',
      item.good.netWeightKg ?? 0
    ).value;
    const grossWeightKg = convertUnits(
      'mass',
      'kg',
      item.good.netWeightUnit?.symbol ?? 'kg',
      item.good.grossWeightKg ?? 0
    ).value;
    const lengthMeters = convertUnits(
      'distance',
      'm',
      item.good.dimensionUnit?.symbol ?? 'm',
      item.good.lengthMeters ?? 0
    ).value;
    const widthMeters = convertUnits(
      'distance',
      'm',
      item.good.dimensionUnit?.symbol ?? 'm',
      item.good.widthMeters ?? 0
    ).value;
    const heightMeters = convertUnits(
      'distance',
      'm',
      item.good.dimensionUnit?.symbol ?? 'm',
      item.good.heightMeters ?? 0
    ).value;

    return {
      ...item,
      good: {
        ...item.good,
        countryOfOriginIso: item.good.countryOfOrigin?.isoCode,
        netWeightKg,
        grossWeightKg,
        lengthMeters,
        widthMeters,
        heightMeters,
      },
    };
  }

  return item as LoadedItem;
}
