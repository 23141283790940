import { ReactElement } from 'react';

export const WhisperIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M198.42 131a12.54 12.54 0 11-25.08 0 35.83 35.83 0 1171.66 0 12.54 12.54 0 11-25.08 0 10.75 10.75 0 00-21.5 0zm10.75-78.84A78.92 78.92 0 00130.34 131a12.54 12.54 0 1025.08 0 53.75 53.75 0 01107.5 0c0 33.66-31.81 31.49-32.25 64.33v.17a23.32 23.32 0 01-23.29 23.29 12.54 12.54 0 000 25.08 48.42 48.42 0 0048.37-48.27C256 180.15 288 174 288 131a78.92 78.92 0 00-78.83-78.87zM17.5 131.15a39.49 39.49 0 010 40.12A7.43 7.43 0 016 172.86l-3.68-3.53a7.45 7.45 0 01-1.4-8.89A19.79 19.79 0 00.88 142a7.44 7.44 0 011.4-8.88L6 129.55a7.43 7.43 0 0111.5 1.6zM74.11 74.91a118.57 118.57 0 010 152.59 7.42 7.42 0 01-10.82.58l-3.58-3.43a7.41 7.41 0 01-.57-10.1 98.82 98.82 0 000-126.69 7.41 7.41 0 01.57-10.1l3.58-3.43a7.43 7.43 0 0110.82.58z"
    />
    <path
      fill="currentColor"
      d="M45.76 102.62a79.07 79.07 0 010 97.17 7.43 7.43 0 01-11 .8l-3.59-3.44a7.39 7.39 0 01-.79-9.82 59.3 59.3 0 000-72.24 7.4 7.4 0 01.79-9.83l3.59-3.44a7.44 7.44 0 0111 .8z"
    />
  </svg>
);
