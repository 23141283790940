import { ForwardedRef, forwardRef, ReactElement } from 'react';
import {
  Box,
  Flex,
  Input,
  List,
  ListItem,
  Text,
  useMultiStyleConfig,
  usePopper,
} from '@chakra-ui/react';
import { useQuery } from '@tanstack/react-query';
import { useCombobox } from 'downshift';
import { useTranslation } from 'react-i18next';

import { formatNumber } from 'app/assets/js/tsutil';
import { Api } from 'BootQuery/Assets/js/api';
import { fixHomeEnd } from 'BootQuery/Assets/js/downshift-util';

import { Contact } from '../../../types';

interface SearchResult {
  type: 'user' | 'person' | 'company' | 'companyLocation' | 'companyDepartment';
  name: string;
  phoneNumber: string;
}

const hasLetters = (str: string): boolean => /\p{L}/gu.test(str);

async function searchContacts(search: string): Promise<SearchResult[]> {
  if (!search.length || !hasLetters(search)) {
    return [];
  }
  const { data } = await Api.get<Contact[]>('/api/phonebook/contacts', {
    params: {
      $search: search,
    },
  });

  return data.reduce<SearchResult[]>((results, contact) => {
    const mapped: SearchResult[] = (contact.phoneNumbers ?? []).map((num) => ({
      phoneNumber: num.phoneNumber.phoneNumberE164,
      type: contact.type,
      name: contact.name,
    }));

    return [...results, ...mapped];
  }, []);
}
interface DialInputProps {
  value: string;
  onChange: (value: string) => void;
  onSubmit?: () => void;
}

type InputRefType = ForwardedRef<HTMLInputElement>;
const DialInput = forwardRef(
  (
    { value, onChange, onSubmit }: DialInputProps,
    ref: InputRefType
  ): ReactElement => {
    const { t } = useTranslation('Telephony');
    const { item: itemStyle, list: listStyle } = useMultiStyleConfig(
      'Menu',
      {}
    );
    // eslint-disable-next-line
    const itemActiveStyle = (itemStyle as any)._hover;

    const { data: results } = useQuery({
      queryKey: ['dialerSearch', value],
      queryFn: () => searchContacts(value),
    });
    const items = results ?? [];

    const {
      isOpen,
      getMenuProps,
      getInputProps,
      getComboboxProps,
      highlightedIndex,
      getItemProps,
      openMenu,
      selectItem,
    } = useCombobox({
      items,
      inputValue: value,
      itemToString: (item) => item?.phoneNumber ?? '',
      stateReducer: (state, { type, changes }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputBlur:
            // Don't auto select item on input blur
            return { ...changes, selectedItem: state.selectedItem };
          default:
            return changes; // Otherwise leave default behaviour
        }
      },
      onInputValueChange(changes) {
        onChange(changes.inputValue ?? '');
      },
    });

    console.log(highlightedIndex);

    const showResults = isOpen && items.length > 0;
    const { ref: downshiftInputRef, ...inputProps } = getInputProps({
      value,
      onChange(ev) {
        if (!(ev.target instanceof HTMLInputElement)) {
          return;
        }
        onChange(ev.target.value);
      },
      onFocus() {
        if (!isOpen) {
          openMenu();
        }
      },
      onKeyDown: (ev) => {
        fixHomeEnd(ev);
        if (ev.key === 'Enter' && !showResults && onSubmit) {
          onSubmit();
        }
      },
    });
    const { popperRef, referenceRef } = usePopper({
      placement: 'bottom-start',
      matchWidth: true,
    });

    return (
      <Flex direction="column" {...getComboboxProps()} flexGrow={1}>
        <Input
          {...inputProps}
          ref={(inputRef) => {
            downshiftInputRef(inputRef);
            referenceRef(inputRef);
            if (typeof ref === 'function') {
              ref(inputRef);
            } else if (ref) {
              ref.current = inputRef;
            }
          }}
          minWidth="4"
          flexGrow={1}
          flexShrink={1}
          placeholder={t('Telephony:phone_number_or_name')}
          bg="white"
          color="gray.600"
        />
        <Box
          ref={popperRef}
          display={showResults ? undefined : 'none'}
          flexGrow={1}
          zIndex={100}
        >
          <List {...getMenuProps()} sx={listStyle} color="gray.600">
            {items.map((item, index) => {
              const isActive = highlightedIndex === index;

              return (
                <ListItem
                  key={index}
                  {...getItemProps({
                    item,
                    index,
                    onMouseDown: () => {
                      selectItem(item);
                    },
                  })}
                  cursor="pointer"
                  sx={{
                    ...itemStyle,
                    ...(isActive ? { ...itemActiveStyle } : {}),
                  }}
                  textAlign="left"
                >
                  <Text mb={0} noOfLines={1} fontWeight="bold">
                    {item.name}
                  </Text>
                  <Text mb={0} color="gray.500" noOfLines={1}>
                    {formatNumber(item.phoneNumber)}
                  </Text>
                </ListItem>
              );
            })}
          </List>
        </Box>
      </Flex>
    );
  }
);
DialInput.displayName = 'DialInput';

export { DialInput };
