import { useMemo } from 'react';

import { useFilterExpression } from 'BootQuery/Assets/components/FilterBar';
import { OBJ } from 'BootQuery/Assets/components/Table/types';
import { useFilters } from 'BootQuery/Assets/js/use-filters';

import { useVisibleColumns } from '../Table';
import { useCustomColumns, useLoadColumns } from './column-utils';
import {
  useColumnVisibility,
  useDensity,
  useDisplayMode,
  useLimit,
  useSort,
} from './generic-settings';
import { UseListingSettingsProps, UseListingSettingsReturn } from './types';
import { useFilteredPage } from './use-filtered-page';
import { sortWithUndefined, useFilterTypes } from './utils';

export const useListingSettings = <ColumnType extends OBJ>({
  filterTypes,
  listingName,
  columns,
  customFields = [],
  defaults,
  otherFilters,
  viewName = 'table',
  storage = 'query',
}: UseListingSettingsProps<ColumnType>): UseListingSettingsReturn<ColumnType> => {
  const allFilterTypes = useFilterTypes(filterTypes, customFields);
  const [filterValues, setFilterValues, search, setSearch] = useFilters(
    allFilterTypes,
    storage
  );
  const { data: filters } = useFilterExpression(
    allFilterTypes,
    [...(otherFilters ?? []), ...filterValues],
    search
  );
  const [visibleColumns, setVisibleColumns] = useColumnVisibility<
    keyof ColumnType
  >(listingName, viewName, defaults?.visibleColumns);

  const customColumns = useCustomColumns<ColumnType>(customFields);
  const loadedColumns = useLoadColumns<ColumnType>(columns);

  const allColumns = useMemo(() => {
    return [...loadedColumns, ...customColumns].sort((a, b) => {
      return sortWithUndefined(a.order, b.order);
    });
  }, [customColumns, loadedColumns]);

  const [displayMode, setDisplayMode] = useDisplayMode(
    listingName,
    defaults?.displayMode
  );
  const [density, setDensity] = useDensity(
    listingName,
    viewName,
    defaults?.density
  );
  const allColumnsToShow = useVisibleColumns(allColumns, visibleColumns);
  const loadedColumnsToShow = useVisibleColumns(loadedColumns, visibleColumns);
  const customColumnsToShow = useVisibleColumns(customColumns, visibleColumns);

  const [sort, setSort] = useSort(listingName, viewName, defaults?.sort ?? []);
  const [limit, setLimit] = useLimit(listingName, viewName, defaults?.limit);
  const [page, setPage] = useFilteredPage(storage, filters);

  return {
    filterValues,
    setFilterValues,
    search,
    setSearch,
    filters,
    visibleColumns,
    setVisibleColumns,
    allColumns,
    displayMode,
    setDisplayMode,
    limit,
    setLimit,
    page,
    setPage,
    columnsToShow: allColumnsToShow,
    density,
    setDensity,
    sort,
    setSort,
    filterTypes: allFilterTypes,
    customColumns: {
      all: customColumns,
      visible: customColumnsToShow,
    },
    loadedColumns: {
      all: loadedColumns,
      visible: loadedColumnsToShow,
    },
  };
};
