import {
  Dispatch,
  forwardRef,
  ReactElement,
  ReactNode,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Box, BoxProps, Flex, HStack, Input, Text } from '@chakra-ui/react';
import { DragOverlay, useDraggable, useDroppable } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useQuery } from '@tanstack/react-query';
import { FaGripVertical } from 'react-icons/fa';

import { DeleteButton } from '@bq/components/DeleteButton';
import { DndItemWithChildren } from '@bq/components/dnd-util';
import {
  BaseItem,
  BaseItemBase,
  DndContainersProvider,
  useActiveItem,
} from '@bq/components/DndContainers';
import { getContacts } from '@bq/components/FormFields/Contact/API/getContacts';
import { uniqid } from 'app/assets/js/tsutil';
import { useChangeEffect } from 'BootQuery/Assets/js/use-change-effect';

import { Contact } from '../../types';

interface Props {
  value: Contact[];
  onChange: Dispatch<SetStateAction<Contact[]>>;
}

type ContactData = { type: 'container' } | Contact;
type ContactItem = BaseItem<ContactData>;
type WithChildren = DndItemWithChildren<ContactData, BaseItemBase<ContactData>>;
type RootVal = [WithChildren, WithChildren];

export const SpeedDialEdit = ({
  value: inValue,
  onChange,
}: Props): ReactElement => {
  const [value, setValue] = useState<ContactItem[]>([
    {
      id: 'availableContacts',
      hasChildren: true,
      children: [],
      content: { type: 'container' },
    },
    {
      id: 'pinnedContacts',
      hasChildren: true,
      children: initialItems(inValue),
      content: { type: 'container' },
    },
  ]);
  useChangeEffect(value, () => {
    const [_availableContacts, pinnedContacts] = value as RootVal;
    onChange(
      pinnedContacts.children.map((contact) => {
        if (contact.content.type === 'container') {
          throw new Error('Container ended up in contact list');
        }

        return contact.content;
      })
    );
  });
  const [availableContacts, pinnedContacts] = value as RootVal;
  const setAvailable = useCallback((newAvailable: ContactItem[]) => {
    setValue((prev) => {
      const [availableContacts, pinnedContacts] = prev as RootVal;

      return [
        {
          ...availableContacts,
          children: newAvailable,
        },
        pinnedContacts,
      ] as ContactItem[];
    });
  }, []);
  const setPinned = useCallback((replace: SetStateAction<ContactItem[]>) => {
    setValue((prev) => {
      const [availableContacts, pinnedContacts] = prev as RootVal;

      const children =
        typeof replace === 'function'
          ? replace(pinnedContacts.children)
          : replace;

      return [
        availableContacts,
        {
          ...pinnedContacts,
          children,
        },
      ] as ContactItem[];
    });
  }, []);

  return (
    <DndContainersProvider<ContactData> items={value} onChange={setValue}>
      <HStack w="full" alignItems="stretch">
        <AvailableContacts
          setAvailable={setAvailable}
          items={availableContacts.children}
        />
        <PinnedContacts items={pinnedContacts.children} setPinned={setPinned} />
      </HStack>
      <SpeedDialEditorDragOverlay />
    </DndContainersProvider>
  );
};

interface ContactProps {
  items: ContactItem[];
}

interface AvailableContactsProps extends ContactProps {
  setAvailable: (items: ContactItem[]) => void;
}

const AvailableContacts = ({
  items,
  setAvailable,
}: AvailableContactsProps): ReactElement => {
  const [search, setSearch] = useState('');
  const { data: searchRes } = useQuery({
    queryKey: ['Telephony.Operator.speedDialSearch', search.trim()],
    queryFn: () => getContacts(search.trim(), ['person', 'company']),
  });
  useEffect(() => {
    if (!searchRes) {
      return;
    }

    setAvailable(
      searchRes.map((contact) => {
        if (contact.type !== 'person' && contact.type !== 'company') {
          throw new Error('Item must be person or company');
        }

        return { id: uniqid(), content: contact, mode: 'clone' };
      })
    );
  }, [searchRes, setAvailable]);

  return (
    <Box flex="1 1 50%">
      <Input
        value={search}
        onChange={(ev) => setSearch(ev.currentTarget.value)}
        mb="3"
      />
      <Box overflow="hidden">
        {items.map((item) => (
          <AvailableContactItem
            key={`${item.content.type}-${item.id}`}
            {...item}
          />
        ))}
      </Box>
    </Box>
  );
};

interface PinnedContactProps extends ContactProps {
  setPinned: Dispatch<SetStateAction<ContactItem[]>>;
}

const PinnedContacts = ({
  items,
  setPinned,
}: PinnedContactProps): ReactElement => (
  <SortableContext
    items={items}
    id="pinnedContacts"
    strategy={verticalListSortingStrategy}
  >
    <PinnedContactsContainer items={items} setPinned={setPinned} />
  </SortableContext>
);

const PinnedContactsContainer = ({
  items,
  setPinned,
}: PinnedContactProps): ReactElement => {
  const { isOver, setNodeRef } = useDroppable({
    id: 'container:pinnedContacts',
  });

  return (
    <Box
      ref={setNodeRef}
      flex="12 1 50%"
      minH="36"
      bg="gray.100"
      borderRadius="base"
      pb="6"
      borderWidth="medium"
      borderColor={isOver ? 'gray.200' : 'transparent'}
      borderStyle="dashed"
      overflow="hidden"
    >
      {items.map((item) => (
        <PinnedContactItem
          key={`${item.id}`}
          item={item}
          setPinned={setPinned}
        />
      ))}
    </Box>
  );
};

type ContactItemProps = Omit<BoxProps, 'id' | 'content'> &
  ContactItem & {
    fullDrag?: boolean;
    isDragging?: boolean;
    children?: ReactNode;
  };

const ContactDragDisplay = forwardRef<HTMLDivElement, ContactItemProps>(
  ({ id, content, isDragging, fullDrag, children, ...props }, ref) => {
    if (content.type === 'container') {
      throw new Error('Container ended up in contact list');
    }

    const cursorStyle = fullDrag
      ? { cursor: isDragging ? 'grabbing' : 'grab' }
      : {};
    const hoverStyle = fullDrag ? { background: 'gray.100' } : {};

    return (
      <Flex
        justifyContent="space-between"
        ref={ref}
        px="2"
        py="1"
        borderWidth="thin"
        borderStyle="solid"
        borderColor="gray.200"
        borderBottomStyle="none"
        background="white"
        overflow="hidden"
        _first={{
          borderTopRadius: 'base',
        }}
        _last={{
          borderBottomRadius: 'base',
          borderBottomStyle: 'solid',
        }}
        _hover={hoverStyle}
        opacity={isDragging ? 0.5 : 1.0}
        {...props}
        style={{
          ...cursorStyle,
          ...(props.style ?? {}),
        }}
      >
        {children}
      </Flex>
    );
  }
);
ContactDragDisplay.displayName = 'ContactDragDisplay';

const AvailableContactItem = (item: ContactItem): ReactElement => {
  if (item.content.type === 'container') {
    throw new Error('Container ended up in contact list');
  }

  const { id, content } = item;
  const { isDragging, listeners, attributes, transform, setNodeRef } =
    useDraggable({
      id,
      data: item,
    });

  return (
    <ContactDragDisplay
      ref={setNodeRef}
      id={id}
      content={content}
      fullDrag
      isDragging={isDragging}
      {...listeners}
      {...attributes}
      style={{
        transform: CSS.Transform.toString(transform),
      }}
    >
      {content.name}
    </ContactDragDisplay>
  );
};

interface PinnedContactItemProps {
  setPinned: Dispatch<SetStateAction<ContactItem[]>>;
  item: ContactItem;
}

const PinnedContactItem = ({
  setPinned,
  item,
}: PinnedContactItemProps): ReactElement => {
  if (item.content.type === 'container') {
    throw new Error('Container ended up in contact list');
  }

  const { id, content } = item;
  const {
    isDragging,
    listeners,
    attributes,
    transition,
    transform,
    setNodeRef,
    setActivatorNodeRef,
  } = useSortable({
    id,
    data: item,
  });

  const handleRemove = useCallback(() => {
    setPinned((prev) => prev.filter((prevItem) => prevItem.id !== id));
  }, [setPinned, id]);

  return (
    <ContactDragDisplay
      ref={setNodeRef}
      id={id}
      content={content}
      isDragging={isDragging}
      style={{
        transform: CSS.Transform.toString(transform),
        transition,
      }}
    >
      <Box
        flex="0 1 auto"
        px="2"
        py="2"
        ref={setActivatorNodeRef}
        {...listeners}
        {...attributes}
        style={{ cursor: isDragging ? 'grabbing' : 'grab' }}
      >
        <FaGripVertical />
      </Box>
      <Text
        flex="1 1 auto"
        as="span"
        whiteSpace="nowrap"
        overflow="hidden"
        textOverflow="ellipsis"
      >
        {content.name}
      </Text>
      <DeleteButton size="sm" onClick={handleRemove} />
    </ContactDragDisplay>
  );
};

const SpeedDialEditorDragOverlay = (): ReactElement => {
  const activeItem = useActiveItem<ContactData>();

  return (
    <DragOverlay>
      {activeItem && activeItem.item.content.type !== 'container' && (
        <ContactDragDisplay
          id={activeItem.item.id}
          content={activeItem.item.content}
        >
          {activeItem.item.content.name}
        </ContactDragDisplay>
      )}
    </DragOverlay>
  );
};

function initialItems(items: Contact[]): ContactItem[] {
  return items.map((item) => ({
    id: uniqid(),
    content: item,
    mode: 'move',
  }));
}
