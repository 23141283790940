export function waitLoad(): Promise<void> {
  return new Promise((resolve, _reject) => {
    if (window.Bootstrap) {
      resolve();
    } else {
      document.addEventListener('clickvoxLoad', () => resolve(), {
        once: true,
      });
    }
  });
}
