import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { ItemProgram, ItemType } from '../../types';

interface GetProgramsParams {
  itemType?: ItemType;
  params?: GetListingParams;
}
export const getPrograms = async ({ itemType, params }: GetProgramsParams) => {
  const { data } = await Api.get<ListingResponse<ItemProgram>>(
    '/api/productPrograms',
    {
      /** Make field params for item type */
      params: {
        ...params,
        filters: {
          ...params?.filters,
          ...(itemType ? { 'productType:eq': itemType } : {}),
        },
      },
    }
  );

  return data;
};
