import { QueryClient } from '@tanstack/react-query';

import {
  FieldValue,
  getFormDefinition,
  useFormDefinition,
} from 'BootQuery/Assets/components/FormEditor';

type ID = number | string;

export const getCustomEventForm = (
  queryClient: QueryClient,
  typeID: ID
): Promise<FieldValue[]> => {
  return getFormDefinition(queryClient, `CustomEventTypes.${typeID}`);
};

export const useCustomEventForm = (typeID: ID): FieldValue[] | null => {
  return useFormDefinition(`CustomEventTypes.${typeID}`);
};
