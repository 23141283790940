import { useCallback, useState } from 'react';

import { useFormState } from '@bq/components/form-state';

import { TicketBaseInfo } from '../types';
import {
  ITicketPickerContext,
  SelectTicketFn,
  SelectTicketOptions,
  TicketPickerModalProps,
  TicketSelectHandler,
} from './types';

export interface UseTicketPickerModalResult extends ITicketPickerContext {
  modalProps: TicketPickerModalProps;
}

interface SelectorState {
  onSelect: TicketSelectHandler;
  options?: SelectTicketOptions;
}

export const useTicketPickerModal = (): UseTicketPickerModalResult => {
  const [selectorState, setSelectorState] = useState<SelectorState | null>(
    null
  );
  const [formState, setFormState] = useFormState();

  const selectTicket: SelectTicketFn = useCallback(
    (onSelect, options) => {
      setFormState(null);
      setSelectorState({
        onSelect,
        options,
      });
    },
    [setFormState]
  );

  const onSubmit = useCallback(
    async (ticket: TicketBaseInfo, newlyAdded: boolean) => {
      setFormState('saving');

      selectorState?.onSelect(ticket, newlyAdded);

      setFormState('saved');
      setSelectorState(null);
    },
    [setFormState, selectorState]
  );
  const onCancel = useCallback(() => {
    setFormState(null);
    setSelectorState(null);
  }, [setFormState]);

  return {
    selectTicket,
    modalProps: {
      isOpen: selectorState !== null,
      onSubmit,
      onCancel,
      options: selectorState?.options,
      formState,
    },
  };
};
