import { createContext, PropsWithChildren, useContext } from 'react';
import { ModalContent } from '@chakra-ui/react';
import { useMutation } from '@tanstack/react-query';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';

import { useFormState } from '@bq/components/form-state';
import { useModalInstance } from '@bq/components/UseModal';
import { CompanyDepartmentFormData } from 'app/Modules/Phonebook/Assets/components/CompanyDepartmentForm/types';

import { patchCompanyDepartment, postCompanyDepartment } from './api';
import {
  CompanyDepartmentFormCtxI,
  CompanyDepartmentModalContentProps,
} from './types';

const CompanyDepartmentFormCtx =
  createContext<CompanyDepartmentFormCtxI | null>(null);

export const CompanyDepartmentFormWrapper = (
  props: PropsWithChildren<CompanyDepartmentModalContentProps>
) => {
  const [formState, setFormState] = useFormState();
  const methods = useForm({ defaultValues: props.data });

  return (
    <CompanyDepartmentFormCtx.Provider
      value={{ formState, mode: props.mode, setFormState }}
    >
      <FormProvider {...methods}>
        <CompanyDepartmentModalForm {...props} />
      </FormProvider>
    </CompanyDepartmentFormCtx.Provider>
  );
};

const CompanyDepartmentModalForm = (
  props: PropsWithChildren<CompanyDepartmentModalContentProps>
) => {
  const { handleSubmit } = useFormContext<CompanyDepartmentFormData>();
  const { setFormState } = useCompanyDepartmentFormWrapper();
  const { closeWithCallback } = useModalInstance();

  const { mutateAsync } = useMutation({
    mutationFn: (data: Partial<CompanyDepartmentFormData>) => {
      const { company, parentDepartment, ...otherData } = data;
      if (props.mode === 'create') {
        return postCompanyDepartment({
          data: {
            ...otherData,
            companyID: company?.ID,
            parentDepartmentID:
              parentDepartment === null ? null : parentDepartment?.ID,
          },
        });
      }

      return patchCompanyDepartment({
        data: {
          ...otherData,
          companyID: company?.ID,
          parentDepartmentID:
            parentDepartment === null ? null : parentDepartment?.ID,
        },
        ID: props.CompanyDepartmentID,
      });
    },
  });

  return (
    <ModalContent
      as="form"
      onSubmit={handleSubmit(
        async (data) => {
          setFormState('saving');
          const { data: newData } = await mutateAsync(data);
          setFormState('saved');
          closeWithCallback({ ...data, ...newData });
        },
        (e) => {
          console.log(e);
          setFormState('error');
        }
      )}
    >
      {props.children}
    </ModalContent>
  );
};

export const useCompanyDepartmentFormWrapper = () => {
  const ctx = useContext(CompanyDepartmentFormCtx);
  if (!ctx) {
    throw Error('Missing CompanyDepartmentFormWrapper');
  }

  return ctx;
};
