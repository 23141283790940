import { useMemo } from 'react';

import { Column, Row } from '../Table';
import { getFieldTypes } from './field-types';
import { FieldType, FieldValue } from './types';

export function customFormColumns(
  fields: FieldValue[],
  fieldTypes: FieldType[]
): Column[] {
  return fields
    .reduce((columns, field) => {
      const fieldType = fieldTypes.find((type) => type.type === field.type);
      if (fieldType?.getColumns) {
        return [...columns, ...fieldType.getColumns(field)];
      }

      return columns;
    }, [] as Column[])
    .map((col) => ({ ...col, defaultVisible: col.defaultVisible ?? false }));
}

export function useCustomFormColumns<RowType = Row>(
  fields: FieldValue[],
  fieldTypes: FieldType[] | null = null
): Column<RowType>[] {
  return useMemo(() => {
    return customFormColumns(
      fields,
      fieldTypes ?? getFieldTypes()
    ) as unknown as Column<RowType>[];
  }, [fields, fieldTypes]);
}
