import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { TicketPriority } from '../../types';

export const getTicketPriorities = async (params: GetListingParams = {}) => {
  const { data } = await Api.get<TicketPriority[]>(
    '/api/ticketing/priorities',
    {
      params,
    }
  );

  return data;
};
