import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { ItemProgram, ItemType } from '../../types';
import { getPrograms } from './getPrograms';

interface SearchProgramsParams {
  itemType?: ItemType;
  search?: string;
  params?: GetListingParams;
}
export const searchPrograms = async ({
  search,
  itemType,
  params,
}: SearchProgramsParams): Promise<ItemProgram[]> => {
  const products = await getPrograms({
    itemType,
    params: { filters: { $search: search }, ...params },
  });

  return products.data;
};
