import { FaBuilding, FaIdCard, FaMapMarkerAlt } from 'react-icons/fa';
import { IconType } from 'react-icons/lib';

import {
  ContactInfo,
  ContactType,
} from 'app/Modules/Phonebook/Assets/js/types';

import { Contact } from '../../types';

export function iconForContactType(type: ContactType): IconType {
  switch (type) {
    case 'company':
      return FaBuilding;
    case 'companyLocation':
      return FaMapMarkerAlt;
    default:
      return FaIdCard;
  }
}

export const contactName = (contact: Contact): string => {
  if (contact.type === 'user') {
    return contact.person ? contact.person.fullName : contact.name;
  }

  return contact.name;
};

export function contactLink(contact: ContactInfo): string | null {
  switch (contact.type) {
    case 'company':
      return `/phonebook/companies/${contact.contact.ID}`;
    case 'companyLocation':
      return `/phonebook/companies/${contact.contact.company.ID}/locations/${contact.contact.ID}/edit`;
    case 'user':
      return contact.contact.person
        ? `/phonebook/people/${contact.contact.person.ID}`
        : null;
    default:
      return `/phonebook/people/${contact.contact.ID}`;
  }
}
