import { useQuery } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';

import { DepartmentsTreeSettings } from './types';

export const getDepartmentsTreeSettings =
  async (): Promise<DepartmentsTreeSettings> => {
    const { data } = await Api.get<DepartmentsTreeSettings>(
      '/api/phonebook/departmentsTreeSettings'
    );

    return data;
  };

export const useDepartmentsTreeSettings = () => {
  return useQuery<DepartmentsTreeSettings>({
    queryKey: ['departmentsTreeSettings'],
    queryFn: async () => {
      const data = await getDepartmentsTreeSettings();

      return data;
    },
  });
};
