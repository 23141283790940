import { DeviceInfo } from '../types';

export async function checkHasPermissions(): Promise<boolean | null> {
  if (navigator.permissions) {
    // Try to do things the nice way. Of course this one is not well supported!
    try {
      const permission = await navigator.permissions.query({
        name: 'microphone' as PermissionName,
      });

      return permission.state === 'granted';
    } catch (err) {
      // Querying microphone permission not supported,
      // let it fall back to enumerating devices and checking labels
    }
  }

  // This stuff actually works in all browsers
  const devs = await navigator.mediaDevices.enumerateDevices();
  if (devs.length === 0) {
    return null; // Unknown state, because we couldn't guess from device list (no input devices)
  }

  return !!devs[0].label; // Empty label means no permissions. Brilliant!
}

export async function getAvailableDevices(): Promise<DeviceInfo[] | null> {
  try {
    const devs = await navigator.mediaDevices.enumerateDevices();

    return devs.map(({ kind, label, deviceId }) => ({ kind, label, deviceId }));
  } catch {
    console.warn('No userMedia permission :(');

    return null;
  }
}

export function checkCanSetOutputs(): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return typeof (HTMLMediaElement.prototype as any).setSinkId === 'function';
}

export function checkCanSelectAudioOutput(): boolean {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { selectAudioOutput } = MediaDevices.prototype as any;

  return typeof selectAudioOutput === 'function';
}
