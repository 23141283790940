import { memo, ReactElement } from 'react';
import { MenuItem } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChartBar } from 'react-icons/fa';
import { Link as RouterLink } from 'react-router-dom';

import {
  HeadingProps,
  ListHeading,
} from 'BootQuery/Assets/components/ListHeading';

import { ExportMenuItem } from '../../../../../../BootQuery/Assets/components/Export';
import { CallListPermissions } from './call-list-permissions';
import { TableRefresh } from './TableRefresh';

interface ExtraCallProps {
  permissions: CallListPermissions;
  refetch: () => void;
  newRows: number;
  isLoading?: boolean;
  withExport?: boolean;
}
type Props = HeadingProps & ExtraCallProps;

const CallListHeading = memo(
  ({
    permissions,
    refetch,
    newRows,
    isLoading,
    menuProps,
    withExport = false,
    ...props
  }: Props): ReactElement => {
    const { t } = useTranslation();

    return (
      <ListHeading
        {...props}
        menuProps={{
          ...menuProps,
          children: (
            <>
              {withExport && <ExportMenuItem />}
              {permissions.reports && (
                <MenuItem as={RouterLink} to="/telephony/reports">
                  <FaChartBar />
                  &nbsp;
                  {t('global:reports.reports')}
                </MenuItem>
              )}
            </>
          ),
        }}
        Actions={
          <>
            {props.Actions}
            {menuProps?.refreshMethod?.value === 'manual' && (
              <TableRefresh
                onRefresh={refetch}
                newRows={newRows}
                isRefreshing={isLoading}
              />
            )}
          </>
        }
      />
    );
  }
);
CallListHeading.displayName = 'ListHeading';

export { CallListHeading };
