import { ParsedQs, parse as parseQuery } from 'qs';

import { FilterType, FilterTypes, FilterValue } from '@bq/components/FilterBar';

export function simplifyFilter(filter: FilterValue) {
  const clean = { ...filter };
  if (clean.operator === null) {
    delete clean.operator;
  }

  return clean;
}

type QueryValueType = string | ParsedQs | string[] | ParsedQs[] | undefined;

function parseValue(
  filterType: FilterType,
  value: QueryValueType,
  operator: string | null,
  filterTypes: FilterTypes
) {
  if (value === '' || value === null || value === undefined) {
    return null;
  }

  if (filterType.fromQueryString) {
    return filterType.fromQueryString(value, operator, filterTypes);
  }

  return value;
}

function filtersFromQuery(
  query: ParsedQs,
  filterTypes: FilterTypes
): FilterValue[] {
  if (!query.filters || !Array.isArray(query.filters)) {
    return [];
  }

  return query.filters.map((filterVal) => {
    if (typeof filterVal === 'string') {
      throw new Error(`Invalid query filter: ${filterVal}`);
    }

    const { value, filter, operator, ...other } = filterVal;

    if (typeof filter !== 'string' || filter == '') {
      throw new Error('Filter type missing');
    }

    const filterDef = filterTypes[filter];
    if (!filterDef) {
      throw new Error(`Unknown filter "${filter}"`);
    }

    const parsedOperator =
      typeof operator !== 'string' || operator === '' ? null : operator;

    return {
      ...other,
      filter,
      operator: parsedOperator,
      value: parseValue(filterDef, value, parsedOperator, filterTypes),
    };
  });
}

function searchFromQuery(query: ParsedQs): string {
  return typeof query.search === 'string' ? query.search : '';
}

interface ParsedRet {
  filters: FilterValue[];
  search: string;
}

export function filtersFromQueryStr(
  queryStr: string,
  filterTypes: FilterTypes
): ParsedRet {
  const query = parseQuery(queryStr, { ignoreQueryPrefix: true });

  const filters = filtersFromQuery(query, filterTypes);
  const search = searchFromQuery(query);

  return { filters, search };
}
