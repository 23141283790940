import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { DeviceInfo } from '../types';
import {
  checkCanSelectAudioOutput,
  checkCanSetOutputs,
  checkHasPermissions,
  getAvailableDevices,
} from './media-devices';

export interface UseMediaDevicesResult {
  devices: DeviceInfo[];
  audioInputDevices: DeviceInfo[];
  audioOutputDevices: DeviceInfo[];
  hasPermissions: boolean | null;
  canSetOutputs: boolean;
  selectAudioOutputSupported: boolean;
}

export const getMediaDeviceInfo = async (): Promise<UseMediaDevicesResult> => {
  const devices = (await getAvailableDevices()) ?? [];

  return {
    devices,
    audioInputDevices: devices.filter((dev) => dev.kind === 'audioinput'),
    audioOutputDevices: devices.filter((dev) => dev.kind === 'audiooutput'),
    hasPermissions: await checkHasPermissions(),
    canSetOutputs: checkCanSetOutputs(),
    selectAudioOutputSupported: checkCanSelectAudioOutput(),
  };
};

export function useMediaDevices(): UseQueryResult<UseMediaDevicesResult> {
  return useQuery({
    queryKey: ['useMediaDevices'],
    queryFn: () => getMediaDeviceInfo(),
    // The audio permissions popup is a seperate window.
    // Clicking accept causes a window focus change
    // By refetching on window focus we get updated when the permissions change
    refetchOnWindowFocus: true,
  });
}
