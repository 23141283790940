import { addition, division, multiplication, subtraction } from './arithmetics';
import {
  toInternal,
  toNumber,
  toSplitNumberRounding,
  toString,
} from './converters';
import {
  PriceOptions,
  PriceOptionsWithDefaults,
  ValidValueTypes,
} from './types';

const defaults: PriceOptionsWithDefaults = {
  roundingDecimals: 2,
  calculatingDecimals: 4,
  valueIsInternal: false,
};
export class Price {
  options: PriceOptionsWithDefaults;

  #value: bigint;

  constructor(value: ValidValueTypes, options?: PriceOptions) {
    this.options = { ...defaults, ...options };
    this.#value =
      this.options.valueIsInternal && typeof value === 'bigint'
        ? value
        : (toInternal(value, this.options) ?? 0n);
  }

  get asNumber(): number {
    return toNumber(this.#value, this.options);
  }

  get asString(): string {
    return toString(
      toSplitNumberRounding(this.#value, this.options),
      this.options
    );
  }

  add(values: ValidValueTypes[]): Price {
    return new Price(addition(this.#value, values, this.options), {
      ...this.options,
      valueIsInternal: true,
    });
  }

  sub(values: ValidValueTypes[]): Price {
    return new Price(subtraction(this.#value, values, this.options), {
      ...this.options,
      valueIsInternal: true,
    });
  }

  mul(values: ValidValueTypes[]): Price {
    return new Price(multiplication(this.#value, values, this.options), {
      ...this.options,
      valueIsInternal: true,
    });
  }

  div(values: ValidValueTypes[]): Price {
    return new Price(division(this.#value, values, this.options), {
      ...this.options,
      valueIsInternal: true,
    });
  }
}
