import parsePhoneNumberFromString from 'libphonenumber-js/max';

import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';

import { CompanyFormData } from './CompanyForm';
import { ParsedCompanyFormData } from './CompanyForm/types';
import { formatNationalNumber } from './NationalNumber';
import { PersonFormData } from './PersonForm';
import { ParsedPersonFormData } from './PersonForm/types';
import { ApiNumber } from './types';

export function firstMobileNumber(numbers: ApiNumber[]): string | null {
  const mobileNumbers = numbers
    .map((number) => number.phoneNumber.phoneNumberE164)
    .filter((num) => {
      const parsed = parsePhoneNumberFromString(num);

      return parsed?.getType() === 'MOBILE';
    });

  return mobileNumbers[0] ?? null;
}

export interface ContactWithNumbers {
  phoneNumbers: ApiNumber[];
}

interface ParsedContact<E extends ContactWithNumbers> {
  phoneNumber: string;
  contact: E;
}

export function parseSmsSelection<E extends ContactWithNumbers>(
  selection: E[]
): ParsedContact<E>[] {
  const validAndNulls: (ParsedContact<E> | null)[] = selection.map(
    (contact) => {
      const phoneNumber = firstMobileNumber(contact.phoneNumbers);

      if (!phoneNumber) {
        return null;
      }

      return {
        phoneNumber,
        contact,
      };
    }
  );

  return validAndNulls.filter(
    (item): item is ParsedContact<E> => item !== null
  );
}

// WHAT FUCKING IDIOT WROTE THE ORIGINAL TYPING FOR THIS (edit. it was me)

export function handleNationalNumber(
  form: Partial<CompanyFormData> | Partial<PersonFormData>
): ParsedCompanyFormData | ParsedPersonFormData {
  if (!form.nationalNumber?.nationalNumber || !form.nationalNumber?.type) {
    return form;
  }

  const { type, nationalNumber } = form.nationalNumber;

  return {
    ...form,
    nationalNumber: {
      type,
      nationalNumber: formatNationalNumber(type, nationalNumber),
    },
  };
}

// eslint-disable-next-line max-len
export const squashOverviews = (overviews: IOverviewEditorItem[]) => {
  return overviews.reduce((all: IOverviewEditorItem[], overview) => {
    let children: IOverviewEditorItem[] = [];
    if (overview.overviews && overview.overviews.length > 0) {
      children = [...squashOverviews(overview.overviews)];
    }

    return [...all, overview, ...children];
  }, []);
};
