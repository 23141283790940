import { FormControl, FormLabel } from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ContactSelect } from '@bq/components/FormFields';

import { Good } from '../../../types';
import { FieldProps } from '../../types';

export const ProducerField = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation('Products');
  const {
    formState: { errors },
    control,
  } = useFormContext<Good>();

  const { field } = useController<Good, 'good.producerCompany'>({
    name: 'good.producerCompany',
    control,
  });

  return (
    <FormControl
      isInvalid={!!errors.good?.producerCompany}
      {...formControlProps}
    >
      <FormLabel htmlFor="producer">{t('Products:producer')}</FormLabel>
      <ContactSelect
        isMulti={false}
        createMode="saveInForm"
        hasDetails={true}
        isClearable={true}
        hasEditModal
        onChange={(value) => {
          field.onChange(value);
        }}
        value={field.value ? { ...field.value, type: 'company' } : null}
        allowedTypes={{
          company: true,
          person: false,
          companyDepartment: false,
          companyLocation: false,
          user: false,
        }}
      />
    </FormControl>
  );
};
