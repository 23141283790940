import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { ReportPermission } from './ReportPermission';

export const PermissionSettings = (): ReactElement => {
  const { t } = useTranslation('User');

  const groupID = window.Bootstrap.bootquery.parameters.groupID as string;

  return (
    <div className="mt-5">
      <h3>
        <span className="fas fa-list-alt"></span> {t('User:user_title')}
      </h3>
      <hr />

      <div className="mt-3 d-flex">
        <ReportPermission groupID={groupID} />
      </div>
    </div>
  );
};
