import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { SimpleContactDisplay } from '@bq/components/ContactDisplay/SimpleContactDisplay';
import { WithSeparator } from '@bq/components/WithSeparator';

import { useDuplicatePerson } from './use-duplicate-person';

export const DuplicatePersonIndicator = () => {
  const { data: duplicates } = useDuplicatePerson();
  const { t } = useTranslation();

  return (
    <>
      {duplicates && duplicates.length >= 1 && (
        <Box fontSize="md" color="yellow.500">
          {t('Phonebook:person_already_exists')}:
        </Box>
      )}
      {duplicates?.map((duplicate) => (
        <WithSeparator key={duplicate.ID} separator=",&nbsp;">
          <SimpleContactDisplay
            fontSize="md"
            contactID={duplicate.ID}
            contactName={`${duplicate.firstName} ${duplicate.lastName}`}
            contactType="person"
          />
        </WithSeparator>
      ))}
    </>
  );
};
