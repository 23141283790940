import { ReactElement } from 'react';
import { Flex } from '@chakra-ui/react';

import { LoaderScale } from './Loader';

export const LoadingPage = (): ReactElement => (
  <Flex justifyContent="center" py="32">
    <LoaderScale height={32} />
  </Flex>
);
