import { create as zustand } from 'zustand';

import { Api } from 'BootQuery/Assets/js/api';
import reusePromise from 'BootQuery/Assets/js/reuse-promise';
import SocketEventListener from 'BootQuery/Assets/js/socket-event-listener';

import {
  Call,
  Callee,
  CalleeState,
  CallPoint,
  ConferenceMember,
  Destination,
  DestinationState,
} from '../types/call';

let eventListener: SocketEventListener | null = null;

export interface CurrentCallee extends Callee {
  currentState: CalleeState;
}

export interface CallCurrentState extends Call {
  currentState: DestinationState | null;
  currentDestination: Destination | null;
  sourceInfo: {
    source: CallPoint;
    phoneNumber: string | null;
  };
  destinationInfo: {
    destination: CallPoint | null;
    phoneNumber: string | null;
  };
  currentCallees: CurrentCallee[];
}

export interface CurrentCallsStateStore {
  loaded: boolean;
  calls: Call[];
  addCall: (newCall: Call) => void;
  updateCall: (newCall: Call) => void;
  removeCall: (callId: string) => void;
  init: () => Promise<void>;
}

export const currrentCalls = zustand<CurrentCallsStateStore>((set, get) => ({
  loaded: false,
  calls: [],
  addCall(newCall) {
    set({
      calls: [...get().calls, newCall],
    });
  },
  updateCall(newCall) {
    set({
      calls: get().calls.map((call) => {
        if (call.callId === newCall.callId) {
          return newCall;
        }

        return call;
      }),
    });
  },
  removeCall(callId) {
    set({
      calls: get().calls.filter((call) => call.callId !== callId),
    });
  },
  init: reusePromise(async () => {
    const state = get();
    if (state.loaded) {
      return;
    }

    if (!eventListener) {
      eventListener = new SocketEventListener('telephonyCurrentCalls');
    }

    eventListener.subscribeWebSocket('telephony/callStart', (ev) => {
      const callData = ev as Call;
      console.log('CALL START: ', callData);
      get().addCall(callData);
    });

    eventListener.subscribeWebSocket('telephony/callUpdate', (ev) => {
      const callData = ev as Call;
      get().updateCall(callData);
    });

    eventListener.subscribeWebSocket(
      ['telephony/callEnd', 'telephony/callDelete'],
      (ev) => {
        const callEnd = ev as { callId: string };
        const { callId } = callEnd;
        console.log('CALL END: ', callId);
        get().removeCall(callId);
      }
    );

    const { data: calls } = await Api.get('/api/telephony/currentCalls', {
      params: { withContacts: true },
    });
    set({ calls: calls as Call[], loaded: true });
  }),
}));

export function getCallCurrentState(call: Call): CallCurrentState {
  const currentDestination =
    call.destinations.find((dest) => dest.exitAt === null) ?? null;

  const destStates = currentDestination?.states ?? [];

  const currentState = destStates.find((state) => state.endAt === null) ?? null;
  const currentCallees: CurrentCallee[] = (call.callees ?? [])
    .filter((callee) => callee.endAt === null)
    .map((callee) => ({
      ...callee,
      currentState: callee.states.find((state) => state.endAt === null)
        ?.state as CalleeState,
    }));

  return {
    ...call,
    currentState,
    currentDestination,
    currentCallees,
    sourceInfo: {
      source: {
        type: 'phonePoint',
        point: call.sourcePhonePoint.phonePoint,
      },
      phoneNumber: call.sourcePhonePoint.phonePoint.phoneNumber,
    },
    destinationInfo: {
      destination: currentDestination?.destination ?? null,
      phoneNumber:
        currentDestination?.destCalleeNumber?.phoneNumberE164 ?? null,
    },
  };
}

export function getCallsCurrentState(calls: Call[]): CallCurrentState[] {
  return calls.map((call) => getCallCurrentState(call));
}

export function getConferenceMembers(
  calls: Call[],
  conference: string
): ConferenceMember[] {
  console.log('Listen here you little: ', calls);

  return getCallsCurrentState(calls)
    .filter((call) => {
      const dest = call.currentDestination?.destination;
      if (!dest) {
        return false;
      }

      return dest.type === 'conference' && dest.point.name === conference;
    })
    .map((call) => ({
      callID: call.ID,
      pbxCallId: call.callId,
      member: call.sourcePhonePoint.phonePoint,
    }));
}
