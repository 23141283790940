import { ReactElement } from 'react';
import { Box, Flex, Link, Tag, Text } from '@chakra-ui/react';
import { format as dateFormat } from 'date-fns';
import { hr } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { FaListAlt } from 'react-icons/fa';

import { EventCardComponent } from 'app/Modules/Events/Assets/components/types';
import { Card, CardFooter, CardHeader } from 'BootQuery/Assets/components/Card';
import { tagTextColor } from 'BootQuery/Assets/components/TagInput';

import { TicketEvent } from './types';

export const TicketEventCard: EventCardComponent<TicketEvent> = ({
  timestamp,
  data,
}): ReactElement => {
  const { t } = useTranslation('Ticketing');

  return (
    <Card>
      <CardHeader
        display="flex"
        flexDirection={['column', 'column', 'column', 'row']}
        justifyContent="space-between"
        alignItems={['left', 'left', 'left', 'center']}
      >
        <Link
          fontWeight="bold"
          display="flex"
          alignItems="center"
          href={`/ticketing/edit/${data.ID}`}
        >
          <FaListAlt />
          &nbsp;
          <Text as="strong" fontWeight="medium">
            #{data.ID}
          </Text>
          &nbsp;-&nbsp;{data.title}
        </Link>
        <Flex mt={['3', '0']} alignItems="center">
          <Tag
            minW="10"
            bgColor={data.state.color}
            color={tagTextColor(data.state.color)}
          >
            {data.state.name}
          </Tag>
          &nbsp;&nbsp;
          <Box>{formatDate(timestamp)}</Box>
        </Flex>
      </CardHeader>
      <CardFooter>
        {t('Ticketing:group')}: {data.group.name}
      </CardFooter>
    </Card>
  );
};

const formatDate = (date: Date | string | number | null): string => {
  if (!date) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return dateFormat(dateObj, 'Ppp', { locale: hr });
};
