import { useMemo } from 'react';
import { format as dateFormat } from 'date-fns';
import { hr } from 'date-fns/locale';

import { TicketContact, TicketCustomer } from '../../types';

export const formatDate = (date: Date | string | number | null): string => {
  if (!date) {
    return '-';
  }

  const dateObj = typeof date === 'string' ? new Date(date) : date;

  return dateFormat(dateObj, 'Pp', { locale: hr });
};

export function valueDisplayable(kv: DetailChangeUnknown): kv is DetailChange {
  const val = kv.value;

  return typeof val === 'string' || typeof val === 'number' || val === null;
}

export interface DetailChange {
  key: string;
  value: string | number | null;
}

export interface DetailChangeUnknown {
  key: string;
  value: unknown;
}

export function useDetailChanges(
  data: Record<string, unknown>
): DetailChange[] {
  return useMemo(() => {
    return Object.entries(data)
      .map(([key, value]) => ({ key, value }))
      .filter(valueDisplayable);
  }, [data]);
}

export function customerName(
  customer: TicketCustomer | TicketContact | null | undefined
): string {
  if (!customer) {
    return '';
  }

  if (customer.type === 'person') {
    return customer.fullName;
  }
  if (customer.type === 'manual') {
    return customer.manualContact;
  }

  return customer.name;
}

export function tagNames(tags: { tag: { tag: string } }[]): string[] {
  return tags.map((tag) => tag.tag.tag);
}
