import { useTranslation } from 'react-i18next';
import { FaListAlt } from 'react-icons/fa';

import { EventCellComponent } from 'app/Modules/Events/Assets/components';

import { TicketEvent } from './types';

export const TicketTypeColumn: EventCellComponent<TicketEvent> = ({
  row: { data: ticket },
}) => {
  const { t } = useTranslation('Ticketing');

  return (
    <>
      <FaListAlt title={t('Ticketing:ticket')} style={{ display: 'inline' }} />
      &nbsp;
      {ticket.ticketType.name}
    </>
  );
};
