import { Api } from 'BootQuery/Assets/js/api';
import {
  DefaultPatchReturn,
  DefaultPostReturn,
} from 'BootQuery/Assets/js/globalTypes';

import { PatchCompanyLocation, PostCompanyLocation } from './types';

export const patchCompanyLocation = <ReturnType = DefaultPatchReturn>({
  data,
  params,
  ID,
}: PatchCompanyLocation) => {
  return Api.patch<ReturnType>(`/api/phonebook/companyLocations/${ID}`, data, {
    params,
  });
};

export const postCompanyLocation = <ReturnType = DefaultPostReturn>({
  data,
  params,
}: PostCompanyLocation) => {
  if ('companyID' in data && data.companyID) {
    return Api.post<ReturnType>('/api/phonebook/companyLocations', data, {
      params,
    });
  }
  throw Error('Tried to post Company Location without Company ID');
};
