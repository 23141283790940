import {
  PropsWithChildren,
  ReactElement,
  SetStateAction,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useQueries, UseQueryResult } from '@tanstack/react-query';

import { getDependencies } from './api';
import { DependenciesContext } from './DependenciesContext';
import { ExpandMap, QueriesMap, TicketWithDependencies } from './types';
import { useExpandedIds } from './use-expanded-ids';

export const DependenciesProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const [expandedTickets, setExpandedTickets] = useState<ExpandMap>({});

  const idsToQuery = useExpandedIds(expandedTickets);
  const depQueries = useQueries({
    queries: idsToQuery.map((ticketID) => ({
      queryKey: ['ticketDependencies', ticketID],
      queryFn: () => getDependencies(ticketID),
    })),
  });
  const queriesMap: QueriesMap = useMemo(
    () => makeQueriesMap(depQueries, idsToQuery),
    [depQueries, idsToQuery]
  );

  const setExpanded = useCallback(
    (ticketID: number, expanded: SetStateAction<boolean>) => {
      setExpandedTickets((prev) => {
        const prevExpanded = prev[ticketID] ?? false;
        const isCallback = typeof expanded === 'function';

        return {
          ...prev,
          [ticketID]: isCallback ? expanded(prevExpanded) : expanded,
        };
      });
    },
    []
  );
  const clearExpanded = useCallback(() => {
    setExpandedTickets([]);
  }, []);

  const value = useMemo(
    () => ({
      expandedTickets,
      setExpanded,
      clearExpanded,
      dependencyQueries: queriesMap,
    }),
    [expandedTickets, setExpanded, clearExpanded, queriesMap]
  );

  return (
    <DependenciesContext.Provider value={value}>
      {children}
    </DependenciesContext.Provider>
  );
};

function makeQueriesMap(
  depQueries: UseQueryResult<TicketWithDependencies[]>[],
  idsToQuery: number[]
): QueriesMap {
  return depQueries.reduce((prev, query, idx) => {
    const ticketID = idsToQuery[idx];
    if (!ticketID) {
      throw new Error('Error finding ticket id for dependencies query');
    }

    return {
      ...prev,
      [ticketID]: query,
    };
  }, {});
}
