import { useCallback, useMemo } from 'react';
import { MenuItem } from '@chakra-ui/react';

import { useRichText } from '@bq/components/RichText/RichtextContex';
import { useModal } from '@bq/components/UseModal/use-modal';
import { OBJ } from 'BootQuery/Assets/js/globalTypes';

import { TemplateElement } from './types';

interface Props<T extends OBJ, U extends OBJ> {
  element: TemplateElement<T, U>;
}

export const ItemPlaceholderMenuItem = <T extends OBJ, U extends OBJ>({
  element,
}: Props<T, U>) => {
  const { editor } = useRichText();
  const { addModal } = useModal();

  const title = useMemo(() => {
    if (typeof element.title === 'function') {
      return element.title();
    }

    return element.title;
  }, [element]);

  const onClickCallback = useCallback(() => {
    if (element.configModal) {
      addModal({
        callback: (data: U) => {
          editor?.commands.addItem(element.key, data);
        },
        children: element.configModal,
        componentProps: { modal: {} },
      });
    } else {
      editor?.commands.addItem(element.key);
    }
  }, [addModal, editor?.commands, element]);

  return <MenuItem onClick={onClickCallback}>{title}</MenuItem>;
};
