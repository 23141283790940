import { ReactNode } from 'react';
import { Box, BoxProps, useColorModeValue } from '@chakra-ui/react';

interface LayoutHeaderProps extends BoxProps {
  children?: ReactNode;
}
export const LayoutHeader = ({
  children,
  ...styleProps
}: LayoutHeaderProps) => {
  const headerColor = useColorModeValue('#F5F5F5', '#393939');

  return (
    <Box
      zIndex={3}
      position="sticky"
      top={0}
      w="full"
      alignItems="center"
      justifyContent="space-between"
      px="6"
      py="4"
      borderBottomWidth="1px"
      display="flex"
      bg={headerColor}
      borderBottom="2px"
      borderColor="brand.500"
      h="72px"
      minH="72px"
      {...styleProps}
    >
      {children}
    </Box>
  );
};
