import {
  QueryClient,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from '@tanstack/react-query';
import { SingleValue } from 'chakra-react-select';

import { formatNumber } from 'app/assets/js/tsutil';
import {
  getFormDefinition,
  loadValues,
} from 'BootQuery/Assets/components/FormEditor';

import { CompanyDepartment } from '../CompanyDepartmentList/types';
import { OffspringMap } from '../DepartmentTreeView/types';
import { makeDepartmentOffspringMap } from '../DepartmentTreeView/utils';
import { ListItem } from '../types';
import {
  getCompanyListItem,
  getDepartmentInfo,
  searchDepartments,
} from './api';
import {
  CompanyDepartmentBackendData,
  CompanyDepartmentFormData,
  FilterOptions,
  UseCompanyDepartmentFormDataProps,
} from './types';

async function loadDefaultsCreateDepartment(
  queryClient: QueryClient,
  companyID: string
): Promise<Partial<CompanyDepartmentFormData>> {
  const [customForm, company] = await Promise.all([
    getFormDefinition(queryClient, 'Phonebook.companyDepartment'),
    getCompanyListItem(companyID),
  ]);

  return {
    company,
    data: loadValues(customForm, {}),
  };
}

async function loadDefaultsEditDepartment(
  queryClient: QueryClient,
  departmentID: string
): Promise<Partial<CompanyDepartmentFormData>> {
  const [customForm, department] = await Promise.all([
    getFormDefinition(queryClient, 'Phonebook.companyDepartment'),
    getDepartmentInfo(departmentID),
  ]);

  return {
    ...BackendToFrontendDepartments(department),
    data: loadValues(customForm, department.data ?? {}),
  };
}

const BackendToFrontendDepartments = (
  department: CompanyDepartmentBackendData
): CompanyDepartmentFormData => ({
  ...department,
  phoneNumbers: department.phoneNumbers.map((numRow) => ({
    phoneNumber: formatNumber(numRow.phoneNumber.phoneNumberE164),
    type: numRow.numberType.ID.toString(),
  })),
  emails: department.emails.map((mailRow) => ({
    email: mailRow.email.email,
  })),
});
export function useCompanyDepartmentFormData(
  props: UseCompanyDepartmentFormDataProps
): UseQueryResult<Partial<CompanyDepartmentFormData> | null> {
  const queryClient = useQueryClient();

  return useQuery<Partial<CompanyDepartmentFormData> | null>({
    queryKey: ['companyDepartmentFormData', props],
    queryFn: () => {
      switch (props.type) {
        case 'create':
          return loadDefaultsCreateDepartment(queryClient, props.companyID);
        case 'edit':
          return loadDefaultsEditDepartment(queryClient, props.departmentID);
        default:
          return null;
      }
    },
  });
}

// eslint-disable-next-line max-len
export function valToFilter(val: ListItem | null): FilterOptions | null {
  return val ? { label: val.name, value: val.ID } : null;
}

export function filToVal(val: SingleValue<FilterOptions>): ListItem | null {
  return val ? { ID: val.value, name: val.label } : null;
}

export function isChildOfF(
  offspringMap: OffspringMap,
  item: CompanyDepartment,
  currentDepartmentID: number
) {
  if (!offspringMap[currentDepartmentID]) {
    return false;
  }

  return offspringMap[currentDepartmentID].includes(item.ID);
}

export function filterInitialData(
  offspringMap: OffspringMap,
  data: CompanyDepartment[] | undefined,
  currentID: string | undefined
): FilterOptions[] | null {
  // data is not loaded so this is null
  if (!data || data.length === 0) {
    return null;
  }
  // offspring map is not loaded so we return null
  if (
    offspringMap &&
    Object.keys(offspringMap).length === 0 &&
    Object.getPrototypeOf(offspringMap) === Object.prototype
  ) {
    return null;
  }
  // if no current ID we don't have to filter data
  if (!currentID) {
    return data.map((item) => ({ label: item.name, value: item.ID }));
  }
  const currentIDNumber = parseInt(currentID, 10);
  // ID can't be NaN so we return non filtered data
  if (Number.isNaN(currentIDNumber)) {
    return data.map((item) => ({ label: item.name, value: item.ID }));
  }

  return data
    .filter((item) => {
      const isSelf = item.ID === currentIDNumber;
      const isChildOf = isChildOfF(offspringMap, item, currentIDNumber);
      // Can't become paren't to self
      if (isSelf) {
        return false;
      }
      // A child can't become parent's parent
      if (isChildOf) {
        return false;
      }

      return true;
    })
    .map((item) => ({ label: item.name, value: item.ID }));
}

export const searchValidParents = async (
  companyID: number | string | null | undefined,
  search: string,
  departmentID: string | undefined
) => {
  if (!companyID) {
    return [];
  }
  const data = await searchDepartments({ companyID, search });
  const offspringMap = makeDepartmentOffspringMap(data ?? []);
  const filterData = filterInitialData(offspringMap, data, departmentID);

  return filterData ?? [];
};
