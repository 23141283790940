import { ChangeEvent, ReactElement } from 'react';
import {
  Checkbox,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCog } from 'react-icons/fa';

import { IconButton } from '../IconButton';
import { BaseSettings, EditorFieldComponentProps } from './types';
import { useFieldType } from './use-field-type';
import { useFieldSettings } from './util';

const DefaultFieldSettings = (
  props: EditorFieldComponentProps<BaseSettings>
): ReactElement => {
  const { name, required } = props.content.settings;

  const { setSetting } = useFieldSettings(props);
  const { t } = useTranslation();

  const setName = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('name', ev.target.value);
  };
  const setRequired = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('required', ev.target.checked);
  };

  return (
    <VStack alignItems="start">
      <Input
        placeholder={t('global:form_editor.field_name') ?? undefined}
        value={name}
        onChange={setName}
      />
      <Checkbox isChecked={required ?? false} onChange={setRequired}>
        {t('global:form_editor.required')}
      </Checkbox>
    </VStack>
  );
};

export const SettingsPopover = <ST extends BaseSettings>(
  props: EditorFieldComponentProps<ST>
): ReactElement => {
  const { content } = props;
  const fieldType = useFieldType<ST>(content.type);
  const SettingsInner =
    fieldType.components?.EditorFieldSettings ?? DefaultFieldSettings;
  const { t } = useTranslation();

  return (
    <Popover isLazy lazyBehavior="keepMounted">
      <PopoverTrigger>
        <IconButton
          label="Settings"
          icon={<FaCog />}
          variant="solid"
          size="sm"
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader display="flex" alignItems="center">
          <FaCog />
          &nbsp;
          {t('global:form_editor.field_settings_title')}
        </PopoverHeader>
        <PopoverBody>
          <SettingsInner {...props} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
