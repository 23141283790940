import { ReactElement, useCallback } from 'react';
import { Button, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';
import { useUpdateEffect } from 'react-use';

import {
  Card,
  CardBody,
  CardFooter,
  CardHeader,
} from 'BootQuery/Assets/components/Card';
import { useDOMInputJSON } from 'BootQuery/Assets/components/use-dom-input';

import { EventTypeListItem } from './EventTypeListItem';
import { useEventTypeSettings } from './EventTypeSettingsContext';
import { CustomEventTypeSettings } from './types';

export const CustomEventTypesEdit = (): ReactElement => {
  const { t } = useTranslation('Events');
  const { eventTypes, add } = useEventTypeSettings();
  const [, setEventsInput] = useDOMInputJSON<CustomEventTypeSettings[]>(
    '#settings-form',
    'customEventTypes',
    eventTypes
  );

  useUpdateEffect(() => {
    setEventsInput(eventTypes);
  }, [eventTypes, setEventsInput]);

  const doAdd = useCallback(() => {
    add();
  }, [add]);

  const visibleEventTypes = eventTypes.filter(
    (eventType) => !eventType.deleted
  );

  return (
    <Card shadow="base">
      <CardHeader title={t('Events:custom_event_types')} />
      {visibleEventTypes.length > 0 ? (
        <CardBody p={0}>
          {visibleEventTypes.map((eventType) => (
            <EventTypeListItem key={eventType.ID} {...eventType} />
          ))}
        </CardBody>
      ) : (
        <CardBody>
          <Heading size="lg" textAlign="center">
            {t('Events:no_custom_event_types')}
          </Heading>
        </CardBody>
      )}
      <CardFooter display="flex" justifyContent="end">
        <Button variant="solid" size="md" colorScheme="green" onClick={doAdd}>
          <FaPlus />
          &nbsp;
          {t('global:add')}
        </Button>
      </CardFooter>
    </Card>
  );
};
