import { ReactElement, useMemo } from 'react';
import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaTimes } from 'react-icons/fa';

import { FilterName } from '../FilterName';
import { FilterProps, FilterType } from '../types';
import {
  DropdownItem,
  FilterDropdownInput,
  FilterDropdownTag,
} from './DropdownInput';

export const BooleanFilterTag = ({
  name,
  value,
  onChange,
  onRemove,
}: FilterProps<boolean>): ReactElement => {
  const stringVal = value ? 'true' : 'false';
  const handleValChange = (val: string) => {
    onChange(val === 'true');
  };

  return (
    <Flex>
      <FilterName name={name} />
      <FilterDropdownTag
        items={useOptions()}
        value={stringVal}
        onChange={handleValChange}
        onRemove={onRemove}
      />
    </Flex>
  );
};

export const BooleanFilterInput = ({
  value,
  onChange,
  onRemove,
}: FilterProps<boolean>): ReactElement => {
  const stringVal = value ? 'true' : 'false';
  const handleValChange = (val: string) => {
    onChange(val === 'true');
  };

  return (
    <>
      <FilterDropdownInput
        items={useOptions()}
        value={stringVal}
        onChange={handleValChange}
        onRemove={onRemove}
      />
    </>
  );
};

type BooleanFilter = FilterType<boolean>;
type BooleanUserDef = Partial<BooleanFilter> &
  Pick<BooleanFilter, 'toFilter' | 'name'>;
export function makeBooleanFilter(filterDef: BooleanUserDef): BooleanFilter {
  return {
    tagComponent: BooleanFilterTag,
    inputComponent: BooleanFilterInput,
    operators: [],
    defaultValue: true,
    fromQueryString: (val) => val === 'true',
    ...filterDef,
  };
}

function useOptions(): DropdownItem[] {
  const { t } = useTranslation('global');

  return useMemo(
    () => [
      {
        key: 'true',
        text: t('global:operators.yes'),
        icon: <FaCheck />,
      },
      {
        key: 'false',
        text: t('global:operators.no'),
        icon: <FaTimes />,
      },
    ],
    [t]
  );
}
