import { memo, ReactElement, useCallback, useContext } from 'react';
import { Box, useColorModeValue } from '@chakra-ui/react';
import {
  FaMicrophone,
  FaMicrophoneSlash,
  FaVideo,
  FaVideoSlash,
} from 'react-icons/fa';

import { basename } from 'app/assets/js/tsutil';
import { IconButton } from 'BootQuery/Assets/components/IconButton';
import { useBrandColor } from 'BootQuery/Assets/js/brand-colors';
import i18n from 'BootQuery/Assets/js/i18n';

import { VoicemailIcon, VoicemailSlashIcon } from '../../icons';
import { markVoicemailListened } from './api';
import { useCallListSettings } from './CallListSettingsContext';
import { PlayerContext, RecordingMedia } from './RecordingPlayer';
import { CallListCall } from './types';

interface RecordingIconProps {
  type: 'audio' | 'video' | 'voicemail';
  hasRecordings: boolean;
  onClick?: () => void;
  highlight?: boolean;
}

const icons = {
  audio: {
    available: FaMicrophone,
    unavailable: FaMicrophoneSlash,
  },
  video: {
    available: FaVideo,
    unavailable: FaVideoSlash,
  },
  voicemail: {
    available: VoicemailIcon,
    unavailable: VoicemailSlashIcon,
  },
};

const labels = {
  get audio() {
    return i18n.t('Telephony:recording.audio');
  },
  get video() {
    return i18n.t('Telephony:recording.video');
  },
  get voicemail() {
    return i18n.t('Telephony:recording.voicemail');
  },
};

const RecordingIcon = memo(
  ({
    type,
    hasRecordings,
    onClick,
    highlight,
  }: RecordingIconProps): ReactElement => {
    const handleClick = () => onClick && onClick();

    const highlightBrandColor = useColorModeValue('brand.700', 'brand.300');
    const highlihgtFallbackColor = 'blue.500';
    const highlightColor = useBrandColor()
      ? highlightBrandColor
      : highlihgtFallbackColor;

    const subtype = hasRecordings ? 'available' : 'unavailable';
    const Icon = icons[type][subtype];
    const label = labels[type];

    return (
      <IconButton
        variant="ghost"
        color={highlight ? highlightColor : undefined}
        label={label}
        onClick={handleClick}
        isDisabled={!hasRecordings}
        icon={<Icon />}
        size="sm"
        minWidth="6"
      />
    );
  }
);
RecordingIcon.displayName = 'RecordingIcon';

export const CallRecordingIndicators = ({
  call,
}: {
  call: CallListCall;
}): ReactElement => {
  const { toggleOpen, setRecordings, setRecording } = useContext(PlayerContext);
  const { enabledRecordingIndicators } = useCallListSettings();

  const hasAudioRecordings =
    call.recordings.filter(({ type }) => type === 'audio').length > 0;
  const hasVideoRecordings =
    call.recordings.filter(({ type }) => type === 'video').length > 0;
  const hasVoicemails = call.voicemails.length > 0;
  const hasUnlistenedVoicemails = call.voicemails.some((vm) => !vm.listened);

  const openRecording = useCallback(
    (type: 'audio' | 'video' | 'voicemail') => {
      let recordings: RecordingMedia[];
      if (type === 'voicemail') {
        recordings = call.voicemails.map((vm) => ({
          url: `/api/telephony/voicemails/${vm.ID}/file`,
          type,
          filename: basename(vm.filename),
          onPlay() {
            if (!vm.listened) {
              markVoicemailListened(vm.ID);
            }
          },
        }));
      } else {
        recordings = call.recordings.map((rec) => ({
          url: `/api/telephony/recordings/${rec.ID}/file`,
          type,
          filename: basename(rec.filename),
        }));
      }

      const rec = recordings.find((rec) => rec.type === type);
      if (!rec) {
        throw new Error('Tried to play non-existent recording');
      }

      setRecordings(recordings);
      setRecording(rec);
      toggleOpen(true);
    },
    [call.recordings, call.voicemails, setRecording, setRecordings, toggleOpen]
  );

  const openAudioRecording = useCallback(
    () => openRecording('audio'),
    [openRecording]
  );
  const openVideoRecording = useCallback(
    () => openRecording('video'),
    [openRecording]
  );
  const openVoicemail = useCallback(
    () => openRecording('voicemail'),
    [openRecording]
  );

  return (
    <>
      <Box>
        {enabledRecordingIndicators.audio && (
          <RecordingIcon
            type="audio"
            onClick={openAudioRecording}
            hasRecordings={hasAudioRecordings}
          />
        )}
      </Box>
      <Box ml="0.5">
        {enabledRecordingIndicators.video && (
          <RecordingIcon
            type="video"
            onClick={openVideoRecording}
            hasRecordings={hasVideoRecordings}
          />
        )}
      </Box>
      <Box ml="0.5">
        {enabledRecordingIndicators.voicemail && (
          <RecordingIcon
            type="voicemail"
            onClick={openVoicemail}
            hasRecordings={hasVoicemails}
            highlight={hasUnlistenedVoicemails}
          />
        )}
      </Box>
    </>
  );
};
