import { ReactElement } from 'react';

export const ReadIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M172.34 102.77h-58.75c-4.42 0-8 3.94-8 8.81v64.62c0 4.87 3.59 8.82 8 8.82h58.75c4.43 0 8-4 8-8.82v-64.62c.01-4.87-3.57-8.81-8-8.81zM172.34 205.53h-58.75c-4.42 0-8 3.95-8 8.81V279c0 4.86 3.59 8.81 8 8.81h58.75c4.43 0 8-3.95 8-8.81v-64.66c.01-4.86-3.57-8.81-8-8.81zM172.34 0h-58.75c-4.42 0-8 4-8 8.81v64.63c0 4.86 3.59 8.81 8 8.81h58.75c4.43 0 8-4 8-8.81V8.81c.01-4.81-3.57-8.81-8-8.81zM79.81 0H21.07c-4.43 0-8 4-8 8.81v64.63c0 4.86 3.59 8.81 8 8.81h58.74c4.43 0 8-4 8-8.81V8.81C87.83 4 84.24 0 79.81 0zM79.81 102.77H21.07c-4.43 0-8 3.94-8 8.81v64.62c0 4.87 3.59 8.82 8 8.82h58.74c4.43 0 8-4 8-8.82v-64.62c.02-4.87-3.57-8.81-8-8.81zM79.81 205.53H21.07c-4.43 0-8 3.95-8 8.81V279c0 4.86 3.59 8.81 8 8.81h58.74c4.43 0 8-3.95 8-8.81v-64.66c.02-4.86-3.57-8.81-8-8.81zM264.87 205.53h-58.75c-4.42 0-8 3.95-8 8.81V279c0 4.86 3.59 8.81 8 8.81h58.75c4.42 0 8-3.95 8-8.81v-64.66c.01-4.86-3.58-8.81-8-8.81zM264.87 102.77h-58.75c-4.42 0-8 3.94-8 8.81v64.62c0 4.87 3.59 8.82 8 8.82h58.75c4.42 0 8-4 8-8.82v-64.62c.01-4.87-3.58-8.81-8-8.81zM264.87 0h-58.75c-4.42 0-8 4-8 8.81v64.63c0 4.86 3.59 8.81 8 8.81h58.75c4.42 0 8-4 8-8.81V8.81c.01-4.81-3.58-8.81-8-8.81z"
    />
  </svg>
);
