import { IOverviewEditorItem } from '../Overviews';
import { OverviewSearchResult } from './types';

export const searchTree = (
  nodes: IOverviewEditorItem[],
  search: string,
  selected: string | null
): OverviewSearchResult[] => {
  const searchValid = search.length > 3 && search !== '';
  if (!searchValid) {
    return nodes;
  }

  return nodes.reduce((previousValue: IOverviewEditorItem[], overview) => {
    const isSelected = overview.slug === selected;
    const children = searchTree(overview.overviews ?? [], search, selected);
    const matched = overview.title.toLowerCase().includes(search.toLowerCase());
    if (isSelected || matched || children.length > 0) {
      return [
        ...previousValue,
        { ...overview, open: searchValid, overviews: children },
      ];
    }

    return previousValue;
  }, []);
};

export const buildOpenItems = (
  parent: string,
  items: OverviewSearchResult[],
  search: string,
  selected: string | null,
  childOpenCb: (isOpen: boolean) => void
): Record<string, number[]> => {
  let accordionIndex = -1;

  return items.reduce((previousValue: Record<string, number[]>, item) => {
    if (item.overviews && item.overviews.length > 0) {
      accordionIndex++;
    }
    let openChild = false;
    const children = buildOpenItems(
      item.slug,
      item.overviews ?? [],
      search,
      selected,
      (isOpen: boolean) => {
        openChild = isOpen;
      }
    );
    if (openChild) {
      childOpenCb(true);
    }
    const isSelected = item.slug === selected;
    const shouldBeOpen = item.open || isSelected || openChild;
    if (shouldBeOpen) {
      childOpenCb(true);
      const value = [...(previousValue[parent] ?? []), accordionIndex];

      return {
        ...previousValue,
        [parent]: value,
        ...children,
      };
    }

    return previousValue;
  }, {});
};

export const parseToSlug = (name: string) => {
  return name.replace(/\s+/g, '-').toLowerCase();
};

export const squashOverviews = (overviews: IOverviewEditorItem[]) => {
  return overviews.reduce((all: IOverviewEditorItem[], overview) => {
    let children: IOverviewEditorItem[] = [];
    if (overview.overviews && overview.overviews.length > 0) {
      children = [...squashOverviews(overview.overviews)];
    }

    return [...all, overview, ...children];
  }, []);
};
