import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { ItemSubgroup } from '../../types';

interface GetSubgroupsParams {
  params?: GetListingParams;
}
export const getSubgroups = async ({ params }: GetSubgroupsParams) => {
  const { data } = await Api.get<ListingResponse<ItemSubgroup>>(
    '/api/productSubgroups',
    {
      /** Make field params for item type */
      params,
    }
  );

  return data;
};
