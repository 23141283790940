import { Center, MenuGroup, Switch } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useShowPeopleFromSubdepartments } from './display-settings';

export const ShowSubdepartmentEmployees = () => {
  const { t } = useTranslation('Phonebook');
  const [showSubdepartmentEmployees, setShowSubdepartmentEmployees] =
    useShowPeopleFromSubdepartments();

  return (
    <MenuGroup title={t('Phonebook:show_subdepartment_employees')}>
      <Center>
        <Switch
          defaultChecked={showSubdepartmentEmployees === 'true'}
          size="lg"
          onChange={() => {
            setShowSubdepartmentEmployees(
              showSubdepartmentEmployees === 'true' ? 'false' : 'true'
            );
          }}
        ></Switch>
      </Center>
    </MenuGroup>
  );
};
