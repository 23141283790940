import { useRef } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';

import { WithSeparator } from '@bq/components/WithSeparator';

import { useReportContext } from './ReportContext';
import { useGroupedBy } from './use-grouped-by';

export const ReportGroupByMenu = () => {
  const { allGroupBy, setGroupBy, groupBy } = useReportContext();
  const inputRef = useRef<HTMLButtonElement>(null);
  const { t } = useTranslation('global');
  const groupedBy = useGroupedBy();

  return (
    <Menu closeOnSelect={false}>
      <MenuButton
        as={Button}
        variant="outline"
        w="full"
        ref={inputRef}
        pr="3"
        pl="5"
        fontWeight="base"
        opacity="0.9"
        textAlign="left"
        rightIcon={<FaChevronDown size="12" />}
      >
        {groupBy.length === 0 && <>{`<${t('global:none_selected')}>`}</>}
        {groupedBy.length > 0 && (
          <WithSeparator separator=" , ">
            {groupedBy.map((item) => item?.name)}
          </WithSeparator>
        )}
      </MenuButton>
      <MenuList w={inputRef.current?.offsetWidth ?? '200px'}>
        <MenuOptionGroup
          title={t('global:group_by')}
          type="checkbox"
          onChange={(val) => {
            if (typeof val === 'string') {
              setGroupBy((prev) => [...prev, val]);
            } else {
              setGroupBy(val);
            }
          }}
        >
          {allGroupBy.map((item) => (
            <MenuItemOption key={item.key} value={item.key}>
              {item.name}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
};
