import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';

import { ReportEntity } from './types';

interface Group {
  ID: number;
  name: string;
}

interface User {
  ID: number;
  username: string;
  person: {
    ID: number;
    fullName: string;
  } | null;
}

async function getUserEntities(search = ''): Promise<ReportEntity[]> {
  const { data } = await Api.get<ListingResponse<User>>('/api/users', {
    params: {
      limit: 'null',
      filters: {
        $search: search,
      },
    },
  });

  return data.data.map((user) => ({ ID: user.ID, name: user.username }));
}

async function getGroupEntities(search = ''): Promise<ReportEntity[]> {
  const { data } = await Api.get<Group[]>('/api/groups', {
    params: {
      limit: 'null',
      filters: {
        $search: search,
      },
    },
  });

  return data.map((group) => ({ ID: group.ID, name: group.name }));
}

export function useUsers(search: string): UseQueryResult<ReportEntity[]> {
  return useQuery({
    queryFn: () => getUserEntities(search),
    queryKey: ['reportAgentsUsers', search],
  });
}

export function useGroups(search: string): UseQueryResult<ReportEntity[]> {
  return useQuery({
    queryFn: () => getGroupEntities(search),
    queryKey: ['reportAgentsGroups', search],
  });
}
