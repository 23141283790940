import { memo, ReactElement, useCallback, useMemo, useState } from 'react';
import {
  Box,
  IconButton,
  Link,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { uniqBy } from 'lodash-es';
import { useTranslation } from 'react-i18next';
import { FaListAlt } from 'react-icons/fa';

import { FixedPopoverContent } from 'BootQuery/Assets/components/FixedPopoverContent';
import { Portal } from 'BootQuery/Assets/components/Portal';
import { makeListener } from 'BootQuery/Assets/js/socket-event-listener';
import { useSocketEvents } from 'BootQuery/Assets/js/use-socket-events';

import { Call, Ticket } from '../../types/call';

interface CallTicketsProps {
  call: Call;
}

interface CallTicketEvent {
  callID: number;
  ticketID: number;
  ticket: Ticket;
}

const listener = makeListener('callTickets');

const CallTicketsInner = ({ call }: CallTicketsProps): ReactElement => {
  const { t } = useTranslation('Telephony');
  const [laterTickets, setLaterTickets] = useState<Ticket[]>([]);

  useSocketEvents(
    listener(),
    'ticketing/callsTickets/add',
    useCallback(
      (ev: CallTicketEvent) => {
        console.log('Calls tickets: ', ev);
        if (ev.callID === call.ID) {
          setLaterTickets([...laterTickets, ev.ticket]);
        }
      },
      [call.ID, laterTickets]
    )
  );

  const allTickets = useMemo(() => {
    const all = [
      ...(call.tickets?.map(({ ticket }) => ticket) ?? []),
      ...laterTickets,
    ];

    return uniqBy(all, (ticket) => ticket.ID);
  }, [call.tickets, laterTickets]);

  return (
    <Popover isLazy>
      <PopoverTrigger>
        <IconButton
          icon={<FaListAlt />}
          aria-label="Tickets"
          size="sm"
          variant="ghost"
          colorScheme="blue"
          isDisabled={allTickets.length === 0}
        />
      </PopoverTrigger>
      {allTickets.length > 0 && (
        <Portal>
          <FixedPopoverContent>
            <PopoverArrow />
            <PopoverHeader>{t('Telephony:tickets')}</PopoverHeader>
            <PopoverBody>
              {allTickets.map((ticket) => (
                <Box key={ticket.ID}>
                  <Link
                    href={`/ticketing/edit/${ticket.ID}#events`}
                    color="blue.500"
                  >
                    #{ticket.ID} - {ticket.title}
                  </Link>
                </Box>
              ))}
            </PopoverBody>
          </FixedPopoverContent>
        </Portal>
      )}
    </Popover>
  );
};

export const CallTickets = memo(CallTicketsInner);
