import { AsyncCreatableSelect, MultiValue } from 'chakra-react-select';
import mime from 'mime';
import { useTranslation } from 'react-i18next';

import { useGetCommonFileExtensionMimes } from './common-file-extensions';
import { MATCH_MIME } from './mime-regex';
import {
  AllowTypesSelectGroups,
  AllowTypesSelectOptions,
  SplitMIME,
} from './types';
import { useFileFieldDefaultOptions } from './use-file-field-default-options';
import { useMimesAsSelectOptions } from './use-mimes-as-select-options';
import { parseValueToSelectOptions } from './utils';

interface Props {
  value?: string | string[];
  onChange: (newValue: MultiValue<AllowTypesSelectOptions>) => void;
}

export const FileFieldAllowedTypes = ({ value, onChange }: Props) => {
  const defaultOptions = useFileFieldDefaultOptions();
  const commonExtensions = useGetCommonFileExtensionMimes();
  const { searchMime } = useMimesAsSelectOptions();
  const { t } = useTranslation();

  return (
    <AsyncCreatableSelect<AllowTypesSelectOptions, true, AllowTypesSelectGroups>
      onChange={(newValue) => {
        onChange(newValue);
      }}
      defaultOptions
      isMulti
      allowCreateWhileLoading
      createOptionPosition="first"
      formatCreateLabel={(inputValue) => {
        return `${t('global:file_types.create_custom_mime')} "${inputValue}"`;
      }}
      loadOptions={async (search: string) => {
        const results = searchMime(search);
        if (results.length === 0) {
          return [
            {
              label: t('global:search_result_groups.default_options'),
              options: defaultOptions,
            },
            {
              label: t('global:search_result_groups.search_results'),
              options: commonExtensions,
            },
          ];
        }
        const parseResults = results.map((result) => ({
          label: result.item.label,
          value: result.item.value,
        }));

        return [
          {
            label: t('global:search_result_groups.default_options'),
            options: defaultOptions,
          },
          {
            label: t('global:search_result_groups.search_results'),
            options: parseResults,
          },
        ];
      }}
      isValidNewOption={(val) => MATCH_MIME.test(val)}
      getNewOptionData={(val) => {
        const splitMIME = val.split('/') as SplitMIME;
        const ext = mime.getExtension(val);

        const label = ext || splitMIME[1];

        return { label, value: val };
      }}
      value={parseValueToSelectOptions(defaultOptions, value)}
    />
  );
};
