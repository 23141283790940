import { useCallback } from 'react';
import { omit } from 'lodash-es';

import { Contact } from 'app/Modules/Phonebook/Assets/js/types';
import { BasicCreatableField, OBJ } from 'BootQuery/Assets/js/globalTypes';

import { useConvertUnits } from '../../js/use-convert-units';
import {
  BackendReqestProductData,
  CreatableOption,
  PostCommonFields,
  PostProductPrices,
} from './API/Product/types';
import { PartialItem } from './types';

export type ConvertFunction = (data: PartialItem) => BackendReqestProductData;

export const useParseToRequestData = () => {
  const { data: convertUnits } = useConvertUnits();

  return useCallback(
    (data: PartialItem): BackendReqestProductData => {
      if (!convertUnits) {
        throw new Error('Units not loaded');
      }

      // Remove fields that don't exist on POST data
      const dataWithOmittedFields = omit(data, [
        'amountUnitGroup',
        'defaultAmountUnit',
        'actionPrices',
        'program',
        'group',
        'subgroup',
        'good',
        'service',
        'price',
      ]);

      // Prices are expected in string format on backend

      const price: Partial<PostProductPrices> = {
        purchasePrice: parsePrice(data?.price?.purchasePrice),
        retailPrice: parsePrice(data?.price?.retailPrice),
        wholesalePrice: parsePrice(data?.price?.wholesalePrice),
        contractPrice: parsePrice(data.price?.contractPrice),
        taxRateID: data?.price?.taxRate?.ID ?? null,
      };

      // Add parsed fields, change ommited fields to their backend variant
      const basicFields: Partial<PostCommonFields> = {
        ...dataWithOmittedFields,
        price,
        amountUnitGroupKey: data?.amountUnitGroup?.key ?? null,
        defaultAmountUnitSymbol: data?.defaultAmountUnit?.symbol,
        program: parseCreatableField(data.program),
        group: parseCreatableField(data.group),
        subgroup: parseCreatableField(data.subgroup),
        suppliers: parseSuppliers(data.suppliers),
        actionPrices: data?.actionPrices?.map((item) => ({
          ID: typeof item.ID === 'number' ? item.ID : undefined,
          price: item.price?.toString(),
          description: item.reason?.name,
          reason: omit(item.reason, ['name']), // Partially need to add support for $new
          validUntil: item.validUntil,
        })),
      };

      if (data.productType === 'good') {
        // Remove fields that don't exist on POST data
        const goodWithOmmitedFields = omit(data.good, [
          'packagingAmountUnit',
          'grossWeightUnit',
          'netWeightUnit',
          'netWeightKg',
          'grossWeightKg',
          'dimensionUnit',
          'countryOfOrigin',
          'producer',
          'barcodes',
          'heightMeters',
          'widthMeters',
          'lengthMeters',
        ]);
        const { good } = data;

        const netWeightKg = convertUnits(
          'mass',
          good.netWeightUnit?.symbol ?? 'kg',
          'kg',
          good.netWeightKg ?? 0
        ).value;
        const grossWeightKg = convertUnits(
          'mass',
          good.netWeightUnit?.symbol ?? 'kg',
          'kg',
          good.grossWeightKg ?? 0
        ).value;
        const lengthMeters = convertUnits(
          'distance',
          good.dimensionUnit?.symbol ?? 'm',
          'm',
          good.lengthMeters ?? 0
        ).value;
        const widthMeters = convertUnits(
          'distance',
          good.dimensionUnit?.symbol ?? 'm',
          'm',
          good.widthMeters ?? 0
        ).value;
        const heightMeters = convertUnits(
          'distance',
          good.dimensionUnit?.symbol ?? 'm',
          'm',
          good.heightMeters ?? 0
        ).value;

        return {
          ...basicFields,
          good: {
            ...goodWithOmmitedFields,
            packagingAmountUnitSymbol: good.packagingAmountUnit?.symbol,
            packagingAmountUnitGroupKey: good.packagingAmountUnitGroup?.key,
            grossWeightUnitSymbol: good.grossWeightUnit?.symbol,
            netWeightUnitSymbol: good.netWeightUnit?.symbol,
            netWeightKg,
            grossWeightKg,
            dimensionUnitSymbol: good.dimensionUnit?.symbol,
            widthMeters,
            heightMeters,
            lengthMeters,
            producerCompany: good.producerCompany
              ? parseCreatableField<object>(good.producerCompany)
              : null,
            barcodes: good.barcodes?.map((item) => ({
              type: item.type,
              barcode: item.barcode,
            })),
          },
        };
      }

      return {
        ...basicFields,
        service: {
          ...data.service,
          isThirdPartyService: data.service.isThirdPartyService ?? false,
        },
      };
    },
    [convertUnits]
  );
};

export const nullableNumberToString = (data: unknown): string | undefined => {
  if (typeof data === 'number' && !Number.isNaN(data)) {
    return data.toString();
  }

  return undefined;
};

export const parsePrice = (val?: string | number): string | undefined => {
  if (!val) {
    return undefined;
  }
  if (typeof val === 'number') {
    if (Number.isNaN(val)) {
      return undefined;
    }

    return val.toString();
  }
  const toNum = parseFloat(val);
  if (Number.isNaN(toNum)) {
    return undefined;
  }

  return toNum.toString();
};

export const parseCreatableField = <T extends OBJ>(
  data?: BasicCreatableField<T>
): CreatableOption | undefined => {
  if (data) {
    if ('$new' in data) {
      return { $new: data.$new };
    }

    return { ID: data.ID };
  }

  return undefined;
};

export const parseSuppliers = (
  data:
    | (
        | Contact
        | { ID: number; supplierCompany: { ID: number; name: string } }
      )[]
    | undefined
) => {
  if (data) {
    return data.reduce((all: CreatableOption[], current) => {
      if ('name' in current) {
        const parseCurrent = parseCreatableField(current);
        if (parseCurrent) {
          return [...all, parseCurrent];
        }
      }

      /** We ignore the case with supplier company, because if its here you fucked up bozo */
      return all;
    }, []);
  }

  return [];
};
