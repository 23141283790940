import { MultiContactSelect } from './MultiContactSelect';
import { SingleContactSelect } from './SingleContactSelect';
import { ContactSelectProps } from './types';

/**
 * @param isMulti - if is multi is true you will be able to select multiple contacts
 * @param createMode - chose to directly post on api - 'saveOnApi', save in form and post later
 * 'saveInForm' this will return {$new: string} when a new value is created,
 * 'none' to disable creation of new options
 * @param allowedTypes - an map of all Contact Types and boolean, to disable a type pass it as false
 * @param onChange - a on change callback
 * @param value - provide value for Contact Select
 * @param isDisabled - pass true to disable input
 */
export const ContactSelect = (props: ContactSelectProps) => {
  if (props.isMulti) {
    return <MultiContactSelect {...props} />;
  }

  return <SingleContactSelect {...props} />;
};
