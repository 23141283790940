import { chakra } from '@chakra-ui/react';

import { CardBody } from 'BootQuery/Assets/components/Card';

export const PhonebookCardBody = chakra(CardBody, {
  baseStyle: {
    px: '3',
    py: '2',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: 'full',
  },
});
