import { ReactElement } from 'react';

export const VoicemailIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M496 128c-79.5 0-144 64.5-144 144 0 28.5 10.4 50.3 26.3 74H261.7c44.2-66.1 28.4-149.6-37.7-193.7S68.5 125.9 24.3 192-2.1 347.6 64 391.7a144.2 144.2 0 0080 24.3h352c79.5 0 144-64.5 144-144s-64.5-144-144-144z"
    />
  </svg>
);

export const VoicemailSlashIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 640 512"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M496 416c79.5 0 144-64.5 144-144s-64.5-144-144-144-144 64.5-144 144c0 22.9 6.8 41.5 17.7 60.3M403 416l-90.5-70h-50.8c5.8-8.7 10.6-17.8 14.4-27L61.8 153.7c-14.4 10-27.2 22.8-37.5 38.3C-19.9 258.1-2.1 347.6 64 391.7c23.7 15.8 51.5 24.3 80 24.3h259"
    />
    <path d="M45.5 3.4C38.5-2 28.5-.8 23 6.2L3.4 31.4c-5.4 7-4.2 17 2.8 22.5l588.4 454.7c7 5.4 17 4.2 22.5-2.8l19.6-25.3c5.4-7 4.2-17-2.8-22.5L45.5 3.4z" />
  </svg>
);
