import { TemplateElement } from '@bq/components/RichText/Placeholders';

import { PartialLoadedOffer } from '../../types';
import {
  buyerNameTemplate,
  offererNameTemplate,
} from './contact-name-template';
import {
  billingStartDateTemplate,
  deliveryDateTemplate,
  offerDateTemplate,
  validUntilDateTemplate,
} from './date-template';
import { nameTemplate } from './name-template';

export const getTemplateElements: () => TemplateElement<PartialLoadedOffer>[] =
  () => [
    nameTemplate(),
    offerDateTemplate(),
    validUntilDateTemplate(),
    deliveryDateTemplate(),
    billingStartDateTemplate(),
    offererNameTemplate(),
    buyerNameTemplate(),
  ];
