import { ReactElement } from 'react';
import { Text, Tooltip } from '@chakra-ui/react';
import { FaCircle } from 'react-icons/fa';

import { DeviceStatus } from '../../types/device';
import { deviceStatusColor, deviceStatusLabel } from './labels-colors';

interface Props {
  status: DeviceStatus | null;
}

export const DeviceStatusIndicator = ({ status }: Props): ReactElement => (
  <Tooltip label={deviceStatusLabel(status)}>
    <Text as="span" color={deviceStatusColor(status)}>
      <FaCircle />
    </Text>
  </Tooltip>
);
