import { useMemo } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { chakraComponents, OptionProps } from 'chakra-react-select';

import { ContactSelectOption } from '../types';
import { CSIconForContactType } from '../utils';

export const ContactOption = (props: OptionProps<ContactSelectOption>) => {
  const Icon = useMemo(
    () => CSIconForContactType(props.data.type),
    [props.data.type]
  );

  return (
    <chakraComponents.Option {...props}>
      <HStack w="full" overflow="hidden">
        <Box w="fit-content">{Icon}</Box>
        <Box textOverflow="ellipsis" overflow="hidden" whiteSpace="nowrap">
          {props.data.label}
        </Box>
      </HStack>
    </chakraComponents.Option>
  );
};
