import { ReactElement } from 'react';

import { EventType } from './types';

export const EventTypeItem = (item: EventType): ReactElement => (
  <>
    {item.icon && <item.icon style={{ display: 'inline' }} />}&nbsp;
    {item.label}
  </>
);
