import { Flex, IconButton, Menu, MenuButton, MenuList } from '@chakra-ui/react';
import { RxHamburgerMenu } from 'react-icons/rx';

import { useIsMobile } from '@bq/components/use-is-mobile';

import { MobileBulkActionStandardButton } from './MobileBulkActionStandardButton';
import { ActionGroup } from './TableBulkActionMenu';
import { OBJ } from './types';

interface Props<T extends OBJ> {
  isSelecting: boolean;
  actionGroups: ActionGroup<T>[];
}

export const CardBulkActionsMobile = <T extends OBJ>({
  isSelecting,
  actionGroups,
}: Props<T>) => {
  const isMobile = useIsMobile();

  return (
    <>
      {!isSelecting && isMobile && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<RxHamburgerMenu />}
            variant="outline"
          />
          <MenuList>
            {actionGroups.map((group, idx) => {
              const { actions } = group;

              return (
                <Flex
                  flexDirection="column"
                  isAttached
                  {...group.styleProps}
                  isDisabled={isSelecting}
                  key={idx}
                >
                  {actions?.map((action) => {
                    return (
                      <MobileBulkActionStandardButton
                        key={action.name}
                        action={action}
                      />
                    );
                  })}
                </Flex>
              );
            })}
          </MenuList>
        </Menu>
      )}
    </>
  );
};
