import { useMemo } from 'react';

import { FilterTypes } from 'BootQuery/Assets/components/FilterBar';
import { makeComboboxFilter } from 'BootQuery/Assets/components/FilterBar/filters/ComboboxFilter';
import { numberFilterExpression } from 'BootQuery/Assets/components/FilterBar/filters/PhoneNumberFilter';
import { makeTextFilter } from 'BootQuery/Assets/components/FilterBar/filters/TextFilter';
import {
  customFormFilters,
  FieldValue,
  getFieldTypes,
} from 'BootQuery/Assets/components/FormEditor';
import { Api } from 'BootQuery/Assets/js/api';
import { ListingResponse } from 'BootQuery/Assets/js/globalTypes';
import i18n from 'BootQuery/Assets/js/i18n';

import { Department } from '../DepartmentTreeView/types';

interface ListItem {
  ID: number;
  name: string;
}

interface CompanyResponse {
  data: ListItem[];
}

interface LocationListItem extends ListItem {
  company: {
    name: string;
  };
}

interface CompanyLocationResponse {
  data: LocationListItem[];
}

export const personFilterTypes: FilterTypes = {
  firstName: makeTextFilter({
    name: 'Ime',
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return ['firstName', operator ?? 'contains:ci', value];
    },
  }),
  lastName: makeTextFilter({
    name: 'Prezime',
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return ['lastName', operator ?? 'contains:ci', value];
    },
  }),
  company: makeComboboxFilter({
    name: 'Tvrtka',
    operators: [],
    toFilter: ({ value }) => {
      if (!value) {
        return null;
      }

      return typeof value === 'string'
        ? { 'company.name:contains:ci': value }
        : { 'company.ID': value.ID };
    },
    extraProps: {
      cacheKey: 'phonebookCompanyFilter',
      search: async (search: string) => {
        const {
          data: { data: companies },
        } = await Api.get<CompanyResponse>('/api/phonebook/companies', {
          params: {
            fields: ['ID', 'name'],
            filters: search ? { $search: search } : {},
            withCount: false,
          },
        });

        return companies;
      },
      itemToString: (item: ListItem) => item.name,
      itemToValue: (company: ListItem) => company,
      valueToItem: (company: ListItem) => company,
    },
  }),
  companyLocation: makeComboboxFilter({
    name: 'Poslovnica',
    operators: [],
    toFilter: ({ value }) => {
      if (!value) {
        return null;
      }

      return typeof value === 'string'
        ? { 'companyLocation.name:contains:ci': value }
        : { 'companyLocation.ID': value.ID };
    },
    extraProps: {
      cacheKey: 'phonebookCompanyLocationFilter',
      search: async (search: string) => {
        const {
          data: { data: locations },
        } = await Api.get<CompanyLocationResponse>(
          '/api/phonebook/companyLocations',
          {
            params: {
              fields: ['ID', 'name', 'company.name'],
              filters: search ? { $search: search } : {},
              withCount: false,
            },
          }
        );

        return locations;
      },
      itemToString: (item: ListItem) => item.name,
      itemToValue: (company: ListItem) => company,
      valueToItem: (company: ListItem) => company,
    },
  }),
  phoneNumber: makeTextFilter({
    name: () => i18n.t('Phonebook:phone_number'),
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return {
        'phoneNumbers:$any': numberFilterExpression(value, operator ?? 'eq'),
      };
    },
  }),
  email: makeTextFilter({
    name: 'E-mail',
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return {
        'emails:$any': {
          [`email.email:${operator ?? 'eq'}:ci`]: value,
        },
      };
    },
  }),
  /* department: makeTextFilter({
    name: 'Odjel',
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return ['department.name', operator ?? 'contains:ci', value];
    },
  }), */
  department: makeComboboxFilter({
    name: () => i18n.t('Phonebook:department'),
    operators: [],
    toFilter: ({ value }) => {
      if (!value) {
        return null;
      }
      if (Array.isArray(value)) {
        return { 'companyDepartmentID:inArray': value };
      }
      if (typeof value === 'string') {
        return { 'department.name:contains:ci': value };
      }

      return { 'department.ID': value.ID };
    },
    extraProps: {
      cacheKey: 'phonebookDepartmentFilter',
      search: async (search: string) => {
        const {
          data: { data: departments },
        } = await Api.get<ListingResponse<Department>>(
          '/api/phonebook/departments',
          {
            params: {
              fields: ['ID', 'name'],
              filters: search ? { 'name:contains:ci': search } : {},
              sort: 'name:asc',
            },
          }
        );

        return departments;
      },
      itemToString: (item: ListItem) => item.name,
      itemToValue: (department: ListItem) => department,
      valueToItem: (department: ListItem) => department,
    },
  }),
  nationalNumber: makeTextFilter({
    name: 'Identifikacijski broj',
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return [
        'nationalNumber.nationalNumber',
        operator ?? 'contains:ci',
        value,
      ];
    },
  }),
};

export function getFilterTypes(customFields: FieldValue[] = []): FilterTypes {
  const customFilters = customFormFilters(customFields, getFieldTypes());

  return {
    ...personFilterTypes,
    ...customFilters,
  };
}

export function useFilterTypes(customFields: FieldValue[] = []): FilterTypes {
  return useMemo(() => getFilterTypes(customFields), [customFields]);
}
