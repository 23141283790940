import { ReactElement, useMemo } from 'react';
import { Heading, HStack, VStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useGroupedColumns } from '@bq/components/FormEditor';
import { useListingSettings } from '@bq/components/ListingSettings';
import { LoadingPage } from '@bq/components/LoadingPage';
import { Table } from '@bq/components/Table';
import { TableMenu } from '@bq/components/TableMenu';
import i18n from 'BootQuery/Assets/js/i18n';
import { useDebouncedQuery } from 'BootQuery/Assets/js/use-debounced-query';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { ExportLink } from './ExportLink';
import { genExportLink } from './gen-export-link';
import { useReportContext } from './ReportContext';
import { getColumns, getGroupOnlyColumns } from './settings';
import { ReportRow } from './types';
import { useGroupedBy } from './use-grouped-by';
import { loadData } from './utils';

const TicketReportContent = (): ReactElement => {
  const { t } = useTranslation('Ticketing');
  const { settings, filterVal, groupBy, filterTypes } = useReportContext();

  const { data, status } = useDebouncedQuery({
    queryKey: ['ticketingTicketsReport', settings, filterVal, groupBy],
    queryFn: () => loadData(settings, filterVal, groupBy),
  });

  const columns = useMemo(getColumns, []);
  const groupOnlyColumns = useMemo(() => {
    return [...columns, ...getGroupOnlyColumns()];
  }, [columns]);
  const specialColumns = useGroupedColumns<ReportRow>(
    useGroupedBy(),
    groupOnlyColumns
  );

  const {
    allColumns,
    density,
    setDensity,
    visibleColumns,
    setVisibleColumns,
    columnsToShow,
  } = useListingSettings({
    listingName: 'Ticketing.Reports',
    viewName: 'tickets',
    columns: [
      {
        key: 'day',
        title: i18n.t('Ticketing:reports.date'),
      },
      ...specialColumns,
      ...columns,
    ],
    filterTypes,
  });

  const exportLink = genExportLink(
    '/api/ticketing/reports/tickets/export',
    settings,
    columnsToShow,
    groupBy
  );

  return (
    <VStack>
      <HStack w="full" justifyContent="space-between" px={5} py={3}>
        <Heading
          as="h2"
          display="flex"
          alignItems="center"
          fontSize="lg"
          mb="0"
          textAlign="left"
        >
          {t('Ticketing:reports.tickets_report_title')}
        </Heading>
        <TableMenu
          visibleColumns={{
            columns: allColumns,
            value: visibleColumns,
            onChange: setVisibleColumns,
          }}
          density={{ value: density, onChange: setDensity }}
        >
          {exportLink && <ExportLink link={exportLink} />}
        </TableMenu>
      </HStack>
      <Table<ReportRow>
        columns={columnsToShow}
        rows={data ?? []}
        isLoading={status === 'pending'}
        size={density}
      />
    </VStack>
  );
};

export const TicketReport = (): ReactElement => {
  const settingsLoaded = useLoadUserSettings('Ticketing.Reports.tickets', {});

  return settingsLoaded ? <TicketReportContent /> : <LoadingPage />;
};
