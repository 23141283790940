import { useState } from 'react';
import {
  FormControl,
  FormLabel,
  Popover,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaLink } from 'react-icons/fa';

import { IconButton } from 'BootQuery/Assets/components/IconButton';

import { useFocusProvider } from '../../FocusContext';
import { useRichText } from '../../RichtextContex';
import { URLtab } from './UrlTab';

export const AddLink = () => {
  const [linkURL, setLinkURL] = useState<string>('');
  const { t } = useTranslation();
  const { editor } = useRichText();
  const submitHandler = () => {
    if (linkURL !== '') {
      editor
        ?.chain()
        .focus()
        .extendMarkRange('link')
        .setLink({ href: linkURL })
        .run();
      setLinkURL('');
    } else {
      editor?.chain().focus().extendMarkRange('link').unsetLink().run();
    }
  };

  const { tabIndex, handleBlur, handleEscapingToolbar } = useFocusProvider();

  return (
    <Popover placement="bottom">
      <PopoverTrigger>
        <IconButton
          onKeyDown={(event) => {
            event.stopPropagation();
            if (event.key === 'Escape') {
              handleEscapingToolbar();
            }
          }}
          onBlur={handleBlur}
          tabIndex={tabIndex}
          icon={<FaLink />}
          label={t('global:richtext.add_link')}
          size="sm"
        />
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverCloseButton zIndex="overlay" />
          <FormControl>
            <PopoverHeader borderBottom="1px solid gray.200">
              <FormLabel
                htmlFor="imageLink imageUpload"
                textAlign="center"
                w="full"
              >
                {t('global:richtext.add_link')}
              </FormLabel>
            </PopoverHeader>
            <PopoverBody>
              <URLtab
                value={linkURL}
                changeCallback={(newVal) => setLinkURL(newVal ?? '')}
                submitHandler={submitHandler}
              />
            </PopoverBody>
          </FormControl>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
