import { inputAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { BrandColors } from 'BootQuery/Assets/js/brand-colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const baseStyle = (brandColors: BrandColors) => {
  return definePartsStyle({
    field: {
      _light: {
        _focus: {
          borderColor: brandColors[300],
          boxShadow: `0 0 0 1px ${brandColors[300]}`,
        },
      },
      _focus: {
        borderColor: brandColors[300],
        boxShadow: `0 0 0 1px ${brandColors[300]}`,
      },
      _dark: {
        _focus: {
          borderColor: brandColors[100],
          boxShadow: `0 0 0 1px ${brandColors[100]}`,
        },
      },
    },
  });
};

export const inputTheme = (brandColors: BrandColors) => {
  return defineMultiStyleConfig({
    baseStyle: baseStyle(brandColors),
  });
};
