import { Checkbox, FormControl, FormLabel } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Service } from '../../../types';
import { FieldProps } from '../../types';

export const IsThirdPartyService = ({ formControlProps }: FieldProps) => {
  const { t } = useTranslation();
  const { register } = useFormContext<Service>();

  return (
    <FormControl
      {...formControlProps}
      display="flex"
      flexDirection="row"
      alignItems="center"
    >
      <FormLabel htmlFor="isThirdPartyService" textAlign="center">
        {`${t('Products:is_third_party_service')}?`}
      </FormLabel>
      <Checkbox {...register('service.isThirdPartyService')} size="lg" />
    </FormControl>
  );
};
