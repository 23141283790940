import { UIEvent, UIEventHandler } from 'react';
import mitt from 'mitt';

import { createUseMitt } from 'BootQuery/Assets/js/use-mitt';

type ScrollEv = UIEvent<HTMLDivElement, globalThis.UIEvent>;

// Must be object instead of interface because of type constraints
export type ScrollEvents = {
  scroll: ScrollEv;
  scrollBottom: ScrollEv;
};

export const scrollEvents = mitt<ScrollEvents>();

export const useScrollEvents = createUseMitt(scrollEvents);

export const handleScroll: UIEventHandler<HTMLDivElement> = (ev) => {
  scrollEvents.emit('scroll', ev);
  const target = ev.currentTarget;
  const { scrollHeight, scrollTop, clientHeight } = target;
  const hasHitBottom = scrollHeight - scrollTop - 10 <= clientHeight;

  if (hasHitBottom) {
    scrollEvents.emit('scrollBottom', ev);
  }
};
