import { ReactElement } from 'react';

import { useIsMobile } from '@bq/components/use-is-mobile';
import { FieldValue } from 'BootQuery/Assets/components/FormEditor';
import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';

import { CallListPermissions } from './call-list-permissions';
import { CallCards } from './CallCards';
import { MobileCallCards } from './CallCards/MobileCallCards';
import { CallsTable } from './Table';
import { useDisplayMode } from './use-display-mode';

interface Props {
  overviews: IOverviewEditorItem[];
  permissions: CallListPermissions;
  customFields: FieldValue[];
}

export const CallListContent = ({
  overviews,
  permissions,
  customFields,
}: Props): ReactElement => {
  const isMobile = useIsMobile();
  const [displayMode] = useDisplayMode();

  if (isMobile) {
    return (
      <MobileCallCards
        overviews={overviews}
        permissions={permissions}
        customFields={customFields}
      />
    );
  }

  return displayMode === 'table' ? (
    <CallsTable
      overviews={overviews}
      permissions={permissions}
      customFields={customFields}
    />
  ) : (
    <CallCards
      overviews={overviews}
      permissions={permissions}
      customFields={customFields}
    />
  );
};
