import { ReactElement } from 'react';
import { chakra, Flex, Tooltip } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  AnnouncementIcon,
  BargeIcon,
  ConferenceIcon,
  MailboxIcon,
  QueueIcon,
  ReadIcon,
  RingGroupIcon,
  SpyIcon,
  SurveyIcon,
  VoicemailIcon,
  WhisperIcon,
} from '../icons';
import { CallPoint, CallPointType } from '../types/call';
import { ContactDisplay } from './ContactDisplay';
import { NumberDisplay } from './NumberDisplay/NumberDisplay';

export interface Props {
  party: CallPoint | null;
  phoneNumber: string | null;
  fontSize?: string;
  allowWrap?: boolean;
}

const PhoneNumber = ({
  phoneNumber,
  fontSize = 'sm',
}: {
  phoneNumber: string;
  fontSize?: string;
}): ReactElement => (
  <NumberDisplay phoneNumber={phoneNumber} fontSize={fontSize} />
);

interface PartyDisplay {
  icon: () => ReactElement;
  typeLabel: string;
}

const simplePartyDisplay: Record<
  Exclude<CallPointType, 'phonePoint' | 'calleeNumber' | 'playbackRecording'>,
  PartyDisplay
> = {
  announcement: {
    icon: AnnouncementIcon,
    typeLabel: 'Announcement',
  },
  bargeUser: {
    icon: BargeIcon,
    typeLabel: 'Barge',
  },
  conference: {
    icon: ConferenceIcon,
    typeLabel: 'Conference',
  },
  mailbox: {
    icon: MailboxIcon,
    typeLabel: 'Mailbox',
  },
  queue: {
    icon: QueueIcon,
    typeLabel: 'Queue',
  },
  read: {
    icon: ReadIcon,
    typeLabel: 'Read (DTMF)',
  },
  ringGroup: {
    icon: RingGroupIcon,
    typeLabel: 'Ring group',
  },
  spyUser: {
    icon: SpyIcon,
    typeLabel: 'Spy',
  },
  survey: {
    icon: SurveyIcon,
    typeLabel: 'Survey',
  },
  voicemail: {
    icon: VoicemailIcon,
    typeLabel: 'Voicemail',
  },
  whisperUser: {
    icon: WhisperIcon,
    typeLabel: 'Whisper',
  },
};

export const CallParty = ({
  party,
  phoneNumber,
  fontSize,
  allowWrap,
}: Props): ReactElement => {
  const { t } = useTranslation('Telephony');

  if (!party) {
    return <> - </>;
  }

  if (party.type === 'phonePoint') {
    if (party.point.contact) {
      return (
        <ContactDisplay
          contact={party.point.contact}
          phoneNumber={phoneNumber}
          fontSize={fontSize}
          allowWrap={allowWrap}
        />
      );
    }
    const user = party.point.userPoint?.pbxUser?.users[0];
    if (user) {
      return (
        <Flex alignItems="center">
          <NumberDisplay
            phoneNumber={phoneNumber ?? ''}
            user={user}
            fontSize={fontSize}
          />
        </Flex>
      );
    }

    return (
      <>
        {phoneNumber ? (
          <PhoneNumber phoneNumber={phoneNumber} fontSize={fontSize} />
        ) : (
          <strong className="text-danger">
            {t('Telephony:details.unknown')}
          </strong>
        )}
      </>
    );
  }

  if (
    party.type === 'spyUser' ||
    party.type === 'whisperUser' ||
    party.type === 'bargeUser'
  ) {
    const simpleDisplay = simplePartyDisplay[party.type];
    const actionDisplay = (
      <Tooltip label={simpleDisplay.typeLabel}>
        <SimpleDisplay>
          <simpleDisplay.icon />{' '}
        </SimpleDisplay>
      </Tooltip>
    );

    if (party.point.contact) {
      return (
        <>
          {actionDisplay}&nbsp;
          <ContactDisplay
            contact={party.point.contact}
            phoneNumber={phoneNumber}
          />
        </>
      );
    }

    return (
      <>
        {actionDisplay}&nbsp;
        <PhoneNumber phoneNumber={party.point.phoneNumber.phoneNumberE164} />
      </>
    );
  }

  const simpleDisplay = simplePartyDisplay[party.type];
  if (simpleDisplay) {
    // Tooltip only wants a single child, content surrounded with a span to avoid errors
    return (
      <Tooltip label={simpleDisplay.typeLabel}>
        <SimpleDisplay>
          <simpleDisplay.icon />{' '}
          {party.type === 'voicemail'
            ? party.point.mailbox.name
            : party.point.name}
        </SimpleDisplay>
      </Tooltip>
    );
  }

  // Fallback if we missed something
  return (
    <pre style={{ marginBottom: 0 }}>{JSON.stringify(party, null, 2)}</pre>
  );
};

const SimpleDisplay = chakra('div', {
  baseStyle: {
    display: 'block',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});
