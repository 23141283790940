import { ReactElement } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import ScaleLoader from 'react-spinners/ScaleLoader';

interface LoaderScaleProps {
  height?: number | string;
}

export const LoaderScale = ({
  height = 10,
}: LoaderScaleProps): ReactElement => {
  const textColor = useColorModeValue('gray.800', 'whiteAlpha.900').replace(
    '.',
    '-'
  );
  const spinnerColor = `var(--chakra-colors-${textColor})`;

  return <ScaleLoader height={height} color={spinnerColor} />;
};
