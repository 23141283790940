import { useTranslation } from 'react-i18next';
import { FaPhone } from 'react-icons/fa';

import { EventCellComponent } from 'app/Modules/Events/Assets/components';

import { CallEvent } from './types';

export const CallTypeColumn: EventCellComponent<CallEvent> = () => {
  const { t } = useTranslation('Telephony');

  return (
    <>
      <FaPhone style={{ display: 'inline' }} />
      &nbsp;
      {t('Telephony:call')}
    </>
  );
};
