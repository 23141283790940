import { ReactElement } from 'react';

import { PlaceholderRow } from './PlaceholderRow';

export const PlaceholderRows = ({
  rowCount,
  colCount,
}: {
  rowCount: number;
  colCount: number;
}): ReactElement => {
  const rows = Array(rowCount).fill(null);

  return (
    <>
      {rows.map((_el, idx) => (
        <PlaceholderRow key={idx} colCount={colCount} />
      ))}
    </>
  );
};
