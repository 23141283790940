import { ModalBody, ModalContent } from '@chakra-ui/react';

import { LoadingPage } from '../LoadingPage';

export const LoadingModal = () => {
  return (
    <ModalContent>
      <ModalBody>
        <LoadingPage />
      </ModalBody>
    </ModalContent>
  );
};
