import {
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverTrigger,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';

import { ColorPicker } from 'BootQuery/Assets/components/ColorPicker';
import { FixedPopoverContent } from 'BootQuery/Assets/components/FixedPopoverContent';

interface BaseProps {
  clearable?: boolean;
  value?: string | null;
  size?: string;
  customTrigger: ReactNode;
}
interface ClearableProps extends BaseProps {
  onChange?: (color: string | null) => void;
  onClear?: () => void;
  clearable: true;
}
interface NonClearableProps extends BaseProps {
  onChange?: (color: string) => void;
  clearable?: false;
}
type Props = ClearableProps | NonClearableProps;

export const ColorInputCustomTrigger = ({
  value,
  customTrigger,
  size,
  ...props
}: Props): ReactElement => {
  const { t } = useTranslation();
  const [color, setColor] = useState<string | null>(value ?? null);

  // Sync outside changes
  useEffect(() => {
    setColor(value ?? null);
  }, [value]);

  const handleChange = useCallback(
    (newVal: string | null) => {
      setColor(newVal);
      if (props.onChange) {
        if (props.clearable) {
          props.onChange(newVal);
        } else if (newVal) {
          props.onChange(newVal);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.onChange, props.clearable]
  );

  const clearColor = useCallback(() => {
    handleChange(null);
    if (props.clearable) {
      if (props.onClear) {
        props.onClear();
      }
    }
  }, [handleChange, props]);

  return (
    <Popover>
      <PopoverTrigger>{customTrigger}</PopoverTrigger>
      <FixedPopoverContent width="auto" p={0}>
        <PopoverArrow />
        <PopoverBody p={0}>
          <ColorPicker
            border="none"
            color={color ?? 'white'}
            onChange={handleChange}
            borderBottomRadius={props.clearable ? 0 : undefined}
          />
          {props.clearable && (
            <Button size="sm" display="flex" w="full" onClick={clearColor}>
              <FaTimes />
              {t('global:clear')}
            </Button>
          )}
        </PopoverBody>
      </FixedPopoverContent>
    </Popover>
  );
};
