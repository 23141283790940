import { forwardRef } from 'react';
import { Breadcrumb, BreadcrumbProps } from '@chakra-ui/react';

// eslint-disable-next-line react/display-name
export const Breadcrumbs = forwardRef<HTMLElement, BreadcrumbProps>(
  (props, ref) => (
    <Breadcrumb
      overflow="hidden"
      whiteSpace="nowrap"
      {...props}
      ref={ref}
      className="no-bottom-margin-breadcrumb"
      sx={{ '& > ol': { marginBottom: 0 } }}
    />
  )
);
