import i18n from 'BootQuery/Assets/js/i18n';

import { ItemColumn } from '../types';

export const logisticalDataColumns = (): ItemColumn[] => [
  heightColumn(),
  widthColumn(),
  lengthColumn(),
  netWeightColumn(),
  grossWeightColumn(),
];

const heightColumn = () => ({
  key: 'height',
  title: i18n.t('Products:height'),
  order: 400,
});
const widthColumn = () => ({
  key: 'width',
  title: i18n.t('Products:width'),
  order: 401,
});
const lengthColumn = () => ({
  key: 'length',
  title: i18n.t('Products:length'),
  order: 402,
});
const netWeightColumn = () => ({
  key: 'netWeight',
  title: i18n.t('Products:net_weight'),
  order: 403,
});
const grossWeightColumn = () => ({
  key: 'grossWeight',
  title: i18n.t('Products:gross_weight'),
  order: 404,
});
