import { useQuery } from '@tanstack/react-query';

import { Api } from 'BootQuery/Assets/js/api';

import { LocalCompany } from './types';

export const getLocalCompany = async () => {
  const data = await Api.get<LocalCompany>('/api/phonebook/localCompany');

  return data;
};

export const useLocalCompany = () => {
  return useQuery<LocalCompany>({
    queryKey: ['localCompany'],
    queryFn: async () => {
      const data = await getLocalCompany();

      return data.data;
    },
  });
};
