import { memo, ReactElement } from 'react';
import { Flex, Input, usePopper } from '@chakra-ui/react';
import { useCombobox } from 'downshift';
import { pick } from 'lodash-es';
import { useTranslation } from 'react-i18next';

import { downshiftKeydownFix } from 'BootQuery/Assets/js/downshift-util';

import { AutocompleteList } from './AutocompleteList';
import { FilterType } from './types';
import { nameStr } from './util';

interface FilterBarSearchProps {
  filters: Record<string, FilterType>;
  value: string;
  onChange: (value: string) => void;
  onSelect: (key: string) => void;
}

const FilterBarSearch = memo(
  ({
    filters,
    value,
    onChange,
    onSelect,
  }: FilterBarSearchProps): ReactElement => {
    const { t } = useTranslation();

    const filterList = Object.entries(filters)
      .map(([key, filter]) => ({
        ...filter,
        name: nameStr(filter.name),
        key,
      }))
      .filter((filter) => filter.selectable !== false);

    const searchStr = value.toLocaleLowerCase();
    const items = filterList.filter((filter) => {
      return nameStr(filter.name).toLowerCase().includes(searchStr);
    });

    const comboboxProps = useCombobox({
      items,
      inputValue: value,
      itemToString: (filter) => nameStr(filter?.name ?? ''),
      onSelectedItemChange: ({ selectedItem }) => {
        if (selectedItem && selectedItem.key) {
          onSelect(selectedItem.key);
        }
      },
      stateReducer: (state, { type, changes }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputBlur:
            // Don't auto select item on input blur
            return { ...changes, selectedItem: state.selectedItem };
          default:
            return changes; // Otherwise leave default behaviour
        }
      },
    });

    const autocompleteProps = pick(comboboxProps, [
      'isOpen',
      'getMenuProps',
      'getItemProps',
      'selectItem',
      'highlightedIndex',
    ]);
    const { isOpen, openMenu, getInputProps, getComboboxProps } = comboboxProps;

    const { ref: downshiftInputRef, ...inputProps } = getInputProps({
      value,
      onChange(ev) {
        if (!(ev.target instanceof HTMLInputElement)) {
          return;
        }
        onChange(ev.target.value);
      },
      onFocus() {
        if (!isOpen) {
          openMenu();
        }
      },
      onKeyDown: downshiftKeydownFix,
    });
    const { popperRef, referenceRef } = usePopper({
      placement: 'bottom-start',
    });

    return (
      <Flex direction="column" {...getComboboxProps()} flexGrow={1}>
        <Input
          {...inputProps}
          ref={(ref) => {
            downshiftInputRef(ref);
            referenceRef(ref);
          }}
          minWidth="4"
          background="none"
          borderLeftRadius={0}
          _hover={{}}
          _focus={{}}
          borderRadius={0}
          border={{}}
          flexGrow={1}
          flexShrink={1}
          placeholder={t('global:filters.filterbar_placeholder') ?? undefined}
          lineHeight={1}
          height="8"
        />
        <AutocompleteList
          ref={popperRef}
          items={items}
          {...autocompleteProps}
        />
      </Flex>
    );
  }
);
FilterBarSearch.displayName = 'FilterBarSearch';

export { FilterBarSearch, FilterBarSearchProps };
