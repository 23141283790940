import { ReactElement } from 'react';
import { Link } from '@chakra-ui/react';

import { EventCellComponent } from 'app/Modules/Events/Assets/components';
import { contactLink } from 'app/Modules/Phonebook/Assets/js/util';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

import { Contact, Destination } from '../../types';
import { contactName } from '../ContactDisplay';
import { CallEvent } from './types';

export const CallContactColumn: EventCellComponent<CallEvent> = ({
  row: { data: call },
}) => {
  const contacts = [
    call.sourcePhonePoint.phonePoint.contact,
    ...call.destinations.map(getDestinationContact),
  ].filter((contact): contact is Contact => !!contact);
  console.log('Contactinjos: ', contacts);

  return (
    <WithSeparator separator=",&nbsp;">
      {contacts.map((contact) => (
        <ContactDisplay key={`${contact.type}-${contact.ID}`} {...contact} />
      ))}
    </WithSeparator>
  );
};

const ContactDisplay = (contact: Contact): ReactElement => {
  const link = contactLink(contact);
  const name = contactName(contact);

  return link ? <Link href={link}>{name}</Link> : <>{name}</>;
};

function getDestinationContact(dest: Destination): Contact | null {
  if (dest.destination.type === 'phonePoint') {
    return dest.destination.point.contact ?? null;
  }

  return null;
}
