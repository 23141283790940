import { EventEmitter } from 'events';

import { ReactRouteMatcher } from './react-routes';
import SocketEventListener from './socket-event-listener';

export default class Module extends EventEmitter {
  socketEvents: SocketEventListener;

  constructor() {
    super();

    this.socketEvents = new SocketEventListener(this.constructor.name);
  }

  public init(data: Record<string, unknown>): void {
    /* Does nothing by default, declared in module */
  }

  public activateElements(target: string, data: Record<string, unknown>): void {
    /* Does nothing by default, declared in module */
  }

  public static canHandleRoute(route: string): boolean {
    return false;
  }

  public static matchReactRoute?: ReactRouteMatcher;
}
