import { ReactElement, useCallback, useMemo } from 'react';
import { Spinner } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import { upperFirst } from 'lodash-es';

import { getContacts } from '@bq/components/FormFields/Contact/API/getContacts';
import { modalSelect } from 'BootQuery/Assets/components/Select/select-styles';

import { usePhonebookFormsContext } from '../ModalForms';
import { Permissions } from '../types';
import { usePermissions } from '../use-permissions';
import { OptionLabel } from './OptionLabel';
import { ContactOption, ContactValue, OnContactChange } from './types';
import { selectToValue, splitName, valueToSelect } from './util';

interface Props {
  value: ContactValue | null;
  onChange: OnContactChange;
}

export const ContactSelector = ({ value, onChange }: Props): ReactElement => {
  const contact = useMemo(() => valueToSelect(value), [value]);
  const { createPerson, createCompany } = usePhonebookFormsContext();
  const { data: permissions, isLoading } = usePermissions();

  const handleChange = useCallback(
    (val: ContactOption | null) => {
      // eslint-disable-next-line no-underscore-dangle
      if (val?.__isNew__) {
        const search = val.search?.trim() ?? '';
        if (val.value === '$new-company') {
          createCompany({ name: upperFirst(search) }, (companyID, data) => {
            onChange(
              { ID: companyID, name: data.name ?? '', type: 'company' },
              true
            );
          });

          return;
        }
        if (val.value === '$new-person') {
          createPerson(splitName(search), (personID, data) => {
            onChange(
              {
                ID: personID,
                name: `${data.firstName ?? ''} ${data.lastName ?? ''}`.trim(),
                type: 'person',
              },
              true
            );
          });

          return;
        }
      }

      onChange(selectToValue(val), false);
    },
    [createCompany, createPerson, onChange]
  );
  if (isLoading || !permissions) {
    return <Spinner />;
  }

  return (
    <AsyncSelect<ContactOption>
      cacheOptions
      defaultOptions
      isClearable
      loadOptions={(search: string) => {
        return getResults(search, permissions);
      }}
      value={contact}
      onChange={handleChange}
      formatOptionLabel={OptionLabel}
      menuPortalTarget={document.body}
      styles={modalSelect}
      selectedOptionColorScheme="brand"
    />
  );
};

async function getResults(
  search: string,
  permissions: Permissions
): Promise<ContactOption[]> {
  const allowedTypes = parsePermissionsToArray(permissions);
  const contacts =
    allowedTypes.length > 0 ? await getContacts(search, allowedTypes) : [];

  const options = contacts.map((contact) => {
    const val = valueToSelect(contact);
    if (!val) {
      throw new Error('Somehow got null contact value');
    }

    return val;
  });

  if (search.length > 0) {
    return [
      ...options,
      ...(permissions.edit.companies
        ? [
            {
              __isNew__: true,
              label: 'new-company',
              value: '$new-company',
              search,
            },
          ]
        : []),
      ...(permissions.edit.people
        ? [
            {
              __isNew__: true,
              label: 'new-person',
              value: '$new-person',
              search,
            },
          ]
        : []),
    ];
  }

  return options;
}

const parsePermissionsToArray = (permissions: Permissions) => {
  return [
    ...(permissions.edit.people ? ['person'] : []),
    ...(permissions.edit.companies ? ['company'] : []),
    ...(permissions.edit.departments ? ['companyDepartment'] : []),
    ...(permissions.edit.locations ? ['companyLocation'] : []),
  ];
};
