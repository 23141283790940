import { escape } from 'lodash-es';

// Pre is used so it works with leading/trailing spaces
const baseGhostElementStyle = `
    display: inline-block;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    visibility: hidden;
    white-space: pre;
`;

const ghostElementId = 'inputAutosizerGhost';

interface Options {
  [key: string]: unknown;
  minWidth?: number;
}

export default class InputAutosizer {
  private options: Options;

  private element: HTMLInputElement;

  constructor(input: HTMLInputElement, options: Options = {}) {
    const isInput = input instanceof HTMLInputElement;
    if (!isInput) {
      throw new Error('Constructor expects an input element');
    }

    this.options = {
      baseGhostElementStyle,
      minWidth: 10,
      ...(options || {}),
    };
    this.element = input;
  }

  init(): void {
    this.update();
    this.element.addEventListener('input', this.update.bind(this));
  }

  update(): number {
    const value =
      this.element.value || this.element.getAttribute('placeholder') || '';
    const elementStyle = window.getComputedStyle(this.element);

    const ghost = this.getGhostElement();
    ghost.style.cssText = `
			${baseGhostElementStyle}
			box-sizing: ${elementStyle.boxSizing};
			border-left: ${elementStyle.borderLeftWidth} solid red;
			border-right: ${elementStyle.borderRightWidth} solid red;
			font-family: ${elementStyle.fontFamily};
			font-feature-settings: ${elementStyle.fontFeatureSettings};
			font-kerning: ${elementStyle.fontKerning};
			font-size: ${elementStyle.fontSize};
			font-stretch: ${elementStyle.fontStretch};
			font-style: ${elementStyle.fontStyle};
			font-variant: ${elementStyle.fontVariant};
			font-variant-caps: ${elementStyle.fontVariantCaps};
			font-variant-ligatures: ${elementStyle.fontVariantLigatures};
			font-variant-numeric: ${elementStyle.fontVariantNumeric};
			font-weight: ${elementStyle.fontWeight};
			letter-spacing: ${elementStyle.letterSpacing};
			margin-left: ${elementStyle.marginLeft};
			margin-right: ${elementStyle.marginRight};
			padding-left: ${elementStyle.paddingLeft};
			padding-right: ${elementStyle.paddingRight};
			text-indent: ${elementStyle.textIndent};
			text-transform: ${elementStyle.textTransform};
		`;
    ghost.innerHTML = escape(value);

    const ghostWidth = parseFloat(window.getComputedStyle(ghost).width);
    const width = Math.max(ghostWidth, this.options.minWidth || 0);
    this.element.style.width = `${width}px`;

    return width;
  }

  createGhostElement(): HTMLElement {
    const ghostElement = document.createElement('div');
    ghostElement.classList.add('input-autosizer-ghost');
    ghostElement.id = 'inputAutosizerGhost';
    ghostElement.style.cssText = baseGhostElementStyle;
    document.body.appendChild(ghostElement);

    return ghostElement;
  }

  getGhostElement(): HTMLElement {
    return document.getElementById(ghostElementId) || this.createGhostElement();
  }
}
