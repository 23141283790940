import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import { TicketType } from '../../types';

interface LimitNullGetListingParams extends Omit<GetListingParams, 'limit'> {
  limit?: number | null;
}
export const getTypes = async <T = TicketType>(
  params: LimitNullGetListingParams = {}
) => {
  const data = await Api.get<T[]>('/api/ticketing/types', {
    params,
  });

  return data;
};
