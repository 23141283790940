import { ReactElement, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import { Select } from 'chakra-react-select';

import { chakraSelectStyles } from '../Select';
import { Operator } from './types';

interface FilterOptions {
  value: string;
  label: string;
  operator: Operator;
}
interface FilterOperatorProps {
  operators: Operator[];
  value: string | null;
  onChange?: (operator: string) => void;
}

export const FilterTagOperator = (props: FilterOperatorProps): ReactElement => {
  const { operators, value, onChange } = props;
  const options = useMemo(() => {
    return operators.map((operator) => ({
      value: operator.operator,
      label: operator.display,
      operator,
    }));
  }, [operators]);
  const selected = options.find((op) => op.value === value);
  const handleChange = (val: string) => onChange && onChange(val);

  return (
    <Select<FilterOptions>
      size="sm"
      useBasicStyles={true}
      chakraStyles={{
        dropdownIndicator: (props) => ({
          ...props,
          w: '10px',
          border: 'none',
          bg: 'none',
        }),
        valueContainer: (props) => ({
          ...props,
          paddingInlineEnd: '0',
        }),
        singleValue: (props) => ({
          ...props,
          maxW: 'full',
          pos: 'static',
          transform: 'none',
        }),
        control: (props) => ({ ...props, border: 'none' }),
        container: (provided) => ({
          ...provided,
          w: 'full',
          alignItems: 'center',
        }),
        menu: (provided) => ({
          ...provided,
          minWidth: '140px',
          width: 'max-content',
        }),
      }}
      variant="filled"
      isClearable={false}
      options={options}
      onChange={(operator) => handleChange(operator?.value ?? '')}
      value={selected}
      styles={chakraSelectStyles}
      isSearchable={false}
      selectedOptionColorScheme="brand"
    />
  );
};

export const FilterOperatorInput = (
  props: FilterOperatorProps
): ReactElement => {
  const { operators, value, onChange } = props;
  const options = useMemo(() => {
    return operators.map((operator) => ({
      value: operator.operator,
      label: operator.display,
      operator,
    }));
  }, [operators]);
  const selected = options.find((op) => op.value === value);
  const handleChange = (val: string) => onChange && onChange(val);

  return (
    <Box w="full" height="8">
      <Select<FilterOptions>
        size="md"
        isClearable={false}
        options={options}
        onChange={(operator) => handleChange(operator?.value ?? '')}
        value={selected}
        styles={chakraSelectStyles}
      />
    </Box>
  );
};
