import { useEffect, useState } from 'react';
import LRU from 'lru-cache';
import hash from 'object-hash';

import { ValBase } from './types';

export const valueItemCache = new LRU<string, ValBase>({ max: 100 });
const inProgressFetches: Record<string, Promise<unknown> | undefined> = {};

export function getValueForItem<
  ValType extends ValBase,
  ItemType extends ValBase,
>(
  val: ValType | string | null,
  getItem: (val: ValType) => ItemType | Promise<ItemType>,
  cacheKey: string
): ItemType | Promise<ItemType> | string | null {
  if (val === null || typeof val === 'string') {
    return val;
  }

  const key = `${cacheKey}-${hash(val)}`;
  if (valueItemCache.has(key)) {
    return valueItemCache.get(key) as ItemType;
  }

  const pending = inProgressFetches[key];
  if (pending) {
    return pending as Promise<ItemType>;
  }

  const itemRet = getItem(val);
  if (itemRet instanceof Promise) {
    inProgressFetches[key] = itemRet;

    return itemRet.then((item) => {
      delete inProgressFetches[key];
      valueItemCache.set(key, item);

      return item;
    });
  }

  return itemRet;
}

export function useValueToItem<
  ValType extends ValBase,
  ItemType extends ValBase,
>(
  val: ValType | string | null,
  getItem: (val: ValType) => ItemType | Promise<ItemType>,
  cacheKey: string
): {
  item: ItemType | string | null;
  itemReady: boolean;
} {
  const [item, setItem] = useState<ItemType | string | null>(null);
  const [itemReady, setItemReady] = useState<boolean>(false);

  useEffect(() => {
    const itemRet = getValueForItem(val, getItem, cacheKey);
    if (itemRet instanceof Promise) {
      setItemReady(false);
      itemRet.then((item) => {
        setItem(item);
        setItemReady(true);
      });
    } else {
      setItem(itemRet);
      setItemReady(true);
    }
  }, [val, getItem, cacheKey]);

  return { item, itemReady };
}
