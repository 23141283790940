import { StyleProps, useColorModeValue, VStack } from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import { SortDirection } from './types';

interface Props extends StyleProps {
  direction?: SortDirection;
}

export const CellArrowsSort = ({ direction, ...styles }: Props) => {
  const selectedChevronColor = useColorModeValue(
    'var(--chakra-colors-brand-900)',
    'var(--chakra-colors-brand-300)'
  );
  const defaultColorChevron = useColorModeValue(
    'var(--chakra-colors-brand-900)',
    'var(--chakra-colors-gray-100)'
  );

  const on =
    window.Bootstrap.bootquery.brandColor === null
      ? defaultColorChevron
      : selectedChevronColor;

  const off = useColorModeValue('lightgray', 'darkgray');

  return (
    <VStack display="inline-flex" position="absolute" top="calc(-1em / 4)">
      <FaChevronUp
        color={direction === 'asc' ? on : off}
        style={{ margin: 0 }}
      ></FaChevronUp>
      <FaChevronDown
        color={direction === 'desc' ? on : off}
        style={{ margin: 0 }}
      ></FaChevronDown>
    </VStack>
  );
};
