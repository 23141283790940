import { create as zustand } from 'zustand';

type ColorMode = 'light' | 'dark';
type ColorModeSetting = ColorMode | 'system';

const darkModeQuery = window.matchMedia('(prefers-color-scheme: dark)');

function determineColorMode(setting: ColorModeSetting): ColorMode {
  if (setting === 'system') {
    return darkModeQuery.matches ? 'dark' : 'light';
  }

  return setting;
}

function readColorModeSetting(): ColorModeSetting {
  const storedPref = localStorage.getItem('colorMode');
  if (
    storedPref === 'system' ||
    storedPref === 'light' ||
    storedPref === 'dark'
  ) {
    return storedPref;
  }

  return 'system';
}

function writeColorModeSetting(setting: ColorModeSetting): void {
  localStorage.setItem('colorMode', setting);
}

export interface ColorModeStore {
  colorModeSetting: ColorModeSetting;
  setColorModeSetting: (mode: ColorModeSetting) => void;
  colorMode: ColorMode;
}

// Initial values from localStorage or defaults
const colorModeSetting = readColorModeSetting();
const colorMode = determineColorMode(colorModeSetting);

export const colorModeStore = zustand<ColorModeStore>((set, get) => {
  // React to real time changes.
  // Useful for scheduled night mode on some operating systems or theme changes.
  darkModeQuery.addEventListener('change', ({ matches: isDark }) => {
    const colorMode = isDark ? 'dark' : 'light';
    const { colorModeSetting } = get();
    if (colorModeSetting === 'system') {
      set({ colorMode });
    }
  });

  window.addEventListener('storage', ({ key, newValue }) => {
    if (key !== 'colorMode') {
      return;
    }

    if (newValue === 'system' || newValue === 'light' || newValue === 'dark') {
      get().setColorModeSetting(newValue);
    }
  });

  return {
    colorModeSetting,
    colorMode,
    setColorModeSetting: (colorModeSetting) => {
      set({
        colorModeSetting,
        colorMode: determineColorMode(colorModeSetting),
      });
      writeColorModeSetting(colorModeSetting);
    },
  };
});
