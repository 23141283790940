import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';

import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';
import { useLoadUserSettings } from 'BootQuery/Assets/js/user-settings';

import { ProductSettingsProvider } from './Context/ProductSettingContext';
import { ProductList } from './ProductList';

export const Settings = (): ReactElement => {
  const loaded = useLoadUserSettings('ProductSettings', {});
  if (!loaded) {
    return <LoadingPage />;
  }

  return (
    <Box>
      <ProductSettingsProvider>
        <ProductList />
      </ProductSettingsProvider>
    </Box>
  );
};
