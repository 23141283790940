import { createContext } from 'react';

import { IOverviewEditorItemId } from '../types';

export interface IOverviewEditorContext {
  editingPersonal: boolean;
  selectedId: string | null;
  setSelectedId: (id: string | null) => void;
  overviews: IOverviewEditorItemId[];
  tempOverviews: IOverviewEditorItemId[];
  setTempOverviews: (overviews: IOverviewEditorItemId[]) => void;
  withGroupVisibility: boolean;
}

export const OverviewEditorContext = createContext<IOverviewEditorContext>({
  editingPersonal: false,
  selectedId: null,
  setSelectedId: () => {
    /* No context */
  },
  overviews: [],
  tempOverviews: [],
  setTempOverviews: () => {
    /* No context */
  },
  withGroupVisibility: false,
});
