import { ReactElement } from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { ExportMenuItem, ExportModalProvider } from '@bq/components/Export';
import { FieldValue } from '@bq/components/FormEditor';
import {
  useHeadingProps,
  useListingSettings,
} from '@bq/components/ListingSettings';
import { defaultDisplayModeOptions } from '@bq/components/TableMenu';
import { Card, CardBody } from 'BootQuery/Assets/components/Card';
import { ListWrapper } from 'BootQuery/Assets/components/ListWrapper';
import { IOverviewEditorItem } from 'BootQuery/Assets/components/Overviews';
import { Column } from 'BootQuery/Assets/components/Table';

import { generateExport } from '../api';
import { CallListPermissions } from '../call-list-permissions';
import { CallListContactModalProvider } from '../CallListContactModalProvider';
import { CallListHeading } from '../CallListHeading';
import { useFilterTypes } from '../filter-types';
import { formatCall } from '../format-call';
import { PageWithPlayer } from '../RecordingPlayer';
import { CallListCall } from '../types';
import { useCalls } from '../use-calls';
import { useSelectedOverview } from '../use-selected-overview';
import { CardList } from './CardList';
import { useRefreshMethod } from './util';

interface Props {
  overviews: IOverviewEditorItem[];
  permissions: CallListPermissions;
  customFields: FieldValue[];
}

export const CallCards = ({
  overviews,
  permissions,
  customFields,
}: Props): ReactElement => {
  const filterTypes = useFilterTypes(customFields);
  const overview = useSelectedOverview(overviews);
  const [refreshMethod, setRefreshMethod] = useRefreshMethod();

  // Never remove the useTranslation to force Telephony translations to load
  const { t } = useTranslation('Telephony');

  const columns: Column<CallListCall>[] = [];
  const listingSettings = useListingSettings<CallListCall>({
    listingName: 'Telephony.CallList',
    viewName: 'card',
    filterTypes,
    columns,
    otherFilters: overview?.filters ?? [],
  });

  const { filters, page, limit } = listingSettings;
  const name = 'Telephony.CallList.card';
  const { calls, status, refetch, newRowIds } = useCalls({
    page,
    limit,
    filters,
    refreshMethod,
    name,
  });
  const itemCount = calls?.meta.count ?? 0;
  const headingProps = useHeadingProps({
    listingProps: listingSettings,
    count: itemCount,
    displayModeOptions: defaultDisplayModeOptions,
    menuChildren: <ExportMenuItem />,
  });

  let rows: CallListCall[] = [];

  if (status === 'success' && calls) {
    rows = calls.data.map(formatCall);
  }

  return (
    <PageWithPlayer>
      <CallListContactModalProvider>
        <ExportModalProvider
          onExport={generateExport}
          rowCount={itemCount ?? 0}
          filterExpression={filters ?? []}
        >
          <ListWrapper spacing="5">
            <Box mx={5} my={9}>
              <CallListHeading
                {...headingProps}
                menuProps={{
                  ...headingProps.menuProps,
                  refreshMethod: {
                    value: refreshMethod,
                    onChange: setRefreshMethod,
                  },
                }}
                withExport
                permissions={permissions}
                refetch={refetch}
                newRows={newRowIds.length}
              />
            </Box>

            {status !== 'pending' && !rows.length ? (
              <Card>
                <CardBody py="16">
                  <Heading color="gray.600" mb={0} textAlign="center">
                    {t('global:no_results')}
                  </Heading>
                </CardBody>
              </Card>
            ) : (
              <CardList calls={rows} />
            )}
          </ListWrapper>
        </ExportModalProvider>
      </CallListContactModalProvider>
    </PageWithPlayer>
  );
};
