import { ReactElement, useEffect, useId, useMemo } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { DeepPartial, FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { FormState } from 'BootQuery/Assets/components/form-state';
import { FormActions } from 'BootQuery/Assets/components/FormActions';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';

import { useCustomEvents } from '../CustomEventsContext';
import { EventFormFields } from '../EventFormFields';
import { CustomEventFormData, CustomEventType } from '../types';

export interface CustomEventModalProps {
  isOpen: boolean;
  onSubmit: (data: CustomEventFormData) => void;
  onCancel: () => void;
  formState?: FormState;
  isLoading?: boolean;
  initial?: DeepPartial<CustomEventFormData> | null;
}

const baseDefaults = {
  contacts: [{}],
  tickets: [{}],
  events: [{}],
};

export const CustomEventModal = ({
  isOpen,
  onSubmit,
  onCancel,
  formState,
  initial,
  isLoading = false,
}: CustomEventModalProps): ReactElement => {
  const { t } = useTranslation('Events');
  const { customEventTypes } = useCustomEvents();
  const formId = `custom-event-form-${useId()}`;
  const defaultValues = useMemo(
    () => ({
      ...baseDefaults,
      ...(initial ?? {}),
    }),
    [initial]
  );

  return (
    <Modal
      size="6xl"
      scrollBehavior="inside"
      isOpen={isOpen}
      onClose={onCancel}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader display="flex" alignItems="center">
          <FaPlus style={{ display: 'inline' }} />
          &nbsp;
          {t('global:add')}
        </ModalHeader>
        <ModalBody display="flex" p={0}>
          {!isLoading && initial && customEventTypes ? (
            <EventModalContent
              formId={formId}
              onSubmit={onSubmit}
              defaultValues={defaultValues}
              customEventTypes={customEventTypes}
            />
          ) : (
            <LoadingPage />
          )}
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          <FormActions form={formId} onCancel={onCancel} state={formState} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

interface EventModalContentProps {
  defaultValues?: DeepPartial<CustomEventFormData>;
  formId?: string;
  onSubmit: (data: CustomEventFormData) => void;
  customEventTypes: CustomEventType[];
}

const EventModalContent = ({
  defaultValues,
  formId,
  customEventTypes,
  onSubmit,
}: EventModalContentProps): ReactElement => {
  const formMethods = useForm<CustomEventFormData>({
    mode: 'onBlur',
    defaultValues,
  });
  const { handleSubmit, reset } = formMethods;

  useEffect(() => {
    reset(defaultValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(defaultValues), reset]);

  return (
    <FormProvider {...formMethods}>
      <form
        style={{ width: '100%' }}
        id={formId}
        data-ignore-form
        onSubmit={(event) => {
          event.preventDefault();
          event.stopPropagation();
          handleSubmit(onSubmit)(event);
        }}
      >
        <EventFormFields customEventTypes={customEventTypes} />
      </form>
    </FormProvider>
  );
};
