import { Api } from 'BootQuery/Assets/js/api';
import { GetOneParams } from 'BootQuery/Assets/js/globalTypes';

interface DeleteProductParams {
  productID: number;
  params?: GetOneParams;
}

export const deleteProduct = async <ReturnDataType = { ID: number }>({
  productID,
  params,
}: DeleteProductParams) => {
  const { data: apiData } = await Api.delete<ReturnDataType>(
    `/api/products/${productID}`,
    { params }
  );

  return apiData;
};
