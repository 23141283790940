import { ReactElement, useCallback } from 'react';
import { Box, HStack } from '@chakra-ui/react';
import { AsyncSelect } from 'chakra-react-select';
import { Controller, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DeleteButton } from 'BootQuery/Assets/components/DeleteButton';
import {
  selectItemToValue,
  SelectOption,
  selectStyles,
  valueToSelectItem,
} from 'BootQuery/Assets/components/Select';
import { modalSelect } from 'BootQuery/Assets/components/Select/select-styles';
import { Api } from 'BootQuery/Assets/js/api';

import { CustomEventFormData } from './types';

interface Props {
  idx: number;
  onRemove: (idx: number) => void;
  ownEventID?: number | null;
}

export const LinkedEventRow = ({
  idx,
  onRemove,
  ownEventID = null,
}: Props): ReactElement => {
  const { t } = useTranslation('Events');

  const { control } = useFormContext<CustomEventFormData>();
  const remove = useCallback(() => {
    onRemove(idx);
  }, [idx, onRemove]);

  const search = useCallback(
    (searchStr: string) => searchEvents(searchStr, ownEventID),
    [ownEventID]
  );

  return (
    <HStack>
      <Box flex="1 1 auto">
        <Controller
          control={control}
          name={`events.${idx}.event`}
          render={({ field }) => (
            <AsyncSelect<SelectOption, false>
              cacheOptions
              defaultOptions
              menuPortalTarget={document.body}
              chakraStyles={selectStyles}
              loadOptions={search}
              value={valueToSelectItem(field.value)}
              onChange={(val: SelectOption | null) => {
                field.onChange(selectItemToValue(val));
              }}
              placeholder={t('Events:event')}
              styles={modalSelect}
              selectedOptionColorScheme="brand"
            />
          )}
        />
      </Box>
      <DeleteButton size="sm" onClick={remove} />
    </HStack>
  );
};

interface SearchResults {
  data: {
    ID: number;
    title: string;
  }[];
}

async function searchEvents(
  search: string,
  ownEventID: number | null = null
): Promise<SelectOption[]> {
  const { data } = await Api.get<SearchResults>('/api/events/customEvents', {
    params: {
      fields: ['ID', 'title'],
      filters: {
        'title:contains:ci': search || undefined,
        'ID:neq': ownEventID || undefined,
      },
    },
  });

  return data.data.map((event) => ({
    label: event.title,
    value: event.ID.toString(),
  }));
}
