import { useCallback } from 'react';

import { useCompanyDepartmentModal } from './CompanyDepartmentModal/useCompanyDepartment';
import { useCompanyLocationModal } from './CompanyLocationModal/useCompanyLocationModal';
import { useCompanyModal } from './CompanyModal/useCompanyModal';
import { usePersonModal } from './PersonModal/usePersonModal';
import { UseContactCreateEditProps } from './types';

export const useContactEditModal = () => {
  const { editCompanyModal } = useCompanyModal();
  const { editPersonModal } = usePersonModal();
  const { editCompanyDepartmentModal } = useCompanyDepartmentModal();
  const { editCompanyLocationModal } = useCompanyLocationModal();

  return useCallback(
    (props: UseContactCreateEditProps) => {
      switch (props.type) {
        case 'company':
          return editCompanyModal(props.ID, props.closeCallback);
        case 'person':
          return editPersonModal(props.ID, props.closeCallback);
        case 'companyDepartment':
          return editCompanyDepartmentModal(props.ID, props.closeCallback);
        case 'companyLocation':
          return editCompanyLocationModal(props.ID, props.closeCallback);
        default:
          throw Error('Uknown Contact Type');
      }
    },
    [
      editCompanyDepartmentModal,
      editCompanyLocationModal,
      editCompanyModal,
      editPersonModal,
    ]
  );
};
