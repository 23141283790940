import { Dispatch, ReactElement, SetStateAction } from 'react';
import { FormControl, FormLabel, Grid, Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useSetPropertyState } from 'app/assets/js/use-set-property-state';

import { useGroups, useUsers } from './api';
import { DefaultInput } from './DefaultInput';
import { ReportAgentsEntities } from './ReportAgentsEntities';
import { AgentPermissions } from './types';

interface Props {
  value: AgentPermissions;
  onChange: Dispatch<SetStateAction<AgentPermissions>>;
}

export const ReportAgentsPermission = ({
  value,
  onChange,
}: Props): ReactElement => {
  const { t } = useTranslation('global');

  const setUsers = useSetPropertyState(onChange, 'users');
  const setGroups = useSetPropertyState(onChange, 'groups');
  const setDefault = useSetPropertyState(onChange, 'default');

  return (
    <Grid
      w="full"
      h="full"
      templateAreas={`
        "defaultSetting defaultSetting"
        "usersHeader groupsHeader"
        "usersSearch groupsSearch"
        "usersItems groupsItems"
      `}
      templateColumns="1fr 1fr"
      templateRows="min-content min-content min-content 1fr"
      columnGap="4"
    >
      <FormControl
        display="flex"
        alignItems="center"
        gridArea="defaultSetting"
        pb="6"
      >
        <FormLabel fontSize="lg">{t('global:default')}:</FormLabel>
        <DefaultInput value={value.default} onChange={setDefault} />
      </FormControl>
      <Heading size="md" gridArea="usersHeader">
        {t('global:users')}
      </Heading>
      <ReportAgentsEntities
        gridAreaPrefix="users"
        value={value.users}
        onChange={setUsers}
        useItems={useUsers}
      />
      <Heading size="md" gridArea="groupsHeader">
        {t('global:groups')}
      </Heading>
      <ReportAgentsEntities
        gridAreaPrefix="groups"
        value={value.groups}
        onChange={setGroups}
        useItems={useGroups}
      />
    </Grid>
  );
};
