import { Api } from 'BootQuery/Assets/js/api';

import { TicketReportMethod } from '../../types';

export const getReportMethod = async (ID: number) => {
  const data = await Api.get<TicketReportMethod>(
    `/api/ticketing/reportMethods/${ID}`
  );

  return data;
};
