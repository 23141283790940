import { ReactElement } from 'react';
import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaBell, FaHeadphones, FaMicrophone } from 'react-icons/fa';

import { softphoneState } from '../store/softphone';
import { DeviceSettings } from './DeviceSettings';
import { SwitchInput } from './SwitchInput';
import { TelLinkHandlerButton } from './TelLinkHandlerButton';
import { VolumeInput } from './VolumeInput';

export const Settings = (): ReactElement => {
  const { t } = useTranslation('Telephony');

  const {
    inputVolume,
    setInputVolume,
    outputVolume,
    setOutputVolume,
    ringVolume,
    setRingVolume,
    noiseSupression,
    setNoiseSupression,
    echoCancellation,
    setEchoCancellation,
    avoidAudioProcessing,
    setAvoidAudioProcessing,
  } = softphoneState((state) => state.settings);

  return (
    <>
      <Box px="3">
        <VolumeInput
          label={t('Telephony:settings.output_volume')}
          icon={<FaHeadphones />}
          value={outputVolume}
          onChange={setOutputVolume}
          id="outputVolume"
          max={avoidAudioProcessing ? 100 : 400}
        />
        <VolumeInput
          label={t('Telephony:settings.input_volume')}
          icon={<FaMicrophone />}
          value={inputVolume}
          onChange={setInputVolume}
          id="inputVolume"
        />
        <VolumeInput
          label={t('Telephony:settings.ring_volume')}
          icon={<FaBell />}
          value={ringVolume}
          onChange={setRingVolume}
          id="ringVolume"
          max={100}
        />
      </Box>

      <DeviceSettings />

      <Box px="3">
        <SwitchInput
          label={t('Telephony:settings.echo_cancellation')}
          id="echoCancellation"
          value={echoCancellation}
          onChange={setEchoCancellation}
        />
        <SwitchInput
          label={t('Telephony:settings.noise_supression')}
          id="noiseSupression"
          value={noiseSupression}
          onChange={setNoiseSupression}
        />
        <SwitchInput
          label={t('Telephony:settings.avoid_audio_processing')}
          id="avoidAudioProcessing"
          value={avoidAudioProcessing}
          onChange={setAvoidAudioProcessing}
        />

        <TelLinkHandlerButton mb="3" />
      </Box>
    </>
  );
};
