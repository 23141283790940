import { Api } from 'BootQuery/Assets/js/api';
import {
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

import { ItemGroup } from '../../types';

interface GetGroupsParams {
  params?: GetListingParams;
}
export const getGroups = async ({ params }: GetGroupsParams) => {
  const { data } = await Api.get<ListingResponse<ItemGroup>>(
    '/api/productGroups',
    {
      /** Make field params for item type */
      params,
    }
  );

  return data;
};
