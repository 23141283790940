import { ReactElement, useCallback } from 'react';
import {
  Button,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useColorModeValue,
} from '@chakra-ui/react';
import { FaChevronDown, FaTimes } from 'react-icons/fa';

import { ButtonStyle, dropdownSelectStyle, dropdownTagStyle } from '../styles';

const IconDisplay = ({ icon }: { icon?: ReactElement }): ReactElement => (
  <>{icon && <>{icon}&nbsp;</>}</>
);

export interface DropdownItem {
  key: string;
  text: string;
  icon?: ReactElement;
}

interface DropdownFilterProps {
  items: DropdownItem[];
  value: string;
  onChange?: (value: string) => void;
  onRemove: () => void;
}

interface DropdownInputProps extends Omit<DropdownFilterProps, 'onRemove'> {
  buttonStyle?: ButtonStyle;
}

const DropdownInput = ({
  items,
  value = '',
  onChange,
  buttonStyle = {},
}: Omit<DropdownInputProps, 'onRemove'>): ReactElement => {
  const selected = items.find((item) => item.key === value);
  const handleChange = useCallback(
    (value: string) => onChange && onChange(value),
    [onChange]
  );

  return (
    <Menu size="sm" isLazy>
      <MenuButton as={Button} {...buttonStyle} rightIcon={<FaChevronDown />}>
        {selected === undefined ? (
          <>–</>
        ) : (
          <Flex>
            <IconDisplay icon={selected.icon} />
            {selected.text}
          </Flex>
        )}
      </MenuButton>
      <MenuList defaultValue={value}>
        {items.map((item) => (
          <MenuItem key={item.key} onClick={() => handleChange(item.key)}>
            <IconDisplay icon={item.icon} />
            {item.text}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export const FilterDropdownTag = ({
  onRemove,
  ...props
}: DropdownFilterProps): ReactElement => {
  const bgColor = useColorModeValue('gray.100', 'whiteAlpha.200');

  return (
    <Flex background={bgColor} height="8" borderRightRadius="md">
      <DropdownInput {...props} buttonStyle={dropdownTagStyle} />
      <IconButton
        onClick={onRemove}
        variant="link"
        aria-label="Close"
        icon={<FaTimes />}
        size="xs"
      />
    </Flex>
  );
};

export const FilterDropdownInput = (
  props: DropdownFilterProps
): ReactElement => (
  <DropdownInput {...props} buttonStyle={dropdownSelectStyle} />
);
