import { Api } from 'BootQuery/Assets/js/api';

import { ContactSelectOption } from '../types';

export const createPerson = async (
  value: string
): Promise<ContactSelectOption> => {
  const split = value.split(' ');
  const { data } = await Api.post<{ ID: number }>('/api/phonebook/people', {
    firstName: split[0],
    lastName: split[1],
  });

  return { type: 'person', value: data.ID, label: value };
};
