import { FaSms, FaTrash } from 'react-icons/fa';
import { NavigateFunction } from 'react-router-dom';

import { ConfirmModalProps } from '@bq/components/ConfirmationModal/types';
import { FilterExpression } from 'BootQuery/Assets/components/FilterBar';
import { BulkAction } from 'BootQuery/Assets/components/Table';
import { Api } from 'BootQuery/Assets/js/api';
import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';
import i18n from 'BootQuery/Assets/js/i18n';

import { CompanyLocationsResponse, getListing } from '../api';
import { ApiNumber, CompanyLocationApiData, PersonApiData } from '../types';
import { parseSmsSelection } from '../utils';

export const deleteItem = (
  refetch: () => void,
  confirmModal: (props: ConfirmModalProps) => void
): BulkAction<LocationData> => ({
  name: i18n.t('global:delete'),
  icon: FaTrash({}),

  isAllowed: (selection) => selection.length > 0,
  handler: async (selection) => {
    const count = selection.length;
    confirmModal({
      headerContent: i18n.t('global:are_you_sure'),
      bodyContent: i18n.t('global:you_will_delete_item', { count }),

      confirmButtonProps: {
        colorScheme: 'red',
        variant: 'solid',
        leftIcon: FaTrash({}),
      },
      onConfirm: async () => {
        await Api.delete('/api/phonebook/companyLocations', {
          params: {
            filters: {
              'ID:inArray': selection.map((item) => item.ID).join(','),
            },
          },
        });
        refetch();
      },
    });
  },
});

export interface SMSContact {
  ID: number;
  name: string;
  phoneNumber: string;
}
export const sendSms = (
  navigate: NavigateFunction
): BulkAction<LocationData, SMSContact> => ({
  name: i18n.t('global:bulk_actions:send_sms'),
  icon: FaSms({}),

  isAllowed: (selection) => selection.length > 0,
  handler: async (selection) => {
    navigate('/sms/conversations/all/editor/on', {
      state: { action: selection },
    });
  },
  parseSelection(selection) {
    return parseSmsSelection(selection).map(({ phoneNumber, contact }) => ({
      phoneNumber,
      ID: contact.ID,
      name: contact.name,
    }));
  },
  displayText: true,
  disabledTooltip: i18n.t('global:disabled_action_tooltips:no_sms_contact'),
});
export const sendSmsEmployees = (
  navigate: NavigateFunction
): BulkAction<LocationData, SMSContact> => ({
  name: i18n.t('Phonebook:send_sms_employees'),
  icon: FaSms({}),

  isAllowed: (selection) => selection.length > 0,
  handler: async (_selection, rawSelection) => {
    const ids = rawSelection.map((loc) => loc.ID);
    const locations = await getLocationsWithEmployees({
      'ID:inArray': ids.join(','),
    });

    const allLocations = locations.map((loc) => ({
      ID: `location-${loc.ID}`,
      name: loc.name,
      phoneNumbers: loc.phoneNumbers,
    }));
    const allEmployees = locations.reduce(
      (employees, loc) => [
        ...employees,
        ...loc.employees.map((emp) => ({
          ID: `person-${emp.ID}`,
          name: `${emp.firstName} ${emp.lastName}`.trim(),
          phoneNumbers: emp.phoneNumbers,
        })),
      ],
      [] as ContactInfo[]
    );
    const allContacts = [...allLocations, ...allEmployees];
    const dest = parseSmsSelection(allContacts).map(
      ({ phoneNumber, contact }) => ({
        phoneNumber,
        ID: contact.ID,
        name: contact.name,
      })
    );

    navigate('/sms/conversations/all/editor/on', {
      state: { action: dest },
    });
  },
  parseSelection(selection) {
    return parseSmsSelection(selection).map(({ phoneNumber, contact }) => ({
      phoneNumber,
      ID: contact.ID,
      name: contact.name,
    }));
  },
  displayText: true,
  disabledTooltip: i18n.t('global:disabled_action_tooltips:no_sms_contact'),
});

type LocationData = Pick<
  CompanyLocationApiData,
  'ID' | 'name' | 'phoneNumbers'
>;
type EmployeeData = Pick<
  PersonApiData,
  'ID' | 'firstName' | 'lastName' | 'phoneNumbers'
>;
interface LocationWithEmployees extends LocationData {
  employees: EmployeeData[];
}

interface ContactInfo {
  ID: string;
  name: string;
  phoneNumbers: ApiNumber[];
}

async function getLocationsWithEmployees(
  filters: FilterExpression | FilterExpression[]
): Promise<LocationWithEmployees[]> {
  const { data } = await getLocations({
    page: 1,
    limit: 99999,
    filters,
    fields: [
      'ID',
      'name',
      'phoneNumbers.phoneNumber.phoneNumberE164',
      [
        'employees',
        [
          'ID',
          'firstName',
          'lastName',
          'phoneNumbers.phoneNumber.phoneNumberE164',
        ],
      ],
    ],
  });

  return data as unknown as LocationWithEmployees[];
}

export function getLocations(
  params: GetListingParams = {}
): Promise<CompanyLocationsResponse> {
  return getListing<CompanyLocationsResponse>(
    '/api/phonebook/companyLocations',
    params
  );
}
