import { useEffect, useRef } from 'react';
import reactFastCompare from 'react-fast-compare';

import { usePage, UsePageResult } from 'BootQuery/Assets/js/use-page';
import { useValueHasChanged } from 'BootQuery/Assets/js/use-value-has-changed';

import { FilterExpression } from '../FilterBar';

/**
 * Wrapper for usePage that resets to first page when filters change.
 *
 * This should be simple, but we do some hacks to optimise it to not cause
 * extra fetches and unecessary resets while things are loading.
 */
export function useFilteredPage(
  storage: 'state' | 'query',
  filters: FilterExpression[] | undefined
): UsePageResult {
  const [page, setPage] = usePage(storage);

  const filtersChanged = useValueHasChanged(filters, {
    enabled: filters !== undefined,
    valuesAreEqual: reactFastCompare,
  });

  // There seems to be a quirk that causes useEffect with boolean dependency to
  // not fire or only fire on some re-renders.
  // We work around it by using a useEffect that fires on every render.
  // Since the callback doesn't seem to receive the latest value this way, we
  // make read a copy of the value in a ref (pendingPageReset).
  const pendingPageReset = useRef(filtersChanged);
  if (filtersChanged) {
    pendingPageReset.current = true;
  }
  useEffect(() => {
    if (pendingPageReset.current) {
      pendingPageReset.current = false;

      // Don't call this uncessarily since it might update the query string
      // and cause some big re-renders even if the value is same
      if (page !== 1) {
        setPage(1);
      }
    }
  });

  // If pending page reset, don't wait for useEffect, return page 1 immediately
  // Otherwise we make an extra request with the wrong page first.
  const outPage = pendingPageReset.current ? 1 : page;

  return [outPage, setPage];
}
