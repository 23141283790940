import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

import { BrandColors } from 'BootQuery/Assets/js/brand-colors';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = (brandColors: BrandColors) => {
  return definePartsStyle({
    dialog: {
      bg: brandColors.background,
      color: brandColors.fontStaticDark,
      _dark: {
        bg: brandColors.backgroundDark,
        color: brandColors.fontStaticLight,
      },
    },
  });
};

export const modalTheme = (brandColors: BrandColors) => {
  return defineMultiStyleConfig({
    baseStyle: baseStyle(brandColors),
  });
};
