import type { IconType } from 'react-icons';
import { FaEdit, FaHeadset, FaPhone, FaVolumeUp } from 'react-icons/fa';

import i18next from 'BootQuery/Assets/js/i18n';

import { DevicePresets } from '../types';

export function usePresetLabel(preset: keyof DevicePresets | null): string {
  switch (preset) {
    case 'handphone':
      return i18next.t('Telephony:presets.handphone');
    case 'headphones':
      return i18next.t('Telephony:presets.headphones');
    case 'speaker':
      return i18next.t('Telephony:presets.speaker');
    case null:
      return i18next.t('Telephony:presets.manual');
    default:
      throw new Error(`Unknown preset ${preset}`);
  }
}

const presetIcons: Record<keyof DevicePresets | 'none', IconType> = {
  handphone: FaPhone,
  headphones: FaHeadset,
  speaker: FaVolumeUp,
  none: FaEdit,
};

export function usePresetIcon(preset: keyof DevicePresets | null): IconType {
  const key = preset ?? 'none';
  const icon = presetIcons[key] ?? null;
  if (!icon) {
    throw new Error(`Unknown preset ${preset}`);
  }

  return icon;
}
