import { useMemo } from 'react';
import { Editor } from '@tiptap/react';

import { wrapAction } from '../../utils';

export const toggleOrderedList = (editor: Editor) => {
  editor.commands.toggleOrderedList();
};

export const toggleBulletList = (editor: Editor) => {
  editor.commands.toggleBulletList();
};

export const useListToggles = (editor: Editor | null) => {
  return useMemo(
    () => ({
      toggleBulletList: wrapAction(toggleBulletList, editor),
      toggleOrderedList: wrapAction(toggleOrderedList, editor),
    }),
    [editor]
  );
};
