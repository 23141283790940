import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal/use-modal';

import { CompanyCallbackData } from '../CompanyModal/types';
import { CompanyLocationModalContent } from './CompanyLocationModalContent';
import { EditCompanyLocationModalContent } from './EditCompanyLocationModal';
import {
  CreateCompanyLocationModal,
  EditCompanyLocationModal,
  UseCompanyLocationModalReturn,
} from './types';

export const useCompanyLocationModal = (): UseCompanyLocationModalReturn => {
  const { addModal } = useModal();

  const createCompanyLocationModal: CreateCompanyLocationModal = useCallback(
    (closeCallback, defaults) => {
      addModal<CompanyCallbackData>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: <CompanyLocationModalContent mode="create" data={defaults} />,
      });
    },
    [addModal]
  );
  const editCompanyLocationModal: EditCompanyLocationModal = useCallback(
    (ID, closeCallback) => {
      addModal<CompanyCallbackData>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: <EditCompanyLocationModalContent ID={ID} />,
      });
    },
    [addModal]
  );

  return { createCompanyLocationModal, editCompanyLocationModal };
};
