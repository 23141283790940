import { useMemo } from 'react';

import { FilterTypes } from '@bq/components/FilterBar';
import {
  customFormFilters,
  FieldValue,
  getFieldTypes,
} from '@bq/components/FormEditor';
import { numberFilterExpression } from 'BootQuery/Assets/components/FilterBar/filters/PhoneNumberFilter';
import { makeTextFilter } from 'BootQuery/Assets/components/FilterBar/filters/TextFilter';
import i18n from 'BootQuery/Assets/js/i18n';

export const filterTypes: FilterTypes = {
  name: makeTextFilter({
    name: 'Naziv',
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return ['name', operator ?? 'contains:ci', value];
    },
  }),
  phoneNumber: makeTextFilter({
    name: () => i18n.t('Phonebook:phone_number'),
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return {
        'phoneNumbers:$any': numberFilterExpression(value, operator ?? 'eq'),
      };
    },
  }),
  email: makeTextFilter({
    name: 'E-mail',
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return {
        'emails:$any': {
          [`email.email:${operator ?? 'eq'}:ci`]: value,
        },
      };
    },
  }),
  nationalNumber: makeTextFilter({
    name: 'Identifikacijski broj',
    toFilter: ({ value, operator }) => {
      if (!value) {
        return null;
      }

      return [
        'nationalNumber.nationalNumber',
        operator ?? 'contains:ci',
        value,
      ];
    },
  }),
};

export function getFilterTypes(customFields: FieldValue[] = []): FilterTypes {
  const customFilters = customFormFilters(customFields, getFieldTypes());

  return {
    ...filterTypes,
    ...customFilters,
  };
}

export function useFilterTypes(customFields: FieldValue[] = []): FilterTypes {
  return useMemo(() => getFilterTypes(customFields), [customFields]);
}
