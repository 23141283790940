import { useTranslation } from 'react-i18next';
import { FaEnvelope } from 'react-icons/fa';

import { EventCellComponent } from 'app/Modules/Events/Assets/components';

import { MailEvent } from './types';

export const MailTypeColumn: EventCellComponent<MailEvent> = () => {
  const { t } = useTranslation('Mails');

  return (
    <>
      <FaEnvelope style={{ display: 'inline' }} />
      &nbsp;
      {t('Mails:email')}
    </>
  );
};
