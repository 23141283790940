import { ColorMode, useColorMode } from '@chakra-ui/react';

import 'BootQuery/Assets/js/i18n';

import { ReactElement, ReactNode, useEffect } from 'react';

import { colorModeStore } from '../js/store/color-mode';
import { setTheme } from '../js/themes';

interface ChildrenProps {
  children?: ReactNode;
}

export const ThemeSync = ({ children }: ChildrenProps): ReactElement => {
  const { colorMode, setColorMode } = useColorMode();

  // Sync chakra colorMode from store changes
  // Allows us to change the theme from outside react
  useEffect(() => {
    const unsub = colorModeStore.subscribe(({ colorMode }) => {
      setColorMode(colorMode);
    });

    return unsub;
  }, [setColorMode]);

  // Sync bootstrap's color mode to chakra
  useEffect(() => {
    setBootstrapColorMode(colorMode);
  }, [colorMode]);

  return <>{children}</>;
};

function setBootstrapColorMode(colorMode: ColorMode): void {
  if (colorMode === 'dark') {
    setTheme('cyborg');
  } else {
    setTheme('default');
  }
}
