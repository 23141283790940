import { useCallback, useMemo, useState } from 'react';
import { useUpdateEffect } from 'react-use';

import { useSettingsMethods } from './methods';
import {
  Modifications,
  UseCrudProps,
  UseSettingsCrudResult,
  WithChange,
  WithID,
} from './types';

export const useCrud = <ItemType extends WithID>({
  defaultValue = {
    add: [],
    upd: {},
    del: [],
  } as unknown as Modifications<ItemType>,
  items,
}: UseCrudProps<ItemType>): UseSettingsCrudResult<ItemType> => {
  const [value, modify] = useSettingsMethods(defaultValue);
  const [modifications, setModifications] =
    useState<Modifications<ItemType>>(value);
  useUpdateEffect(() => {
    setModifications(value);
  }, [value, setModifications]);
  const modifiedItems = useMemo(
    () => applyModifications(items, modifications),
    [modifications, items]
  );
  const clearModifications = useCallback(() => {
    setModifications(defaultValue);
    modify.clearAll();
  }, [defaultValue, setModifications, modify]);

  return [modifiedItems, modify, modifications, clearModifications];
};

function applyModifications<ItemType extends WithID>(
  items: ItemType[],
  modifications: Modifications<ItemType>
): WithChange<ItemType>[] {
  const withRemovedAndUpdated = items.map((item): WithChange<ItemType> => {
    const isDeleted = modifications.del.includes(item.ID);
    if (isDeleted) {
      return { ...item, change: 'del' };
    }

    const updates = modifications.upd[item.ID as ItemType['ID']];

    return updates ? { ...item, ...updates, change: 'upd' } : item;
  });

  const additions = modifications.add.map(
    (added): WithChange<ItemType> => ({
      ...added,
      change: 'add',
    })
  );

  return [...withRemovedAndUpdated, ...additions];
}
