import { FaEdit } from 'react-icons/fa';

import { EventCellComponent } from 'app/Modules/Events/Assets/components';

import { useCustomEvents } from '../CustomEventsContext';
import { CustomEventItem } from '../types';

export const CustomTypeColumn: EventCellComponent<CustomEventItem> = ({
  row: { data: event },
}) => {
  const { customEventTypes } = useCustomEvents();
  if (!customEventTypes) {
    // eslint-disable-next-line react/jsx-no-literals
    return <>[{event.typeID}]</>;
  }

  const type = customEventTypes.find((type) => type.ID === event.typeID);

  return (
    <>
      <FaEdit style={{ display: 'inline' }} />
      &nbsp;
      {type?.name}
    </>
  );
};
