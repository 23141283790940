import {
  Button,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { useModalInstance } from '../UseModal';
import { ConfirmModalProps } from './types';

export const ConfirmationModalContent = ({
  headerContent,
  bodyContent,
  cancelButtonProps,
  confirmButtonProps,
  onConfirm,
  onCancel,
}: ConfirmModalProps) => {
  const { t } = useTranslation();
  const { closeWithNoCallback } = useModalInstance();

  return (
    <ModalContent>
      <ModalHeader>{headerContent ?? t('global:are_you_sure')}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>{bodyContent ?? t('global:you_will_delete')}</ModalBody>

      <ModalFooter>
        <Button
          mr={3}
          variant="ghost"
          onClick={() => {
            if (onCancel) {
              onCancel();
            }
            closeWithNoCallback();
          }}
          {...cancelButtonProps}
        >
          {cancelButtonProps?.children ?? t('global:cancel')}
        </Button>
        <Button
          variant="solid"
          colorScheme="red"
          onClick={() => {
            if (onConfirm) {
              onConfirm();
              closeWithNoCallback();
            }
          }}
          {...confirmButtonProps}
        >
          {confirmButtonProps?.children ?? t('global:delete')}
        </Button>
      </ModalFooter>
    </ModalContent>
  );
};
