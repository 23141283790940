import {
  ChangeEvent,
  MouseEvent,
  ReactElement,
  useCallback,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  chakra,
  Checkbox,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaChevronDown } from 'react-icons/fa';

import { Portal } from '../Portal';
import { useTableSelection } from './TableSelection';
import { CellProps, Column, IdTypes, KeyDesc, OBJ } from './types';

export function makeSelectionCell<
  R extends OBJ,
  // Force second param to a default, so we can easily just specify the
  // row type and let K be infered
>(): Column<R> {
  return {
    key: '$selected',
    title: '',
    width: '60px',
    children: <SelectionHeader<R> />,
    Cell: (props: CellProps<R>): ReactElement => {
      const wrapperRef = useRef<HTMLDivElement>();
      const { selection, toggleSelected, idColumn } = useTableSelection<R>();
      const checked = selection.some(
        (item) => item[idColumn] === props.row[idColumn]
      );
      const onChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => {
          toggleSelected(props.row, ev.target.checked);
        },
        [toggleSelected, props.row]
      );
      const onCellClick = useCallback(
        (ev: MouseEvent<HTMLDivElement>) => {
          if (ev.target === wrapperRef.current) {
            toggleSelected(props.row);
          }
        },
        [toggleSelected, props.row]
      );

      return (
        <CellWrapper
          ref={wrapperRef}
          onClick={onCellClick}
          style={inlineAbsolute}
        >
          <Checkbox
            isChecked={checked}
            onChange={onChange}
            mb={0}
            zIndex={3}
            colorScheme="brand"
          />
        </CellWrapper>
      );
    },
  };
}

const inlineAbsolute = { position: 'absolute' };

const CellWrapper = chakra(Box, {
  baseStyle: {
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    zIndex: 3,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
});

export function getId<R extends OBJ, K extends KeyDesc<R>>(
  row: R,
  idCol: K
): IdTypes {
  const key =
    typeof idCol === 'function' ? idCol(row) : row[idCol as unknown as keyof R];

  return key as IdTypes;
}

export const SelectionHeader = <T extends OBJ>() => {
  const { clearSelected, selectPage, selectAll, allSelected, selection } =
    useTableSelection<T>();
  const { t } = useTranslation();
  const [currentSelected, setCurrentSelected] = useState<
    PossibleSelectedVals | undefined
  >(undefined);

  return (
    <CellWrapper w="full" h="full">
      <Menu>
        <Button
          w="full"
          h="full"
          borderRadius={0}
          size="sm"
          variant="ghost"
          rightIcon={
            <FaChevronDown style={{ marginLeft: '16px', marginTop: '6px' }} />
          }
          as={MenuButton}
        >
          <Portal>
            <MenuList zIndex={6}>
              <MenuOptionGroup type="radio" value={currentSelected}>
                {selectPage && (
                  <MenuItemOption
                    value="allPage"
                    onClick={() => {
                      selectPage();
                      setCurrentSelected('allPage');
                    }}
                  >
                    {t('global:bulk_actions.select_all_on_page')}
                  </MenuItemOption>
                )}
                {selectAll && (
                  <MenuItemOption
                    value="allFilter"
                    onClick={() => {
                      selectAll();
                      setCurrentSelected('allFilter');
                    }}
                  >
                    {t('global:bulk_actions.select_all_that_match')}
                  </MenuItemOption>
                )}
              </MenuOptionGroup>
            </MenuList>
          </Portal>
        </Button>
      </Menu>
      <Checkbox
        colorScheme="brand"
        pos="absolute"
        left={3.5}
        mb={0}
        zIndex={6}
        isIndeterminate={!allSelected && selection.length > 0}
        isChecked={selection.length > 0}
        onChange={(_e) => {
          if (selection.length === 0 && selectPage !== undefined) {
            selectPage();
            setCurrentSelected('allPage');
          } else {
            clearSelected();
            setCurrentSelected('none');
          }
        }}
      ></Checkbox>
    </CellWrapper>
  );
};

type PossibleSelectedVals = 'allPage' | 'allFilter' | 'none';
