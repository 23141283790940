import { ReactElement } from 'react';

export const RingGroupIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 288 288"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M182.4 228.1A115.5 115.5 0 0167 112.6a6 6 0 014.6-5.8l26-6a6 6 0 016.7 3.5l12 27.8a6 6 0 01-1.7 7l-15.1 12.4a92.4 92.4 0 0044 44.1l12.4-15a6 6 0 017-1.8l27.9 12a6 6 0 013.4 6.8l-6 25.9a6 6 0 01-5.8 4.6zm-64.3-138a22.3 22.3 0 0116 16 4.2 4.2 0 01-4 5.3h-2.8a4.2 4.2 0 01-4.1-3 11.1 11.1 0 00-7.4-7.3 4.2 4.2 0 01-3-4v-3a4.2 4.2 0 015.3-4zm.2-45A66.8 66.8 0 01179 106a4.2 4.2 0 01-4.1 4.5h-2.8a4.2 4.2 0 01-4.3-3.7 55.7 55.7 0 00-50.4-50.5 4.2 4.2 0 01-3.8-4.2v-2.8a4.2 4.2 0 014.6-4.1zm-.3 22.5a44.5 44.5 0 0138.7 38.7 4.2 4.2 0 01-4 4.7h-2.8a4.2 4.2 0 01-4.3-3.6 33.4 33.4 0 00-28.8-28.7 4.2 4.2 0 01-3.6-4.3v-2.8a4.2 4.2 0 014.8-4z"
    />
    <circle
      cx="143.5"
      cy="142.7"
      r="125.7"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="24"
    />
  </svg>
);
