import { useCallback } from 'react';

import { useModal } from '@bq/components/UseModal/use-modal';

import { EditPersonModalContent } from './EditPersonModal';
import { PersonModalContent } from './PersonModalContent';
import {
  CreatePersonModal,
  EditPersonModal,
  PersonCallbackData,
  UsePersonModalReturn,
} from './types';

export const usePersonModal = (): UsePersonModalReturn => {
  const { addModal } = useModal();

  const createPersonModal: CreatePersonModal = useCallback(
    (closeCallback, defaults) => {
      addModal<PersonCallbackData>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: <PersonModalContent mode="create" data={defaults} />,
      });
    },
    [addModal]
  );
  const editPersonModal: EditPersonModal = useCallback(
    (ID, closeCallback) => {
      addModal<PersonCallbackData>({
        callback: (data) => {
          closeCallback(data);
        },
        componentProps: { modal: { size: '6xl', scrollBehavior: 'inside' } },
        children: <EditPersonModalContent ID={ID} />,
      });
    },
    [addModal]
  );

  return { createPersonModal, editPersonModal };
};
