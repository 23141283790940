import { ReactElement, useCallback } from 'react';

import { usePickTicketEvent } from '../../js/pick-ticket';
import { TicketPickerModal } from './TicketPickerModal';
import { useTicketPickerModal } from './use-ticket-picker-modal';

export const TicketPickerConnector = (): ReactElement => {
  const { selectTicket, modalProps } = useTicketPickerModal();

  usePickTicketEvent(
    'pickTicket',
    useCallback(
      ({ onSelect, options }) => {
        selectTicket(onSelect, options);
      },
      [selectTicket]
    )
  );

  return <TicketPickerModal {...modalProps} />;
};
