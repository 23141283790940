import { ChangeEvent, ReactElement } from 'react';
import {
  Checkbox,
  FormControl,
  FormLabel,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputProps,
  NumberInputStepper,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import { EditorFieldComponentProps } from '../../types';
import { useFieldSettings } from '../../util';
import { TextFieldSettings } from './types';

export const TextFieldSettingsComponent = (
  props: EditorFieldComponentProps<TextFieldSettings>
): ReactElement => {
  const { name, required, minLength, maxLength } = props.content.settings;

  const { setSetting } = useFieldSettings(props);
  const { t } = useTranslation('global');

  const setName = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('name', ev.target.value);
  };
  const setRequired = (ev: ChangeEvent<HTMLInputElement>) => {
    setSetting('required', ev.target.checked);
  };
  const setMin = (val: string) => {
    setSetting('minLength', parseIntInput(val));
  };
  const setMax = (val: string) => {
    setSetting('maxLength', parseIntInput(val));
  };

  return (
    <VStack alignItems="start">
      <Input
        placeholder={t('global:form_editor.field_name') ?? undefined}
        value={name}
        onChange={setName}
      />
      <Checkbox isChecked={required ?? false} onChange={setRequired}>
        {t('global:form_editor.required')}
      </Checkbox>
      <NumberSetting
        title={t('global:form_editor.minimum_length')}
        max={maxLength ?? undefined}
        precision={1}
        step={1}
        value={minLength ?? undefined}
        onChange={setMin}
        size="sm"
      />
      <NumberSetting
        title={t('global:form_editor.maximum_length')}
        min={minLength ?? undefined}
        precision={1}
        step={1}
        value={maxLength ?? undefined}
        onChange={setMax}
        size="sm"
      />
    </VStack>
  );
};

interface NumberSettingProps extends NumberInputProps {
  title: string;
}

const NumberSetting = ({
  title,
  ...numProps
}: NumberSettingProps): ReactElement => (
  <FormControl size="sm">
    <FormLabel size="sm">{title}:</FormLabel>
    <NumberInput placeholder={title} size="sm" {...numProps}>
      <NumberInputField />
      <NumberInputStepper>
        <NumberIncrementStepper />
        <NumberDecrementStepper />
      </NumberInputStepper>
    </NumberInput>
  </FormControl>
);

function parseIntInput(rawValue: string): number | null {
  if (rawValue.trim() === '') {
    return null;
  }

  const value = parseInt(rawValue, 10);

  return Number.isNaN(value) ? null : value;
}
