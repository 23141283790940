import {
  Checkbox,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  SimpleGrid,
  VStack,
} from '@chakra-ui/react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ColorInput } from '@bq/components/ColorInput';

import { TicketStateExtended } from './types';

export const StateFormFields = () => {
  const { t } = useTranslation('');
  const { register, control } = useFormContext<TicketStateExtended>();
  const {
    field: colorField,
    fieldState: { error },
  } = useController<TicketStateExtended, 'color'>({
    control,
    name: 'color',
    rules: {
      required: {
        value: true,
        message: t('Ticketing:errors.color_is_required'),
      },
    },
  });

  return (
    <VStack w="full">
      <HStack w="full" alignItems="center" spacing={5}>
        <FormControl w="full">
          <FormLabel w="full">{t('global:name')}</FormLabel>
          <Input
            {...register('name', { required: true })}
            size="sm"
            w="full"
          ></Input>
        </FormControl>
        <FormControl w="min-content">
          <FormLabel w="full">{t('Ticketing:color')}</FormLabel>
          <ColorInput
            size="34px"
            onChange={(color) => {
              colorField.onChange(color);
            }}
            value={colorField.value}
          />
          {error && <FormErrorMessage>{error.message}</FormErrorMessage>}
        </FormControl>
      </HStack>

      <SimpleGrid columns={[1, 2, 2, 2]} gridGap={2} w="full">
        <FormControl>
          <FormLabel>{t('Ticketing:is_responded')}</FormLabel>
          <Checkbox {...register('isResponded')} />
        </FormControl>
        <FormControl>
          <FormLabel>{t('Ticketing:is_resolved')}</FormLabel>
          <Checkbox {...register('isResolved')} />
        </FormControl>
      </SimpleGrid>
    </VStack>
  );
};
