import { useState } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  useDisclosure,
  VStack,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEdit, FaPlus, FaTrash, FaUndo } from 'react-icons/fa';

import { ChangeBadge } from '@bq/components/ChangeBadge';
import { useSettingsCrud } from '@bq/components/SettingsCRUD';

import { useTicketingSettingsContext } from '../TicketSettingsContext';
import { GroupsModalForm } from './TicketGroupsModalForm';
import { TicketGroupSettings } from './types';

export const TicketGroups = () => {
  const { t } = useTranslation();
  const { groups } = useTicketingSettingsContext();
  const [selected, setSelected] = useState<TicketGroupSettings | null>(null);
  const { isOpen, onClose, onOpen } = useDisclosure();
  const [modifiedGroups, modify] = useSettingsCrud<TicketGroupSettings>({
    parent: '#settings-form',
    inputName: 'ticketing[groups]',
    defaultValue: { add: [], upd: {}, del: [] },
    items: groups,
  });

  return (
    <>
      <VStack w="full" pr={0}>
        {modifiedGroups.map((group) => (
          <HStack key={group.ID} w="full">
            <HStack w="full">
              <Box
                fontWeight="bold"
                mr={2}
                textDecor={group.change === 'del' ? 'line-through' : 'none'}
              >
                {group.name}
              </Box>
              <ChangeBadge type={group.change} />
            </HStack>
            <HStack>
              {group.change && (
                <IconButton
                  aria-label={t('global:undo_changes')}
                  icon={<FaUndo />}
                  variant="ghost"
                  size="sm"
                  onClick={() => {
                    modify.undo(group.ID);
                  }}
                />
              )}
              <IconButton
                aria-label={t('global:edit')}
                icon={<FaEdit />}
                variant="ghost"
                size="sm"
                onClick={() => {
                  setSelected(group);
                  onOpen();
                }}
              />
              <IconButton
                aria-label={t('global:delete')}
                isDisabled={group.change === 'del'}
                size="sm"
                icon={<FaTrash />}
                variant="ghost"
                colorScheme="red"
                onClick={() => {
                  modify.del(group.ID);
                }}
              />
            </HStack>
          </HStack>
        ))}
        <Flex w="full" justify="end">
          <IconButton
            aria-label={t('global:add')}
            mr={2}
            icon={<FaPlus />}
            colorScheme="green"
            size="sm"
            onClick={() => {
              setSelected(null);
              onOpen();
            }}
          />
        </Flex>
      </VStack>
      {isOpen && (
        <GroupsModalForm
          isOpen={isOpen}
          selected={selected}
          onClose={onClose}
          onChange={(modifications) => {
            if (modifications.change === 'add') {
              modify.add(modifications);
            }
            if (modifications.change === 'upd') {
              modify.upd(modifications);
            }
          }}
        />
      )}
    </>
  );
};
