import { useTranslation } from 'react-i18next';
import { FaComments } from 'react-icons/fa';

import { EventCellComponent } from 'app/Modules/Events/Assets/components';

import { ChatEvent } from '../types';

export const ChatTypeColumn: EventCellComponent<ChatEvent> = ({
  row: { data: chat },
}) => {
  const { t } = useTranslation('CustomerChat');

  return (
    <>
      <FaComments
        title={t('CustomerChat:chat')}
        style={{ display: 'inline' }}
      />
      &nbsp;
      {chat.group.name}
    </>
  );
};
