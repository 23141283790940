import { ReactElement, useCallback } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Flex,
  HStack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaBell, FaHeadphones, FaLockOpen, FaMicrophone } from 'react-icons/fa';

import { LoaderScale } from 'BootQuery/Assets/components/Loader';

import { useMediaDevices } from '../softphone/use-media-devices';
import { softphoneState } from '../store/softphone';
import { DeviceInput } from './DeviceInput';
import { PresetButton } from './PresetButton';
import { PresetSettingsButton } from './PresetSettingsButton';

export const DeviceSettings = (): ReactElement => {
  const { t } = useTranslation('Telephony');
  const btnColor = useColorModeValue('whiteAlpha', undefined);
  const {
    audioInputDevice,
    setAudioInputDevice,
    audioOutputDevice,
    setAudioOutputDevice,
    ringDevice,
    setRingDevice,
    presets,
    preset,
  } = softphoneState((state) => state.settings);

  const { data: mediaDevices, refetch: refetchMediaDevices } =
    useMediaDevices();

  const requestPermissions = useCallback(async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
    } catch (err) {
      /* May be denied */
    }

    refetchMediaDevices();
  }, [refetchMediaDevices]);

  if (!mediaDevices) {
    return <LoaderScale />;
  }

  const {
    hasPermissions,
    audioInputDevices,
    audioOutputDevices,
    canSetOutputs,
    selectAudioOutputSupported,
  } = mediaDevices;

  if (hasPermissions === false) {
    return (
      <Box textAlign="center">
        <Text as="div" color="red.400" display="block">
          {t('Telephony:settings.no_audio_devices_permission')}
        </Text>
        <Flex justifyContent="center">
          <Button
            rightIcon={<FaLockOpen />}
            onClick={requestPermissions}
            colorScheme="blue"
          >
            {t('Telephony:settings.grant_permissions')}
          </Button>
        </Flex>
      </Box>
    );
  }

  if (audioInputDevices.length === 0) {
    return (
      <Text as="div" color="red.400" textAlign="center">
        {t('Telephony:settings.no_microphone_detected')}
      </Text>
    );
  }

  const canSetOutput =
    (audioOutputDevices.length > 0 && canSetOutputs) ||
    selectAudioOutputSupported;

  return (
    <>
      <HStack mb="3">
        <ButtonGroup isAttached>
          <PresetButton colorScheme={btnColor} preset={null} />
          {presets.handphone?.enabled && (
            <PresetButton colorScheme={btnColor} preset="handphone" />
          )}
          {presets.headphones?.enabled && (
            <PresetButton colorScheme={btnColor} preset="headphones" />
          )}
          {presets.speaker?.enabled && (
            <PresetButton colorScheme={btnColor} preset="speaker" />
          )}
        </ButtonGroup>
        <PresetSettingsButton colorScheme={btnColor} />
      </HStack>
      {!preset && (
        <Accordion allowToggle mb="3">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <Box>{t('Telephony:settings.devices')}</Box>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel>
              {canSetOutput && (
                <DeviceInput
                  type="output"
                  selectAudioOutputSupported={selectAudioOutputSupported}
                  label={t('Telephony:settings.output_device')}
                  id="outputDevice"
                  icon={<FaHeadphones />}
                  devices={audioOutputDevices}
                  value={audioOutputDevice}
                  onChange={setAudioOutputDevice}
                  buttonColorScheme={btnColor}
                />
              )}

              <DeviceInput
                type="input"
                label={t('Telephony:settings.input_device')}
                id="inputDevice"
                icon={<FaMicrophone />}
                devices={audioInputDevices}
                value={audioInputDevice}
                onChange={setAudioInputDevice}
                buttonColorScheme={btnColor}
              />

              {canSetOutput && (
                <DeviceInput
                  type="output"
                  selectAudioOutputSupported={selectAudioOutputSupported}
                  label={t('Telephony:settings.ring_device')}
                  id="ringDevice"
                  icon={<FaBell />}
                  devices={audioOutputDevices}
                  value={ringDevice}
                  onChange={setRingDevice}
                  buttonColorScheme={btnColor}
                />
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      )}
    </>
  );
};
