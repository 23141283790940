import { Api } from 'BootQuery/Assets/js/api';
import {
  BasicField,
  GetListingParams,
  ListingResponse,
} from 'BootQuery/Assets/js/globalTypes';

interface GetActionPriceReasonsParams {
  params?: GetListingParams;
}
export const getActionPriceReasons = async ({
  params,
}: GetActionPriceReasonsParams) => {
  const { data } = await Api.get<ListingResponse<BasicField>>(
    '/api/actionPriceReasons',
    {
      /** Make field params for item type */
      params,
    }
  );

  return data;
};
