import { ReactElement } from 'react';

export const DropdownIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 935 814"
    fill="currentColor"
    style={{ display: 'inline', width: '1em' }}
  >
    <path d="M895 40v146H41V40h854m19-40H22A21 21 0 0 0 1 21v184.1A21 21 0 0 0 22 226h892.1a21 21 0 0 0 21-20.95V21a21 21 0 0 0-21-21ZM107 315v67H40v-67h67m20-40H20a20 20 0 0 0-20 20v107a20 20 0 0 0 20 20h107a20 20 0 0 0 20-20V295a20 20 0 0 0-20-20Zm-20 236v67H40v-67h67m20-40H20a20 20 0 0 0-20 20v107a20 20 0 0 0 20 20h107a20 20 0 0 0 20-20V491a20 20 0 0 0-20-20Zm-20 236v67H40v-67h67m20-40H20a20 20 0 0 0-20 20v107a20 20 0 0 0 20 20h107a20 20 0 0 0 20-20V687a20 20 0 0 0-20-20Z" />
    <path d="M217.16 353.16h475.69v6.69H217.16z" />
    <path d="M694.71 331H215.29A20.3 20.3 0 0 0 195 351.29v10.42A20.3 20.3 0 0 0 215.29 382h479.42A20.3 20.3 0 0 0 715 361.71v-10.42A20.3 20.3 0 0 0 694.71 331ZM217.16 541.16h475.69v6.69H217.16z" />
    <path d="M694.71 519H215.29A20.3 20.3 0 0 0 195 539.29v10.42A20.3 20.3 0 0 0 215.29 570h479.42A20.3 20.3 0 0 0 715 549.71v-10.42A20.3 20.3 0 0 0 694.71 519ZM217.16 737.16h475.69v6.69H217.16z" />
    <path d="M694.71 715H215.29A20.3 20.3 0 0 0 195 735.29v10.42A20.3 20.3 0 0 0 215.29 766h479.42A20.3 20.3 0 0 0 715 745.71v-10.42A20.3 20.3 0 0 0 694.71 715ZM894 40v146H748V40h146m20-40H728a20 20 0 0 0-20 20v186a20 20 0 0 0 20 20h186a20 20 0 0 0 20-20V20a20 20 0 0 0-20-20Z" />
    <path d="M857.42 141.58h-74.91a7.56 7.56 0 0 1-5.35-12.9l37.46-37.46a7.52 7.52 0 0 1 10.67 0l37.49 37.46a7.56 7.56 0 0 1-5.36 12.9Z" />
  </svg>
);
