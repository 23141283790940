import {
  CSSProperties,
  ForwardedRef,
  MouseEventHandler,
  PointerEvent,
  useCallback,
} from 'react';
import { Box, HStack, TableProps, Text, Th } from '@chakra-ui/react';
import { css } from '@emotion/css';
import { startCase } from 'lodash-es';

import { CellArrowsSort } from './CellArrowsSort';
import { Column, SortState } from './types';
import { useSortingHeaders } from './use-sorting-headers';

type PointerDownHandler = (ev: PointerEvent<HTMLDivElement>) => void;

interface Props<RowType> {
  column: Column<RowType>;
  onResizerPointerDown?: PointerDownHandler;
  hasResizer?: boolean;
  style?: CSSProperties;
  className?: string;
  // Declared here because forwardRef typings refuse
  // to play nice with generic components.
  // "ref" is reserved, so we shall call it thRef
  thRef: ForwardedRef<HTMLTableCellElement>;
  sort?: SortState;
  tableSize?: TableProps['size'];
  isStatic?: boolean;
}

const resizeHandle = css({
  width: '9px',
  position: 'absolute',
  top: '-1px',
  bottom: '-1px',
  right: '-4px',
  cursor: 'col-resize',
  touchAction: 'none',
  ':hover > *': {
    background: '#92acbd',
  },
});

const resizeHandleInner = css({
  background: '#dee2e6',
  width: '2px',
  position: 'absolute',
  left: '3.5px',
  top: '1px',
  bottom: '1px',
});

const thStyle = css({
  position: 'relative',
});
const thContent = css({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  display: 'block',
  flex: '1 1 auto',
});

const thContentStatic = css({
  whiteSpace: 'normal',
  display: 'block',
  textAlign: 'center',
  flex: '1 1 auto',
});

// eslint-disable-next-line react/display-name
export const TableHeader = <RowType,>({
  onResizerPointerDown,
  column,
  hasResizer,
  style,
  className,
  thRef,
  tableSize,
  sort: sortState,
  isStatic,
}: Props<RowType>): JSX.Element => {
  // eslint-disable-next-line max-len
  const onPointerDown: PointerDownHandler = useCallback(
    (ev) => {
      if (onResizerPointerDown) {
        onResizerPointerDown(ev);
      }
    },
    [onResizerPointerDown]
  );
  const disableOnClick: MouseEventHandler = useCallback((ev) => {
    ev.stopPropagation();
  }, []);
  const title = column.title ?? startCase(column.key);
  const thClass = className ? `${thStyle} ${className}` : thStyle;
  const {
    onClick,
    sortingElement: elem,
    sortingIndex: elemIndex,
  } = useSortingHeaders(sortState, column.sort);

  const hasMultisort =
    sortState?.value !== undefined && sortState?.value.length > 1;

  return (
    <Th
      className={thClass}
      style={style}
      key={column.key}
      ref={thRef}
      transition="padding 0.3s"
      px={4}
      onClick={column.sort ? onClick : undefined}
      cursor={column.sort ? 'pointer' : 'default'}
    >
      <HStack>
        <Text
          as="span"
          className={isStatic ? thContentStatic : thContent}
          title={title}
          fontWeight="bold"
        >
          {column.children ? column.children : title}
        </Text>
        {!!column.sort && (
          <Box
            width="1em"
            position="relative"
            fontSize={tableSize === 'sm' ? '0.8em' : undefined}
          >
            &nbsp;
            <CellArrowsSort direction={elem?.direction}></CellArrowsSort>
          </Box>
        )}
        {indexDisplay(elemIndex) && hasMultisort && (
          <Box>{indexDisplay(elemIndex)}</Box>
        )}
      </HStack>

      {hasResizer && (
        <div
          onPointerDown={onPointerDown}
          className={resizeHandle}
          onClick={disableOnClick}
        >
          <div className={resizeHandleInner}></div>
        </div>
      )}
    </Th>
  );
};

const indexDisplay = (idx: number | undefined) => {
  if (idx === undefined || idx === -1) {
    return undefined;
  }

  return idx + 1;
};
