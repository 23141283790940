import { useMemo } from 'react';

import { ExpandMap } from './types';

export function getExpandedIds(expanded: ExpandMap): number[] {
  return Object.entries(expanded)
    .filter(([_ticketID, expanded]) => expanded)
    .map(([ticketID, _expanded]) => parseInt(ticketID, 10));
}

export function useExpandedIds(expanded: ExpandMap): number[] {
  return useMemo(() => getExpandedIds(expanded), [expanded]);
}
