import { ReactElement, useCallback } from 'react';
import { Button, Flex, FormControl, VStack } from '@chakra-ui/react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa';

import { FormAddress } from './FormAddress';
import { AddressFormData } from './types';

interface FormWithAddresses {
  addresses: AddressFormData[];
}

export const FormAddressList = (): ReactElement => {
  const { t } = useTranslation('Phonebook');
  const { control } = useFormContext<FormWithAddresses>();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'addresses',
  });

  const addRow = useCallback(() => {
    append({
      $isNew: true,
      addressType: null,
      country: null,
      city: null,
      postalCode: null,
      street: null,
      streetNumber: null,
    });
  }, [append]);

  return (
    <FormControl>
      <VStack spacing="5" alignItems="stretch">
        {fields.map((field, idx) => (
          <AddressRow key={field.id} idx={idx} onRemove={remove} />
        ))}
        <Flex justifyContent="flex-end">
          <Button
            colorScheme="green"
            bg="#34CB7F"
            onClick={addRow}
            verticalAlign="middle"
            rightIcon={<FaPlus />}
          >
            {t('global:add')}
          </Button>
        </Flex>
      </VStack>
    </FormControl>
  );
};

interface AddressRowProps {
  idx: number;
  onRemove: (idx: number) => void;
}

const AddressRow = ({ idx, onRemove }: AddressRowProps): ReactElement => {
  const handleDelete = useCallback(() => {
    onRemove(idx);
  }, [idx, onRemove]);

  return (
    <FormAddress name={`addresses.${idx}`} onDelete={handleDelete} hasType />
  );
};
