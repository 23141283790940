import { ReactElement } from 'react';

export const CheckboxIcon = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 1000 1000"
    style={{ display: 'inline', width: '1em' }}
  >
    <path
      fill="currentColor"
      d="M892.86 0H107.14A107.14 107.14 0 0 0 0 107.14v785.72A107.14 107.14 0 0 0 107.14 1000h785.72A107.14 107.14 0 0 0 1000 892.86V107.14A107.14 107.14 0 0 0 892.86 0Zm0 892.86H107.14V107.14h785.72ZM812.8 353.29 427.56 735.45a26.79 26.79 0 0 1-37.88-.16L187 531a26.79 26.79 0 0 1 .16-37.88l50.71-50.31a26.79 26.79 0 0 1 37.88.16l133.5 134.56 315.52-313a26.79 26.79 0 0 1 37.88.15L813 315.41a26.79 26.79 0 0 1-.16 37.88Z"
    />
  </svg>
);
