import { ReactElement, useCallback } from 'react';
import { Select } from 'chakra-react-select';

import { chakraSelectStyles } from '../Select';
import { colourStyles } from './styles-config';
import { TagOption, TagOptionObject } from './types';
import { useFullOptions } from './use-full-options';
import { useFullTags } from './use-full-tags';
import { useVisibleTags } from './use-visible-tags';

interface Props {
  options: TagOption[];
  value?: string[];
  onChange?: (value: string[]) => void;
}

export const TagInput = ({ value, onChange, options }: Props): ReactElement => {
  const optionValues = useFullOptions(options);
  const visibleOptions = useVisibleTags(optionValues);
  const tagValues = useFullTags(value ?? [], optionValues);
  const handleChange = useCallback(
    (value: readonly TagOptionObject[]) => {
      if (onChange) {
        onChange(value.map((val) => val.value));
      }
    },
    [onChange]
  );

  return (
    <Select
      isMulti
      value={tagValues}
      onChange={handleChange}
      chakraStyles={colourStyles}
      options={visibleOptions}
      menuPortalTarget={document.body}
      styles={chakraSelectStyles}
      selectedOptionColorScheme="brand"
    />
  );
};
