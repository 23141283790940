/* eslint-disable @typescript-eslint/no-empty-function */
import { ReactElement, useCallback } from 'react';
import {
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FaEllipsisV, FaPen, FaPlus, FaTrashAlt } from 'react-icons/fa';

import { Portal } from '@bq/components/Portal';

import { useEventList } from './EventList/EventListContext';
import { EventMenuItem } from './EventMenu';
import { useEventMenus } from './EventMenusContext';
import { CustomEventItem } from './types';

export const CustomEventMenu = (event: CustomEventItem): ReactElement => {
  const { ID, title } = event.data;
  const { t } = useTranslation('Events');
  const items = useEventMenus(event.type);
  const { addCustomEvent, editCustomEvent } = useEventList();

  const edit = useCallback(() => {
    editCustomEvent(ID);
  }, [editCustomEvent, ID]);
  const createEvent = useCallback(() => {
    addCustomEvent({ events: [{ event: { ID, name: title } }] });
  }, [addCustomEvent, ID, title]);
  const remove = () => {};

  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        ml="2"
        size="sm"
        variant="ghost"
        icon={<FaEllipsisV />}
      />
      <Portal>
        <MenuList>
          <MenuItem onClick={edit}>
            <FaPen />
            &nbsp;
            {t('global:edit')}
          </MenuItem>
          <MenuItem onClick={createEvent}>
            <FaPlus />
            &nbsp;
            {t('Events:create_linked_event')}
          </MenuItem>
          {items.map((item) => (
            <EventMenuItem
              key={`${event.type}-${event.id}`}
              {...item}
              event={event}
            />
          ))}
          <MenuItem color="red" onClick={remove}>
            <FaTrashAlt />
            &nbsp;
            {t('global:delete')}
          </MenuItem>
        </MenuList>
      </Portal>
    </Menu>
  );
};
