import { ReactElement, ReactNode } from 'react';
import { IconButton, Menu, MenuButton, MenuDivider } from '@chakra-ui/react';

import { CardViewSort } from 'app/Modules/Ticketing/Assets/components/TicketSplitPane/ListHeader/CardViewSort';
import { LimitSelector } from 'BootQuery/Assets/components/TableMenu/LimitSelector';
import { WithSeparator } from 'BootQuery/Assets/components/WithSeparator';

import { Portal } from '../Portal';
import { OBJ } from '../Table/types';
import { ColumnSelector } from './ColumnSelector';
import { DensitySelector } from './DensitySelector';
import { DisplayModeToggle } from './DisplayModeToggle';
import { RefreshMethodSelector } from './RefreshMethodSelector';
import { ScrollableMenuList } from './ScrollableMenuList';
import {
  ColumnMap,
  DisplayMode,
  DisplayModeOption,
  RefreshMethod,
  TableDensity,
} from './types';

export interface TableMenuProps<T extends OBJ = OBJ> {
  visibleColumns?: {
    columns: { key: string; title?: string }[];
    value: ColumnMap<keyof T>;
    onChange: (visible: ColumnMap<keyof T>) => void;
  };
  displayMode?: {
    options: DisplayModeOption[];
    value: DisplayMode;
    onChange: (value: DisplayMode) => void;
  };
  limit?: {
    value: number;
    onChange: (value: number) => void;
    limits?: number[];
  };
  density?: {
    value: TableDensity;
    onChange: (value: TableDensity) => void;
  };
  refreshMethod?: {
    value: RefreshMethod;
    onChange: (value: RefreshMethod) => void;
  };

  sorting?: {
    displayMode: string;
    isMobile: boolean;
  };

  children?: ReactNode;
  color?: string;
}

export const TableMenu = ({
  visibleColumns,
  displayMode,
  limit,
  density,
  refreshMethod,
  children,
  color,
  sorting,
}: TableMenuProps): ReactElement => (
  <Menu isLazy modifiers={popperModifiers} closeOnSelect={false}>
    <MenuButton
      as={IconButton}
      variant="outline"
      icon={<span className="fas fa-bars"></span>}
      {...(color ? { color } : {})}
    />
    <Portal>
      <ScrollableMenuList>
        <WithSeparator separator={<MenuDivider />}>
          {displayMode &&
            displayMode.options &&
            displayMode.options.length > 0 && (
              <DisplayModeToggle {...displayMode} />
            )}
          {sorting && <CardViewSort {...sorting} />}
          {density && <DensitySelector {...density} />}
          {limit && <LimitSelector {...limit} />}
          {visibleColumns && visibleColumns.columns.length > 0 && (
            <ColumnSelector {...visibleColumns} />
          )}
          {refreshMethod && <RefreshMethodSelector {...refreshMethod} />}
          {children}
        </WithSeparator>
      </ScrollableMenuList>
    </Portal>
  </Menu>
);

const popperModifiers = [
  { name: 'flip', options: { fallbackPlacements: ['top', 'left'] } },
  { name: 'preventOverflow', options: { altAxis: true, padding: 20 } },
];
