import { lazyImport } from '@bq/components/lazy-import';

import 'BootQuery/Assets/js/i18n';

import { ReactRoute } from 'BootQuery/Assets/js/react-routes';

const Sms = lazyImport(() => import('../pages/Sms'), 'Sms');

export const ReactRoutes: ReactRoute[] = [
  { path: '/sms/conversations/', component: Sms },
  { path: '/sms/conversations/:overview', component: Sms },
  { path: '/sms/conversations/:overview/editor/:editor', component: Sms },
  {
    path: '/sms/conversations/:overview/conversation/:conversation',
    component: Sms,
  },
  { path: '/sms/conversations/:overview/:conversation', component: Sms },
];
