import { forwardRef, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTrashAlt } from 'react-icons/fa';

import { Optional } from 'app/assets/js/tsutil';

import { IconButton, IconButtonProps } from './IconButton';

type DeleteButtonProps = Optional<IconButtonProps, 'label' | 'icon'>;

// eslint-disable-next-line react/display-name
const DeleteButtonInner = forwardRef<HTMLDivElement, DeleteButtonProps>(
  (props, ref) => {
    const { t } = useTranslation();

    return (
      <IconButton
        label={t('global:delete')}
        icon={<FaTrashAlt />}
        variant="solid"
        colorScheme="red"
        {...props}
        ref={ref}
      />
    );
  }
);

export const DeleteButton = memo(DeleteButtonInner);
