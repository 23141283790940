import { ReactElement } from 'react';
import { useColorModeValue } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

import {
  Breadcrumb,
  Breadcrumbs,
} from 'BootQuery/Assets/components/Breadcrumbs';
import { FormState } from 'BootQuery/Assets/components/form-state';
import { Layout } from 'BootQuery/Assets/components/Layout/Layout';
import { LayoutBody } from 'BootQuery/Assets/components/Layout/LayoutBody';
import { LayoutFooter } from 'BootQuery/Assets/components/Layout/LayoutFooter';
import { LayoutHeader } from 'BootQuery/Assets/components/Layout/LayoutHeader';

import { PersonBreadcrumbs } from '../PersonBreadcrumbs';
import { PersonFormActions } from './PersonFormActions';
import { PersonFormFields } from './PersonFormFields';

interface EditProps {
  mode: 'edit';
  personID: string | number;
  name: string;
  formState: FormState;
}

interface CreateProps {
  mode: 'create';
  formState: FormState;
}

type Props = CreateProps | EditProps;

const PersonCreateBreadcrumbs = (): ReactElement => {
  const { t } = useTranslation('Phonebook');

  return (
    <Breadcrumbs>
      <Breadcrumb to="/phonebook/people">
        {t('Phonebook:phonebook_title')}
      </Breadcrumb>
      <Breadcrumb to="/phonebook/people">{t('Phonebook:people')}</Breadcrumb>
      <Breadcrumb to="/phonebook/people/create">
        {t('global:create')}
      </Breadcrumb>
    </Breadcrumbs>
  );
};
export const PersonForm = ({ formState, ...props }: Props): ReactElement => {
  const headerColor = useColorModeValue('#F5F5F5', '#393939');

  const cancelLink =
    props.mode === 'create'
      ? '/phonebook/people'
      : `/phonebook/people/${props.personID}`;

  return (
    <Layout h="fullNoNavbar">
      <LayoutHeader
        justifyContent="start"
        borderBottom="2px"
        borderColor="brand.500"
        bg={headerColor}
      >
        {props.mode === 'edit' ? (
          <PersonBreadcrumbs personID={props.personID} name={props.name} edit />
        ) : (
          <PersonCreateBreadcrumbs />
        )}
      </LayoutHeader>
      <LayoutBody h="full">
        <PersonFormFields />
      </LayoutBody>
      <LayoutFooter isSticky bg={headerColor}>
        <PersonFormActions
          state={formState}
          mode={props.mode}
          cancelLink={cancelLink}
        />
      </LayoutFooter>
    </Layout>
  );
};
