import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { FaSearch, FaTimes } from 'react-icons/fa';

import { SearchInterface } from './types';

export const TreeSearchBar = () => {
  const { register, reset } = useFormContext<SearchInterface>();

  return (
    <Box w="full" px={4}>
      <InputGroup w="full" boxSizing="border-box" size="sm">
        <Input borderRadius="5px" mr="2px" {...register('search')} />
        <InputRightElement w="fit-content">
          <IconButton
            aria-label="clear"
            icon={<FaTimes />}
            variant="ghost"
            _hover={{ bg: 'transparent', color: 'gray' }}
            _active={{}}
            size="sm"
            onClick={() => {
              reset();
            }}
          />
          <IconButton
            colorScheme="brand"
            borderTopLeftRadius={0}
            borderBottomLeftRadius={0}
            icon={<FaSearch />}
            aria-label=""
            size="sm"
            borderRadius="5px"
            type="submit"
          />
        </InputRightElement>
      </InputGroup>
    </Box>
  );
};
