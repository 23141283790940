import { ReactElement, useMemo } from 'react';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/react';
import { FaEllipsisV } from 'react-icons/fa';

import { IconButton } from '@bq/components/IconButton';
import { Portal } from '@bq/components/Portal';

import { useEventMenus } from './EventMenusContext';
import { Event, EventMenuItem as IEventMenuItem } from './types';

type EventMenuItemProps<E extends Event> = IEventMenuItem<E> & {
  event: E;
};

export const EventMenuItem = <E extends Event>({
  label,
  color,
  icon,
  event,
  ...props
}: EventMenuItemProps<E>): ReactElement => {
  const handleClick = useMemo(() => {
    if ('onClick' in props) {
      return () => props.onClick(event);
    }

    return undefined;
  }, [props, event]);
  const href = useMemo(() => {
    if ('href' in props) {
      if (typeof props.href === 'string') {
        return props.href;
      }
      if (typeof props.href === 'function') {
        return props.href(event);
      }
    }

    return undefined;
  }, [props, event]);

  return href ? (
    <MenuItem as="a" href={href} color={color}>
      <EventMenuItemContent label={label} icon={icon} />
    </MenuItem>
  ) : (
    <MenuItem as="a" onClick={handleClick} color={color}>
      <EventMenuItemContent label={label} icon={icon} />
    </MenuItem>
  );
};

type EventMenuItemContentProps = Pick<IEventMenuItem, 'icon' | 'label'>;

export const EventMenuItemContent = ({
  label,
  icon: Icon,
}: EventMenuItemContentProps): ReactElement => (
  <>
    {Icon && (
      <>
        <Icon />
        &nbsp;
      </>
    )}
    {label}
  </>
);

export const EventMenuItems = <E extends Event = Event>(
  event: E
): ReactElement => {
  const items = useEventMenus(event.type);

  return (
    <>
      {items.map((item, idx) => (
        <EventMenuItem key={idx} {...item} event={event} />
      ))}
    </>
  );
};

export const EventMenu = <E extends Event = Event>(event: E): ReactElement => {
  const items = useEventMenus(event.type);

  if (items.length === 0) {
    return <></>;
  }

  return (
    <Menu isLazy>
      <MenuButton
        as={IconButton}
        ml="2"
        size="sm"
        variant="ghost"
        icon={<FaEllipsisV />}
      />
      <Portal>
        <MenuList>
          <EventMenuItems {...event} />
        </MenuList>
      </Portal>
    </Menu>
  );
};
