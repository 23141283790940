import { Link } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { CellProps } from '@bq/components/Table';

import { Ticket } from '../../../types';

export const TicketTypeCell = (props: CellProps<Ticket>) => {
  if (props.row.ticketType) {
    const { name, slug } = props.row.ticketType;

    return (
      <Link as={RouterLink} to={`/ticketing/create/${slug}`}>
        {name}
      </Link>
    );
  }

  return <>-</>;
};
