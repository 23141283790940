import {
  Box,
  Divider,
  FormControl,
  FormLabel,
  Grid,
  Input,
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  GeneratedForm,
  useFormDefinition,
} from 'BootQuery/Assets/components/FormEditor';
import { LoadingPage } from 'BootQuery/Assets/components/LoadingPage';

import { FormMails } from '../FormMails';
import { FormPhoneNumbers } from '../FormPhoneNumbers';
import { CompanyInput } from '../PersonForm/CompanyInput';
import { ParentSelect } from './ParentSelect';
import { CompanyDepartmentFormData } from './types';

export const CompanyDepartmentFormFields = () => {
  const { t } = useTranslation('Phonebook');
  const {
    register,
    formState: { errors },
  } = useFormContext<CompanyDepartmentFormData>();
  const customFields = useFormDefinition('Phonebook.companyDepartment');

  return (
    <>
      <FormControl mb="5">
        <FormLabel fontWeight="bold">{t('Phonebook:name')}:</FormLabel>
        <Input
          {...register('name', { required: true })}
          isInvalid={!!errors.name}
          placeholder={t('Phonebook:name')}
        />
      </FormControl>
      <Grid templateColumns="1fr 1fr" gap={6}>
        <FormControl>
          <FormLabel fontWeight="bold">{t('Phonebook:company')}:</FormLabel>
          <CompanyInput />
        </FormControl>
        <FormControl>
          <FormLabel fontWeight="bold">
            {t('Phonebook:parent_department')}:
          </FormLabel>
          <ParentSelect />
        </FormControl>
      </Grid>
      <Divider />
      <Box mb="5">
        <FormMails />
      </Box>
      <Divider />
      <Box mb="5">
        <FormPhoneNumbers />
      </Box>
      <Box mb="5">
        {customFields ? (
          <GeneratedForm fields={customFields} />
        ) : (
          <LoadingPage />
        )}
      </Box>
    </>
  );
};
