import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { QueryClient, useQueryClient } from '@tanstack/react-query';
import { difference, isEqual, uniq } from 'lodash-es';

import { FilterExpression } from 'BootQuery/Assets/components/FilterBar';
import { RefreshMethod } from 'BootQuery/Assets/components/TableMenu';
import { makeListener } from 'BootQuery/Assets/js/socket-event-listener';
import {
  useDebouncedAsyncCallback,
  useDebouncedQuery,
} from 'BootQuery/Assets/js/use-debounced-query';
import { useSocketEvents } from 'BootQuery/Assets/js/use-socket-events';

import { Call } from '../../types/call';
import { CallsResponse, GetCallParams, getCalls } from './api';
import { useCallListEvent } from './call-list-events';

export interface UseCallsProps {
  page?: number;
  limit: number;
  refreshMethod: RefreshMethod;
  name?: string;
  filters?: FilterExpression[];
}

const listener = makeListener('callEvent');
export function useCalls({
  page = 1,
  limit,
  refreshMethod,
  name = 'callList',
  filters,
}: UseCallsProps) {
  const [newRowIds, setNewRowIds] = useState<string[]>([]);
  const queryClient = useQueryClient();

  const queryKey = useMemo(
    () => [name, page, filters, limit],
    [name, page, filters, limit]
  );
  const { status, data, refetch } = useDebouncedQuery({
    queryKey,
    queryFn: () => getCalls({ page, filters, limit }),
    enabled: filters !== undefined,
    duration: 1000,
  });
  const shownCallIds = useMemo(
    () => (data ? (data.data.map((row) => row.callId) ?? []) : null),
    [data]
  );

  const doCheckForNewRowsManual = useCallback(async () => {
    const { data: currentRows } = await getCalls({
      fields: ['callId'],
      page,
      filters,
      limit,
      withContacts: false,
      withCount: false,
    });

    const currentRowIds = currentRows.map((row) => row.callId);

    const newPending = uniq([
      ...newRowIds,
      ...difference(currentRowIds, shownCallIds ?? []),
    ]);

    setNewRowIds(newPending);
  }, [filters, limit, newRowIds, page, shownCallIds]);
  const checkForNewRowsManual = useDebouncedAsyncCallback(
    doCheckForNewRowsManual,
    1000
  );
  const checkForNewRowsAuto = useCallback(async () => {
    refetch();
    // Always clear new rows on auto
    setNewRowIds([]);
  }, [refetch]);
  const checkForNewRows = useCallback(async () => {
    if (refreshMethod === 'auto') {
      checkForNewRowsAuto();
    } else {
      checkForNewRowsManual();
    }
  }, [refreshMethod, checkForNewRowsAuto, checkForNewRowsManual]);
  useEffect(() => {
    setNewRowIds([]);
  }, [refreshMethod, shownCallIds]);

  useSocketEvents(
    listener(),
    ['telephony/callUpdate', 'telephony/callStart'],
    useCallback(
      (call: Call) => {
        setQueryCall(queryClient, queryKey, call.callId, call);

        const prevCall = data?.data.find((row) => row.callId === call.callId);
        const callbackStatusChanged = prevCall
          ? call.callbackStatus !== prevCall.callbackStatus
          : false;

        const shownCallIds = data?.data.map((row) => row.callId) ?? [];
        const rowsChanged =
          !shownCallIds.includes(call.callId) &&
          !newRowIds.includes(call.callId);

        if (rowsChanged || callbackStatusChanged) {
          checkForNewRows();
        }
      },
      [queryClient, queryKey, newRowIds, checkForNewRows, data]
    )
  );
  useSocketEvents(
    listener(),
    'telephony/callEnd',
    useCallback(
      (ev: Pick<Call, 'hangupCause' | 'callId'>) => {
        const endAt = new Date().toISOString();
        setQueryCall(queryClient, queryKey, ev.callId, { ...ev, endAt });
      },
      [queryClient, queryKey]
    )
  );

  useCallListEvent(
    'contactUpdated',
    useCallback(() => {
      refetch();
    }, [refetch])
  );

  return {
    status,
    refetch,
    shownCallIds,
    newRowIds,
    calls: data,
  };
}

interface UseInfiniteCallsProps {
  limit?: number;
  filters: FilterExpression[];
  refreshMethod: RefreshMethod;
}

export function useInfiniteCalls({
  limit = 10,
  filters,
  refreshMethod,
}: UseInfiniteCallsProps) {
  const [data, setData] = useState<CallsResponse | undefined>();
  const [isLoading, setLoading] = useState(false);
  const [newRowIds, setNewRowIds] = useState<string[]>([]);

  const count = data?.meta?.count ?? 0;
  const loadedCount = data?.data.length ?? 0;
  const hasMore = data ? loadedCount < count : false;

  const loadMore = useCallback(async () => {
    setLoading(true);

    const page = Math.floor(loadedCount / limit) + 1;
    const { data: calls } = await getCalls({ limit, page, withCount: false });
    setData((prev) => {
      if (!prev) {
        console.error('Tried to load more calls, but previous data is empty');

        return prev;
      }

      return {
        ...prev,
        data: [...prev.data, ...calls],
      };
    });

    setLoading(false);
  }, [limit, loadedCount]);

  const fetch = useCallback(
    async (params: GetCallParams = {}) => {
      setLoading(true);

      const data = await getCalls({
        limit,
        filters,
        ...(params ?? {}),
      });
      setData(data);

      setLoading(false);
    },
    [limit, filters]
  );

  const refetch = useCallback(() => {
    fetch({ limit: Math.max(limit, loadedCount) });
  }, [fetch, limit, loadedCount]);

  useEffect(() => {
    fetch();
  }, [fetch]);

  const shownCallIds = useMemo(
    () => (data ? (data.data.map((row) => row.callId) ?? []) : null),
    [data]
  );

  const checkForNewRows = useCallback(async () => {
    const { data: currentRows } = await getCalls({
      fields: ['callId'],
      limit: Math.max(limit, loadedCount),
      filters,
    });

    const currentRowIds = currentRows.map((row) => row.callId);

    const newPending = uniq([
      ...newRowIds,
      ...difference(currentRowIds, shownCallIds ?? []),
    ]);
    if (refreshMethod === 'auto') {
      refetch();
      // Always clear new rows on auto
      if (!isEqual(newRowIds, [])) {
        setNewRowIds([]);
      }
    } else {
      setNewRowIds(newPending);
    }
  }, [
    filters,
    limit,
    loadedCount,
    newRowIds,
    refetch,
    refreshMethod,
    shownCallIds,
  ]);
  useEffect(() => {
    setNewRowIds([]);
  }, [refreshMethod, shownCallIds]);

  useSocketEvents(
    listener(),
    ['telephony/callUpdate', 'telephony/callStart'],
    useCallback(
      (call: Call) => {
        setInfiniteCall(setData, call.callId, call);
        const shownCallIds = data?.data.map((row) => row.callId) ?? [];
        if (
          !shownCallIds.includes(call.callId) &&
          !newRowIds.includes(call.callId)
        ) {
          checkForNewRows();
        }
      },
      [newRowIds, checkForNewRows, data]
    )
  );
  useSocketEvents(
    listener(),
    'telephony/callEnd',
    useCallback((ev: Pick<Call, 'hangupCause' | 'callId'>) => {
      const endAt = new Date().toISOString();
      setInfiniteCall(setData, ev.callId, { ...ev, endAt });
    }, [])
  );

  return {
    calls: data?.data,
    refetch,
    hasMore,
    loadMore,
    isLoading,
    newRows: newRowIds.length,
  };
}

type CallsSetter = Dispatch<SetStateAction<CallsResponse | undefined>>;
function setInfiniteCall(
  setState: CallsSetter,
  callId: string,
  changes: Partial<Call>
): void {
  setState((prev) => {
    if (!prev) {
      return prev;
    }

    return {
      ...prev,
      data: prev.data.map((call) => {
        if (call.callId === callId) {
          return { ...call, ...changes };
        }

        return call;
      }),
    };
  });
}

export function setQueryCall(
  queryClient: QueryClient,
  queryKey: unknown[],
  callId: string,
  changed: Partial<Call>
): void {
  const existing = queryClient.getQueryData<CallsResponse>(queryKey);
  const containsUpdatedCall = (existing?.data ?? []).some(
    (row) => row.callId === callId
  );

  // No row to update, don't do anything to avoid a re-render
  if (!containsUpdatedCall) {
    return;
  }

  queryClient.setQueriesData<CallsResponse>({ queryKey }, (prev) => {
    if (!prev) {
      return { data: [], meta: { count: 0 } };
    }
    const { data, meta } = prev;

    return {
      meta,
      data: data.map((call) => {
        if (call.callId === callId) {
          return { ...call, ...changed };
        }

        return call;
      }),
    };
  });
}
