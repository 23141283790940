import { GetListingParams } from 'BootQuery/Assets/js/globalTypes';

import {
  CompanyLocationsResponse,
  getCompanyLocations,
  getCompanyLocationsAll,
} from '../api';

export function getLocations(
  companyID: number,
  params: Partial<GetListingParams> = {}
): Promise<CompanyLocationsResponse> {
  return getCompanyLocations(companyID, {
    fields: [
      'ID',
      'name',
      ['phoneNumbers', ['phoneNumber.phoneNumberE164', 'numberType.ID']],
      'emails.email.email',
      'data',
      ['company', ['ID', 'name']],
    ],
    ...params,
  });
}

export function getAllCompanyLocations(
  params: Partial<GetListingParams> = {}
): Promise<CompanyLocationsResponse> {
  return getCompanyLocationsAll({
    fields: [
      'ID',
      'name',
      ['phoneNumbers', ['phoneNumber.phoneNumberE164', 'numberType.ID']],
      'emails.email.email',
      'data',
      ['company', ['ID', 'name']],
    ],
    ...params,
  });
}
