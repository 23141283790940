import { ChakraStylesConfig } from 'chakra-react-select';
import tinycolor from 'tinycolor2';

import { selectStyles } from '../Select';
import { tagTextColor } from './tag-text-color';
import { TagOptionObject } from './types';

export const colourStyles: ChakraStylesConfig<TagOptionObject, true> = {
  ...selectStyles,
  option: (styles, { data, isDisabled }) => {
    const color = tinycolor(data.color);

    return {
      ...styles,
      backgroundColor: color.setAlpha(0.4).toHex8String(),
      cursor: isDisabled ? 'not-allowed' : 'default',
      padding: '4px 8px',
      marginBottom: '4px',
      ':last-child': {
        marginBottom: 0,
      },
      ':active': {
        backgroundColor: color.setAlpha(0.5).toHex8String(),
      },
    };
  },
  multiValue: (styles, { data }) => ({
    ...styles,
    borderRadius: '4px',
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: tagTextColor(data.color),
    backgroundColor: data.color,
    borderRadius: '4px',
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    fontWeight: 'bold',
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    borderRadius: '4px',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    color: '#555',
    backgroundColor: tinycolor(data.color).setAlpha(0.2).toHex8String(),
    ':hover': {
      backgroundColor: tinycolor(data.color).setAlpha(0.4).toHex8String(),
    },
  }),
};
