import { Api } from 'BootQuery/Assets/js/api';

import { loadFullContacts } from '../../HopsOperator/HopsContacts/api';
import {
  ApiContactCategory,
  ContactCategory,
  UserGroup,
} from '../../HopsOperator/HopsContacts/types';

export async function getGroups(): Promise<UserGroup[]> {
  const { data: groups } = await Api.get<UserGroup[]>('/api/groups');

  return groups.map(({ ID, name }) => ({ ID, name }));
}

export async function getCategories(): Promise<ApiContactCategory[]> {
  const { data } = await Api.get<ApiContactCategory[]>(
    '/api/hops/allContactCategories'
  );

  return data;
}

type CategoriesResponse = Promise<ContactCategory[]>;
export async function getSettingsCategories(): CategoriesResponse {
  const categories = await getCategories();

  return Promise.all(categories.map(loadFullCategory));
}

export async function loadFullCategory(
  category: ApiContactCategory
): Promise<ContactCategory> {
  return {
    id: category.id,
    name: category.name,
    color: category.color ?? null,
    contacts: await loadFullContacts(category.contacts),
    groups: category.groups,
  };
}
