import { PartialOnly } from 'BootQuery/Assets/components/type-util';

import { FilterType, FilterTypes, FilterValue, ToFilterRet } from '../../types';
import { ComboboxFilterInput } from './ComboboxFilterInput';
import { ComboboxFilterTag } from './ComboboxFilterTag';
import { getValueForItem } from './items';
import { ComboboxFilterProps, ValBase } from './types';

const comboboxFilterDefaults = {
  tagComponent: ComboboxFilterTag,
  inputComponent: ComboboxFilterInput,
};

export type ComboboxFilterType<
  I extends ValBase,
  V extends ValBase,
> = FilterType<V | string | null, ComboboxFilterProps<I, V>>;

export type WrappedComboboxFilter<I extends ValBase, V extends ValBase> = Omit<
  ComboboxFilterType<I, V>,
  'toFilter'
> & {
  toFilter: (
    val: FilterValue<I | string | null>,
    filterTypes: FilterTypes
  ) => ToFilterRet;
};

export type ComboboxFilterDef<
  I extends ValBase,
  V extends ValBase,
> = PartialOnly<WrappedComboboxFilter<I, V>, 'tagComponent' | 'inputComponent'>;

export function makeComboboxFilter<I extends ValBase, V extends ValBase>(
  filterDef: ComboboxFilterDef<I, V>
): FilterType {
  const fullFilter: ComboboxFilterType<I, V> = {
    ...comboboxFilterDefaults,
    ...filterDef,
    toFilter: (filterVal, filterTypes) => {
      if (!filterDef.extraProps?.valueToItem) {
        throw new Error('Missing valueToItem on combobox filter definition');
      }
      if (!filterDef.extraProps?.cacheKey) {
        throw new Error('Missing cacheKey on combobox filter definition');
      }

      const toFilter = (item: I | string | null) => {
        return filterDef.toFilter({ ...filterVal, value: item }, filterTypes);
      };

      const item = getValueForItem(
        filterVal.value,
        filterDef.extraProps.valueToItem,
        filterDef.extraProps.cacheKey
      );
      if (item instanceof Promise) {
        return item.then(toFilter);
      }

      return toFilter(item);
    },
  };

  return fullFilter as unknown as FilterType;
}
