import { ReactElement } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';

import { baseStickyProps } from './sticky-props';

interface CardFooterProps extends BoxProps {
  isSticky?: boolean;
}

const stickBottomProps: BoxProps = {
  ...baseStickyProps,
  bottom: 0,
};

function stickyProps(isSticky = false) {
  return isSticky ? stickBottomProps : {};
}

export const CardFooter = ({
  isSticky,
  ...boxProps
}: CardFooterProps): ReactElement => (
  <Box
    alignItems="center"
    justifyContent="space-between"
    px="6"
    py="4"
    borderTopWidth="1px"
    {...stickyProps(isSticky)}
    {...boxProps}
  />
);
