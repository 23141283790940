import { PropsWithChildren, ReactElement, useState } from 'react';
import { endOfMonth, startOfMonth } from 'date-fns';

import { FilterValue } from '@bq/components/FilterBar';

import { ticketFilterTypes } from '../../filter-types';
import { useGroupBys } from '../settings';
import { ReportSettings } from '../types';
import { ReportContext } from './ReportContext';

export const ReportContextProvider = ({
  children,
}: PropsWithChildren): ReactElement => {
  const now = new Date();
  const [settings, setSettings] = useState<ReportSettings>({
    datePeriod: {
      from: startOfMonth(now),
      to: endOfMonth(now),
    },
    step: 'month',
  });
  const [filterVal, setFilterVal] = useState<FilterValue[]>([]);
  const [groupBy, setGroupBy] = useState<string[]>([]);

  // TODO: figure out how to handle custom fields
  const filterTypes = ticketFilterTypes;
  const allGroupBy = useGroupBys();

  return (
    <ReportContext.Provider
      value={{
        settings,
        setSettings,
        filterVal,
        filterTypes,
        setFilterVal,
        groupBy,
        setGroupBy,
        allGroupBy,
      }}
    >
      {children}
    </ReportContext.Provider>
  );
};
