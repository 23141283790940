import { IconType } from 'react-icons';
import { FaBuilding, FaIdCard, FaMapMarkerAlt } from 'react-icons/fa';

import { TicketContactType } from './type';

export function iconForTicketingContactType(type: TicketContactType): IconType {
  switch (type) {
    case 'company':
      return FaBuilding;
    case 'companyLocation':
      return FaMapMarkerAlt;
    default:
      return FaIdCard;
  }
}
