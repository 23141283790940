import { useTranslation } from 'react-i18next';

import { CallState } from '../../types/call';
import { DeviceStatus } from '../../types/device';
import { SessionState, SoftphoneState } from './types';

export const softphoneStateColors = {
  connecting: 'yellow.400',
  inactive: 'gray.400',
  ready: 'green.400',
  registering: 'yellow.400',
};
export function useSoftphoneStateLabels(): Record<SoftphoneState, string> {
  const { t } = useTranslation('Telephony');

  return {
    connecting: t('Telephony:softphone_state.connecting'),
    inactive: t('Telephony:softphone_state.inactive'),
    ready: t('Telephony:softphone_state.ready'),
    registering: t('Telephony:softphone_state.registering'),
    usedElsewhere: t('Telephony:softphone_state.used_elsewhere'),
  };
}
export const softphoneSessionStateColors: Record<SessionState, string> = {
  dialing: 'blue.400',
  'dialing+ringback': 'blue.400',
  ringing: 'orange.400',
  inCall: 'green.400',
  onHold: 'yellow.400',
  ended: 'red.400',
};
export function useSoftphoneSessionStateLabels(): Record<SessionState, string> {
  const { t } = useTranslation('Telephony');

  return {
    dialing: t('Telephony:call_status.dialing'),
    'dialing+ringback': t('Telephony:call_status.dialing'),
    ringing: t('Telephony:call_status.ringing'),
    inCall: t('Telephony:call_status.in_call'),
    onHold: t('Telephony:call_status.on_hold'),
    ended: t('Telephony:call_status.end'),
  };
}

export const pbxCallStateColors: Record<CallState, string> = {
  dialing: 'blue.400',
  ringing: 'orange.400',
  progress: 'orange.400',
  up: 'green.400',
  hold: 'yellow.400',
  terminated: 'red.400',
  queued: 'white',
  idle: 'white',
  routing: 'blue.400',
};
export function usePbxCallStateLabels(): Record<CallState, string> {
  const { t } = useTranslation('Telephony');

  return {
    dialing: t('Telephony:call_status.dialing'),
    ringing: t('Telephony:call_status.ringing'),
    progress: t('Telephony:call_status.ringing'),
    up: t('Telephony:call_status.in_call'),
    hold: t('Telephony:call_status.on_hold'),
    terminated: t('Telephony:call_status.end'),
    queued: t('Telephony:call_status.queued'),
    idle: 'idle',
    routing: t('Telephony:call_status.dialing'),
  };
}

export function deviceStatusColor(state: DeviceStatus | null): string {
  if (!state) {
    return 'gray.400';
  }

  return state === 'online' ? 'green.400' : 'red.400';
}
export function deviceStatusLabel(state: DeviceStatus | null): string {
  if (!state) {
    return 'Unknown';
  }

  return state === 'online' ? 'Online' : 'Offline';
}
