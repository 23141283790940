import { ReactElement } from 'react';

import {
  IconButton,
  IconButtonProps,
} from 'BootQuery/Assets/components/IconButton';

export const ActionButton = ({
  size,
  ...btnProps
}: IconButtonProps): ReactElement => (
  <IconButton
    variant="ghost"
    size={size ?? 'sm'}
    height={size === undefined ? '6' : undefined}
    bg="brand.backgroundDark"
    color="brand.fontStaticLight"
    {...btnProps}
  />
);
